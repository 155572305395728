import styled from 'styled-components';

type Props = {
  customBackgroundColor: string,
  customBorderColor: string,
  customTextColor: string
}

export default styled.button<Props>`
background: ${(props) => props.customBackgroundColor};
color: ${(props) => props.customTextColor};
border: 1px solid  ${(props) => props.customBorderColor};
border-radius: 5px;
font-family: "Inter";
font-style: normal;
font-weight: 500;
font-size: 16px;
line-height: 16px;
padding: 1px 17px;
height: 40px;
margin: 0px 5px;
position: relative;
:hover {
  box-shadow: 1px 1px 2px 1px #dbdbdb;
    transform: scale(1.01);
    -webkit-transform: scale(1.01);
    -moz-transform: scale(1.01);
    -ms-transform: scale(1.01);
    -o-transform: scale(1.01);
}

a{
  text-decoration:none;
  color: ${(props) => props.customTextColor};
}
@media (-webkit-min-device-pixel-ratio: 1) and (max-width: 778px){
  margin: 8px 5px;
}
@media (-webkit-min-device-pixel-ratio: 1) and (max-width: 300px){
  padding: 1px 5px;
}
`