import React from 'react';
import { Container, Row } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import DashboardNavbar from '../CommonCompoment/DashboardNavbar/DashboardNavbar';
import { GOOGLE_FORM, GOOGLE_FEEDBACK } from "../../config";
import support from "../../assets/content/support.png";

const Support = () => {
    
    return (
        <>
            <DashboardNavbar />
            <section className='contactPage'>
                <div className='imageContent'>
                    <Container>
                        <Row>
                            <div className='lineBox'>
                                <div className="leftImage">
                                    <img src={support} alt='support' />
                                </div>
                                <div className="rightContent">
                                    <h1>Support</h1>
                                    <p>Our support team is here during business hours to help you quickly. We aim to offer great service and make your experience with our products/services smooth. Feel free to contact us anytime for assistance!</p>
                                   <div className='d-flex justify-content-center'>
                                   <Link className='googleForm' target="_blank" to={`${GOOGLE_FORM}`}>Let Us Know Your Issue</Link>
                                    <Link className='googleForm' target="_blank" to={`${GOOGLE_FEEDBACK}`}>Give Us Feedback</Link>
                                   </div>
                                </div>
                            </div>
                        </Row>
                    </Container>
                </div>
            </section>
        </>
    )
}

export default Support