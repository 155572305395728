import React from 'react'
import { Col, Modal, Row } from 'react-bootstrap';
interface SaveTemplateProps {
    templateShow: boolean;
    templateError: string;
    requestTemplateId: string;
    setTemplateError: React.Dispatch<React.SetStateAction<string>>;
    setTemplateShow: any;
    closeTemplateModal: () => void;
    updateTemplateModal: () => void;
    saveTemplateFunction: (event: React.ChangeEvent<HTMLInputElement>) => void;
}
const SaveTemplate = ({ setTemplateShow, closeTemplateModal,requestTemplateId, updateTemplateModal, setTemplateError, saveTemplateFunction, templateError, templateShow }: SaveTemplateProps) => {
    return (
        <>
            <Modal show={templateShow} className="loadModal settingModalBody" size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered>
                <Modal.Body >
                    <div className="requestModal">
                        <Row>
                            <Col md={12}>
                                <div className="saveTemplateHeading">
                                    <h1>Save as Template</h1>
                                </div>
                            </Col>
                            <Col md={12}>
                                <div className="requestInputForm">
                                    <label htmlFor="requestTitle">Template Name</label>
                                    <input type="text" placeholder='Template Name (eg. Employee List)' onChange={saveTemplateFunction} />
                                    {templateError && templateError.length ? <p className='errMsg'>{templateError}</p> : ''}
                                </div>
                                {requestTemplateId && requestTemplateId ? <>
                                    <div className="templateUpdate mt-4">
                                    <span>OR</span>
                                </div>
                                <div className='templateUpdateBtn'>
                                    <button onClick={updateTemplateModal}>Update Template</button>
                                </div></>:""}
                                
                            </Col>
                        </Row>
                    </div>

                    <div className="footerModalButton">
                        <button className='backDashboard' onClick={() => { setTemplateShow(false); setTemplateError("") }}>Back</button>
                        <button className='nextButton' onClick={closeTemplateModal}>Save</button>
                    </div>
                </Modal.Body>

            </Modal>
        </>
    )
}

export default SaveTemplate