import React, { useState, useEffect } from 'react'
import { Modal } from 'react-bootstrap';
import { updateUser } from '../../api/user';
import { showNotifications } from '../../helpers/toaster';
import { ToastContainer } from 'react-toastify';
import WarningModal from '../CommonCompoment/WarningModal/WarningModal';
import { deleteSystemConfig } from '../../api/systemConfig';
import PhoneInput from 'react-phone-input-2';
import { useLocation } from 'react-router-dom';

interface EditUserProps {
  handleEditClose: () => void;
  editShow: boolean;
  setEditShow: (type: boolean) => void;
  warningShow: boolean;
  setWarningShow: (type: boolean) => void;
  contactInfo?: any;
}
const EditUser = ({ editShow, setEditShow, contactInfo, handleEditClose, warningShow, setWarningShow }: EditUserProps) => {
  const [telephone, setTelephone] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [role, setRole] = useState("");
  const [email, setEmail] = useState("");
  const [employeeId, setEmployeeId] = useState("");
  const handleWarningClose = () => setWarningShow(false);
  const location = useLocation();
  const { pathname } = location;

  useEffect(() => {
    setFirstName(contactInfo && contactInfo.firstName);
    setLastName(contactInfo && contactInfo.lastName);
    setTelephone(contactInfo && contactInfo.telephone);
    setEmail(contactInfo && contactInfo.email);
    setEmployeeId(contactInfo && contactInfo.userId);
    setRole(contactInfo && contactInfo.userRole);
  }, [contactInfo]);

  const updateUserInfo = (userEmail: string) => {
    let user = {
      "firstName": firstName,
      "lastName": lastName,
      "telephone": telephone
    }
    updateUser(userEmail, user).then((data) => {
      if (data.statusCode !== 200) {
        showNotifications("error", data.message);
      }
      else {
        setEditShow(false);
        showNotifications('success', data.message)
      }
    });
  }
  const deleteWarning = () => {
    setWarningShow(true);
    setEditShow(false);
  }

  const deleteEmployee = () => {
    deleteSystemConfig(employeeId).then((data) => {
      if (data.statusCode !== 200) {
        showNotifications("error", data.message);
      }
      else {
        setEditShow(false);
        setWarningShow(false);
        showNotifications('success', data.message)
      }
    });
  }

  const headingText = "This will delete all your user-related data.";
  const paragraphText = "**You won't be able to recover any information.";
  const handlePhoneChange = (value: string) => {
    setTelephone(value);
  };

  return (
    <>
      <ToastContainer />
      <Modal show={editShow} onHide={handleEditClose}>
        <div className="addContactForm">
          <h1>Update User Information</h1>
          <div style={{ padding: "0px 30px" }}>
            <div className="registrationInput">
              <label>First Name</label>
              <input type="text" id="firstName" value={firstName} onChange={(e) => setFirstName(e.target.value)} placeholder='First Name' />
            </div>
            <div className="registrationInput">
              <label>Last Name</label>
              <input type="text" id="lastName" value={lastName} onChange={(e) => setLastName(e.target.value)} placeholder='Last Name' />
            </div>
            <div className="registrationInput">
              <label>Phone</label>
              <PhoneInput country={'us'} disableCountryCode={false} value={telephone} onChange={(value) => handlePhoneChange(value)} />
            </div>
          </div>
          <div className="editContactBtn mt-4">
            <button type='button' className='back' onClick={handleEditClose}>Back</button>
            {pathname && pathname === "/admin" ?
              <>{role === "TENANT_ADMIN" ? "" : <button type='button' className='delete' onClick={deleteWarning}>Delete</button>}</>
              : ""}
            <button type='submit' className='save' onClick={() => updateUserInfo(email)}>Update</button>
          </div>
        </div>
      </Modal>
      <WarningModal headingText={headingText} paragraphText={paragraphText} deleteEmployee={deleteEmployee} handleWarningClose={handleWarningClose} warningShow={warningShow} />
    </>
  )
}

export default EditUser