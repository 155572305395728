import React, { useState } from 'react'
import { Modal, Form } from 'react-bootstrap'
import { useForm } from 'react-hook-form';
import { createUser } from '../../api/user';
import { useNavigate } from 'react-router-dom';
import { showNotifications } from '../../helpers/toaster';
import { ToastContainer } from 'react-toastify';
import { handleForbiddenError, removeCookie } from '../CommonInterface/CommonFunction';

interface AddUserProps {
  handleClose: () => void;
  show: boolean;
  count: number;
  setCount: (value: number) => void;
}
const AddUser = ({ count, setCount, show, handleClose }: AddUserProps) => {

  const navigate = useNavigate();
  const [selectedUserRole, setSelectedUserRole] = useState<string>('EMPLOYEE');

  // Handle the onChange event
  const handleUserRoleChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    setSelectedUserRole(event.target.value);
  };
  const {
    register,
    handleSubmit,
    setValue,
  } = useForm();

  let onSubmit = (user: any) => {
    let userInfo = {
      "firstName": user.firstName,
      "lastName": user.lastName,
      "email": user.email,
      "userRole": selectedUserRole,
    }
    createUser(userInfo).then((data) => {
      if (data.statusCode !== 201) {
        if (data.statusCode === 403) {
          handleForbiddenError(navigate);
        }
        else {
          showNotifications("error", data.message);
        }
      }
      else {
        showNotifications('success', data.message)
        setCount(count + 1)
        handleClose();
        setValue("firstName", "", { shouldValidate: false });
        setValue("lastName", "", { shouldValidate: false });
        setValue("email", "", { shouldValidate: false });
      }
    })
  }

  return (
    <>
      <ToastContainer />
      <Modal show={show} onHide={handleClose}>
        <div className="addContactForm">
          <h1>Add User</h1>
          <form onSubmit={handleSubmit(onSubmit)}>
            <div style={{ padding: "0px 30px" }}>
              <div className="registrationInput">
                <label>First Name</label>
                <input type="text" id="firstName" placeholder='First Name' {...register("firstName", { required: true })} />
              </div>
              <div className="registrationInput">
                <label>Last Name</label>
                <input type="text" id="lastName" placeholder='Last Name' {...register("lastName", { required: true })} />
              </div>
              <div className="registrationInput">
                <label>E-mail Address</label>
                <input type="email" id="email" placeholder='Email Address'  {...register("email", { required: true })} />
              </div>
              <div className="registrationInput">
                <label>User Type</label>
                <Form.Select className='userRoleSelect' aria-label="Default select example" name="userRole" value={selectedUserRole}>
                  <option className='userOption' value="EMPLOYEE" disabled>Employee</option>
                </Form.Select>
              </div>
            </div>
            <div className="addContactBtn">
              <div className="addContact">
                <button type='button' className='back' onClick={handleClose}>Back</button>
              </div>
              <div className="addContact">
                <button type='submit' className='save'>Add</button>
              </div>
            </div>
          </form>
        </div>

      </Modal>
    </>
  )
}

export default AddUser