import React from 'react'
import { Container, Row } from 'react-bootstrap';
import './Footer.css'
// google font awesome
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faLinkedin, faTwitter } from '@fortawesome/free-brands-svg-icons';
import { Link } from 'react-router-dom';
import logo from "../../../assets/logo/clearLogo.png";
import { DOCOTEAM_LINKEDIN_URL, DOCOTEAM_TWITTER_URL } from '../../../config';

const Footer = () => {
    const scrollToTop = () => {
        window.scrollTo(0, 0);
    };
    return (
        <>
            <section className="footer">
                <Container>
                    <Row>
                        <div className='footerLine'>
                            <div className="footerLogo">
                                <img src={logo} alt="" width="180px" />
                                <p>© 2024 Docoteam, Inc. <br />All rights reserved.</p>
                                <Link className='email' to={`mailto:hello@docoteam.com`}>hello@docoteam.com</Link>
                            </div>
                            <div className="footerLink">
                                <h1>Why Docoteam?</h1>
                                <Link to="/why-docoteam#docoteam-vs-email">Docoteam vs. Email</Link>
                                <Link to="/why-docoteam#data-rooms">Data Rooms</Link>
                                <Link to="/why-docoteam#how-to-transition">How to Transition</Link>
                            </div>
                            <div className="footerLink">
                                <h1>Security</h1>
                                <Link to="/security#data-encryption">Data Encryption</Link>
                                <Link to="/security#data-tracking">Data Tracking</Link>
                                <Link to="/security#key-feature">Key Feature</Link>
                            </div>
                            <div className="footerLink">
                                <h1>Company</h1>
                                <Link to="/privacy-policy" onClick={scrollToTop}>Privacy Policy</Link>
                                <Link to="/terms-of-service" onClick={scrollToTop}>Terms of Service</Link>
                                <Link to="/contact" onClick={scrollToTop}>Contact us</Link>
                            </div>
                            <div className="footerLink socialLinkButton">
                                <h1>Connect</h1>
                                <Link to={`${DOCOTEAM_LINKEDIN_URL}`} target='_blank'><FontAwesomeIcon icon={faLinkedin} />Linkedin</Link>
                                <Link to={`${DOCOTEAM_TWITTER_URL}`} target='_blank'><FontAwesomeIcon icon={faTwitter} />X</Link>
                            </div>
                        </div>
                    </Row>
                </Container>
            </section>
        </>
    )
}

export default Footer