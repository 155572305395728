import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import DashboardNavbar from '../CommonCompoment/DashboardNavbar/DashboardNavbar'
import { Col, Container, Row, Toast, ToastContainer } from 'react-bootstrap'
import DatePicker from 'react-datepicker';
import './Sent.css';
import UploadFiles from './UploadFiles';
import { RecipientUser, SentFileInterface, SentUPloadInterface, UserInterface, UserListInterface } from '../CommonInterface/CommonInterface';
import { myContacts } from '../../api/contact';
import Loader from '../CommonCompoment/Loader/Loader';
import { CustomDateFormatInput, CustomDatePickerInput, isValidDate, multiplyBySixAndShowSeries, removeCookie } from '../CommonInterface/CommonFunction';
import { getAllUser } from '../../api/user';
import AddEmployee from '../CreateRequest/AddEmployee';
import { getSingleSentFile } from '../../api/sent';
import { v4 as uuidv4 } from 'uuid';
import moment from 'moment';

const Sent = () => {
    const navigate = useNavigate();
    const [authValue, setAuthValue] = useState(false)
    const [titleError, setTitleError] = useState("")
    const [sentFileError, setSentFileError] = useState("")
    const [dueDate, setDueDate] = useState<any>("");
    const [shreddingDate, setShreddingDate] = useState<any>(new Date());
    const [dateShredding, setDateShredding] = useState("");
    const [title, setTitle] = useState("");
    const [customMshHandle, setCustomMshHandle] = useState("");
    const [recipientsValue, setRecipientsValue] = useState<RecipientUser[]>([]);
    const [userList, setUserList] = useState<UserListInterface[]>([]);
    const [userIds, setUserIds] = useState<string[]>([]);
    const [spinLoading, setSpinLoading] = useState<boolean>(false);
    const [senderTypeValue, setSenderTypeValue] = useState<string>("individual");
    const [dueDateError, setDueDateError] = useState("")
    // upload item
    const [sentFile, setSentFile] = useState<SentFileInterface[]>([]);
    const [sentUploadURl, setSentUploadURl] = useState<SentUPloadInterface[]>([]);
    const [downloadTooltip, setDownloadTooltip] = useState(false);
    // contact
    const [contacts, setContacts] = useState<UserInterface[]>([]);
    // pagination state
    const [totalValue, setTotalValue] = useState<any>();
    const [limitValue, setLimitValue] = useState<any>();
    const [page, setPage] = useState(1);
    const [limit, setLimit] = useState<number>(6);
    const [pageValue, setPageValue] = useState<number>();
    const [resultLength, setResultLength] = useState<number>(0);
    const pageResult = multiplyBySixAndShowSeries(totalValue);
    const sendObj = JSON.parse(localStorage.getItem("send") || '{}');
    const { id }: any = useParams();
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);


    // due date
    const dueDateChange = (date: any) => {
        const selectedDate = new Date(date);
        selectedDate.setHours(0, 0, 0, 0);
        setDueDate(selectedDate)
    }

    // Shredding Date
    const shreddingDateChange = (date: any) => {
        setShreddingDate(date);
        setDateShredding(date)
    }

    // two factor
    const authClick = () => {
        setAuthValue(!authValue)
    }

    const titleHandle = (event: React.ChangeEvent<HTMLInputElement>) => {
        setTitleError("")
        setTitle(event.target.value)
    }
    const customMsgHandle = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
        setCustomMshHandle(event.target.value)
    }

    const backToDashboard = () => {
        return navigate('/dashboard')
    }


 
    const nextRecipient = () => {

        window.scrollTo({
            top: 0,
            behavior: "smooth"
        });

        if (sentFile.length === 0) {
            setSentFileError("Please insert one item")
        }
        else {
            setSentFileError("")
        }
        if (title.length === 0) {
            setTitleError("Please insert title name")
        }
        else {
            setTitleError("")
        }

        const dateFuture = moment(dueDate).format('YYYY-MM-DD')
        const dueDateSave = isValidDate(dateFuture);


        let sendFiles: any = {
            "files": sentFile,
            "settings": {
                "title": title,
                "customEmailMsg": customMshHandle,
                ...(dueDate && { "dueDate": dueDate }),
                "isMfaRequired": authValue,
                "shreddingDate": dateShredding
            },
            "sentUploadURl": sentUploadURl
        }
        if (sendObj.recipients) {
            sendFiles.recipients = sendObj.recipients;
        }
        if (recipientsValue.length) {
            let recipientsJson = {
                "users": recipientsValue,
                "senderType": senderTypeValue
            }
            sendFiles.recipients = recipientsJson;
        }
        if (sendObj.sendId) {
            sendFiles.sendId = sendObj.sendId;
        }
        if (userIds) {
            sendFiles.associatedUserIds = userIds;
        }
        if (dueDate) {
            if (dueDateSave === false) {
                setDueDateError("Please insert future date")
            }
            else {
                setDueDateError("")
            }
        }

        if (title.length > 0 && sentFile.length > 0 && dueDateSave !== false) {
            setTitleError("")
            setSentFileError("")
            localStorage.setItem("send", JSON.stringify(sendFiles));

            if (id) {
                return navigate(`/send-contacts/${id}`);
            }
            else {
                return navigate("/send-contacts");
            }
        }
       
    }


    //get my contacts 
    let getMyContacts = () => {
        myContacts(100, 1, "", "").then(data => {
            if (data.statusCode !== 200) {
                if (data.statusCode === 401) {
                    removeCookie('docoteam');
                    return navigate("/login");
                }
                else {
                    setContacts([])
                }
            }
            else {
                setContacts(data.body.contacts);
            }
        })
    }


    //load my contacts on load
    useEffect(() => {
        getMyContacts();
        if (id) {
            getSingleSentFile(id).then(data => {
                if (Object.keys(sendObj).length !== 0) {
                    setTitle(sendObj.settings.title);
                    setCustomMshHandle(sendObj.settings.customEmailMsg);
                    const selectedDate = new Date(sendObj.settings.dueDate);
                    selectedDate.setHours(0, 0, 0, 0);
                    if (sendObj.settings.dueDate) {
                        setDueDate(selectedDate)
                    }
                    setSentFile(sendObj.files);
                    setSentUploadURl(sendObj.sentUploadURl);
                }
                else {
                    // setting
                    setTitle(data.body.settings.title);
                    setCustomMshHandle(data.body.settings.customEmailMsg);
                    setAuthValue(data.body.settings.isMfaRequired);
                    const selectedDate = new Date(data.body.settings.dueDate);
                    selectedDate.setHours(0, 0, 0, 0);
                    if (data.body.settings.dueDate) {
                        setDueDate(selectedDate)
                    }
                    // //   files
                    const updatedFiles = data.body.files.map((file: any) => ({
                        ...file,
                        fileUploadLockId: uuidv4()
                    }));
                    setSentFile(updatedFiles);
                    // recipients
                    setRecipientsValue(data.body.recipients.users);
                    // sender type
                    setSenderTypeValue(data.body.recipients.senderType);
                    // associatedUserIds
                    setUserIds(data.body.associatedUserIds);
                }

            })
        }
        else {
            if (Object.keys(sendObj).length !== 0) {
                setTitle(sendObj.settings?.title);
                setCustomMshHandle(sendObj.settings?.customEmailMsg);
                const selectedDate = new Date(sendObj.settings?.dueDate);
                selectedDate.setHours(0, 0, 0, 0);
                if (sendObj.settings?.dueDate) {
                    setDueDate(selectedDate)
                }
                setSentFile(sendObj.files);
                setSentUploadURl(sendObj.sentUploadURl);
                setAuthValue(sendObj.settings?.isMfaRequired);
                if (sendObj.associatedUserIds) {
                    setUserIds(sendObj.associatedUserIds);
                }
            }
        }

    }, [])

    const showTooltip = () => {
        return (
            <>
                <ToastContainer position="top-end" className="p-3">
                    <Toast onClose={() => setDownloadTooltip(false)} show={downloadTooltip} delay={2000} autohide>
                        <div className="bg-danger text-white">
                            <Toast.Body>Please enter valid phone number</Toast.Body>
                        </div>
                    </Toast>
                </ToastContainer>
            </>
        );
    };


    const addEmployee = () => {
        setShow(true);
        getAllUser(limit, page).then((data) => {
            if (data.statusCode !== 200) {
                console.log('error', data);
            }
            else {
                setUserList(data.body.users);
                setResultLength(data.body.users.length);
                setTotalValue(data.body.total);
                setLimitValue(data.body.limit);
                setPageValue(data.body.page);
            }
        });
    }

    
    const handleKeyPress = (event: KeyboardEvent) => {
        if (event.key === 'Enter') {
           nextRecipient()
        }
    };

    useEffect(() => {
        document.body.addEventListener('keypress', handleKeyPress);
        return () => {
            document.body.removeEventListener('keypress', handleKeyPress);
        };
    }, [title,sentFile,dueDate,customMshHandle,authValue,dateShredding,sentUploadURl]);

    return (
        <>
            <DashboardNavbar />
            {spinLoading && <Loader />}
            {showTooltip()}
            <section className="createRequest">
                <Container fluid>
                    <Row>
                        <Col md={12}>
                            <div className="headingTitle">
                                {id ? <h1>Update Send Files</h1> : <h1>Send Files</h1>}
                            </div>
                        </Col>

                        <Col md={6}>
                            <div className='createDropBox'>
                                <h1>Upload and Attach Files</h1>
                                <UploadFiles setSentFileError={setSentFileError} sentFileError={sentFileError} setSentUploadURl={setSentUploadURl} sentUploadURl={sentUploadURl} setSentFile={setSentFile} sentFile={sentFile} />
                                <button className="sendListNumber">{sentFile && sentFile.length > 9 ? `${sentFile.length}` : <>0{sentFile.length}</>}</button>
                                <button className='addEmployBtn' onClick={addEmployee}>Add Users to Request {userIds && userIds.length === 0 ? "" : userIds.length}</button>
                            </div>
                        </Col>
                        <Col md={6}>
                            <div className='createLeftBox settings'>
                                <h1>Settings</h1>
                                <div className="requestInputForm">
                                    <label htmlFor="requestTitle">Title*</label>
                                    <input type="text" value={title} onChange={titleHandle} placeholder='Title (e.g. company employees list)' required />
                                    {titleError && titleError.length ? <p className='errMsg'>{titleError}</p> : <></>}
                                </div>
                                <div className='requestInputForm dateInput mt-3' style={{ maxWidth: '230px' }}>
                                    <label htmlFor="requestTitle">Due Date (Optional)</label>
                                    {dueDate && dueDate ?
                                        <DatePicker selected={dueDate} placeholderText="Select a date" onChange={dueDateChange} dateFormat="MM/dd/yyyy" customInput={<CustomDatePickerInput />} />
                                        : <div className='dueDateFormat'>
                                            <DatePicker placeholderText="Select a date" onChange={dueDateChange} dateFormat="MM/dd/yyyy" customInput={<CustomDateFormatInput />} />
                                        </div>
                                    }
                                    {dueDateError && dueDateError.length ? <p className='errMsg'>{dueDateError}</p> : <></>}
                                </div>
                                <div className='requestInputForm dateInput mt-3' style={{ maxWidth: '230px' }}>
                                    <label htmlFor="requestTitle">Virtual Shredding Date (Optional)</label>
                                    <DatePicker selected={shreddingDate} onChange={shreddingDateChange} dateFormat="MM/dd/yyyy" customInput={<CustomDatePickerInput />} />
                                </div>
                                <div className='requestInputForm mt-3'>
                                    <label htmlFor="requestTitle">2-Factor Authentication (SMS):</label>
                                    <div className="authToggle">
                                        <p>OFF</p>
                                        {authValue === true ?
                                            <label className="switch">
                                                <input type="checkbox" onClick={authClick} defaultChecked />
                                                <span className="slider round"></span>
                                            </label> :
                                            <label className="switch">
                                                <input type="checkbox" onClick={authClick} />
                                                <span className="slider round"></span>
                                            </label>}
                                        <p>ON</p>
                                    </div>
                                </div>
                                <div className="customMsgShow mt-3">
                                    <label htmlFor="">Custom Email Message</label>
                                    <textarea name="" id="" value={customMshHandle} onChange={customMsgHandle} placeholder='This message will be included in the email sent to your recipients'></textarea>
                                </div>
                            </div>
                        </Col>


                        <Col md={12}>
                            <div className="footerDashboard">
                                <div className="templateButton">
                                  <button className='backDashboard' onClick={backToDashboard}>Back to Dashboard</button>
                                  <button className='nextButton' onClick={nextRecipient}>Next</button>
                                </div>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </section>
            <AddEmployee userIds={userIds} setUserIds={setUserIds} userList={userList} handleClose={handleClose} show={show} setShow={setShow} />
        </>
    )
}

export default Sent