import React,{useState} from 'react'
import { Col, Dropdown, Row } from 'react-bootstrap'
import leftDouble from '../../assets/icon/leftDouble.png';
import leftSingle from '../../assets/icon/leftSingle.png';
import rightArrow from '../../assets/icon/rightArrow.png';
import rightSingle from '../../assets/icon/rightSingle.png';
import { CreateRequestInterface, SendFileListInterface } from '../CommonInterface/CommonInterface';
import { multiplyBySixAndShowSeries } from '../CommonInterface/CommonFunction';

interface PaginationProps {
    prevPage: () => void;
    nextPage: () => void;
    pageResult: any;
    limit: number;
    setLimit: (value: number) => void;
    prevButton: boolean;
    nextButton: boolean;
    tableActive: any
    pageValue: any;
    totalValue: any;
    allRequestList: CreateRequestInterface["body"]["request"];
    allSentList: SendFileListInterface["body"]["sendFileRequest"];
}

const Pagination = ({ tableActive, allSentList, limit, setLimit, pageResult, prevPage, nextPage, prevButton, nextButton, pageValue, totalValue, allRequestList }: PaginationProps) => {
    
    const [selectedValue, setSelectedValue] = useState<number | null>(null);
    const limitDivided = multiplyBySixAndShowSeries(totalValue)
    const handleSelect = (selectedValue: any) => {
        const integerValue = parseInt(selectedValue);
        setLimit(integerValue);
        setSelectedValue(selectedValue);
    };

    return (
        <>
            <div className="paginationFooter">
                <Row>
                    <Col md={6}>
                        <nav aria-label="Page navigation example" className='bottomPagination'>
                            <ul className="pagination">
                                <li className="page-item">
                                    <button onClick={() => prevPage()} className={prevButton === true ? "page-link" : "page-link disablePag"} aria-label="Previous">
                                        <span aria-hidden="true"><img src={leftDouble} alt="" /></span>
                                    </button>
                                </li>
                                <li className="page-item"><button onClick={() => prevPage()} className={prevButton === true ? "page-link" : "page-link disablePag"}><img src={leftSingle} alt="" /></button></li>
                                <li className="page-item"><button className="paginationNumber">{pageValue}</button></li>
                                <li className="page-item"><button onClick={() => nextPage()} className={nextButton === true ? "page-link" : "page-link disablePag"}><img src={rightSingle} alt="" /></button></li>
                                <li className="page-item">
                                    <button onClick={() => nextPage()} className={nextButton === true ? "page-link" : "page-link disablePag"} aria-label="Next">
                                        <span aria-hidden="true"> <img src={rightArrow} alt="" /></span>
                                    </button>
                                </li>
                            </ul>
                        </nav>
                    </Col>
                    <Col md={6}>
                        <div className="tablePagination">
                            <div className="show-entries">
                                <div className="hint-text"> {tableActive === 'request' ? allRequestList.length : allSentList.length} result showing out of</div>
                                <Dropdown onSelect={handleSelect} className="dropdownNumber">
                                    <Dropdown.Toggle>
                                    {selectedValue !== null ? selectedValue : (limitDivided.length > 0 && limitDivided[limitDivided.length - 1])}
                                    </Dropdown.Toggle>
                                    <Dropdown.Menu>
                                        {limitDivided &&
                                            limitDivided.map((data, index) => (
                                                <Dropdown.Item key={`limitNumber`+index} eventKey={data}>
                                                    {data}
                                                </Dropdown.Item>
                                            ))}
                                    </Dropdown.Menu>
                                </Dropdown>
                            </div>
                        </div>
                    </Col>
                </Row>
            </div>
        </>
    )
}

export default Pagination