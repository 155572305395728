import React, { useState, useEffect } from 'react';
import { Modal, Table } from 'react-bootstrap';
import { UserListInterface } from '../CommonInterface/CommonInterface';
import { countCheckedItems } from '../CommonInterface/CommonFunction';
interface AddEmployeeProps {
    userIds: any;
    setUserIds: any;
    handleClose: () => void;
    show: boolean;
    setShow: (type: boolean) => void;
    userList: UserListInterface[];
}
const AddEmployee = ({ userIds, setUserIds, handleClose, setShow, show, userList }: AddEmployeeProps) => {
    const [selectAll, setSelectAll] = useState(false);

    useEffect(() => {
        let tempObj = userList;
        for (let i = 0; i < tempObj.length; i++) {
            if (tempObj[i].userId === userIds) {
                tempObj[i].isChecked = true;
                break;
            }
        }
    }, []);

    const handleAllRowSelection = () => {
        let tempObj = userList;
        let tempArr: any = [];
        if (countCheckedItems(userList) === userList.length) {
            for (let i = 0; i < tempObj.length; i++) {
                tempObj[i].isChecked = false;
                tempArr.push(tempObj[i].userId);
                setUserIds([]);
            }
        }
        else {
            for (let i = 0; i < tempObj.length; i++) {
                tempObj[i].isChecked = true;
                tempArr.push(tempObj[i].userId);
                //    create group user id list
                setUserIds((prevGroupIds:any) => {
                    // Check if contactId already exists in the array
                    if (!prevGroupIds.includes(tempObj[i].userId)) {
                        return [...prevGroupIds, tempObj[i].userId];
                    } else {
                        return prevGroupIds;
                    }
                });
            }
        }
        if (countCheckedItems(userList) === userList.length) {
            setSelectAll(true)
        }
        else {
            setSelectAll(false)
        }
    };

    const handleRowSelection = (contact: any, userId: string) => {
        //    create group user id list
        setUserIds((prevGroupIds:any) => {
            const index = prevGroupIds.indexOf(userId);
            if (index !== -1) {
                return [...prevGroupIds.slice(0, index), ...prevGroupIds.slice(index + 1)];
            } else {
                return [...prevGroupIds, userId];
            }
        });

        // checkbox active inactive show
        let tempObj = userList;
        for (let i = 0; i < tempObj.length; i++) {
            if (tempObj[i].userId === userId) {
                tempObj[i].isChecked = !tempObj[i].isChecked
                break;
            }
        }
    };


    return (
        <>
            <Modal show={show} onHide={handleClose} centered size="lg">
                <div className="employeeBox">
                    <h1>Select Users</h1>
                    <div className="">
                        <div className="employeeTable">
                            <Table responsive className='tableBody'>
                                <thead>
                                    <tr>
                                        <th>
                                            <label className='tableCheckBox mobileTable'>
                                                <div className="contactCheck">
                                                    <input type="checkbox" checked={selectAll} onChange={handleAllRowSelection} />
                                                    <span className="checkmark"></span>
                                                </div>
                                            </label>
                                        </th>
                                        <th>First Name</th>
                                        <th>Last Name</th>
                                        <th>Email Address</th>
                                        <th>Role</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {userList && userList.map((data, index: number) => <tr className='leftTable' key={`user` + index}>
                                        <td className='tableMark'>
                                            <label className='tableCheckBox mobileTable'>
                                                <div className="contactCheck">
                                                    <input type="checkbox" onChange={() => handleRowSelection(data, data.userId)} checked={data.isChecked} />
                                                    <span className="checkmark"></span>
                                                </div>
                                            </label>
                                        </td>
                                        <td>{data.firstName}</td>
                                        <td>{data.lastName}</td>
                                        <td>{data.email}</td>
                                        <td>{data.userRole}</td>
                                    </tr>)}

                                </tbody>
                            </Table>
                        </div>
                    </div>
                    <div className='employeeBtn'>
                        <button className='back' onClick={handleClose}>Back</button>
                        <button className='save' onClick={handleClose}>Save</button>
                    </div>
                </div>
                
            </Modal>
        </>
    )
}

export default AddEmployee