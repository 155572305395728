import React, { useState } from 'react'
// google font awesome
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSearch } from '@fortawesome/free-solid-svg-icons';
import { Table } from 'react-bootstrap';
import SmallButton from '../../styleComponent/SmallButton/SmallButton';
import { RecipientUser, UserInterface } from '../CommonInterface/CommonInterface';
import { shortEmail } from '../CommonInterface/CommonFunction';

interface ContactListProps {
  contacts: UserInterface[];
  setErrorContact: (type: string) => void;
  recipientsValue: RecipientUser[]
  setRecipientsValue: (recipient: RecipientUser[]) => void;
  senderTypeValue?: string;
}
const ContactList = ({ contacts, senderTypeValue, setErrorContact, recipientsValue, setRecipientsValue }: ContactListProps) => {

  const [searchTerm, setSearchTerm] = useState("");
  const pathSegments = window.location.pathname.split('/');
  const urlParams = pathSegments[1];
  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(event.target.value);
  };
  const contactResults = contacts.filter((item) => {
    const fullName = `${item.firstName} ${item.lastName} ${item.email}`;
    return fullName.toLowerCase().includes(searchTerm.toLowerCase())
  });

  const findEmail = (email: string) => {
    const foundContact = recipientsValue.find((contact: any) => contact.email === email);
    return foundContact
  };

  const duplicateFunction = (recipientsObj: any) => {
    const recipients = recipientsValue
    for (let i = 0; i < recipients.length; i++) {
      if (recipients[i].email === recipientsObj.email) {
        return recipients;
      }
    }
    return [...recipients, recipientsObj];
  }
  const addRecipientsFunction = (contact: any, i: any) => {
    setErrorContact("");
    const recipientsObj = {
      "contactId": contact.contactId,
      "fullName": `${contact.firstName}${" "}${contact.lastName}`,
      "email": contact.email,
      "phoneNumber": `${contact.phoneNumber && contact.phoneNumber.length ? contact.phoneNumber : ""}`
    }
    let newData = duplicateFunction(recipientsObj);

    let recipientList = {
      "users": newData,
      "senderType": senderTypeValue,
    };


    if (urlParams === "request-contacts") {
      const existingDataString = JSON.parse(localStorage.getItem("request") || '{}');
      const updatedData = { ...existingDataString, ...{ recipients: recipientList } };
      const jsonString = JSON.stringify(updatedData);
      localStorage.setItem('request', jsonString);
    }
    else {
      const existingDataString = JSON.parse(localStorage.getItem("send") || '{}');
      const updatedData = { ...existingDataString, ...{ recipients: recipientList } };
      const jsonString = JSON.stringify(updatedData);
      localStorage.setItem('send', jsonString);
    }

    setRecipientsValue(newData);

  }


  return (
    <>
      <div className="rightTable">
        <div className="contactTop">
          <h1>My Contacts</h1>
          <div className="searchName contactSearch">
            <input type="text" value={searchTerm} onChange={handleInputChange} className="form-control" id="inputValidation" placeholder="Search Contacts & Groups" />
            <FontAwesomeIcon icon={faSearch} />
          </div>
        </div>
        <div className="sentList">
          <Table responsive className='tableBody'>
            <thead>
              <tr>
                <th>First Name</th>
                <th>Last Name</th>
                <th>Email</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              {contactResults && contactResults.map((contact: any, i: number) => <tr className='leftTable' key={`contact` + i}>
                <td><p className={contact.firstName && contact.firstName.length > 12 ? 'tooltip-top' : ""} data-tooltip={contact.firstName && contact.firstName.length > 12 ? contact.firstName : ""}>
                  {contact.firstName && contact.firstName.length > 12 ? contact.firstName.substring(0, 10) + '..' : contact.firstName}
                </p></td>
                <td><p className={contact.lastName && contact.lastName.length > 12 ? 'tooltip-top' : ""} data-tooltip={contact.lastName && contact.lastName.length > 12 ? contact.lastName : ""}>
                  {contact.lastName && contact.lastName.length > 12 ? contact.lastName.substring(0, 10) + '..' : contact.lastName}
                </p></td>
                <td><p className={contact.email && contact.email.length > 15 ? 'tooltip-top' : ""} data-tooltip={contact.email && contact.email.length > 15 ? contact.email : ""}>
                  {shortEmail(contact.email)}
                </p></td>
                <td className='tableInnerButton'>
                  {findEmail(contact.email) ?
                    <SmallButton customWidth="66" customHeight="24" customBackgroundColor='#fff' customBorderColor='#F94A29' customTextColor='#F94A29' onClick={() => addRecipientsFunction(contact, i)}>Added</SmallButton> :
                    <SmallButton customWidth="62" customHeight="24" customBackgroundColor='#F94A29' customBorderColor='#F94A29' customTextColor='#fff' onClick={() => addRecipientsFunction(contact, i)}>Add</SmallButton>
                  }
                </td>
              </tr>
              )}
            </tbody>
          </Table>

        </div>
      </div>
    </>
  )
}

export default ContactList