import React, { useState, useEffect } from 'react';
import {
    CardNumberElement,
    CardExpiryElement,
    CardCvcElement,
    Elements,
    useStripe,
    useElements
} from "@stripe/react-stripe-js";
import { loadStripe } from '@stripe/stripe-js';
import { Container, Dropdown, Row } from 'react-bootstrap';
import { STIPE_PUBLISHABLE_API_KEY } from "../../../config"
import countries from "../../../helpers/countryName.json";
import { showNotifications } from '../../../helpers/toaster';
import { ToastContainer } from 'react-toastify';
import { billingInfo, createPayment } from '../../../api/payment';
import { isAuthenticate } from '../../../api/auth';
import Loader from '../Loader/Loader';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown } from '@fortawesome/free-solid-svg-icons';
import { useNavigate } from 'react-router-dom';
import PricePlan from '../PricePlan/PricePlan';
import DashboardNavbar from '../DashboardNavbar/DashboardNavbar';


const stripePromise = loadStripe(`${STIPE_PUBLISHABLE_API_KEY}`);

const createOptions = (fontSize: any) => {
    return {
        style: {
            base: {
                fontSize: "15px",
                color: "rgba(54, 54, 55, 1)",
                letterSpacing: "0.025em",
                fontFamily: "inter",
                "::placeholder": {
                    color: "rgba(116, 118, 121, 0.8)"
                }
            },
            invalid: {
                color: "rgba(54, 54, 55, 1)"
            }
        }
    };
};

const SplitForm = ({ totalUser, setTotalUser, setPaymentSkip, setActiveStep, activeStep, fontSize, setPaymentOption, setSpinLoading }: any) => {
    const stripe: any = useStripe();
    const elements = useElements();
    const [paymentIntent, setPaymentIntent] = useState<any[]>();
    const [error, setError] = useState<string | null>(null);
    const { payment } = isAuthenticate();
    const navigate = useNavigate();
    const [billingCity, setBillingCity] = useState("");
    const [billingState, setBillingState] = useState("");
    const [billingAddressOne, setBillingAddressOne] = useState("");
    const [billingAddressTwo, setBillingAddressTwo] = useState("");
    const [billingZip, setBillingZip] = useState("");
    const [billingZipTwo, setBillingZipTwo] = useState("");
    const [totalUserError, setTotalUserError] = useState("");
    const [billingCityError, setBillingCityError] = useState("");
    const [billingStateError, setBillingStateError] = useState("");
    const [billingAddressOneError, setBillingAddressOneError] = useState("");
    const [billingZipCodeError, setBillingZipCodeError] = useState("");
    const [billingZipCodeTwoError, setBillingZipCodeTwoError] = useState("");
    const [selectedCountry, setSelectedCountry] = useState<string | null>(null);
 


    useEffect(() => {


        const fetchPaymentIntent = async () => {
            const stripe = await stripePromise;
            if (stripe) {
                const { data }: any = await stripe.retrievePaymentIntent(payment.clientSecret);
                setPaymentIntent(data);
            }

        };
        if (payment && payment.clientSecret) {
            fetchPaymentIntent();
        }

        billingInfo().then((data) => {
            if (data.billingInfo) {
            setBillingCity(data.billingInfo.billingCity)
            setBillingState(data.billingInfo.billingState)
            setBillingAddressOne(data.billingInfo.billingAddress1)
            setBillingAddressTwo(data.billingInfo.billingAddress2)
            setBillingZipTwo(data.billingInfo.billingZIPCode)
            }
        })

    }, []);

    const userHandle = (event: React.ChangeEvent<HTMLInputElement>) => {
        setTotalUser(event.target.value);
        setTotalUserError("");
    }
    const cityHandle = (event: React.ChangeEvent<HTMLInputElement>) => {
        setBillingCity(event.target.value);
        setBillingCityError("");
    }
    const stateHandle = (event: React.ChangeEvent<HTMLInputElement>) => {
        setBillingState(event.target.value);
        setBillingStateError("");
    }
    const addressOneHandle = (event: React.ChangeEvent<HTMLInputElement>) => {
        setBillingAddressOne(event.target.value);
        setBillingAddressOneError("");
    }
    const addressTwoHandle = (event: React.ChangeEvent<HTMLInputElement>) => {
        setBillingAddressTwo(event.target.value);
    }

    const zipCodeHandle = (event: React.ChangeEvent<HTMLInputElement>) => {
        setBillingZip(event.target.value);
        setBillingZipCodeError("");
    }

    const zipCodeTwoHandle = (event: React.ChangeEvent<HTMLInputElement>) => {
        setBillingZipTwo(event.target.value);
        setBillingZipCodeTwoError("");
    }


    const handleSubmit = async (event: React.FormEvent) => {
        event.preventDefault();

        if (parseInt(totalUser) < 1 || totalUser === "") {
            setTotalUserError("Please enter minimum user 1")
        }
        else {
            setTotalUserError("")
        }
        if (billingCity.length === 0) {
            setBillingCityError("Please enter your city")
        }
        else {
            setBillingCityError("")
        }
        if (billingState.length === 0) {
            setBillingStateError("Please enter your state")
        }
        else {
            setBillingStateError("")
        }
        if (billingAddressOne.length === 0) {
            setBillingAddressOneError("Please enter your billing address")
        }
        else {
            setBillingAddressOneError("")
        }
        if (billingZip.length === 0) {
            setBillingZipCodeError("Please enter your zip code")
        }
        else {
            setBillingZipCodeError("")
        }
        if (billingZipTwo.length === 0) {
            setBillingZipCodeTwoError("Please enter your billing zip code")
        }
        else {
            setBillingZipCodeTwoError("")
        }
        if (parseInt(totalUser) > 0 && billingCity.length > 0 && billingState.length > 0 && billingAddressOne.length > 0 && billingZip.length > 0 && billingZipTwo.length > 0) {
            setSpinLoading(true);
            if (elements) {

                // Confirm the PaymentIntent with the card element
                const { paymentIntent, error } = await stripe.confirmCardPayment(payment.clientSecret, {
                    payment_method: {
                        type: "card",
                        card: elements.getElement(CardNumberElement)
                    },
                });

                if (error) {
                    showNotifications("error", error.message);
                    setSpinLoading(false);
                } else {

                    setError(null);
                    let paymentObj = {
                        "stripeID": payment.stripeID,
                        "totalUser": parseInt(totalUser),
                        "billingCity": billingCity,
                        "billingState": billingState,
                        "billingAddress1": billingAddressOne,
                        "billingAddress2": billingAddressTwo,
                        "billingZIPCode": billingZipTwo
                    }
                    createPayment(paymentObj).then((data) => {
                        if (data.statusCode !== 200) {
                            setSpinLoading(false);
                            showNotifications("error", data.message);
                        }
                        else {
                            setSpinLoading(false);
                            showNotifications("success", data.message);
                            return navigate(`/admin`);
                        }

                    })
                }
            }
        }
    }


    const handleSelect = (country: string) => {
        setSelectedCountry(country);
    };

    return (
        <form onSubmit={handleSubmit}>
            <h1 className='paymentHeading'>Plan Information</h1>
            <div className="registrationInput billInput">
                <label>Total Users <sup className='asterisk'>*</sup></label>
                <input
                    type="number"
                    id="text"
                    placeholder="Number of Users"
                    value={totalUser}
                    onChange={userHandle}
                />
                {totalUserError && totalUserError.length ? <p className='errMsg'>{totalUserError}</p> : ""}
            </div>
            <h1 className='paymentHeading'>Payment Details</h1>

            <div className="registrationInput billInput">
                <label>Country</label>
                <Dropdown>
                    <Dropdown.Toggle className="custom-toggle">
                        {selectedCountry || 'Select Country'} <FontAwesomeIcon icon={faChevronDown} />
                    </Dropdown.Toggle>

                    <Dropdown.Menu>
                        {countries.map((country, index) => (
                            <Dropdown.Item key={index} onClick={() => handleSelect(country)}>
                                {country}
                            </Dropdown.Item>
                        ))}
                    </Dropdown.Menu>
                </Dropdown>
            </div>
            <div className="registrationInput billInput">
                <label>Name on Card</label>
                <input
                    type="text"
                    id="text"
                    placeholder="Full name"
                />

            </div>
            <div className="registrationInput billInput">
                <label>Credit Card Number <sup className='asterisk'>*</sup></label>
                <CardNumberElement options={createOptions(fontSize)} />
            </div>
            <div className='d-flex'>
                <div className="registrationInput billInput" style={{ width: "70%" }}>
                    <label>Expiration Date <sup className='asterisk'>*</sup></label>
                    <CardExpiryElement options={createOptions(fontSize)} />
                </div>
                <div className="registrationInput billInput" style={{ width: "30%" }}>
                    <label>CVV <sup className='asterisk'>*</sup></label>
                    <CardCvcElement options={createOptions(fontSize)} />
                </div>
            </div>
            <div className="registrationInput billInput">
                <label>Zip Code <sup className='asterisk'>*</sup></label>
                <input
                    type="text"
                    id="text"
                    placeholder="Zip Code"
                    onChange={zipCodeHandle}
                />
                {billingZipCodeError && billingZipCodeError.length ? <p className='errMsg'>{billingZipCodeError}</p> : ""}
            </div>
            <h1 className='paymentHeading'>Billing Details</h1>
            <div className="registrationInput billInput">
                <label>Address <sup className='asterisk'>*</sup></label>
                <input
                    type="text"
                    id="text"
                    placeholder="123 Main Street"
                    value={billingAddressOne}
                    onChange={addressOneHandle}
                />
                {billingAddressOneError && billingAddressOneError.length ? <p className='errMsg'>{billingAddressOneError}</p> : ""}
            </div>
            <div className="registrationInput billInput">
                <label>Address 2</label>
                <input
                    type="text"
                    id="text"
                    placeholder="123 Main Street"
                    value={billingAddressTwo}
                    onChange={addressTwoHandle}
                />
            </div>
            <div className='d-flex'>
                <div className="registrationInput billInput" style={{ width: "50%" }}>
                    <label>City <sup className='asterisk'>*</sup></label>
                    <input
                        type="text"
                        id="text"
                        placeholder="City"
                        value={billingCity}
                        onChange={cityHandle}
                    />
                    {billingCityError && billingCityError.length ? <p className='errMsg'>{billingCityError}</p> : ""}
                </div>
                <div className="registrationInput billInput" style={{ width: "50%" }}>
                    <label>State/Provience <sup className='asterisk'>*</sup></label>
                    <input
                        type="text"
                        id="text"
                        placeholder="State/Provience"
                        value={billingState}
                        onChange={stateHandle}
                    />
                    {billingStateError && billingStateError.length ? <p className='errMsg'>{billingStateError}</p> : ""}
                </div>
            </div>
            <div className="registrationInput billInput">
                <label>Billing Zip Code <sup className='asterisk'>*</sup></label>
                <input
                    type="text"
                    id="text"
                    placeholder="Billing Zip Code"
                    value={billingZipTwo}
                    onChange={zipCodeTwoHandle}
                />
                {billingZipCodeTwoError && billingZipCodeTwoError.length ? <p className='errMsg'>{billingZipCodeTwoError}</p> : ""}
            </div>
            <div className="registrationBtn">
                <p><span className='asterisk'>*</span> Indicates required field</p>
                <button type='submit'>Getting Started</button>
            </div>

            {error && <div className="error-message">{error}</div>}

        </form>
    );
};


const PaymentUpdate = () => {
    const getElementFontSize = () => (window.innerWidth < 450 ? "14px" : "18px");
    const [elementFontSize, setElementFontSize] = useState(getElementFontSize);
    const [spinLoading, setSpinLoading] = useState(false);
    const [fadeAnimation, setFadeAnimation] = useState(false);
    const [totalUser, setTotalUser] =  React.useState<number>(1);
    useEffect(() => {
        setFadeAnimation(true);
        const onResize = () => {
            setElementFontSize(getElementFontSize());
        };

        window.addEventListener("resize", onResize);

        return () => {
            window.removeEventListener("resize", onResize);
        };
    });



    return (
        <>
            <DashboardNavbar />
            {spinLoading &&
                <Loader />
            }
            <section className={`fade-in adminPage ${fadeAnimation ? 'visible' : ''}`}>
                <Container fluid>
                    <Row>
                        <div className='d-flex justify-content-center flex-wrap mt-3 mb-3'>
                            <div>
                                <div className='paymentMethod'>
                                    <ToastContainer />
                                    <Elements stripe={stripePromise}>
                                        <SplitForm totalUser={totalUser} setTotalUser={setTotalUser} setSpinLoading={setSpinLoading} fontSize={elementFontSize} />
                                    </Elements>
                                </div>
                            </div>
                            <div>
                                <PricePlan totalUser={totalUser} />
                            </div>
                        </div>
                    </Row>
                </Container>
            </section>
        </>
    )
}

export default PaymentUpdate
