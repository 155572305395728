import React, { useState } from 'react'
import { Col, Modal, Row } from 'react-bootstrap'
import { LoadTemplateInterface } from '../CommonInterface/CommonInterface';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch } from '@fortawesome/free-solid-svg-icons';
interface LoadTemplateProps {
    saveTemplateShow: boolean;
    saveTemplateModalClose: () => void;
    loadTemplateInfo: LoadTemplateInterface[];
    singleTemplateLoad: (templateId: string) => void;
}
const LoadTemplate = ({ singleTemplateLoad, loadTemplateInfo, saveTemplateModalClose, saveTemplateShow }: LoadTemplateProps) => {
    const [searchTerm, setSearchTerm] = useState("");
    const searchTemplate = loadTemplateInfo.filter((item: any) => {
        const fullName = `${item.templateTitle}`;
        return fullName.toLowerCase().includes(searchTerm.toLowerCase())
    });
    return (
        <>

            <Modal show={saveTemplateShow} className="loadModal settingModalBody" size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered>
                <Modal.Body>
                    <div className="templateModal">
                        <Row>
                            <Col md={12}>
                                <div className="loadTemplateHeading">
                                    <h1>Load Template</h1>
                                </div>
                            </Col>
                            <Col md={12} className="">

                                <div className="templateName">
                                    {loadTemplateInfo && loadTemplateInfo.length > 0 ?
                                        <div className="searchName dashboardInput mb-2">
                                            <input type="text" value={searchTerm} onChange={(e) => setSearchTerm(e.target.value)} className="form-control" id="inputValidation" placeholder="Search Template" />
                                            <FontAwesomeIcon icon={faSearch} />
                                        </div> : <p>No template here</p>}
                                    {searchTemplate && searchTemplate.map((data: any, index: number) => <button key={`template` + index} className='loadTemplateButton' type='submit' onClick={() => singleTemplateLoad(data.requestTemplateId)}>{data.templateTitle}</button>)}
                                </div>
                            </Col>
                        </Row>
                    </div>
                    <div className="footerModalButton">
                        <button className='backDashboard' onClick={saveTemplateModalClose}>Back</button>
                    </div>
                </Modal.Body>

            </Modal>
        </>
    )
}

export default LoadTemplate