import React,{useEffect} from 'react'
import { Modal, Row, Col } from 'react-bootstrap'
import moment from 'moment';
import { RecipientUser, SentFileInterface } from '../CommonInterface/CommonInterface';

interface SentModalProps {
    receiptShow: boolean;
    title: string;
    dueDate: string;
    shreddingDate: string;
    customMshHandle: string;
    authValue: boolean;
    modalClose: () => void;
    sentConfirm: () => void;
    sentFile: SentFileInterface[];
    recipientsValue: RecipientUser[];
}

const SentResult = ({ sentConfirm, modalClose, shreddingDate, authValue, dueDate, customMshHandle, recipientsValue, sentFile, title, receiptShow }: SentModalProps) => {
    return (
        <>
            <Modal show={receiptShow} className="recipientModalBody" size="xl"
                aria-labelledby="contained-modal-title-vcenter"
                centered>
                <Modal.Body >
                    <div className="requestModal">
                        <Row>
                            <Col md={4} className='mobileBottom'>
                                <div className="requestInput">
                                    <div className="itemLine">
                                        <p>Item</p>
                                        <button>{sentFile && sentFile.length > 9 ? `${sentFile.length}` : <p>0{sentFile.length}</p>}</button>
                                    </div>
                                    <div className="itemSerialList">
                                        {sentFile && sentFile.map((data, index: number) =>
                                            <div key={`showItem` + index} className='modalItem'>
                                                <p>{index + 1}.&nbsp;{data.fileName}</p>
                                            </div>

                                        )}
                                    </div>
                                </div>
                            </Col>
                            <Col md={4} className='mobileBottom'>
                                <div className='requestInput middleRequest'>
                                    <h1>Settings</h1>
                                    <label htmlFor="Title">Title</label>
                                    <p className='modalValue titleWidth'>{title}</p>
                                    {dueDate && dueDate ?
                                        <div className='customMessageRequestList mt-3'>
                                            <label htmlFor="">Due Date (Optional)</label>
                                            <div className='calenderShowBox'>
                                                <p className='modalValue'>{moment(dueDate).format('MM/DD/YYYY')}</p>
                                            </div>
                                        </div>
                                        : ""}
                                    <div className='customMessageRequestList mt-3'>
                                        <label htmlFor="">Virtual Shredding Date</label>
                                        <div className='calenderShowBox'>
                                            <p className='modalValue'>{moment(shreddingDate).format('MM/DD/YYYY')}</p>
                                        </div>
                                    </div>
                                    <div className='customMessageRequestList mt-3'>
                                        <label htmlFor="">2-Factor Authentication</label>
                                        <div className="authShow">
                                            <p className='modalValue'>{authValue === false ? 'OFF' : 'ON'}</p>
                                        </div>
                                    </div>
                                    <div className="customMessageRequest">
                                        <label htmlFor="">Custom Email Message</label>
                                        <div className="sendCustomMsg">
                                            <p>{customMshHandle}</p>
                                        </div>
                                    </div>
                                </div>

                            </Col>
                            <Col md={4} className='mobileBottom'>
                                <div className='requestInput'>
                                    <div className="itemLine recipientsBottom">
                                        <p>Recipents</p>
                                        <button>{recipientsValue && recipientsValue.length > 9 ? `${recipientsValue.length}` : <p>0{recipientsValue.length}</p>}</button>
                                    </div>
                                    <div className="nameEmail">
                                        {recipientsValue && recipientsValue.map((data: any, index: number) =>
                                            <div key={`email` + index} className="listEmail">

                                                <p>{data.fullName}</p>
                                                <span>{data.email}</span> <br />
                                                {data.phoneNumber && data.phoneNumber.length ? <span>{data.phoneNumber}</span> : <></>}
                                            </div>
                                        )}
                                    </div>
                                </div>
                            </Col>

                        </Row>
                    </div>
                    <div className="footerModalButton">
                        <button className='backDashboard' onClick={modalClose}>Back</button>
                        <button className='nextButton' onClick={sentConfirm}>Confirm & Send</button>
                    </div>
                </Modal.Body>

            </Modal>
        </>
    )
}

export default SentResult