import React from 'react';
import { Link } from 'react-router-dom';
import "./ConfirmPayment.css";

const ConfirmPayment = () => {
    return (
        <div className='d-flex justify-content-center w-100'>
            <div className='confirmMsg'>
                <h1>You’re All Set</h1>
                <h2>You are all set to get started</h2>
                <svg version="1.1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 130.2 130.2">
                    <circle className="path circle" fill="#F94B29" stroke="#FFFFFF" strokeWidth="6" strokeMiterlimit="10" cx="65.1" cy="65.1" r="62.1" />
                    <polyline className="path check" fill="none" stroke="#FFFFFF" strokeWidth="6" strokeLinecap="round" strokeMiterlimit="10" points="100.2,40.2 51.5,88.8 29.8,67.5 " />
                </svg>
                <div className='paymentContinue'>
                    <Link to="/login">Continue</Link>
                </div>
            </div>
        </div>
    )
}

export default ConfirmPayment