import { post, get, del, put, verifyGet } from './base-api';

// create new contact
export const addSecurity = (securityInfo: {}) => {
    return post(`/users/add/security-question`, securityInfo);
};

// get security question
export const getSecurity = (userEmail: string) => {
    return get(`/users/forget-password/get-security-qus/${userEmail}`);
};

// check security answer
export const checkSecurity = (userEmail: string, securityAns: {}) => {
    return post(`/users/verify/security-question-answer/${userEmail}`, securityAns);
};

// get phone number code
export const getPhoneCode = (userPhone: string, body = {}) => {
    return post(`/users/forget-password/${userPhone}`, body);
};

// add user
export const createUser = (body: {}) => {
    return post(`/system-config/add-user`, body);
};
// get all user
export const getAllUser = (limit: number, Page: number) => {
    return get(`/users/lists?limit=${limit}&page=${Page}`);
};
// update user
export const updateUser = (userEmail: string, body: {}) => {
    return put(`/users/${userEmail}`, body);
};
// account delete password check
export const userPassword = (body: {}) => {
    return post(`/users/delete/account`, body);
};

// account delete confirm
export const passwordCheck = (code: string) => {
    return del(`/users/confirm-delete/${code}`);
};
// email account verify
export const emailVerifyApi = (tokenId: string) => {
    return verifyGet(`/users/mail/verify?token=${tokenId}`, tokenId);
};

// account login otp
export const loginOtpCheck = (body: {}) => {
    return post(`/users/login-with-otp`, body);
};