import { post, get, del, itemPost, patch } from './base-api';

// get all user files
export const getMyFiles = (limit: number,page: number,searchString: string,sortBy: string,sortType: string) => {
    return get(`/files/myFiles?limit=${limit}&page=${page}&searchString=${searchString}&sortBy=${sortBy}&sortOrder=${sortType}`);
};
// delete files
export const deleteFile = (fileId:string) => {
    return del(`/files/${fileId}?deletePermanently=true`);
};

// get all delete valut files
export const getVoltMyFiles = (limit: number,page: number,searchString: string,sortBy: string,sortType: string) => {
    return get(`/files/myFiles?limit=${limit}&page=${page}&searchString=${searchString}&isDocoVaultItem=true&sortBy=${sortBy}&sortOrder=${sortType}`);
};
// restore files
export const restoreData = (restoreId: any) => {
    return post(`/files/restore/${restoreId}`);
};
export const restoreDelete = (tag:string, restoreId: any) => {
    return post(`/${tag}/restore/${restoreId}?restoreSoftDeleteItem=true`);
};
// Initiate File replacement
export const fileReplace = (requestId: string,body={}) => {
    return post(`/requests/requestFileReplacement/${requestId}`,body);
};

// Replace and Create upload file url
export const replaceOldFiles = (fileId: string, body={},token:string) => {
    return itemPost(`/files/replaceAndInitiateUpload/${fileId}`, body,token);
};

// Accepted file 
export const acceptFiles = (fileId: string,body={}) => {
    return patch(`/requests/${fileId}`,body);
};

// send reminder
export const reminderSend = (requestId: string) => {
    return post(`/requests/reminder/${requestId}`);
};