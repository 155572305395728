import React, { useState } from 'react'
import './Registration.css';
import Step from '../../CommonCompoment/Step/Step';
import { Col, Container, Row } from 'react-bootstrap';
import PricePlan from '../../CommonCompoment/PricePlan/PricePlan';
import CardPayment from '../../CommonCompoment/CardPayment/CardPayment';
import ConfirmPayment from '../../CommonCompoment/ConfirmPayment/ConfirmPayment';
import { ToastContainer } from 'react-toastify';
import RegistrationForm from '../../CommonCompoment/RegistrationForm/RegistrationForm';
import Loader from '../../CommonCompoment/Loader/Loader';
import SignUpNavbar from '../../CommonCompoment/SignUpNavbar/SignUpNavbar';


const Register = () => {

  const [activeStep, setActiveStep] = React.useState(1);
  const [spinLoading, setSpinLoading] = useState(false);
  const [paymentOption, setPaymentOption] = useState<boolean>(false);
  const [paymentSkip, setPaymentSkip] = useState(false);
  const [totalUser, setTotalUser] =  React.useState<number>(1);
  const steps = [
    {
      label: 'Step 1',
      step: 1,
      textStep: 1,
    },
    {
      label: 'Step 2',
      step: 2,
      textStep: 2,
    },
    {
      label: 'Finish',
      step: 3,
      textStep: 3,
    }
  ]



  return (
    <>

      {spinLoading &&
        <Loader />
      }
      <SignUpNavbar />
      <section className='registrationStep'>

        <Step activeStep={activeStep} setActiveStep={setActiveStep} steps={steps} />

        <Container>
          <Row>
            <ToastContainer />
            <Container>
              <Row>
                {paymentSkip === true ? <>
                  <Col md={12}>
                    <ConfirmPayment />
                  </Col>
                </> :
                  <div className='d-flex justify-content-center flex-wrap'>
                    <div>
                      {paymentOption === true ? <>
                         <CardPayment totalUser={totalUser} setTotalUser={setTotalUser} activeStep={activeStep} setPaymentOption={setPaymentOption} setPaymentSkip={setPaymentSkip} setActiveStep={setActiveStep} />
                      </> :
                        <><RegistrationForm setPaymentSkip={setPaymentSkip} setPaymentOption={setPaymentOption} activeStep={activeStep} setActiveStep={setActiveStep} setSpinLoading={setSpinLoading} /></>
                      }
                    </div>
                    <div>
                      <PricePlan totalUser={totalUser} />
                    </div>
                  </div>
                }
              </Row>
            </Container>
          </Row>
        </Container>
      </section>
    </>
  )
}

export default Register
