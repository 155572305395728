import React from 'react';
import { Table } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCheck, faMinus } from '@fortawesome/free-solid-svg-icons';
import tableIcon from '../../assets/icon/priceIcon.png';
import "./Pricing.css";
import SmallButton from '../../styleComponent/SmallButton/SmallButton';
import { Link } from 'react-router-dom';
import { ENTERPRISE_FORM } from "../../config";
import { PackageInterface } from '../CommonInterface/CommonInterface';
import { convertGB } from '../CommonInterface/CommonFunction';

interface tablePriceProps {
    standardMin: string | null;
    standardMax: string | null;
    plusMin: string | null;
    plusMax: string | null;
    billingType: string | null;
    packagePrice: PackageInterface[] | undefined;
    selectChoosePlan: (plan: string) => void;
}
const PriceTable = ({ selectChoosePlan, packagePrice, billingType, standardMin, standardMax, plusMin, plusMax }: tablePriceProps) => {

    return (
        <>
            <Table responsive>
                <thead>
                    <tr>
                        <th></th>
                        <th>
                            <div style={{ display: 'flex', justifyContent: 'space-between', flexDirection: 'column' }}>
                                <div className="packageName">
                                    <h1>Business Standard</h1>
                                </div>
                                <div className="packagePrice">
                                    <h1>{standardMax ? <span className='previousPrice'><span className='icon'>$</span>{standardMax}</span> : ""}<span className='icon'>$</span>{standardMin}<span className='currency'>USD</span></h1>
                                    <p>/User/Month Billed {billingType === "Monthly" ? "Monthly" : "Annually"}</p>
                                </div>
                                <div className="packageSelect">
                                    <SmallButton onClick={() => selectChoosePlan("STANDARD")} customWidth="140" customHeight="44" customBackgroundColor='#F94A29' customBorderColor='#F94A29' customTextColor='#fff'>Select</SmallButton>
                                </div>
                            </div>
                        </th>
                        <th>
                            <div style={{ display: 'flex', justifyContent: 'space-between', flexDirection: 'column', position: 'relative' }}>
                                <div className="recommendTableLine">
                                    <p>Recommended</p>
                                </div>
                                <div className="packageName">
                                    <h1>Business <br /> Plus</h1>
                                </div>
                                <div className="packagePrice">
                                    <h1>{plusMax ? <span className='previousPrice'><span className='icon'>$</span>{plusMax}</span> : ""}<span className='icon'>$</span>{plusMin}<span className='currency'>USD</span></h1>
                                    <p>/User/Month Billed {billingType === "Monthly" ? "Monthly" : "Annually"}</p>
                                </div>
                                <div className="packageSelect">
                                    <SmallButton onClick={() => selectChoosePlan("PLUS")} customWidth="140" customHeight="44" customBackgroundColor='#F94A29' customBorderColor='#F94A29' customTextColor='#fff'>Select</SmallButton>
                                </div>
                            </div>
                        </th>
                        <th>
                            <div style={{ display: 'flex', justifyContent: 'space-between', flexDirection: 'column' }}>
                                <div className="packageName">
                                    <h1>Enterprise <br /> <br /></h1>
                                    <br />
                                </div>
                                <div className="enterprisePackage">
                                    <h2>Custom</h2>
                                </div>
                                <div className='packagePrice'><p></p></div>
                                <div className="packageSelect">
                                    <Link className='googleForm' target="_blank" to={`${ENTERPRISE_FORM}`}>Contact us</Link>
                                </div>
                            </div>
                        </th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td>
                            <div className='leftHeading'>
                                <p>Cloud and Account Storage</p>
                                <div className="tooltipArrow"><img src={tableIcon} alt="" />
                                    <span className="tooltipText shortContent">Total account storage available. (Admins can request more storage within their account page)</span>
                                </div>
                            </div>
                        </td>
                        <td>{packagePrice && convertGB(packagePrice[1].totalStorage)} GB Storage</td>
                        <td>{packagePrice && convertGB(packagePrice[2].totalStorage)} GB Storage</td>
                        <td>Custom space requirements</td>
                    </tr>
                    <tr>
                        <td>
                            <div className='leftHeading'>
                                <p>Unlimited* secure file transfers</p>
                                <div className="tooltipArrow"><img src={tableIcon} alt="" />
                                    <span className="tooltipText shortContent">Secure file transfers include streamlined requesting and
                                        sending. (Free tiered users have limited access to this feature)
                                    </span>
                                </div>
                            </div>
                        </td>
                        <td><FontAwesomeIcon icon={faCheck} /></td>
                        <td><FontAwesomeIcon icon={faCheck} /></td>
                        <td><FontAwesomeIcon icon={faCheck} /></td>
                    </tr>
                    <tr>
                        <td>
                            <div className='leftHeading'>
                                <p>Data Rooms</p>
                                <div className="tooltipArrow"><img src={tableIcon} alt="" />
                                    <span className="tooltipText longContent">Data rooms serve as the centralized and secure environment where communications, documents, and files are shared as well as stored for efficient communication between users and their counterparts.</span>
                                </div>
                            </div>
                        </td>
                        <td><FontAwesomeIcon icon={faCheck} /></td>
                        <td><FontAwesomeIcon icon={faCheck} /></td>
                        <td><FontAwesomeIcon icon={faCheck} /></td>
                    </tr>
                    <tr>
                        <td>
                            <div className='leftHeading'>
                                <p>Auto reminders</p>
                                <div className="tooltipArrow"><img src={tableIcon} alt="" />
                                    <span className="tooltipText longContent">Auto reminders empower users to create automated prompts that enhance efficiency and optimize the exchange process. Once set, these reminders operate autonomously and play a vital role in streamlining workflow cycles.</span>
                                </div>
                            </div>
                        </td>
                        <td><FontAwesomeIcon icon={faCheck} /></td>
                        <td><FontAwesomeIcon icon={faCheck} /></td>
                        <td><FontAwesomeIcon icon={faCheck} /></td>
                    </tr>
                    <tr>
                        <td>
                            <div className='leftHeading'>
                                <p>Custom Templates</p>
                                <div className="tooltipArrow"><img src={tableIcon} alt="" />
                                    <span className="tooltipText longContent">Custom templates provide users with the ability to create and save predefined actions for effortless and efficient future use. By leveraging these templates, users can simplify repetitive tasks, thus contributing to the continuous streamlining and optimization of workflow cycles.</span>
                                </div>
                            </div>
                        </td>
                        <td><FontAwesomeIcon icon={faCheck} /></td>
                        <td><FontAwesomeIcon icon={faCheck} /></td>
                        <td><FontAwesomeIcon icon={faCheck} /></td>
                    </tr>
                    <tr>
                        <td>
                            <div className='leftHeading'>
                                <p>App integrations</p>
                                <div className="tooltipArrow"><img src={tableIcon} alt="" />
                                    <span className="tooltipText shortContent">Effortlessly work across multiple platforms, ensuring a smooth and uninterrupted workflow.</span>
                                </div>
                            </div>
                        </td>
                        <td><FontAwesomeIcon icon={faCheck} /></td>
                        <td><FontAwesomeIcon icon={faCheck} /></td>
                        <td><FontAwesomeIcon icon={faCheck} /></td>
                    </tr>
                    <tr>
                        <td>
                            <div className='leftHeading'>
                                <p>2-Factor Authentication</p>
                                <div className="tooltipArrow"><img src={tableIcon} alt="" />
                                    <span className="tooltipText shortContent">Secure your accounts with an added layer of security: Two-Factor Authentication (2FA).</span>
                                </div>
                            </div>
                        </td>
                        <td><FontAwesomeIcon icon={faCheck} /></td>
                        <td><FontAwesomeIcon icon={faCheck} /></td>
                        <td><FontAwesomeIcon icon={faCheck} /></td>
                    </tr>
                    <tr>
                        <td>
                            <div className='leftHeading'>
                                <p>Dedicated Support</p>
                                <div className="tooltipArrow"><img src={tableIcon} alt="" />
                                    <span className="tooltipText shortContent">Our dedicated team here to provide personalized assistance and support for your account.</span>
                                </div>
                            </div>
                        </td>
                        <td><FontAwesomeIcon icon={faCheck} /></td>
                        <td><FontAwesomeIcon icon={faCheck} /></td>
                        <td><FontAwesomeIcon icon={faCheck} /></td>
                    </tr>
                    <tr>
                        <td>
                            <div className='leftHeading'>
                                <p>Advanced security controls</p>
                                <div className="tooltipArrow"><img src={tableIcon} alt="" />
                                    <span className="tooltipText shortContent">Add advanced security controls to further comply with security policies. <Link to="/security">See our security page</Link> for more info.</span>
                                </div>
                            </div>
                        </td>
                        <td><FontAwesomeIcon icon={faMinus} /></td>
                        <td><FontAwesomeIcon icon={faCheck} /></td>
                        <td><FontAwesomeIcon icon={faCheck} /></td>
                    </tr>
                    <tr>
                        <td>
                            <div className='leftHeading'>
                                <p>Employee management</p>
                                <div className="tooltipArrow"><img src={tableIcon} alt="" />
                                    <span className="tooltipText shortContent">Governance controls and employee management tools to enforce internal controls and specific policy adherence.</span>
                                </div>
                            </div>
                        </td>
                        <td><FontAwesomeIcon icon={faMinus} /></td>
                        <td><FontAwesomeIcon icon={faCheck} /></td>
                        <td><FontAwesomeIcon icon={faCheck} /></td>
                    </tr>
                    <tr>
                        <td>
                            <div className='leftHeading'>
                                <p>Docovault</p>
                                <div className="tooltipArrow"><img src={tableIcon} alt="" />
                                    <span className="tooltipText shortContent">DocoVault File recovery set to custom retention standards for internal compliance.</span>
                                </div>
                            </div>
                        </td>
                        <td><FontAwesomeIcon icon={faMinus} /></td>
                        <td><FontAwesomeIcon icon={faCheck} /></td>
                        <td><FontAwesomeIcon icon={faCheck} /></td>
                    </tr>
                    <tr>
                        <td>
                            <div className='leftHeading'>
                                <p>Fully custom solutions</p>
                                <div className="tooltipArrow"><img src={tableIcon} alt="" />
                                    <span className="tooltipText shortContent">Contact <Link to={`mailto:sales@docoteam.com`}>sales@docoteam.com</Link> for a fully customized enterprise solution.</span>
                                </div>
                            </div>
                        </td>
                        <td><FontAwesomeIcon icon={faMinus} /></td>
                        <td><FontAwesomeIcon icon={faMinus} /></td>
                        <td><FontAwesomeIcon icon={faCheck} /></td>
                    </tr>
                    <tr>
                        <td>
                            <div className='leftHeading'>
                                <p>Enhanced 24/hr support</p>
                                <div className="tooltipArrow"><img src={tableIcon} alt="" />
                                    <span className="tooltipText shortContent">Have a dedicated support expert available 24/7.</span>
                                </div>
                            </div>
                        </td>
                        <td><FontAwesomeIcon icon={faMinus} /></td>
                        <td className='bottomBorder'><FontAwesomeIcon icon={faMinus} /></td>
                        <td><FontAwesomeIcon icon={faCheck} /></td>
                    </tr>
                </tbody>
            </Table>
        </>
    )
}

export default PriceTable