import React, { useState, useEffect, useRef } from 'react'
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { authenticate, login, qrGenerate } from '../../../api/auth';
import './login.css';
import loginContent from '../../../assets/dashboard/loginContent.png'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons';
import { Link } from 'react-router-dom';
import UAParser from 'ua-parser-js';
import axios from 'axios';
import Loader from '../../CommonCompoment/Loader/Loader';
import UpdatePassword from './UpdatePassword';
import { LoginSocialGoogle, IResolveParams } from 'reactjs-social-login';
import logo from "../../../assets/logo/largeLogo.png";
import { GOOGLE_CLIENT_ID } from "../../../config";
import { showNotifications } from '../../../helpers/toaster';
import { ToastContainer } from 'react-toastify';
import VerificationInput from 'react-verification-input';
import { loginOtpCheck } from '../../../api/user';
import { reSendCode } from '../../../api/job';
import { decodeJwt } from '../../CommonInterface/CommonFunction';
import UpgradePlan from '../../CommonCompoment/UpgradePlan/UpgradePlan';

const Login = () => {
    const navigate = useNavigate();
    // Get user's operating system
    const [locationName, setLocationName] = useState<string[]>([]);
    const [loginError, setLoginError] = useState("")
    const [recapError, setRecapError] = useState("")
    const [togglePassword, setTogglePassword] = useState(false);
    const [toggleType, setToggleType] = useState("password");
    const [fadeAnimation, setFadeAnimation] = useState(false);
    const [spinLoading, setSpinLoading] = useState(false);
    const [loginOtp, setLoginOtp] = useState(false);
    const [codeValue, setCodeValue] = useState("");
    const [codeError, setCodeError] = useState("");
    const [latitude, setLatitude] = useState<number>();
    const [longitude, setLongitude] = useState<number>();
    const [expireStatus, setExpireStatus] = useState<number>(0);
    console.log('expireStatus',expireStatus);
    
    const [recap, setReCap] = useState<any>(false)
    const [browserInfo, setBrowserInfo] = useState<string>('');
    const [osName, setOsName] = useState("");
    const [email, setEmail] = useState<string>('');
    const [show, setShow] = useState(false);
    const [requiredPass, setRequiredPass] = useState(false);
    const handleClose = () => setShow(false);
    const [googleAccess, setGoogleAccess] = useState("");
    const osNameRef = useRef<string>('');
    const browserInfoRef = useRef<string>('');
    const latitudeRef = useRef<number>();
    const longitudeRef = useRef<number>();
    const accessLocationRef = useRef<string>('');
    const [userId, setUserId] = useState("");
    const [externalData, setExternalData] = useState({});
    const [requiredMFA, setRequiredMFA] = useState(false)
    const [secretCode, setSecretCode] = useState(false)
    const [qrImage, setQrImage] = useState("");
    const [updatePayment, setUpdatePayment] = useState(false);
    const [upgradeShow, setUpgradeShow] = useState(false);
    const handleUpgradeClose = () => setUpgradeShow(false);

    const handleVerificationCodeChange = (code: string) => {
        setCodeValue(code)
    };

    useEffect(() => {
        setFadeAnimation(true);
        // windows name and ps name
        const userAgent = window.navigator.userAgent;
        const parser = new UAParser(userAgent);
        const result = parser.getResult();
        setBrowserInfo(`${result.browser.name}`);
        if (userAgent.indexOf('Win') !== -1) {
            setOsName('Windows');
        } else if (userAgent.indexOf('Mac') !== -1) {
            setOsName('MacOS');
        } else if (userAgent.indexOf('Linux') !== -1) {
            setOsName('Linux');
        } else if (userAgent.indexOf('Android') !== -1) {
            setOsName('Android');
        } else if (userAgent.indexOf('iOS') !== -1) {
            setOsName('iOS');
        } else {
            setOsName('Unknown'); // Fallback for unrecognized OS
        }

        // location name
        navigator.geolocation.getCurrentPosition((position) => {
            const latitude = position.coords.latitude;
            const longitude = position.coords.longitude;
            setLatitude(latitude);
            setLongitude(longitude);
            const apiKey = 'AIzaSyB1xd6-NIxRPU0UYej-blqi3_eVlccWOQI';
            const apiUrl = `https://maps.googleapis.com/maps/api/geocode/json?latlng=${latitude},${longitude}&key=${apiKey}`;
            axios
                .get(apiUrl)
                .then((response) => {
                    const { results } = response.data;
                    if (results && results.length > 0) {
                        const shortNameArray = [];
                        for (const component of results[0].address_components) {
                            if (
                                component.types.includes("country") ||
                                component.types.includes("administrative_area_level_1") ||
                                component.types.includes("administrative_area_level_2")
                            ) {
                                shortNameArray.push(component.short_name);
                            }
                        }
                        setLocationName(shortNameArray);
                    } else {
                        setLocationName(['Location not found']);
                    }
                })
                .catch((error) => {
                    console.error('Error fetching location data', error);
                });
        });

    }, []);


    useEffect(() => {
        osNameRef.current = osName;
        browserInfoRef.current = browserInfo;
        latitudeRef.current = latitude;
        longitudeRef.current = longitude;
        const delimiter = ', ';
        const locationMark = locationName.join(delimiter);
        accessLocationRef.current = locationMark;
    }, [osName]);

    const {
        register,
        handleSubmit,
        setValue,
    } = useForm();

    let onSubmit = (user: any) => {
        setSpinLoading(true)
        const { email, password } = user;
        setEmail(email)
        const delimiter = ', ';
        const location = locationName.join(delimiter);
        const loginObj: any = {
            "email": email,
            "password": password,
            "os": osName,
            "browser": browserInfo,
            "latitude": latitude,
            "longitude": longitude,
            "accessLocation": location
        }
        if (googleAccess) {
            loginObj["thirdPartyToken"] = googleAccess;
            loginObj["thirdPartyApp"] = "GOOGLE";
        }

        if (!loginOtp) {
            login(loginObj).then((data) => {
                if (data.statusCode != 200) {
                    setLoginError(data.message)
                    setSpinLoading(false)
                    setExpireStatus(data.status)
                }
                else {
                   
                    setUserId(data.userId);
                    setRequiredMFA(data.requiredMFA);
                    setSecretCode(data.isSecretAlreadyGenerated);
                    if (data.user) {
                        if (data.user.passwordReset === true) {
                            setSpinLoading(false)
                            setShow(true);
                            setRequiredPass(data.user.requireStrongPassword);
                        }
                        else {
                            authenticate(data, () => {
                                setTimeout(() => {
                                    setSpinLoading(false)
                                    setValue("email", "", { shouldValidate: false });
                                    setValue("password", "", { shouldValidate: false });
                                    setLoginError("")
                                    return navigate("/dashboard");
                                }, 500)

                            })
                        }
                    }
                    else {
                        setSpinLoading(false)
                        setLoginOtp(true)
                        setValue("email", "", { shouldValidate: false });
                        setValue("password", "", { shouldValidate: false });
                    }
                }
            })
        }
        else {
            setSpinLoading(false)
        }
    }

    const showPass = () => {
        setTogglePassword(!togglePassword)
        setToggleType("password")
    }

    const hidePass = () => {
        setTogglePassword(!togglePassword)
        setToggleType("")
    }

    function onChange(value: any) {
        if (value.length > 0) {
            setReCap(true)
        }
    }

    const tryLoginWithGoogleOAuth = (userData: any) => {
        setEmail(userData.email);
        setSpinLoading(true);
        if (userData.email.length === 0) {
            setSpinLoading(true);
        }
        else {

            const googleLoginObj: any = {
                "email": userData.email,
                "thirdPartyToken": userData.access_token,
                "ThirdPartyApp": "GOOGLE",
                "os": osNameRef.current,
                "browser": browserInfoRef.current,
                "latitude": latitudeRef.current,
                "longitude": longitudeRef.current,
                "accessLocation": accessLocationRef.current,
            }

            login(googleLoginObj).then((data) => {

                if (data.statusCode != 200) {
                    showNotifications("error", "This email is not registered !!")
                    setSpinLoading(false)
                }
                else {
                    setUserId(data.userId);
                    setRequiredMFA(data.requiredMFA);
                    setSecretCode(data.isSecretAlreadyGenerated);
                    if (data.user) {
                        if (data.user.passwordReset === true) {
                            setSpinLoading(false)
                            setShow(true);
                            setRequiredPass(data.user.requireStrongPassword);
                        }
                        else {
                            authenticate(data, () => {
                                setTimeout(() => {
                                    setSpinLoading(false);
                                    setLoginError("")
                                    return navigate("/dashboard");
                                }, 500)
                            })
                        }
                    }
                    else if(data.requiredMFA === true) {
                        setLoginOtp(false)
                    }
                    else {
                        setLoginOtp(true)
                    }


                }
            })
            setSpinLoading(false);
        }
    };


    const validateCheck = () => {
        const delimiter = ', ';
        const location = locationName.join(delimiter);
        const loginObj: any = {
            "email": email,
            "otp": parseInt(codeValue),
            "otpType":  requiredMFA ? "MFA" : "2FA",
            "os": osName,
            "browser": browserInfo,
            "latitude": latitude,
            "longitude": longitude,
            "accessLocation": location
        }
        loginOtpCheck(loginObj).then((data) => {
            if (data.statusCode !== 200) {
                setCodeError("Please insert valid code")
            }
            else {
                setCodeError("")
                authenticate(data, () => {
                    setTimeout(() => {
                        setSpinLoading(false);
                        setLoginError("")
                        return navigate("/dashboard");
                    }, 500)
                })
            }
        })
    }

    // re-send code
    const reSend = () => {
        let reSendInfo = {
            "requestType": "login",
            "processableID": userId
        }
        reSendCode(reSendInfo).then((data: any) => {
            if (data.statusCode !== 200) {
                showNotifications('error', data.message);
            }
            else {
                showNotifications('success', data.message);
            }
        })
    }


    const qrCode = () => {
        let qrInfo = {
            "email": email
        }
        qrGenerate(qrInfo).then((data: any) => {
            setQrImage(data.qrCodeURL)
            setSecretCode(true)
        })
    }

    const accountUpdate = () => {
setUpgradeShow(true);
    }

    return (
        <>
            {spinLoading && <Loader />}
            <ToastContainer />
            <div className={`fade-in Auth-form-container ${fadeAnimation ? 'visible' : ''}`}>
                <div className="loginBox">
                    <div className="loginForm">
                        <Link to="/"><img src={logo} alt="" /></Link>
                        <form className="Auth-form" onSubmit={handleSubmit(onSubmit)}>
                            <div className="Auth-form-content">
                                <h3>Log into docoteam</h3>
                                {qrImage ? <img src={qrImage} alt="qr-code" /> : "" }
                                {requiredMFA ? <>
                                    {requiredMFA && !secretCode ? <>
                                    <div className="appInstruction">
                                        <h6>Set up Google Authenticator</h6>
                                        <ul>
                                            <li>Download the from your device app store</li>
                                            <li>Install the app and sign-in using your google account</li>
                                            <li>Tap the + icon to scan the QR code</li>
                                        </ul>
                                        <span className='resend' onClick={qrCode}>View QR Code</span>
                                    </div>
                                </> : <>
                                <div className="appInstruction">
                                        <h6>Please open your app to insert the code</h6>
                                        <div id="otp" className="verificationCode inputs d-flex flex-row justify-content-center mt-3 flex-column">
                                        <VerificationInput
                                            onChange={handleVerificationCodeChange}
                                            length={6}
                                            validChars="0-9"
                                            inputProps={{ inputMode: "numeric" }}
                                            placeholder=""
                                            classNames={{
                                                container: "container",
                                                character: "character",
                                                characterInactive: "character--inactive",
                                                characterSelected: "character--selected",
                                            }}
                                        />
                                        {codeError && codeError.length ? <p className='errMsg text-center'>{codeError}</p> : ''}
                                        {/* <span className='resendQr' onClick={qrCode}>Forget Password</span> */}
                                        <div className="mt-2"> <button type="submit" className='validate' onClick={validateCheck}>Validate</button></div>
                                    </div>
                                    
                                    </div>
                                </>}
                                </>:""}
                                

                                 {loginOtp ? <>
                                    {requiredMFA ? <></>:<>
                                    <div id="otp" className="verificationCode inputs d-flex flex-row justify-content-center mt-3 flex-column">
                                        <VerificationInput
                                            onChange={handleVerificationCodeChange}
                                            length={6}
                                            validChars="0-9"
                                            inputProps={{ inputMode: "numeric" }}
                                            placeholder=""
                                            classNames={{
                                                container: "container",
                                                character: "character",
                                                characterInactive: "character--inactive",
                                                characterSelected: "character--selected",
                                            }}
                                        />
                                        {codeError && codeError.length ? <p className='errMsg text-center'>{codeError}</p> : ''}
                                        <p>Please check your e-mail for the OTP number</p>
                                        <span className='resend' onClick={reSend}>Resend Code</span>
                                        <div className="mt-2"> <button type="submit" className='validate' onClick={validateCheck}>Validate</button></div>
                                    </div>
                                    </>}
                                    
                                </> : <>
                                {requiredMFA ? "" : <>
                                {loginError && loginError.length ? <p className='errMsg text-center'>{loginError}</p> : <></>}
                                {expireStatus === 401 ?  <p className='upgradeBtn' onClick={accountUpdate}>Upgrade account</p> : ''}
                               
                                  
                                    <div className="loginInput">
                                        <input
                                            type="email"
                                            id="email"
                                            placeholder="Email address"
                                            {...register("email", { required: true })}
                                        />
                                    </div>

                                    <div className="loginInput">
                                        <input
                                            type={toggleType}
                                            id="password"
                                            placeholder="Password"
                                            {...register("password", { required: true })}
                                        />
                                        {togglePassword ? <span onClick={() => showPass()}><FontAwesomeIcon icon={faEye} /> </span> : <span onClick={() => hidePass()}><FontAwesomeIcon icon={faEyeSlash} /></span>}
                                    </div>
                                    <div className="forgot-password">
                                        <Link to="/account-recovery">Forgot password?</Link>
                                    </div>
                                    <div className="registrationBtn">
                                        <button type='submit'>Log In</button>
                                    </div>
                                    <div className='googleBtn mt-3'>
                                        <LoginSocialGoogle
                                            client_id={GOOGLE_CLIENT_ID || ""}
                                            isOnlyGetToken={false}
                                            scope="openid profile email"
                                            discoveryDocs="claims_supported"
                                            onResolve={({ provider, data }: IResolveParams) => {
                                                tryLoginWithGoogleOAuth(data);
                                            }}
                                            onReject={err => {
                                                console.log(err);
                                            }}
                                        >
                                            <span><img src="https://cdn1.iconfinder.com/data/icons/google-s-logo/150/Google_Icons-09-512.png" width="50px" alt="" /></span>
                                        </LoginSocialGoogle>
                                    </div>
                                </>}
                                   
                                </>} 
                               
                                <div className="loginLink">
                                    <Link to='/choose-plan'>Create a account</Link>
                                </div>
                            </div>
                        </form>
                    </div>
                    <div className="loginContent">
                        <div className="dashImage">
                            <img src={loginContent} alt="" />
                        </div>
                    </div>
                </div>
                <UpdatePassword requiredPass={requiredPass} show={show} setShow={setShow} handleClose={handleClose} email={email} />
                <UpgradePlan email={email} setUpdatePayment={setUpdatePayment} upgradeShow={upgradeShow} setUpgradeShow={setUpgradeShow} handleUpgradeClose={handleUpgradeClose} />
            </div>
        </>
    )
}

export default Login
