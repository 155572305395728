import React from 'react';
import { ProgressBar } from 'react-bootstrap';
import "./ProgressLine.css";

interface ProgressProps {
    attachmentList: any;
}

const ProgressLine = ({ attachmentList }: ProgressProps) => {

    return (
        <div className='progressBox'>
            <h6>Uploading</h6>
            {attachmentList.map((data: any, index: number) => <div key={`progressFile` + index}>
                <p>{data.fileName.length > 25 ? `${data.fileName.substring(0, 25)}...` : data.fileName}</p>
                <p>{data.progress ? data.progress : 0}%</p>
                <ProgressBar variant="info" now={data.progress} />
            </div>)}
        </div>
    )
}

export default ProgressLine