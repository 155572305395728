import React, { useRef, useState } from 'react'
import { Col, Table, Modal } from 'react-bootstrap';
import dragAndDrop from '../../assets//dragDrop/dragDrop.png';
import { v4 as uuidv4 } from 'uuid';
import SmallButton from '../../styleComponent/SmallButton/SmallButton';
import Button from '../../styleComponent/Button/Button';
import excel from '../../assets/icon/excel.png'
import pdf from '../../assets/icon/pdf.png'
import word from '../../assets/icon/word.png'
import powerpoint from '../../assets/icon/powerpoint.png'
import { splitNameAndExtension } from '../../helpers/generalUtil';
import { AttachmentInterface, AttachmentUPloadInterface } from '../CommonInterface/CommonInterface';
import requestState from '../../helpers/requestState';


interface itemListProps {
    addItemFunction: () => void;
    setErrorItem: React.Dispatch<React.SetStateAction<string>>;
    itemUpdateValue: () => void;
    updateButton: boolean;
    addNoteValue: string;
    setAddNoteValue: React.Dispatch<React.SetStateAction<string>>;
    viewBtn: boolean;
    setViewBtn: any;
    setAttachmentList: (attach: AttachmentInterface[]) => void;
    attachmentList: AttachmentInterface[];
    setAttachmentUploadList: (attachUploadId: AttachmentUPloadInterface[]) => void;
    attachmentUploadList: AttachmentUPloadInterface[];
    addEmployee: () => void;
    userIds: any;
}
const DragDrop = ({ userIds, addEmployee, attachmentUploadList, setAttachmentUploadList, attachmentList, setAttachmentList, viewBtn, setViewBtn, addNoteValue, setAddNoteValue, updateButton, itemUpdateValue, setErrorItem, addItemFunction }: itemListProps) => {

    let attachmentId = uuidv4();
    const wrapperRef = useRef<HTMLInputElement>(null);
    const [show, setShow] = useState(false);
    const [notesId, setNotesId] = useState("")
    // notes modal
    const noteShow = (attachmentUploadLockId: string) => {
        setNotesId(attachmentUploadLockId)
        setShow(true);
    };

    // notes value
    const addNoteFunction = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
        setAddNoteValue(event.target.value)
    }
    const updateFiles = (newFiles: typeof requestState.files) => {
        requestState.updateFiles(newFiles);
    };
    async function fileToBase64(file: File): Promise<string> {
        return new Promise<string>((resolve, reject) => {
            const reader = new FileReader();
            reader.onload = () => {
                resolve(reader.result?.toString().split(',')[1] || '');
            };
            reader.onerror = reject;
            reader.readAsDataURL(file);
        });
    }
    async function saveFileInLocalStorage(file: File) {
        try {
            const base64String = await fileToBase64(file);
            const fileData = {
                name: file.name,
                type: file.type,
                size: file.size,
                base64: base64String
            };
            const existingFilesJson = localStorage.getItem('requestFile');
            let existingFiles: any[] = [];
            if (existingFilesJson) {
                existingFiles = JSON.parse(existingFilesJson);
            }
            const uploadFileObj = {
                "attachmentUploadLockId": attachmentId,
                "file": fileData
            };
            existingFiles.push(uploadFileObj);
            updateFiles(existingFiles);
        } catch (error) {
            console.error('Error saving file:', error);
        }
    }

    const onFileDrop = (event: any) => {
        const newFile = event.target.files[0];
        const fileNameExtension = splitNameAndExtension(newFile.name)
        if (newFile) {
            saveFileInLocalStorage(newFile);
        }

        let fileObj: AttachmentInterface = {
            "attachmentUploadLockId": attachmentId,
            "fileName": fileNameExtension.name,
            "extension": fileNameExtension.extension,
            "originalFileName": newFile.name,
            "isRequireSignature": false,
            "notes": "",
            "size": newFile.size
        }
        let uploadFileObj = {
            "attachmentUploadLockId": attachmentId,
            "file": newFile
        }
        setAttachmentUploadList([...attachmentUploadList, uploadFileObj])
        setAttachmentList([...attachmentList, fileObj])
        setErrorItem("")
    }

    // file remove
    const fileRemove = (attachmentUploadLockId: string) => {
        const filteredItems = attachmentList.filter(item => item.attachmentUploadLockId !== attachmentUploadLockId);
        setAttachmentList(filteredItems)
        requestState.removeFilesByUploadLockId(attachmentUploadLockId);
    }

    // notes close and value save
    const notesClose = () => {
        const updatedItems = attachmentList.map((item) => {
            if (item.attachmentUploadLockId === notesId) {
                return { ...item, notes: addNoteValue };
            }
            return item;
        });
        setAttachmentList(updatedItems);
        setShow(false);
        setAddNoteValue("")
        // view button change
        setViewBtn(true)
    };

    //   view notes values show and update
    const notesValue = (attachmentUploadLockId: string) => {
        setNotesId(attachmentUploadLockId)
        attachmentList.map((item) => {
            if (item.attachmentUploadLockId === attachmentUploadLockId) {
                setAddNoteValue(item.notes)
            }
        });
        setShow(true);
    };

    return (
        <>

            <Col md={4}>
                <div ref={wrapperRef} className="drop-file-input">
                    <div className="drop-file-input__label">
                        <img src={dragAndDrop} alt="" />
                        <p>Drag & Drop your files here</p>
                        <div className="bottomIcon">
                            <img src={pdf} alt="" />
                            <img src={powerpoint} alt="" />
                            <img src={word} alt="" />
                            <img src={excel} alt="" />
                        </div>
                    </div>
                    <input type="file" value="" onChange={onFileDrop} />
                </div>
            </Col>
            <Col md={8} style={{ position: 'relative' }}>
                <div className="requestFileList">
                    <Table responsive className='tableBody'>
                        <thead>
                            <tr>
                                <th>File Name</th>
                                <th>Extension</th>
                                <th></th>
                            </tr>
                        </thead>
                        <tbody>
                            {attachmentList && attachmentList.length ? attachmentList.map((item, index: number) => (
                                <tr key={`contact` + index} className="fileUploadText">
                                    <td>{item.fileName.length > 10 ? item.fileName.substring(0, 8) + '..' : item.fileName}</td>
                                    <td>{item.extension}</td>
                                    <td className='tableInnerButton'>
                                        {item.notes.length > 0 ?
                                            <SmallButton customWidth="71" customHeight="26" customBackgroundColor='#F94A29' customBorderColor='#F94A29' customTextColor='#fff' onClick={() => notesValue(item.attachmentUploadLockId)} className="mr-2">View Note</SmallButton> :
                                            <SmallButton customWidth="71" customHeight="26" customBackgroundColor='#F94A29' customBorderColor='#F94A29' customTextColor='#fff' onClick={() => noteShow(item.attachmentUploadLockId)} className="mr-2">Add Note</SmallButton>}
                                        <SmallButton customWidth="71" customHeight="26" customBackgroundColor='#fff' customBorderColor='#F94A29' customTextColor='#F94A29' onClick={() => fileRemove(item.attachmentUploadLockId)}>Remove</SmallButton>

                                    </td>
                                </tr>
                            )) : ""}
                        </tbody>
                    </Table>
                </div>
            </Col>
            <Col md={12}>
                <div className='addEmployeeBtn'>
                    <button className='addEmployee' onClick={addEmployee}>Add Users to Request {userIds && userIds.length === 0 ? "" : userIds.length}</button>
                    {updateButton && updateButton ? <>
                        <button className='addItemList' onClick={itemUpdateValue}>Update</button>
                    </> : <>
                        <button className='addItemList' onClick={addItemFunction}>Add Item</button>
                    </>}
                </div>
            </Col>

            {/* Note view */}
            <Modal show={show} onHide={notesClose} backdrop="static" keyboard={false}>
                <Modal.Header closeButton>
                    <Modal.Title>Add Note</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="addNote">
                        <h1>Other Information</h1>
                        <textarea value={addNoteValue} onChange={addNoteFunction}></textarea>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button customBackgroundColor="#F94A29" customBorderColor="#F94A29" customTextColor="#fff" onClick={notesClose}>Save</Button>
                </Modal.Footer>
            </Modal>
        </>
    )
}

export default DragDrop