import { post, get, del } from './base-api';

// create new contact
export const createContact = (contactInfo: any) => {
    return post(`/contacts`, contactInfo);
};
// get user all contacts
export const myContacts = (limit: number,page: number,filterTag: string,searchString: string) => {
    return get(`/contacts/mycontacts/?limit=${limit}&page=${page}&filterBy=${filterTag}&searchString=${searchString}`);
};
// get single contacts
export const singleContact = (contactId:string) => {
    return get(`/contacts/${contactId}`);
};
// delete  contact
export const deleteContactApi = (contactId: string) => {
    return del(`/contacts/${contactId}`);
};
// update contact
export const updateContact = (contactId:string,contactInfo: any) => {
    return post(`/contacts/${contactId}`, contactInfo);
};

// restore data
