import styled from 'styled-components';

type Props = {
  customBackgroundColor: string,
  customBorderColor: string,
  customTextColor: string
}

export default styled.button<Props>`
background: ${(props) => props.customBackgroundColor};
box-shadow: 2px 6px 20px 2px rgba(0, 0, 0, 0.15);
border-radius: 5px;
font-family: 'Inter', sans-serif;
font-style: normal;
font-weight: 600;
font-size: 16px;
line-height: 19px;
color: ${(props) => props.customTextColor};
padding: 14px 30px;
border: none;
border: 1px solid  ${(props) => props.customBorderColor};
text-decoration: none;
`