import React, { useState, useEffect } from 'react';
import { Table, Modal } from 'react-bootstrap';
import SmallButton from '../../styleComponent/SmallButton/SmallButton';
import { fileDownloadApi, singleSentFileApi } from '../../api/auth';
import { downloadFilesAsZip } from '../../helpers/fileDownload';
import logoIcon from '../../assets/logo/clearLogo.png';
import excel from "../../assets/icon/excelFile.png";
import video from "../../assets/icon/youtube.png";
import audio from "../../assets/icon/volume.png";
import img from "../../assets/icon/image-gallery.png"
import Loader from '../CommonCompoment/Loader/Loader';
import { showNotifications } from '../../helpers/toaster';
import { ToastContainer } from 'react-toastify';
import { FileObjectInterface } from '../CommonInterface/CommonInterface';
import { getCookieValue } from '../CommonInterface/CommonFunction';
import { Link } from 'react-router-dom';

const DownloadFile = () => {

  const [tableList, setTableList] = useState<string>("list");
  const sentToken = JSON.parse(getCookieValue('sentId') || "");
  const [sentTitle, setSentTitle] = useState("")
  const [sentFileList, setSentFileList] = useState<FileObjectInterface[] | undefined>();
  const [spinLoading, setSpinLoading] = useState<boolean>(false);
  const [allSentId, setAllSentId] = useState([]);
  const [sentId, setSentId] = useState("")
  const [selectedFile, setSelectedFile] = useState<string[]>([]);
  // modal notes
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const [viewNotes, setViewNotes] = useState("")

  function bytesToMB(size: string) {
    const bytes = parseInt(size) / (1024 * 1024);
    if (bytes < 0.50) {
      return (bytes * 1024).toFixed(2) + " KB";
    }
    else {
      return bytes.toFixed(2) + " MB";
    }
  }
  // single file info
  const onLoadItem = () => {
    singleSentFileApi(sentToken.sendId, sentToken.token).then((data) => {
      if (data.statusCode !== 200) {
        showNotifications("error", "No items show");
      } else {
        setSentId(data.body.sendId);
        setAllSentId(data.body);
        const filePromises: any = [];
        const filesWithData = data.body.files.map((file: any) => {
          const promise = fileDownloadApi(file.fileId, sentToken.token).then((fileData) => {
            if (fileData.statusCode !== 200) {
              return { ...file, downloadUrl: null };
            } else {
              return { ...file, downloadUrl: fileData.body.downloadUrl };
            }
          });

          filePromises.push(promise);
        });
        Promise.all(filePromises).then((filesWithDownloadUrls) => {
          setSentFileList(filesWithDownloadUrls);
        });

        setSentTitle(data.body.settings.title);
      }
    });
  }

  useEffect(() => {
    onLoadItem()
  }, []);

  // notes
  const handleShow = (itemNotes: string) => {
    setShow(true);
    setViewNotes(itemNotes)
  }

  const downloadFile = (fileId: string) => {
    setSpinLoading(true)
    fileDownloadApi(fileId, sentToken.token).then((data) => {
      if (data.statusCode !== 200) {
        console.log('error', data);
      }
      else {
        window.location.href = data.body.downloadUrl;
        setSpinLoading(false)
      }
    })
  }

  // download all
  const sentAllDownloadFile = async (allSentId: any) => {
    setSpinLoading(true);
    const urls: string[] = []
    for (let i = 0; i < allSentId.files.length; i++) {
      const response = await fileDownloadApi(allSentId.files[i].fileId, sentToken.token);
      if (!response.body || !response.body.downloadUrl) {
        throw new Error("Invalid response from server");
      }
      urls.push(response.body.downloadUrl);
    }
    if (urls.length === 0) {
      setSpinLoading(false)
    }
    else {
      downloadFilesAsZip(urls, allSentId.sendId, setSpinLoading)
    }
  }

  // download only selected file
  const sentSelectDownloadFile = async () => {
    if (selectedFile.length === 0) {
      showNotifications("error", "Please selected some items");
    }
    else {
      setSpinLoading(true);
      const urls: string[] = [];
      for (let i = 0; i < selectedFile.length; i++) {
        const response = await fileDownloadApi(selectedFile[i], sentToken.token);
        if (!response.body || !response.body.downloadUrl) {
          throw new Error("Invalid response from server");
        }
        urls.push(response.body.downloadUrl);
      }

      if (urls.length === 0) {
        setSpinLoading(false)
      }
      else if (urls.length === 1) {
        downloadFile(selectedFile[0])
      }
      else {
        downloadFilesAsZip(urls, sentId, setSpinLoading)
      }
    }
  }

  // list and grid option
  const listGrid = (fileId: string) => {
    setTableList(fileId);
    setSelectedFile([]);
  }

  // selected list
  const selectedList = (fileId: string) => {
    if (selectedFile.includes(fileId)) {
      const updatedSelectedFile = selectedFile.filter((id) => id !== fileId);
      setSelectedFile(updatedSelectedFile);
    } else {
      setSelectedFile([...selectedFile, fileId]);
    }
  }

  function areAllUploadsComplete(files: FileObjectInterface[] | undefined) {
    return files?.every((file: FileObjectInterface) => file.isUploadComplete === true);
  }
  const allUploadsComplete = areAllUploadsComplete(sentFileList);
  const isSelectIdSelected = (selectId: string) => {
    return selectedFile.includes(selectId);
  };

  return (
    <>
      {spinLoading && <Loader />}
      <ToastContainer />
      <section className="downloadFileSection">
        <Link to="/" className='logo navbar-brand'>
          <img src={logoIcon} alt="" style={{ marginBottom: "15px" }} width="210px" />
        </Link>

        <div className="downloadFileUpload">
          {sentFileList && sentFileList ? <>
            <div className="downloadFile">
              <div className='d-flex justify-content-between align-items-center flex-wrap'>
                <h1>Title: {sentTitle}</h1>
                <div className='d-flex justify-content-between align-items-center'>
                  <SmallButton style={{ marginRight: '6px' }} customWidth="140" customHeight="30" customBackgroundColor='#fff' customBorderColor='#F94A29' customTextColor='#F94A29' onClick={() => sentSelectDownloadFile()}>Download Selected</SmallButton>
                  <SmallButton disabled={allUploadsComplete === true ? false : true} customWidth="90" customHeight="30" customBackgroundColor={allUploadsComplete === true ? '#F94A29' : '#eee'} customBorderColor='#F94A29' customTextColor='#fff' onClick={() => sentAllDownloadFile(allSentId)}>Download all</SmallButton>
                </div>
              </div>
              <div className='d-flex justify-content-left align-items-center mt-3 mb-3'>
                <SmallButton style={{ marginRight: '15px', boxShadow: '0px 0px 10px 0px #0000001A' }} customWidth="93" customHeight="30" customBackgroundColor='#fff' customBorderColor={tableList === "list" ? '#F94A29' : '#fff'} customTextColor={tableList === "list" ? '#F94A29' : '#747679'} onClick={() => listGrid("list")}>List View</SmallButton>
                <SmallButton style={{ boxShadow: '0px 0px 10px 0px #0000001A' }} customWidth="96" customHeight="30" customBackgroundColor='#FFF' customBorderColor={tableList === "grid" ? '#F94A29' : '#fff'} customTextColor={tableList === "grid" ? '#F94A29' : '#747679'} onClick={() => listGrid("grid")}>Grid View</SmallButton>
              </div>
              <div className="listShowView">
                {tableList && tableList === "list" ?
                  <>
                    <Table responsive className='tableBody sentTable'>
                      <thead>
                        <tr>
                          <th></th>
                          <th>File Name</th>
                          <th>File size</th>
                          <th></th>
                        </tr>
                      </thead>
                      <tbody>
                        {sentFileList && sentFileList.map((data: any, index: number) =>
                          <tr key={`tableList` + index}>
                            <td>
                              <label className='tableCheckBox mobileTable'>
                                <div className="contactCheck">
                                  <input type="checkbox" onChange={() => selectedList(data.fileId)} />
                                  <span className="checkmark"></span>
                                </div>
                              </label>
                            </td>
                            <td>{data.fileName && data.fileName}</td>
                            <td>{bytesToMB(data.size)}</td>
                            <td>
                              {data.notes && data.notes.length > 0 ?
                                <SmallButton customWidth="90" customHeight="30" customBackgroundColor='#fff' customBorderColor='#F94A29' customTextColor='#F94A29' className='mr-2' onClick={() => handleShow(data.notes)}>View note</SmallButton> :
                                <></>}
                              <SmallButton disabled={data.isUploadComplete === true ? false : true} customWidth="90" customHeight="30" customBackgroundColor={data.isUploadComplete === true ? '#fff' : '#ddd'} customBorderColor='#F94A29' customTextColor='#F94A29' onClick={() => downloadFile(data.fileId)}>Download</SmallButton>
                            </td>
                          </tr>
                        )}
                      </tbody>
                    </Table>
                  </>
                  : <>
                    <div className='girdView'>
                      {sentFileList && sentFileList.map((data: any, index: number) =>
                        <div className="gridBox" key={`gridFile` + index}>
                          <div className="imageHeading">
                            <div className="gridCheck">
                              <label className='tableCheckBox mobileTable'>
                                <div className="contactCheck">
                                  <input type="checkbox" checked={isSelectIdSelected(data.fileId)} onChange={() => selectedList(data.fileId)} />
                                  <span className="checkmark"></span>
                                </div>
                              </label>
                            </div>

                            {["png", "jpg", "gif", "bmp", "svg", "ico", "webp"].includes(data.extension.toLowerCase()) && (
                              <img className='notFound' src={img} alt={data.fileName && data.fileName} />
                            )}
                            {["mp4", "3gp", "avi", "mkv", "mov", "wmv", "ogv"].includes(data.extension.toLowerCase()) && (
                              <>
                                <img className='notFound' src={video} alt={data.fileName && data.fileName} />
                              </>
                            )}
                            {["docx", "txt", "csv", "json", "doc", "pdf"].includes(data.extension.toLowerCase()) && (
                              <>
                                <img className='notFound' src={excel} alt={data.fileName && data.fileName} />
                              </>
                            )}
                            {["mp3", "wav", "ogg"].includes(data.extension.toLowerCase()) && (
                              <img className='notFound' src={audio} alt={data.fileName && data.fileName} />
                            )}
                          </div>
                          <p className={data.fileName && data.fileName.length > 22 ? 'tooltip-top' : ""} data-tooltip={data.fileName && data.fileName.length > 22 ? data.fileName : ""}>
                            {data.fileName && data.fileName.length > 22 ? data.fileName.substring(0, 20) + '..' : data.fileName}
                          </p>
                          <div className="gridBottom">
                            {data.notes && data.notes.length > 0 ?
                              <SmallButton customWidth="100" customHeight="30" customBackgroundColor='#fff' customBorderColor='#F94A29' customTextColor='#F94A29' className='mr-2' onClick={() => handleShow(data.notes)}>View note</SmallButton> :
                              <></>}
                            <SmallButton customWidth="100" customHeight="30" customBackgroundColor='#fff' customBorderColor='#F94A29' customTextColor='#F94A29' onClick={() => downloadFile(data.fileId)}>Download</SmallButton>
                          </div>
                        </div>
                      )}
                    </div>
                  </>}
              </div>

            </div>
          </> : <Loader></Loader>}

        </div>


        <Modal
          show={show}
          onHide={handleClose}
          backdrop="static"
          keyboard={false}
        >
          <Modal.Header closeButton>
            <Modal.Title>View Note</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="addNote">
              <textarea value={viewNotes}></textarea>
            </div>
          </Modal.Body>
        </Modal>
      </section>
    </>
  )
}

export default DownloadFile