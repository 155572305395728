import { post, get, del, put } from './base-api';

// create new contact
export const createPayment = (cardInfo: any) => {
    return post(`/payments`, cardInfo);
};

// change billing
export const changeBilling = (packageDetails = {}) => {
    return post(`/payments/upgrade-billing`, packageDetails);
};

// change billing
export const freeBilling = (packageDetails = {}) => {
    return post(`/payments/upgrade-free-billing`, packageDetails);
};

// billing history
export const billingHistory = () => {
    return get(`/payments/billing-history`);
};

// billing info
export const billingInfo = () => {
    return get(`/payments/billing-info`);
};

// billing info update
export const updateBillingInfo = (billingInfo = {}) => {
    return put(`/payments/billing-info`, billingInfo);
};

// change billing
export const generateBilling = (paymentInfo = {}) => {
    return post(`/users/generate-payment-secret`, paymentInfo);
};

