import React, { useState, useEffect } from 'react'
import { Col, Modal, Row, Table } from 'react-bootstrap'
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';

import { RecipientUser } from '../CommonInterface/CommonInterface';

interface TwoAuthProps {
  AuthModalClose: () => void;
  authShow: boolean;
  recipientsValue: RecipientUser[];
  setRecipientsValue: (recipient: RecipientUser[]) => void;
  setReceiptShow: any;
  setAuthShow: any;
}
const TwoFA = ({ setAuthShow, setReceiptShow, setRecipientsValue, authShow, AuthModalClose, recipientsValue }: TwoAuthProps) => {

  const [phoneNumbers, setPhoneNumbers] = useState<any[]>([]);
  const [phoneError, setPhoneError] = useState("")
  const handlePhoneChange = (value: string, index: number) => {
    setPhoneNumbers((prevPhoneNumbers) => {
      const newPhoneNumbers = [...prevPhoneNumbers];
      newPhoneNumbers[index] = value;
      return newPhoneNumbers;
    });
  };

  function extractPhoneNumbers(contacts: RecipientUser[]) {
    const phoneNumbers = contacts.map(contact => contact.phoneNumber.replace(/\+/g, ''));
    return phoneNumbers;
  }

  useEffect(() => {
    const phonePrevList = extractPhoneNumbers(recipientsValue)
    if (phonePrevList.length > 0) {
      setPhoneNumbers(phonePrevList)
    }
  }, [recipientsValue])


  function checkData(arr: string[]) {
    for (let i = 0; i < arr.length; i++) {
      if (!arr[i]) {
        return false;
      }
    }
    return true;
  }
  const allDataAvailable = checkData(phoneNumbers);
  // two factor
  const numberAdd = () => {
    let tempArr = [];
    for (let i = 0; i < recipientsValue.length; i++) {
      const updatedContact = {
        ...recipientsValue[i],
        phoneNumber: `+${phoneNumbers[i]}`
      };
      tempArr.push(updatedContact)
    }
    setRecipientsValue(tempArr);

    function hasValidPhoneNumber(tempArr: any) {
      return tempArr.some((contact: any) => contact.phoneNumber === "+undefined");
    }
    if (allDataAvailable === true && phoneNumbers.length > 0 && hasValidPhoneNumber(tempArr) === false) {
      setReceiptShow(true)
      setAuthShow(false)
      setPhoneError("")
    }
    else {
      setPhoneError("Please enter all the phone number")
    }
  };

  return (
    <>
      <Modal show={authShow} className="recipientModalBody" size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered>
        <Modal.Body >
          <div className="requestModal">
            <Row>
              <Col md={12} className=''>
                <h1 className='sentHeading'>Phone number for 2-Factor-Authentication (SMS)</h1>
              </Col>
              <Col md={12}>
                <div className='factorTable'>
                  <Table responsive className='tableBody text-center'>
                    <thead>
                      <tr>
                        <th>#</th>
                        <th>Recipient</th>
                        <th>Phone</th>
                      </tr>
                    </thead>
                    <tbody>
                      {recipientsValue && recipientsValue.map((data: any, index: number) =>
                        <tr key={`factor` + index}>
                          <td>{index + 1}</td>
                          <td className='nameText'>{data.fullName}</td>
                          <td>
                            <PhoneInput
                               country={'us'}
                              value={data.phoneNumber.length ? data.phoneNumber : phoneNumbers[index]}
                              disableCountryCode={false}
                              onChange={(value) => handlePhoneChange(value, index)}
                            />
                          </td>
                        </tr>
                      )}
                    </tbody>
                  </Table>
                </div>
              </Col>
            </Row>
          </div>
          {phoneError && phoneError.length ? <p className='errMsg text-center'>{phoneError}</p> : <></>}
          <div className="footerModalButton">
            <button className='backDashboard' onClick={AuthModalClose}>Back</button>
            <button className='nextButton' onClick={numberAdd}>Next</button>
          </div>
        </Modal.Body>
      </Modal>
    </>
  )
}

export default TwoFA