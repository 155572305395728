import React, { useEffect, useState } from 'react'
import { Navbar, Nav, Dropdown, Modal } from 'react-bootstrap';
import './DashboardNavbar.css'
import { Link, useNavigate, NavLink } from 'react-router-dom';
import exit from '../../../assets/icon/exit.png';
import logo from "../../../assets/logo/clearLogo.png";
import { calculateDaysRemaining, getCookieValue, handleForbiddenError, removeCookie } from '../../CommonInterface/CommonFunction';
import { singleUser } from '../../../api/auth';
import { UserDataInterface } from '../../CommonInterface/CommonInterface';
import globalState from '../../../helpers/globalState';
import requestState from '../../../helpers/requestState';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faWarning } from '@fortawesome/free-solid-svg-icons';

interface dashboardInterface {
    dashboardLoad?: () => void;
}

const DashboardNavbar = ({ dashboardLoad }: dashboardInterface) => {
    const navigate = useNavigate();
    const [user, setUser] = useState<UserDataInterface>();
    const [showNotification, setShowNotification] = useState(false);
    const pathSegments = window.location.pathname.split('/');
    const urlParams = pathSegments[1];
    const userInfo = JSON.parse(getCookieValue('docoteam') || '{}');

    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);


    const logout = () => {
        document.cookie = 'docoteam=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;';
        return navigate('/login');
    };

    useEffect(() => {

        singleUser(userInfo.user.userId, true).then((data) => {
            if (data.statusCode !== 200) {
                if (data.statusCode === 403) {
                    handleForbiddenError(navigate);
                }
            }
            else {
                setUser(data.user);
                if (!data.user.package) {
                    setShowNotification(true)
                }
            }
        });

        if (urlParams === "sent" || urlParams === "send-contacts") {
            console.log('send');
        }
        else {
            localStorage.removeItem('send');
            localStorage.removeItem('sendFile');
            globalState.clearState();
        }
        if (urlParams === "create-request" || urlParams === "request-contacts") {
            console.log('request');
        }
        else {
            localStorage.removeItem('request');
            localStorage.removeItem('requestFile');
            requestState.clearState();
        }

        if (urlParams !== "send") {
            removeCookie('requestOtp');
        }
        if (urlParams !== "downloadCode") {
            removeCookie('sendOtp');
        }

        if (userInfo.isSecretAlreadyGenerated === false) {
            if (urlParams === "admin-security") {
                setShow(false);
            }
            else {
                setShow(true);
            }
        }
    }, [])

  

    useEffect(() => {
        const timer = setTimeout(() => {
            setShowNotification(false);
        }, 5000);
        return () => clearTimeout(timer);
    }, []);

    return (
        <>
            <section className="dashboardNavbar">
            {user && user.package ?  "" : <>  {showNotification && (
                    <div className='freeNotification'>
                        <p>
                            <FontAwesomeIcon icon={faWarning} /> This account will expire in {calculateDaysRemaining(user?.createdAt)} days
                        </p>
                    </div>
                )}</>}
              

                <Navbar collapseOnSelect expand="lg" fixed='top' className='header'>
                    <NavLink to="/dashboard" className='logo navbar-brand'>
                        <img src={logo} alt="" width="210px" />
                    </NavLink>
                    <Navbar.Toggle aria-controls="responsive-navbar-nav" />
                    <Navbar.Collapse id="responsive-navbar-nav">
                        <Nav className="ml-auto headerItem">
                            <NavLink className={({ isActive }) => isActive ? 'nav-link activeNavbar' : 'nav-link'} to="/dashboard" onClick={dashboardLoad}>Dashboard</NavLink>
                            <NavLink className={({ isActive }) => isActive ? 'nav-link activeNavbar' : 'nav-link'} to="/files">Files</NavLink>
                            <NavLink className={({ isActive }) => isActive ? 'nav-link activeNavbar' : 'nav-link'} to="/my-contact">My Contacts</NavLink>
                            <NavLink className={({ isActive }) => isActive ? 'nav-link activeNavbar' : 'nav-link'} to="/support">Support</NavLink>
                            {user && user.package ? <>
                                <Dropdown>
                                    <Dropdown.Toggle className='accountBtn' id="dropdown-basic">
                                        Account
                                    </Dropdown.Toggle>
                                    <Dropdown.Menu>
                                        <Dropdown.Item as={Link} to="/profile">Profile</Dropdown.Item>
                                        <Dropdown.Item as={Link} to="/billing-history">Billing History</Dropdown.Item>
                                        <Dropdown.Item as={Link} to="/admin">Admin Settings</Dropdown.Item>
                                        <Dropdown.Item as={Link} to="/admin-security">Security</Dropdown.Item>
                                    </Dropdown.Menu>
                                </Dropdown>
                            </> : <>
                                <Dropdown>
                                    <Dropdown.Toggle className='accountBtn' id="dropdown-basic">
                                        Account
                                    </Dropdown.Toggle>
                                    <Dropdown.Menu>
                                        <Dropdown.Item as={Link} to="/profile">Profile</Dropdown.Item>
                                        <Dropdown.Item as={Link} to="/admin-security">Security</Dropdown.Item>
                                    </Dropdown.Menu>
                                </Dropdown>
                            </>
                            }
                            <button className='logoutBtn' onClick={logout}>Logout <img src={exit} alt="" /> </button>
                        </Nav>
                    </Navbar.Collapse>
                </Navbar>
            </section>
            <Modal show={show} centered>
                <Modal.Body>
                    <div className="msfModal text-center">
                        <h1>Your administrator has enabled MFA settings. <br /> Please set up your MFA.</h1>
                        <Link to="/admin-security">Setup</Link>
                    </div>
                </Modal.Body>
            </Modal>
        </>
    )
}

export default DashboardNavbar
