import React, { useState, useEffect } from 'react';
import DashboardNavbar from '../CommonCompoment/DashboardNavbar/DashboardNavbar'
import { Col, Container, ProgressBar, Row, Table } from 'react-bootstrap';
import "./Admin.css";
import { Link, useNavigate } from 'react-router-dom';
// google font awesome
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSearch } from '@fortawesome/free-solid-svg-icons';
import Pagination from '../CommonCompoment/Pagination/Pagination';
import AddUser from './AddUser';
import SmallButton from '../../styleComponent/SmallButton/SmallButton';
import { singleUser } from '../../api/auth';
import { UserDataInterface, UserListInterface } from '../CommonInterface/CommonInterface';
import { getAllUser } from '../../api/user';
import moment from 'moment';
import { convertGB, convertMBToGB, getCookieValue, handleForbiddenError, multiplyBySixAndShowSeries } from '../CommonInterface/CommonFunction';
import EditUser from './EditUser';
import UpgradePlan from '../CommonCompoment/UpgradePlan/UpgradePlan';
import { REQUEST_FOR_MORE_STORAGE_GOOGLE_FROM_URL } from '../../config';
import Loader from '../CommonCompoment/Loader/Loader';


const Admin = () => {

    const navigate = useNavigate();
    const [fadeAnimation, setFadeAnimation] = useState(false);
    const [updatePayment, setUpdatePayment] = useState(false);
    const [show, setShow] = useState(false);
    const [userInfo, setUserInfo] = useState<UserDataInterface>();
    const [userList, setUserList] = useState<UserListInterface[]>([]);
    const [contactInfo, setContactInfo] = useState({});
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    const token = JSON.parse(getCookieValue('docoteam') || '{}');
    const { user } = token;
    const [searchTerm, setSearchTerm] = useState("");
    const [count, setCount] = useState(0);
    const [activeStep, setActiveStep] = React.useState(1);
    const [upgradeShow, setUpgradeShow] = useState(false);
    const handleUpgradeClose = () => setUpgradeShow(false);
    const handleUpgradeShow = () => setUpgradeShow(true);
    const [warningShow, setWarningShow] = useState(false);
    // pagination state
    const [totalValue, setTotalValue] = useState<any>();
    const [limitValue, setLimitValue] = useState<any>();
    const [page, setPage] = useState(1);
    const [totalUsers, setTotalUsers] = useState<number>(0);
    const [limit, setLimit] = useState<number>(6);
    const [pageValue, setPageValue] = useState<number>();
    const pageCount = Math.ceil(totalValue / limitValue);
    const [prevButton, setPrevButton] = useState<boolean>(false);
    const [nextButton, setNextButton] = useState<boolean>(false);
    const [doubleNextButton, setDoubleNextButton] = useState<boolean>(false);
    const [doublePrevButton, setDoublePrevButton] = useState<boolean>(false);
    const [editShow, setEditShow] = useState(false);
    const handleEditClose = () => setEditShow(false);
    const [resultLength, setResultLength] = useState<number>(0);
    const pageResult = multiplyBySixAndShowSeries(totalValue);

    useEffect(() => {
        setFadeAnimation(true);
        singleUser(user.userId).then((data) => {
            if (data.statusCode !== 200) {
                if (data.statusCode === 403) {
                    handleForbiddenError(navigate);
                }
            }
            else {
                setUserInfo(data.user);
                setTotalUsers(data.user.package.totalUser);
            }
        });
        getAllUser(limit, page).then((data) => {
            if (data.statusCode !== 200) {
                if (data.statusCode === 403) {
                    handleForbiddenError(navigate);
                }
            }
            else {
                setUserList(data.body.users);
                setResultLength(data.body.users.length);
                setTotalValue(data.body.total);
                setLimitValue(data.body.limit);
                setPageValue(data.body.page);
            }
        });

        if (pageCount > 1) {
            setPrevButton(true)
        }
        if (page === 1) {
            setPrevButton(false)
        }
        // next button
        if (pageCount > 1) {
            setNextButton(true)
        }
        if (pageCount === page) {
            setNextButton(false)
        }
        // double next step
        if (pageCount > 2) {
            setDoubleNextButton(true)
        }
        if (pageCount - 1 === page || pageCount === page) {
            setDoubleNextButton(false)
        }
        // double prev step
        if (pageCount > 2) {
            setDoublePrevButton(true)
        }
        if (page === 1 || page === 2) {
            setDoublePrevButton(false)
        }
    }, [count, page, totalValue, limitValue, limit, editShow, warningShow]);

    const nextPage = (pageNumber: number) => {
        setPage(page + pageNumber)
        setNextButton(true)
    }
    const prevPage = (pageNumber: number) => {
        setPage(page - pageNumber)
    }
    const showResult = (value: number) => {
        setPage(1)
        setLimit(value)
    }
    const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setSearchTerm(event.target.value);
    };
    const userResults = userList.filter((item: any) => {
        const searchItem = `${item.firstName} ${item.lastName} ${item.email} ${item.userRole} ${item.createdAt}`;
        return searchItem.toLowerCase().includes(searchTerm.toLowerCase())
    });

    const editUserContact = (userId: string) => {
        singleUser(userId).then((data) => {
            if (data.statusCode !== 200) {
                if (data.statusCode === 403) {
                    handleForbiddenError(navigate);
                }
            }
            else {
                setEditShow(true);
                setContactInfo(data.user);
            }
        });
    };


    const dummyArray = Array.from({ length: totalUsers - (userResults && userResults.length) }, (_, index) => index);


    return (
        <>
            <DashboardNavbar />
            <section className={`fade-in adminPage ${fadeAnimation ? 'visible' : ''}`}>
                {userInfo ? <>
                    <Container fluid>
                        <Row>
                            <Col md={12}>
                                <div className="adminHeading">
                                    <h2>{userInfo && userInfo.companyName}</h2>
                                    <h2>Account Settings</h2>
                                </div>
                            </Col>
                        </Row>
                        <Row>
                            <Col md={6}>
                                <div className="currentPlan">
                                    <div className="planHeading">
                                        <h2>Current Plan</h2>
                                        <button onClick={() => setUpgradeShow(true)}>Change Billing/Plan</button>
                                    </div>
                                    <div className="planList">
                                        <div className="planDescription">
                                            <p>Plan Type</p>
                                            <div className='planName'>{userInfo && userInfo.package.packageName}</div>
                                        </div>
                                        <div className="planDescription">
                                            <p>Billing Type</p>
                                            <div className='planName'>{userInfo && userInfo.package.billingType}</div>
                                        </div>
                                        <div className="planDescription">
                                            <p>Price</p>
                                            <div className='planName'>{userInfo && userInfo.package.paymentAmount / 100}$/{userInfo && userInfo.package.billingType}/user</div>
                                        </div>
                                        <div className="planDescription">
                                            <p>Total User</p>
                                            <div className='planName'>{userInfo && userInfo.package.totalUser}</div>
                                        </div>
                                    </div>
                                </div>
                            </Col>
                            <Col md={6}>
                                <div className="storagePlan">
                                    <div className="planHeading">
                                        <h2>Current Plan</h2>
                                    </div>
                                    <div className="storageProgress">
                                        <p>Usage</p>
                                        <ProgressBar now={userInfo && userInfo.occupiedStorage / parseInt(userInfo.totalStorage) * 100} variant="custom-progress-bar-progress" className="custom-progress-bar-progress" />
                                        <h5>{userInfo && convertMBToGB(userInfo.occupiedStorage)} used of {userInfo && convertGB(userInfo.totalStorage)} GB</h5>
                                        {userInfo && userInfo.package && userInfo.package ? <Link to={`${REQUEST_FOR_MORE_STORAGE_GOOGLE_FROM_URL}`} target='_blank'>Request more storage</Link> : ""}
                                    </div>
                                </div>
                            </Col>
                        </Row>
                        <Row>
                            <Col md={12}>
                                <div className="userTableBox">
                                    <div className="userHeading">
                                        <h1>Users</h1>
                                        <div className='contactAddBtn'>
                                            <div className="searchName contactSearch">
                                                <input type="text" className="form-control" id="inputValidation" onChange={handleInputChange} placeholder="Search" />
                                                <FontAwesomeIcon icon={faSearch} />
                                            </div>
                                            {userResults && userResults.length === totalUsers ? <button className='blankBtn'>Add User</button>
                                                : <button onClick={handleShow}>Add User</button>}

                                        </div>
                                    </div>
                                    <div className="userListTable">
                                        <div className="adminListTable">
                                            <Table responsive className='tableBody'>
                                                <thead>
                                                    <tr>
                                                        <th>#</th>
                                                        <th>First Name</th>
                                                        <th>Last Name</th>
                                                        <th>Email Address</th>
                                                        <th>Role</th>
                                                        <th>Start Date</th>
                                                        <th></th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {userResults && userResults.map((data, index: number) => <tr className='leftTable' key={`user` + index}>
                                                        <td>{index + 1}</td>
                                                        <td>{data.firstName}</td>
                                                        <td>{data.lastName}</td>
                                                        <td>{data.email}</td>
                                                        <td>{data.userRole === "TENANT_ADMIN" ? "Admin" : "Employee"}</td>
                                                        <td>{moment(data.createdAt).format('MMM DD YYYY')}</td>
                                                        <td className=''>
                                                            <SmallButton customWidth="62" customHeight="24" customBackgroundColor='#FFFFFF' customBorderColor='#F94A29' customTextColor='#F94A29' onClick={() => editUserContact(data.userId)}>Edit</SmallButton>
                                                        </td>
                                                    </tr>)}
                                                    {dummyArray.map((data, index: number) => <tr className='leftTable' key={`blankUser` + index}>
                                                        <td>{index + 1 + userResults.length}</td>
                                                        <td>User {index + 1 + userResults.length}</td>
                                                        <td>----</td>
                                                        <td>----</td>
                                                        <td>----</td>
                                                        <td>----</td>
                                                        <td className=''>
                                                            <SmallButton customWidth="62" customHeight="24" customBackgroundColor='#FFFFFF' customBorderColor='#F94A29' customTextColor='#F94A29' onClick={handleShow}>Invite</SmallButton>
                                                        </td>
                                                    </tr>)}
                                                </tbody>
                                            </Table>
                                        </div>
                                    </div>
                                    {userResults && userResults.length ? <>
                                        <Pagination doublePrevButton={doublePrevButton} doubleNextButton={doubleNextButton} showResult={showResult} pageResult={pageResult} prevButton={prevButton} nextButton={nextButton} pageValue={pageValue} totalValue={totalValue} prevPage={prevPage} nextPage={nextPage} resultLength={resultLength} />
                                    </> : <></>}
                                </div>
                            </Col>
                        </Row>
                    </Container>
                </>
                    : <Loader />}
                {/* add user */}
                <AddUser show={show} handleClose={handleClose} count={count} setCount={setCount} />
                {/* edit user */}
                <EditUser warningShow={warningShow} setWarningShow={setWarningShow} contactInfo={contactInfo} editShow={editShow} setEditShow={setEditShow} handleEditClose={handleEditClose} />
                {/* Upgrade plan */}
                <UpgradePlan setUpdatePayment={setUpdatePayment} upgradeShow={upgradeShow} setUpgradeShow={setUpgradeShow} handleUpgradeClose={handleUpgradeClose} />

            </section>
        </>
    )
}

export default Admin
