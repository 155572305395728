import React, { useEffect, useState } from 'react';
import { billingHistory, billingInfo } from '../../api/payment';
import DashboardNavbar from '../CommonCompoment/DashboardNavbar/DashboardNavbar';
import { Container, Row, Col, Table } from 'react-bootstrap';
import { singleUser } from '../../api/auth';
import { getCookieValue, handleForbiddenError } from '../CommonInterface/CommonFunction';
import { BillingInfoInterface, UserDataInterface } from '../CommonInterface/CommonInterface';
import moment from 'moment';
import UpdateBilling from './UpdateBilling';
import { useNavigate } from 'react-router-dom';
import Loader from '../CommonCompoment/Loader/Loader';
import { ToastContainer } from 'react-toastify';

const BillingHistory = () => {
    const [fadeAnimation, setFadeAnimation] = useState(false);
    const [userInfo, setUserInfo] = useState<UserDataInterface>();
    const [billing, setBilling] = useState<BillingInfoInterface>();
    const navigate = useNavigate();
    const [transactions, setTransactions] = useState([]);
    const [editShow, setEditShow] = useState(false);
    const handleEditClose = () => setEditShow(false);
    const token = JSON.parse(getCookieValue('docoteam') || '{}');
    const { user } = token;
    useEffect(() => {
        setFadeAnimation(true);
        billingHistory().then((data) => {
            setTransactions(data.transactions)
        })
        billingInfo().then((data) => {
            setBilling(data.billingInfo)
        })

        singleUser(user.userId).then((data) => {
            if (data.statusCode !== 200) {
                if (data.statusCode === 403) {
                    handleForbiddenError(navigate);
                }
            }
            else {
                setUserInfo(data.user);
            }
        });
    }, [editShow])

    return (
        <>
            <DashboardNavbar />
            <section className={`fade-in adminPage ${fadeAnimation ? 'visible' : ''}`}>
                <ToastContainer/>
                {userInfo ? <>
                    <Container fluid>
                        <Row>
                            <Col md={12}>
                                <div className="adminHeading">
                                    <h2>{userInfo && userInfo.companyName}</h2>
                                    <h2>Billing History</h2>
                                </div>
                            </Col>
                            <Col md={6}>
                                <div className="currentPlan">
                                    <div className="planHeading">
                                        <h2>Billing Information</h2>
                                        <button onClick={() => setEditShow(true)}>Update</button>
                                    </div>
                                    <div className="planList">
                                        <div className="planDescription">
                                            <p>Address One</p>
                                            <div className='planName'>{billing && billing.billingAddress1}</div>
                                        </div>
                                        {billing && billing.billingAddress2 ? <div className="planDescription">
                                            <p>Address Two</p>
                                            <div className='planName'>{billing && billing.billingAddress2}</div>
                                        </div> : ""}

                                        <div className="planDescription">
                                            <p>City</p>
                                            <div className='planName'>{billing && billing.billingCity}</div>
                                        </div>
                                        <div className="planDescription">
                                            <p>State/Provience</p>
                                            <div className='planName'>{billing && billing.billingState}</div>
                                        </div>
                                        <div className="planDescription">
                                            <p>Zip Code</p>
                                            <div className='planName'>{billing && billing.billingZIPCode}</div>
                                        </div>
                                    </div>
                                </div>
                            </Col>
                            <Col md={12}>
                                <div className="currentPlan mt-5">
                                    <h2>Billing list</h2>
                                    <Table responsive className='tableBody'>
                                        <thead>
                                            <tr>
                                                <th>#</th>
                                                <th>Address</th>
                                                <th>City</th>
                                                <th>State</th>
                                                <th>Zip code</th>
                                                <th>Type</th>
                                                <th>Package name</th>
                                                <th>Amount</th>
                                                <th>Purchase date</th>
                                                <th>Expire date</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {transactions && transactions.map((data: any, index: number) => <tr className='leftTable' key={`user` + index}>
                                                <td>{index + 1}</td>
                                                <td>{data.billingAddress1}</td>
                                                <td>{data.billingCity}</td>
                                                <td>{data.billingState}</td>
                                                <td>{data.billingZIPCode}</td>
                                                <td>{data.billingType}</td>
                                                <td>{data.packageName}</td>
                                                <td>{data.paymentAmount}</td>
                                                <td>{moment(data.purchaseDate).format('MMM DD YYYY')}</td>
                                                <td>{moment(data.expireDate).format('MMM DD YYYY')}</td>
                                            </tr>)}
                                        </tbody>
                                    </Table>
                                </div>
                            </Col>
                        </Row>
                    </Container>
                </>
                    : <Loader />}
            </section>
            {/* edit billing info */}
            <UpdateBilling editShow={editShow} handleEditClose={handleEditClose} setEditShow={setEditShow} billingInfo={billing} />
        </>
    )
}

export default BillingHistory