import React, { useEffect, useState } from 'react';
import { Col, Container, Row, Table } from 'react-bootstrap';
import DashboardNavbar from '../CommonCompoment/DashboardNavbar/DashboardNavbar';
import './CreateRequest.css'
import DragDrop from './DragDrop';
import { useNavigate, useParams } from 'react-router-dom';
import { v4 as uuidv4 } from 'uuid';
import SmallButton from '../../styleComponent/SmallButton/SmallButton';
import { loadTemplate, saveTemplate, sentCompleteUploadApi, singleRequests, updateTemplate } from '../../api/auth';
import { singleTemplate } from './../../api/auth';
import { ToastContainer } from 'react-toastify';
import { AttachmentInterface, AttachmentUPloadInterface, ItemInterface, LoadTemplateInterface, RecipientUser, UpdateItemInterface, UserListInterface } from '../CommonInterface/CommonInterface';
import SentFiles from '../SentFiles/SentFiles';
import SaveTemplate from './SaveTemplate';
import LoadTemplate from './LoadTemplate';
import { showNotifications } from '../../helpers/toaster';
import { getAllUser } from '../../api/user';
import { multiplyBySixAndShowSeries, removeCookie } from '../CommonInterface/CommonFunction';
import AddEmployee from './AddEmployee';
import { handleAttachmentUpload } from '../../helpers/fileUpload';
import Loader from '../CommonCompoment/Loader/Loader';
import requestState from '../../helpers/requestState';

const CreateRequest = () => {

  const { id } = useParams();
  const { template } = useParams();
  const navigate = useNavigate();
  const [itemValue, setItemValue] = useState<ItemInterface[]>([]);
  const [requestTitleValue, setRequestTitleValue] = useState<string>("");
  const [addItemValue, setAddItemValue] = useState("");
  const [requestDescriptionValue, setRequestDescriptionValue] = useState("");
  const [error, setError] = useState(false);
  const [addNoteValue, setAddNoteValue] = useState("");
  const [saveTemplateValue, setSaveTemplateValue] = useState("");
  const [errorTitle, setErrorTitle] = useState("")
  const [errorItem, setErrorItem] = useState<string>("")
  const [errorItemList, setErrorItemList] = useState<string>("")
  const [checkBoxOnOff, setCheckBoxOnOff] = useState<boolean>(false)
  // template modal
  const [templateShow, setTemplateShow] = useState<boolean>(false);
  const [saveTemplateShow, setSaveTemplateShow] = useState(false);
  const [loadTemplateInfo, setLoadTemplateInfo] = useState<LoadTemplateInterface[]>([]);
  const [requestTemplateId, setRequestTemplateId] = useState<string>("")
  const [templateTitle, setTemplateTitle] = useState("")

  // edit
  const [saveItemId, setSaveItemId] = useState("")
  const [templateError, setTemplateError] = useState<string>("");
  const [updateButton, setUpdateButton] = useState<boolean>(false)
  const [viewBtn, setViewBtn] = useState<boolean>(false);
  const [fadeAnimation, setFadeAnimation] = useState(false);
  const [templateItemShow, setTemplateItemShow] = useState("")
  const [requestSent, setRequestSent] = useState(true)
  const [userList, setUserList] = useState<UserListInterface[]>([]);
  const [userIds, setUserIds] = useState<string[]>([]);
  // file list
  const [attachmentList, setAttachmentList] = useState<AttachmentInterface[]>([])
  const [attachmentUploadList, setAttachmentUploadList] = useState<AttachmentUPloadInterface[]>([])
  const [recipientsValue, setRecipientsValue] = useState<RecipientUser[]>([]);

  // pagination state
  const [totalValue, setTotalValue] = useState<any>();
  const [limitValue, setLimitValue] = useState<any>();
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState<number>(6);
  const [pageValue, setPageValue] = useState<number>();
  const pageCount = Math.ceil(totalValue / limitValue);
  const [prevButton, setPrevButton] = useState<boolean>(false);
  const [nextButton, setNextButton] = useState<boolean>(false);
  const [doubleNextButton, setDoubleNextButton] = useState<boolean>(false);
  const [doublePrevButton, setDoublePrevButton] = useState<boolean>(false);
  const [spinLoading, setSpinLoading] = useState<any>(false);
  const [resultLength, setResultLength] = useState<number>(0);
  const pageResult = multiplyBySixAndShowSeries(totalValue);
  const [senderTypeValue, setSenderTypeValue] = useState("individual");

  const [count, setCount] = useState(0);
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const requestObj = JSON.parse(localStorage.getItem("request") || '{}');

  const requestTitleHandle = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRequestTitleValue(event.target.value)
    setErrorTitle("")
  }
  const addItemHandle = (event: React.ChangeEvent<HTMLInputElement>) => {
    setAddItemValue(event.target.value)
    setErrorItem('')
  }
  const descriptionHandle = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRequestDescriptionValue(event.target.value)
  }

  const isRequiredFunction = () => {
    setCheckBoxOnOff(!checkBoxOnOff)
  }

  const addItemFunction = () => {
    if (requestTitleValue.length === 0) {
      setErrorTitle("Please insert the title name")
    }
    else {
      setErrorTitle("")
    }

    if (addItemValue.length === 0) {
      setErrorItem("Please insert the item name")
    }
    else {
      setErrorItem("")
    }
    setErrorItemList("")
    

    if (requestTitleValue.length > 0 && addItemValue.length > 0) {

      setViewBtn(false)
      setCheckBoxOnOff(false)
      setAddItemValue("")
      setRequestDescriptionValue("");
      setAddNoteValue("")

      const itemObj: ItemInterface = {
        "itemLockId": uuidv4(),
        "itemName": addItemValue,
        "isRequired": checkBoxOnOff,
        "itemDescription": requestDescriptionValue,
        "attachments": attachmentList
      }
      setItemValue([...itemValue, itemObj])
      setAttachmentList([])
    }
  }

  function countRequiredItems(itemValue: ItemInterface[]) {
    const result = itemValue && itemValue.reduce(
      (acc, item) => {
        if (item.isRequired) {
          acc.trueCount++;
        } else {
          acc.falseCount++;
        }
        return acc;
      },
      { trueCount: 0, falseCount: 0 }
    );
    return result;
  }
  const result = countRequiredItems(itemValue);

  const nextRequest = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth"
    });

    if (requestTitleValue.length === 0) {
      setErrorTitle("Please insert the title name")
    }
    else {
      setErrorTitle("")
    }
    if (addItemValue.length === 0) {
      setErrorItem("Please insert the item name")
    }
    else {
      setErrorItem("")
    }
    if (itemValue.length === 0) {
      setErrorItemList("Item list empty")
    }
    else {
      setErrorItemList("")
    }
    const requestObject: any = {
      "title": requestTitleValue,
      "items": itemValue,
      "attachmentUpload": attachmentUploadList
    }
    if (requestObject.recipients) {
      requestObject.recipients = requestObject.recipients;
    }
    if (requestObj.settings) {
      requestObject.settings = requestObj.settings;
    }
    if (userIds) {
      requestObject.associatedUserIds = userIds;
    }
    if (recipientsValue.length) {
      let recipientsJson = {
        "users": recipientsValue,
        "senderType": senderTypeValue
      }
      requestObject.recipients = recipientsJson;
    }
    if (itemValue.length > 0) {
      setRequestSent(false)
      localStorage.setItem("request", JSON.stringify(requestObject));

      if (id) {
        return navigate(`/request-contacts/${id}`);
      }
      else {
        return navigate("/request-contacts");
      }
    }
    else {
      setError(true)
    }

  }

  function fileRemove(itemValue: ItemInterface[], name: string) {
    const indexToDelete = itemValue.findIndex((item) => item.itemLockId === name);
    if (indexToDelete !== -1) {
      itemValue.splice(indexToDelete, 1);
    }
    setItemValue([...itemValue]);
  }

  // save template
  const templateHandleClose = () => {
    if (requestTitleValue.length > 0) {
      setTemplateError("")
    }
    setTemplateShow(true)
  };

  const closeTemplateModal = () => {
    const templateObject = {
      "templateTitle": saveTemplateValue,
      "title": requestTitleValue,
      "items": itemValue,
      "settings": [],
      "associatedUserIds": userIds
    }

    if (requestTitleValue.length === 0) {
      setTemplateError("Please insert request title")
    }
    else {
      setTemplateError("")
    }
    if (saveTemplateValue.length > 0) {
      setTemplateError("");
      saveTemplate(templateObject).then(async (data) => {
        if (data.statusCode !== 201) {
          if (data.statusCode === 401) {
            removeCookie('docoteam');
            return navigate("/login");
          }
          else {
            showNotifications("error", data.message);
          }
        }
        else {
          let progress = 0;
          const uploadLockIds = data.body.attachmentUploadLockIds;
          for (let i = 0; i < attachmentUploadList.length; i++) {
            const uploadUrlId = uploadLockIds[attachmentUploadList[i].attachmentUploadLockId];
            await handleAttachmentUpload(attachmentUploadList[i].file, `/files/uploadUrl/${uploadUrlId}`, i, (progress: any) => {
              setItemValue((prevList: any) => {
                const newList = [...prevList];
                newList[i] = { ...newList[i], progress };
                return newList;
              });
            });
            await sentCompleteUploadApi(uploadUrlId)
            setItemValue((prevList: any) => {
              const newList = [...prevList];
              newList[i] = { ...newList[i], status: 'uploaded' };
              return newList;
            });

          }
          setTemplateShow(false)
          showNotifications("success", data.message)
        }

      })
    }
  }
  // update template
  const updateTemplateModal = () => {
    const templateObject = {
      "requestTemplateId": requestTemplateId,
      "templateTitle": templateTitle,
      "title": requestTitleValue,
      "items": itemValue,
      "settings": [],
      "associatedUserIds": userIds
    }

    if (requestTitleValue.length === 0) {
      setTemplateError("Please insert request title")
    }
    else {
      setTemplateError("")
    }

    setTemplateError("");
    updateTemplate(requestTemplateId, templateObject).then(async (data) => {

      if (data.statusCode !== 200) {
        if (data.statusCode === 401) {
          removeCookie('docoteam');
          return navigate("/login");
        }
        else {
          showNotifications("error", data.message);
        }
      }
      else {
        let progress = 0;
        const uploadLockIds = data.attachmentUploadLockIds;
        for (let i = 0; i < attachmentUploadList.length; i++) {
          const uploadUrlId = uploadLockIds[attachmentUploadList[i].attachmentUploadLockId];
          await handleAttachmentUpload(attachmentUploadList[i].file, `/files/uploadUrl/${uploadUrlId}`, i, (progress: any) => {
            setItemValue((prevList: any) => {
              const newList = [...prevList];
              newList[i] = { ...newList[i], progress };
              return newList;
            });
          });
          await sentCompleteUploadApi(uploadUrlId)
          setItemValue((prevList: any) => {
            const newList = [...prevList];
            newList[i] = { ...newList[i], status: 'uploaded' };
            return newList;
          });

        }
        setTemplateShow(false)
        showNotifications("success", data.message)
      }

    })

  }

  // load template
  const loadTemplateList = () => {
    loadTemplate().then((data) => {
      if (data.statusCode !== 200) {
        console.log('error');
      }
      else {
        setSpinLoading(false)
        setLoadTemplateInfo(data.body.request)
      }
    })
  }

  const saveTemplateModal = () => {
    setSpinLoading(true)
    setSaveTemplateShow(true)
    loadTemplateList()
  };

  const saveTemplateModalClose = () => {
    setSaveTemplateShow(false)
  };
  const saveTemplateFunction = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSaveTemplateValue(event.target.value)
  }

  // single load template
  const singleTemplateLoad = (templateId: string) => {
    setTemplateItemShow(templateId)
    singleTemplate(templateId).then((data) => {
      setRequestTemplateId(data.body.requestTemplate.requestTemplateId);
      setTemplateTitle(data.body.requestTemplate.templateTitle);
      setRequestTitleValue(data.body.requestTemplate.title);
      const updatedItems = data.body.requestTemplate.items.map((item: any) => ({
        ...item,
        attachments: item.attachments.map((attachment: any) => ({
          ...attachment,
          attachmentUploadLockId: attachment.attachmentId,
        })),
        itemLockId: uuidv4(),
      }));
      setItemValue(updatedItems);
      setUserIds(data.body.requestTemplate.associatedUserIds)
      setSaveTemplateShow(false)
    })
  }

  useEffect(() => {
    if (template) {
      singleTemplate(template).then((data) => {
        setRequestTemplateId(data.body.requestTemplate.requestTemplateId);
        setTemplateTitle(data.body.requestTemplate.templateTitle);
        setRequestTitleValue(data.body.requestTemplate.title);
        const updatedItems = data.body.requestTemplate.items.map((item: any) => ({
          ...item,
          attachments: item.attachments.map((attachment: any) => ({
            ...attachment,
            attachmentUploadLockId: attachment.attachmentId,
          })),
          itemLockId: uuidv4(),
        }));
        setItemValue(updatedItems);
        setUserIds(data.body.requestTemplate.associatedUserIds)
        setSaveTemplateShow(false)
      })
    }
  }, [])


  const preview = (attachmentValue: ItemInterface) => {
    setAttachmentList(attachmentValue.attachments)
    setUpdateButton(true)
    setAddItemValue(attachmentValue.itemName)
    setRequestDescriptionValue(attachmentValue.itemDescription)
    setCheckBoxOnOff(attachmentValue.isRequired)
    setSaveItemId(attachmentValue.itemLockId);
  }


  useEffect(() => {
    setFadeAnimation(true);
  }, [saveItemId])

  useEffect(() => {
    if (id) {
      singleRequests(id).then((data) => {
        if (Object.keys(requestObj).length !== 0) {
          setRequestTitleValue(requestObj.title);
          setItemValue(requestObj.items);
          if (requestObj.recipients) {
            setRecipientsValue(requestObj.recipients.users);
            setSenderTypeValue(requestObj.recipients.senderType);
          }

          // setSentUploadURl(sendObj.sentUploadURl);
        }
        else {
          setRequestTitleValue(data.body.title);
          const updatedFiles = data.body.items.map((file: any) => ({
            ...file,
            itemLockId: uuidv4(),
            attachments: file.attachments.map((attachment: any) => ({
              ...attachment,
              attachmentUploadLockId: uuidv4(),
            })),
          }));

          setItemValue(updatedFiles);
          setRecipientsValue(data.body.recipients && data.body.recipients.users);
        }

      })
    }
    else {
      if (Object.keys(requestObj).length !== 0) {
        setRequestTitleValue(requestObj.title);
        setItemValue(requestObj.items);

        if (requestObj.recipients) {
          setRecipientsValue(requestObj.recipients.users);
          setSenderTypeValue(requestObj.recipients.senderType);
        }

        if (requestObj.associatedUserIds) {
          setUserIds(requestObj.associatedUserIds)
        }

      }
    }
  }, [])


  const backToDashboard = () => {
    return navigate('/dashboard');
  }

  // item item new concept
  function updateItemsByItemId(itemLockId: string, newValues: UpdateItemInterface, items: ItemInterface[]) {
    const updatedItems = items.map((item) => {
      if (item.itemLockId === itemLockId) {
        return { ...item, ...newValues };
      } else {
        return item;
      }
    });
    return updatedItems;
  }

  const itemUpdateValue = () => {
    setCheckBoxOnOff(false)
    setAddItemValue("")
    setRequestDescriptionValue("")
    setUpdateButton(false);
    setAddNoteValue("")
    const updatedItems = updateItemsByItemId(saveItemId,
      {
        "itemName": addItemValue,
        "isRequired": checkBoxOnOff,
        "itemDescription": requestDescriptionValue,
        "attachments": attachmentList,
      },
      itemValue);
    setItemValue(updatedItems)
    setAttachmentList([])
  }

  const addEmployee = () => {
    setShow(true);
    getAllUser(limit, page).then((data) => {
      if (data.statusCode !== 200) {
        console.log('error', data);
      }
      else {
        setUserList(data.body.users);
        setResultLength(data.body.users.length);
        setTotalValue(data.body.total);
        setLimitValue(data.body.limit);
        setPageValue(data.body.page);
      }
    });
  }

  const handleKeyPress = (event: KeyboardEvent) => {
    if (event.key === 'Enter') {
      nextRequest();
    }
  };

  useEffect(() => {
    document.body.addEventListener('keypress', handleKeyPress);
    return () => {
      document.body.removeEventListener('keypress', handleKeyPress);
    };
  }, [attachmentUploadList, requestTitleValue, itemValue, requestObj, userIds, recipientsValue, senderTypeValue]);

  return (
    <>
      <DashboardNavbar />
      {spinLoading && <Loader />}
      <ToastContainer />

      <section className={`fade-in createRequest ${fadeAnimation ? 'visible' : ''}`}>
        <Container fluid>
          <Row>
            <Col md={12}>
              <div className="headingTitle">
                {id ? <h1>Update Request</h1> : <h1>Create Request</h1>}
              </div>
            </Col>
            <Col lg={7}>
              <div className="createLeftBox">
                <div className="templateLine">
                  <div className="templateHeading">
                    {id ? <h1>Update Request Information</h1> : <h1>Create New Request</h1>}
                  </div>
                  <div className="templateButton tempBtn">
                    <button onClick={templateHandleClose}>Save as Template</button>
                    <button onClick={saveTemplateModal}>Load Template</button>
                  </div>
                </div>
                <div className="requestInputForm">
                  <label htmlFor="requestTitle">Request Title*</label>
                  <input type="text" placeholder='Title (e.g. company employes list)' value={requestTitleValue} onChange={requestTitleHandle} required />
                  {errorTitle && errorTitle.length ? <p className='errorMsg'>{errorTitle}</p> : ""}
                  <div className="addItem mt-2">
                    <h1>Add Items </h1>
                  </div>

                  <label htmlFor="requestTitle">Item Name*</label>
                  <input type="text" placeholder='Item name (eg. Photo ID, Bank Statement, etc)' value={addItemValue} onChange={addItemHandle} required />
                  {errorItem && errorItem.length ? <p className='errorMsg'>{errorItem}</p> : ""}
                  <label htmlFor="requestTitle" className='mt-3'>Item Description/Instructions (optional)</label>
                  <input type="text" placeholder='Item description/instruction (optional)' value={requestDescriptionValue} onChange={descriptionHandle} />

                </div>
                <div className="dragDropFile mt-3">
                  <div className="requiredLine">
                    <p>Required </p>
                    <label className='tableCheckBox'>
                      <div className="contactCheck">
                        <input type="checkbox" onClick={isRequiredFunction} checked={checkBoxOnOff ? true : false} onChange={() => { }} />
                        <span className="checkmark"></span>
                      </div>
                    </label>
                  </div>
                  <div className="fileUploadBox">
                    <div className="attachFile">
                      <p>Attachment(s) (optional)</p>
                      <div className="">
                        <Row>
                          <DragDrop userIds={userIds} addEmployee={addEmployee} setAttachmentUploadList={setAttachmentUploadList} attachmentUploadList={attachmentUploadList} setAttachmentList={setAttachmentList} attachmentList={attachmentList} setViewBtn={setViewBtn} viewBtn={viewBtn} setAddNoteValue={setAddNoteValue} addNoteValue={addNoteValue} itemUpdateValue={itemUpdateValue} updateButton={updateButton} setErrorItem={setErrorItem} addItemFunction={addItemFunction} />
                        </Row>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </Col>
            <Col lg={5}>
              <div className="itemList itemTable">
                <h1>Items </h1>
                <Table responsive className='tableBody'>
                  <thead>
                    <tr>
                      <th>#</th>
                      <th>Item Name</th>
                      <th></th>
                      <th></th>
                    </tr>
                  </thead>
                  <tbody>
                    {itemValue && itemValue.map((data, index: number) => <tr key={`tableList` + index}>
                      <td>{index + 1}</td>
                      <td>{data.itemName}</td>
                      <td>{data.isRequired === false ? <>Optional</> : <>Required</>}</td>
                      <td className='tableInnerButton'>
                        <SmallButton customWidth="60" customHeight="24" customBackgroundColor='#fff' customBorderColor='#F94A29' customTextColor='#F94A29' onClick={() => preview(data)} className='mr-2'>Edit</SmallButton>
                        <SmallButton customWidth="66" customHeight="24" customBackgroundColor='#fff' customBorderColor='#F94A29' customTextColor='#F94A29' onClick={() => fileRemove(itemValue, data.itemLockId)}>Remove</SmallButton>
                      </td>
                    </tr>)}
                    {errorItemList && errorItemList.length ?  <p className='errorMsg text-center w-100'>{errorItemList}</p> : ""}
                  </tbody>
                </Table>
            

                <div className="optionalButton">
                  <div className="required">
                    <p>Required</p> <button>{result && result.trueCount && result.trueCount > 9 ? `${result.trueCount}` : `0${result.trueCount}`}</button>
                  </div>
                  <div className="required">
                    <p>Optional</p> <button>{result && result.falseCount && result.falseCount > 9 ? `${result.falseCount}` : `0${result.falseCount}`}</button>
                  </div>
                </div>
              </div>
            </Col>
            <Col md={12}>
              <div className="footerDashboard">
                <div className="templateButton">
                  <button className='backDashboard' onClick={backToDashboard}>Back to Dashboard</button>
                  <button className='nextButton' onClick={nextRequest}>Next</button>
                </div>
              </div>
            </Col>
          </Row>
        </Container>

        {/* save template */}
        <SaveTemplate requestTemplateId={requestTemplateId} updateTemplateModal={updateTemplateModal} closeTemplateModal={closeTemplateModal} setTemplateShow={setTemplateShow} setTemplateError={setTemplateError} saveTemplateFunction={saveTemplateFunction} templateError={templateError} templateShow={templateShow} />
        {/* load template */}
        <LoadTemplate singleTemplateLoad={singleTemplateLoad} loadTemplateInfo={loadTemplateInfo} saveTemplateModalClose={saveTemplateModalClose} saveTemplateShow={saveTemplateShow} />
      </section>

      {/* add employee */}
      <AddEmployee userIds={userIds} setUserIds={setUserIds} userList={userList} handleClose={handleClose} show={show} setShow={setShow} />
    </>
  )
}

export default CreateRequest