import React from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleCheck, faEnvelopeOpenText } from '@fortawesome/free-solid-svg-icons';

const AccountVerify = () => {
  return (
    <>
      <section className="congratulationPage">
                <Container>
                    <Row className='d-flex align-items-center justify-content-center'>
                        <div className="accountReady">
                            <FontAwesomeIcon icon={faEnvelopeOpenText} />
                            <h1>E-mail Verification</h1>
                            <p>Please check your e-mail to continue the process</p>
                        </div>
                    </Row>
                </Container>
            </section>   
    </>
  )
}

export default AccountVerify