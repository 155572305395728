import React, { useRef, useState, useEffect } from 'react';
import { Modal, Table } from 'react-bootstrap';
import dragAndDrop from '../../assets//dragDrop/dragDrop.png';
import excel from '../../assets/icon/excel.png';
import pdf from '../../assets/icon/pdf.png';
import word from '../../assets/icon/word.png';
import powerpoint from '../../assets/icon/powerpoint.png';
import { splitNameAndExtension } from '../../helpers/generalUtil';
import { AttachmentInterface, ItemUploadInterface } from '../CommonInterface/CommonInterface';
import { v4 as uuidv4 } from 'uuid';
import SmallButton from '../../styleComponent/SmallButton/SmallButton';
import { fileUploadApi, filesCreateUrl } from '../../api/auth';
import { cloneDeep } from 'lodash';
import { handleFileUpload } from '../../helpers/fileUpload';
import Loader from '../CommonCompoment/Loader/Loader';
import { showNotifications } from '../../helpers/toaster';

interface ImportProps {
    handleExportClose: () => void;
    exportShow: boolean;
    setExportShow: (type: boolean) => void;
    setProgressCount: (type: number) => void;
    setProgressName: (type: string) => void;
    setProgressIndex: (type: number) => void;
    attachmentList: any;
    setAttachmentList: any;
    setProgressLoading: (type: boolean) => void;
}

const ImportFile = ({ attachmentList,setProgressLoading, setAttachmentList, exportShow, setProgressName, setProgressCount, setProgressIndex, setExportShow, handleExportClose }: ImportProps) => {
    const [spinLoading, setSpinLoading] = useState(false);

    const wrapperRef = useRef<HTMLInputElement>(null);
    const onFileDrop = (event: any) => {
        const newFile = event.target.files[0];
        const fileNameExtension = splitNameAndExtension(newFile.name)
        let fileObj = {
            "fileName": fileNameExtension.name,
            "extension": fileNameExtension.extension,
            "originalFileName": newFile.name,
            "size": newFile.size,
            "file": newFile
        }
        setAttachmentList([...attachmentList, fileObj])
    }

    const fileRemove = (itemId: string) => {
        const filteredItems = attachmentList.filter((item: any) => item.itemId !== itemId);
        setAttachmentList(filteredItems)
    }


    const fileUpload = async () => {
        setSpinLoading(true);
        setProgressLoading(true);
        try {
            const promises = [];

            for (let index = 0; index < attachmentList.length; index++) {
                const fileObj = cloneDeep(attachmentList[index]);
                const file = fileObj.file;
                delete fileObj.file;
                attachmentList[index] = { ...fileObj, progress: 0, status: 'uploading' };

                const promise = filesCreateUrl(fileObj).then(async (data) => {
                    await handleFileUpload(file, data.body.uploadUrl, index, (progress: any) => {
                        setAttachmentList((prevList: any) => {
                            const newList = [...prevList];
                            newList[index] = { ...newList[index], progress };
                            return newList;
                        });
                    });
                    await fileUploadApi(data.body.fileId);
                    setAttachmentList((prevList: any) => {
                        const newList = [...prevList];
                        newList[index] = { ...newList[index], status: 'uploaded' };
                        return newList;
                    });
                });
                promises.push(promise);
            }

            await Promise.all(promises);
            setAttachmentList([]);
        } catch (error) {
            showNotifications("error", `Error while uploading: ${error}`);
        } finally {
            setSpinLoading(false);
            setProgressLoading(false);
            setExportShow(false);
        }
    };

    const handleKeyPress = (event: KeyboardEvent) => {
        if (event.key === 'Enter') {
            fileUpload()
        }
    };
   


    useEffect(() => {
        document.body.addEventListener('keypress', handleKeyPress);
        return () => {
            document.body.removeEventListener('keypress', handleKeyPress);
        };
    }, [attachmentList]);

 
    return (
        <>
            <Modal show={exportShow} onHide={handleExportClose} centered>
                {spinLoading && <Loader />}
                <div className="importFiles">
                    <h1>Import Files</h1>
                    <div ref={wrapperRef} className="drop-file-input">
                        <div className="drop-file-input__label">
                            <img src={dragAndDrop} alt="" />
                            <p>Drag & Drop your files here</p>
                            <div className="bottomIcon">
                                <img src={pdf} alt="" />
                                <img src={powerpoint} alt="" />
                                <img src={word} alt="" />
                                <img src={excel} alt="" />
                            </div>
                        </div>
                        <input type="file" value="" onChange={onFileDrop} />
                    </div>
                    {attachmentList && attachmentList.length ?
                        <div className="requestFileList">
                            <Table responsive className='tableBody'>
                                <thead>
                                    <tr>
                                        <th>File Name</th>
                                        <th>Extension</th>
                                        <th></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {attachmentList && attachmentList.length ? attachmentList.map((item: any, index: number) => (
                                        <tr key={`contact` + index} className="fileUploadText">
                                            <td>{item.fileName.length > 10 ? item.fileName.substring(0, 8) + '..' : item.fileName}</td>
                                            <td>{item.extension}</td>
                                            <td className='tableInnerButton'>
                                                <SmallButton customWidth="71" customHeight="26" customBackgroundColor='#fff' customBorderColor='#F94A29' customTextColor='#F94A29' onClick={() => fileRemove(item.itemId)}>Remove</SmallButton>
                                            </td>
                                        </tr>
                                    )) : ""}
                                </tbody>
                            </Table>
                            <div className="filesBtn">
                                <button onClick={fileUpload}>Upload</button>
                            </div>
                        </div>
                        : ""}
                </div>
            </Modal>
        </>
    )
}

export default ImportFile