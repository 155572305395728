import React from 'react'
import Layout from '../CommonCompoment/Layout/Layout'
import ContentImage from '../CommonCompoment/ContentImage/ContentImage'
import ImageContent from '../CommonCompoment/ImageContent/ImageContent';
import FeatureBanner from './FeatureBanner';
// image
import image90 from "../../assets/content/image90.png";
import image71 from "../../assets/content/image71.png";
import image72 from "../../assets/content/image72.png";
import image91 from "../../assets/content/image91.png";
import image92 from "../../assets/content/image92.png";
import image75 from "../../assets/content/image75.png";
import image76 from "../../assets/content/image76.png";

const Features = () => {

  const currentPath = window.location.pathname;
  const pathSegments = currentPath.split('/');
  const features = pathSegments[1];
  
  const contentDataOne = {
    title: "Streamlined Requests",
    description: "Docoteam offers a streamlined solution for managing information requests and file sharing. Its intuitive platform simplifies the process of requesting and sharing information with others, eliminating the need for manual tracking and communication. With this solution, users can easily send and receive requests, track responses, and collaborate on files in a secure and efficient manner. Docoteam helps organizations save time and improve productivity while maintaining a high level of data security."
  };
  const contentDataTwo = {
    title: "Organized and Controlled Sending",
    description: "Organized Controlled and Send provides users with a powerful toolset to organize their workstreams in the ever increasing and expanding document and file world."
  };
  const contentDataThree = {
    title: "Enterprise Secure Cloud Storage",
    description: "Secure cloud storage is a vital tool that enables users to store and access their data in the cloud, while ensuring the utmost security and privacy. With features such as data encryption, access controls, and real-time activity monitoring, users can be confident that their data is always secure and protected from unauthorized access or data breaches. The convenience and accessibility of cloud storage make it an indispensable part of modern business operations, and with secure cloud storage, users can enjoy the benefits of cloud technology while keeping their data safe and secure."
  };
  const contentDataFour = {
    title: "Secure Governance Controls",
    description: "With governance controls, organizations can set up and enforce policies for data retention, access controls, and file sharing, helping to mitigate the risks of data breaches and regulatory non-compliance. This feature includes real-time monitoring and audit trails to provide visibility and control over data throughout its lifecycle, as well as automated enforcement to ensure policy compliance. By using governance controls, organizations can improve their data management practices, minimize risks, and maintain compliance with industry standards and regulations."
  };
  const contentDataFive = {
    title: "Live Data Rooms",
    description: "Live data rooms are the nexus for communication, collaboration, and document circulation. This feature enables real-time collaboration, eliminating the need for physical meetings or exchanging documents through less secure methods like email. Live data rooms offer advanced security features, and access controls to ensure that only authorized parties can view or download the shared documents. This feature is particularly useful for businesses that deal with sensitive information and require a secure platform for collaboration and file sharing."
  };
  const contentDataSix = {
    title: "Collaborate and Streamline your Workflow",
    description: "Collaborate and streamline your workflow. This is designed by teams for teams to help them work together more efficiently and effectively. Users can collaborate on files in real-time, create joint actions, and accomplish tasks all within the same platform. This enables teams to streamline their workflows and improve productivity, while also ensuring that everyone is on the same page. The collaboration and workflow management feature is an essential tool for any team that needs to work together on files and projects in a secure and organized manner."
  };
  const contentDataSeven = {
    title: "Custom Branding",
    description: "Custom branding allows organizations to customize the appearance of their data rooms to match their brand identity. This includes the ability to add logos, custom colors, and other branding elements to the platform's interface, making it more recognizable and familiar to users. Custom branding can help organizations create a consistent brand experience across all of their digital channels, while also increasing user engagement and adoption of the platform."
  };
  
  return (
    <Layout>
      <FeatureBanner/>
      <ContentImage styleClass={`contentWithImg`} image={image90} content={contentDataOne} />
      <ImageContent display={`none`} styleClass={`imageWithContent`} image={image71} content={contentDataTwo} />
      <ContentImage styleClass={`contentWithImg`} image={image72} content={contentDataThree} />
      <ImageContent display={`none`} styleClass={`imageWithContent`} image={image91} content={contentDataFour} />
      <ContentImage styleClass={`contentWithImg`} image={image92} content={contentDataFive} />
      <ImageContent display={`none`} styleClass={`imageWithContent`} image={image75} content={contentDataSix} />
      <ContentImage styleClass={`contentWithImg`} image={image76} content={contentDataSeven} />
    </Layout>
  )
}

export default Features