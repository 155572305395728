export interface FileNameAndExtension {
  name: string;
  extension: string;
}

export const splitNameAndExtension = (title: string): FileNameAndExtension => {
  const lastIndex = title.lastIndexOf('.');
  const extension = lastIndex !== -1 ? title.slice(lastIndex + 1) : '';
  const name = lastIndex !== -1 ? title.slice(0, lastIndex) : title;
  return { name, extension };
};
