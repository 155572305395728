import React from 'react';
import Layout from '../CommonCompoment/Layout/Layout';
import { Container, Row } from 'react-bootstrap';
import "./ContactUs.css";
import image70 from '../../assets/content/contact.png';
import { Link } from 'react-router-dom';

const ContactUs = () => {
    return (
        <Layout>
            <section className='contactPage'>
                <div className='imageContent'>
                    <Container>
                        <Row>
                            <div className='lineBox'>
                                <div className="leftImage">
                                    <img src={image70} alt='content' />
                                </div>
                                <div className="rightContent">
                                    <h1>Contact Support</h1>
                                    <p>Our support team is here during business hours to help you quickly. We aim to offer great service and make your experience with our products/services smooth. Feel free to contact us anytime for assistance!</p>
                                    <Link to="mailto:support@docoteam.com">Contact Us</Link>
                                </div>
                            </div>
                        </Row>
                    </Container>
                </div>

                <div className='imageContent'>
                    <Container>
                        <Row>
                            <div className='lineBox'>
                                <div className="rightContent">
                                    <h1>Contact Sales</h1>
                                    <p>Explore our unique collection of products under 'The Other Sales.' Discover exclusive deals and special offers on items that stand out from the ordinary. From one-of-a-kind finds to distinctive selections, we've curated a selection that sets itself apart.</p>
                                    <Link to="mailto:Sales@docoteam.com">Contact Sales</Link>
                                </div>
                            </div>
                        </Row>
                    </Container>
                </div>
            </section>
        </Layout>
    )
}

export default ContactUs