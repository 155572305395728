import React, { useState, useEffect } from 'react'
import { Col, Modal, Row, Tab, Table, Tabs } from 'react-bootstrap'
import { getCookieValue, getPackageIdByName, getPriceByName, handleForbiddenError, setCookie } from '../../CommonInterface/CommonFunction';
import { PackageInterface, UserDataInterface } from '../../CommonInterface/CommonInterface';
import { singleUser } from '../../../api/auth';
import { getAllPackage } from '../../../api/package';
import { changeBilling, freeBilling } from '../../../api/payment';
import SmallButton from '../../../styleComponent/SmallButton/SmallButton';
import { Link, useLocation } from 'react-router-dom';
import { ENTERPRISE_FORM } from "../../../config";
import { useNavigate } from 'react-router-dom';

interface UpgradePlanProps {
    handleUpgradeClose: () => void;
    upgradeShow: boolean;
    setUpgradeShow: (type: boolean) => void;
    setUpdatePayment: (type: boolean) => void;
    email?: string;
}


const UpgradePlan = ({ upgradeShow, setUpdatePayment, setUpgradeShow, handleUpgradeClose, email }: UpgradePlanProps) => {
    const [userInfo, setUserInfo] = useState<UserDataInterface>();
    const [packagePrice, setPackagePrice] = useState<PackageInterface[] | undefined>();
    const token = JSON.parse(getCookieValue('docoteam') || '{}');
    const [billingType, setBillingType] = useState("Monthly");
    const storedPaymentPlan = JSON.parse(getCookieValue('choosePlan') || '{}');
    const { user } = token;
    const navigate = useNavigate();
    const location = useLocation();
    const pathParts = location.pathname.split('/');

    useEffect(() => {
        singleUser(user?.userId).then((data) => {
            if (data.statusCode !== 200) {
                if (data.statusCode === 403) {
                    handleForbiddenError(navigate);
                }
            }
            else {
                setUserInfo(data.user);
            }
        });
        getAllPackage().then((data) => {
            setPackagePrice(data.packages);
        });

    }, []);

    const choosePlan = (plan: string) => {
        setBillingType(plan)
        if (storedPaymentPlan) {
            storedPaymentPlan.billingType = plan;
            setCookie('choosePlan', JSON.stringify(storedPaymentPlan));
        }
    }

    const selectChoosePlan = (plan: string) => {
        if (storedPaymentPlan) {
            storedPaymentPlan.price = plan;
            storedPaymentPlan.billingType = billingType;
            setCookie('choosePlan', JSON.stringify(storedPaymentPlan));
        }
        getAllPackage().then((data) => {
            if (data.statusCode === 200) {
                const planPackage = JSON.parse(getCookieValue('choosePlan') || '{}');
                if (planPackage !== null) {
                    const packageId = getPackageIdByName(data.packages, planPackage.price);
                    let packageInfo = {
                        "packageId": packageId,
                        "billingType": billingType
                    }
                    let freePackageInfo = {
                        "packageId": packageId,
                        "billingType": billingType,
                        "userMail": email
                    }
                    if (pathParts[1] === "login") {
                        freeBilling(freePackageInfo).then((data) => {
                            if (data.statusCode !== 200) {
                                console.log('error', data);
                            }
                            else {
                                const userInfo = JSON.parse(getCookieValue('docoteam') || '{}');
                                const updatedData = JSON.stringify({ ...userInfo, ...data });
                                setCookie('docoteam', updatedData);
                                setUpdatePayment(true);
                                setUpgradeShow(false);
                                return navigate("/update-payment")
                            }
                        });
                    }
                    else {
                        changeBilling(packageInfo).then((data) => {
                            if (data.statusCode !== 200) {
                                console.log('error', data);
                            }
                            else {
                                const userInfo = JSON.parse(getCookieValue('docoteam') || '{}');
                                const updatedData = JSON.stringify({ ...userInfo, ...data });
                                setCookie('docoteam', updatedData);
                                setUpdatePayment(true);
                                setUpgradeShow(false);
                                return navigate("/update-payment")
                            }
                        });
                    }


                }
            }
        })
    }

    return (
        <>
            <Modal show={upgradeShow} onHide={handleUpgradeClose} centered size="lg">
                <div className="upgradePlanBox">
                    <Row>
                        <Col md={12} className='mb-5'>
                            <div className="currentPlan">
                                <div className="planHeading">
                                    <h2>Current Plan</h2>
                                </div>
                                <div className="planList">
                                    <div className="planDescription">
                                        <p>Plan Type</p>
                                        <div className='planName'>{userInfo && userInfo.package && userInfo.package.packageName ? userInfo?.package?.packageName : "Free"}</div>
                                    </div>
                                    <div className="planDescription">
                                        <p>Billing Type</p>
                                        <div className='planName'>{userInfo && userInfo.package && userInfo.package.billingType ? userInfo.package.billingType : "7 days"}</div>
                                    </div>
                                    <div className="planDescription">
                                        <p>Price</p>
                                        <div className='planName'>{userInfo && userInfo.package && userInfo.package.paymentAmount ? <>{userInfo && userInfo.package && userInfo.package.paymentAmount / 100}$/{userInfo && userInfo.package && userInfo.package.billingType}/user</> : "0"}</div>
                                    </div>
                                </div>
                            </div>
                        </Col>
                    </Row>
                    <Tabs
                        defaultActiveKey="monthly"
                        id="uncontrolled-tab-example"
                        className="priceTab"
                        onSelect={(key: string | null) => {
                            if (key) {
                                choosePlan(key === 'monthly' ? 'Monthly' : 'Annually');
                            }
                        }}
                    >
                        <Tab className='monthly' eventKey="monthly" title="Monthly">
                            <Row>
                                <Table responsive>
                                    <thead>
                                        <tr>
                                            <th>
                                                <div style={{ display: 'flex', justifyContent: 'space-between', flexDirection: 'column' }}>
                                                    <div className="packageName">
                                                        <h1>Business Standard</h1>
                                                    </div>
                                                    <div className="packagePrice">
                                                        <h1><span className='icon'>$</span>{getPriceByName(packagePrice, 'STANDARD', 0, 1)}<span className='currency'>USD</span></h1>
                                                        <p>/User/Month Billed Monthly</p>
                                                    </div>
                                                    <div className="packageSelect">
                                                        <SmallButton onClick={() => selectChoosePlan("STANDARD")} customWidth="140" customHeight="44" customBackgroundColor='#F94A29' customBorderColor='#F94A29' customTextColor='#fff'>Select</SmallButton>
                                                    </div>
                                                </div>
                                            </th>
                                            <th>
                                                <div style={{ display: 'flex', justifyContent: 'space-between', flexDirection: 'column' }}>
                                                    <div className="recommendText"><p>Recommended</p></div>
                                                    <div className="packageName">
                                                        <h1>Business Plus</h1>
                                                    </div>
                                                    <div className="packagePrice">
                                                        <h1><span className='icon'>$</span>{getPriceByName(packagePrice, 'PLUS', 0, 1)}<span className='currency'>USD</span></h1>
                                                        <p>/User/Month Billed Monthly</p>
                                                    </div>
                                                    <div className="packageSelect">
                                                        <SmallButton onClick={() => selectChoosePlan("PLUS")} customWidth="140" customHeight="44" customBackgroundColor='#F94A29' customBorderColor='#F94A29' customTextColor='#fff'>Select</SmallButton>
                                                    </div>
                                                </div>
                                            </th>
                                            <th>
                                                <div style={{ display: 'flex', justifyContent: 'space-between', flexDirection: 'column' }}>
                                                    <div className="packageName">
                                                        <h1>Enterprise <br /> <br /></h1>

                                                    </div>
                                                    <div className="enterprisePackage">
                                                        <h2>Custom</h2>
                                                    </div>
                                                    <div className='packagePrice'><p></p></div>
                                                    <div className="packageSelect">
                                                        <Link className='googleForm' target="_blank" to={`${ENTERPRISE_FORM}`}>Contact us</Link>
                                                    </div>
                                                </div>
                                            </th>
                                        </tr>
                                    </thead>
                                </Table>
                            </Row>
                        </Tab>
                        <Tab className='yearly' eventKey="yearly" title="Yearly">
                            <Row>
                                <Table responsive>
                                    <thead>
                                        <tr>
                                            <th>
                                                <div style={{ display: 'flex', justifyContent: 'space-between', flexDirection: 'column' }}>
                                                    <div className="packageName">
                                                        <h1>Business Standard</h1>
                                                    </div>
                                                    <div className="packagePrice">
                                                        <h1><span className='previousPrice'><span className='icon'>$</span>{getPriceByName(packagePrice, 'STANDARD', 0, 1)}</span><span className='icon'>$</span>{getPriceByName(packagePrice, 'STANDARD', 5, 1)}<span className='currency'>USD</span></h1>
                                                        <p>/User/Month Billed Annually</p>
                                                    </div>
                                                    <div className="packageSelect">
                                                        <SmallButton onClick={() => selectChoosePlan("STANDARD")} customWidth="140" customHeight="44" customBackgroundColor='#F94A29' customBorderColor='#F94A29' customTextColor='#fff'>Select</SmallButton>
                                                    </div>
                                                </div>
                                            </th>
                                            <th>
                                                <div style={{ display: 'flex', justifyContent: 'space-between', flexDirection: 'column' }}>
                                                    <div className="recommendText"><p>Recommended</p></div>
                                                    <div className="packageName">
                                                        <h1>Business Plus</h1>
                                                    </div>
                                                    <div className="packagePrice">
                                                        <h1><span className='previousPrice'><span className='icon'>$</span>{getPriceByName(packagePrice, 'PLUS', 0, 1)}</span><span className='icon'>$</span>{getPriceByName(packagePrice, 'PLUS', 5, 1)}<span className='currency'>USD</span></h1>
                                                        <p>/User/Month Billed Annually</p>
                                                    </div>
                                                    <div className="packageSelect">
                                                        <SmallButton onClick={() => selectChoosePlan("PLUS")} customWidth="140" customHeight="44" customBackgroundColor='#F94A29' customBorderColor='#F94A29' customTextColor='#fff'>Select</SmallButton>
                                                    </div>
                                                </div>
                                            </th>
                                            <th>
                                                <div style={{ display: 'flex', justifyContent: 'space-between', flexDirection: 'column' }}>
                                                    <div className="packageName">
                                                        <h1>Enterprise <br /> <br /></h1>
                                                    </div>
                                                    <div className="enterprisePackage">
                                                        <h2>Custom</h2>
                                                    </div>
                                                    <div className='packagePrice'><p></p></div>
                                                    <div className="packageSelect">
                                                        <Link className='googleForm' target="_blank" to={`${ENTERPRISE_FORM}`}>Contact us</Link>
                                                    </div>
                                                </div>
                                            </th>
                                        </tr>
                                    </thead>
                                </Table>
                            </Row>
                        </Tab>
                    </Tabs>
                </div>
            </Modal>
        </>
    )
}

export default UpgradePlan