import React, { useState, useEffect } from 'react'
import DashboardNavbar from '../CommonCompoment/DashboardNavbar/DashboardNavbar'
import { Col, Container, Modal, Row, Table } from 'react-bootstrap';
import Loader from '../CommonCompoment/Loader/Loader';
import { useNavigate } from 'react-router-dom';
// google font awesome
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronDown, faChevronUp, faLock, faSearch } from '@fortawesome/free-solid-svg-icons';
import SmallButton from '../../styleComponent/SmallButton/SmallButton';
import { fileSize, getCookieValue, handleForbiddenError, multiplyBySixAndShowSeries } from '../CommonInterface/CommonFunction';
import { deleteFile, getMyFiles } from '../../api/files';
import { FileInformationInterface } from '../CommonInterface/CommonInterface';
import moment from 'moment';
import "./MyFiles.css";
import { filesDownloadApi } from '../../api/auth';
import { showNotifications } from '../../helpers/toaster';
import { ToastContainer } from 'react-toastify';
import Pagination from '../CommonCompoment/Pagination/Pagination';
import Vault from './Vault';
import { downloadFilesAsZip } from '../../helpers/fileDownload';
import WarningModal from '../CommonCompoment/WarningModal/WarningModal';
import ImportFile from './ImportFile';
import ProgressLine from '../CommonCompoment/ProgressBar/ProgressLine';


const MyFiles = () => {
    const navigate = useNavigate();
    const [fadeAnimation, setFadeAnimation] = useState(false);
    const [fileId, setFileId] = useState("");
    const [spinLoading, setSpinLoading] = useState<boolean>(false);
    const [progressLoading, setProgressLoading] = useState<boolean>(false);
    const [filesList, setFilesList] = useState<FileInformationInterface[]>([]);
    const [tableActive, setTableActive] = useState("files");
    const userValue = JSON.parse(getCookieValue('docoteam') || "");
    const [sortBy, setSortBy] = useState("");
    const [sortType, setSortType] = useState("ASC");
    const [tableFileName, setTableFileName] = useState(false);
    const [tableFileExtension, setTableFileExtension] = useState(false);
    const [tableFileSize, setTableFileSize] = useState(false);
    const [tableFileCreate, setTableFileCreate] = useState(false);
    const [show, setShow] = useState(false);
    const [multiDownload, setMultiDownload] = useState<boolean>(false);
    const [progressCount, setProgressCount] = useState(0);
    const [progressIndex, setProgressIndex] = useState(0);
    const [progressName, setProgressName] = useState("");
    const handleClose = () => setShow(false);
    const handleShow = (fileId: string) => {
        setShow(true);
        setFileId(fileId);
    }

    // pagination state
    const [totalValue, setTotalValue] = useState<any>();
    const [limitValue, setLimitValue] = useState<any>();
    const [page, setPage] = useState(1);
    const [limit, setLimit] = useState<number>(6);
    const [pageValue, setPageValue] = useState<number>();
    const pageCount = Math.ceil(totalValue / limitValue);
    const [prevButton, setPrevButton] = useState<boolean>(false);
    const [nextButton, setNextButton] = useState<boolean>(false);
    const [doubleNextButton, setDoubleNextButton] = useState<boolean>(false);
    const [doublePrevButton, setDoublePrevButton] = useState<boolean>(false);
    const [resultLength, setResultLength] = useState<number>(0);
    const pageResult = multiplyBySixAndShowSeries(totalValue);
    const [allCheck, setAllCheck] = useState(false);
    const [searchTerm, setSearchTerm] = useState("");
    const [searchString, setSearchString] = useState("");
    const [warningShow, setWarningShow] = useState(false);
    const [attachmentList, setAttachmentList] = useState<any[]>([]);

    // export file
    const [exportShow, setExportShow] = useState(false);
    const handleExportClose = () => setExportShow(false);
    const handleExportShow = () => setExportShow(true);


    const handleWarningClose = () => {
        setWarningShow(false);
    }
    const headingText = "This will permanently delete all of your file";
    const paragraphText = "**You won't be able to recover any file";

    //load my contacts on load
    useEffect(() => {
        setFadeAnimation(true);
        getMyFiles(limit, page, searchString, sortBy, sortType).then(data => {
            if (data.statusCode !== 200) {
                if (data.statusCode === 403) {
                    handleForbiddenError(navigate);
                }
            }
            else {
                // setFilesList(data.body.files);
                setResultLength(data.body.files.length);
                setTotalValue(data.body.total);
                setLimitValue(data.body.limit);
                setPageValue(data.body.page);
                const updatedResponseTable = data.body.files.map((r: FileInformationInterface) => ({ ...r, isChecked: false, }));
                setFilesList(updatedResponseTable);
                setSpinLoading(false);
            }
        });

        if (pageCount > 1) {
            setPrevButton(true)
        }
        if (page === 1) {
            setPrevButton(false)
        }
        // next button
        if (pageCount > 1) {
            setNextButton(true)
        }
        if (pageCount === page) {
            setNextButton(false)
        }
        // double next step
        if (pageCount > 2) {
            setDoubleNextButton(true)
        }
        if (pageCount - 1 === page || pageCount === page) {
            setDoubleNextButton(false)
        }
        // double prev step
        if (pageCount > 2) {
            setDoublePrevButton(true)
        }
        if (page === 1 || page === 2) {
            setDoublePrevButton(false)
        }


    }, [spinLoading, show, page, totalValue, limitValue, limit, searchString, sortType, exportShow]);

    const nextPage = (pageNumber: number) => {
        setPage(page + pageNumber)
        setNextButton(true)
    }
    const prevPage = (pageNumber: number) => {
        setPage(page - pageNumber)
    }
    const showResult = (value: number) => {
        setPage(1)
        setLimit(value)
    }


    // download file
    const downloadFile = (fileId: string) => {
        setSpinLoading(true)
        filesDownloadApi(fileId).then((data) => {
            if (data.statusCode !== 200) {
                setSpinLoading(false)
            }
            else {
                window.location.href = data.body.downloadUrl;
                setSpinLoading(false)
            }
        })
    }

    // delete file
    const removeFile = () => {
        setShow(false);
        setSpinLoading(true)
        deleteFile(fileId).then((data) => {
            if (data.statusCode !== 200) {
                showNotifications('error', data.message);
                setSpinLoading(false)
            }
            else {
                setSpinLoading(false);
                showNotifications('success', "This file delete successfully");
            }
        })
    }
    //    contact searching
    const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setSearchTerm(event.target.value);
        setSearchString(event.target.value);
    };

    function countCheckedItems(arr: any) {
        let count = 0;
        for (let i = 0; i < arr.length; i++) {
            if (arr[i].isChecked === true) {
                count++;
            }
        }
        return count;
    }

    // table check
    const singleTable = (fileId: string) => {
        setMultiDownload(true);
        const filesTempObj = [...filesList];
        for (let i = 0; i < filesTempObj.length; i++) {
            if (filesTempObj[i].fileId === fileId) {
                filesTempObj[i].isChecked = !filesTempObj[i].isChecked
                break;
            }
        }
        setFilesList(filesTempObj);
        if (countCheckedItems(filesTempObj) === filesTempObj.length) {
            setAllCheck(true)
        } else {
            setAllCheck(false);
        }
        if (countCheckedItems(filesTempObj) === 0) {
            setMultiDownload(false);
        }
    }
    const checkAllSelect = () => {
        setMultiDownload(!multiDownload);
        let sentTempObj = filesList;
        let sentArr: any = [];
        if (countCheckedItems(filesList) === filesList.length) {
            for (let i = 0; i < sentTempObj.length; i++) {
                sentTempObj[i].isChecked = false;
                sentArr.push(sentTempObj[i].fileId)
            }
        }
        else {
            for (let i = 0; i < sentTempObj.length; i++) {
                sentTempObj[i].isChecked = true;
                sentArr.push(sentTempObj[i].fileId)
            }
        }

        if (countCheckedItems(filesList) === filesList.length) {
            setAllCheck(true)
        }
        else {
            setAllCheck(false)
        }

    }

    // all download
    const allDownload = async () => {
        setSpinLoading(true)
        const urls: string[] = []
        for (let i = 0; i < filesList.length; i++) {
            if (filesList[i].isChecked === true) {
                const response = await filesDownloadApi(filesList[i].fileId);
                if (!response.body || !response.body.downloadUrl) {
                    throw new Error("Invalid response from server");
                }
                urls.push(response.body.downloadUrl);
            }
        }
        if (urls.length === 0) {
            setSpinLoading(false)
        }
        else {
            downloadFilesAsZip(urls, "All-files", setSpinLoading)
        }
    }

    const removeFiles = () => {
        setWarningShow(true);
    }
    // all delete
    const allDelete = async () => {
        setSpinLoading(true)
        for (let i = 0; i < filesList.length; i++) {
            if (filesList[i].isChecked === true) {
                deleteFile(filesList[i].fileId).then((data) => {
                    if (data.statusCode !== 200) {
                        showNotifications('error', "Something wrong");
                        setSpinLoading(false)
                    }
                    else {
                        setSpinLoading(false);
                    }
                })
            }

        }
        showNotifications('success', "Your files remove successfully");
        setWarningShow(false);
    }

    const sortData = (columnName: string) => {
        if (columnName === "fileName") {
            setTableFileName(!tableFileName)
        }
        if (columnName === "extension") {
            setTableFileExtension(!tableFileExtension)
        }
        if (columnName === "size") {
            setTableFileSize(!tableFileSize)
        }
        if (columnName === "createdAt") {
            setTableFileCreate(!tableFileCreate)
        }
        if (columnName === sortBy) {
            setSortType(sortType === "ASC" ? "DESC" : "ASC");
        } else {
            setSortBy(columnName);
            setSortType("ASC");
        }
    }

    const tableTab = (tableName: string) => {
        setSpinLoading(true);
        setTableActive(tableName)
    }


    // all download
    const exportFiles = async () => {
        setSpinLoading(true)
        const urls: string[] = []
        for (let i = 0; i < filesList.length; i++) {
            const response = await filesDownloadApi(filesList[i].fileId);
            if (!response.body || !response.body.downloadUrl) {
                throw new Error("Invalid response from server");
            }
            urls.push(response.body.downloadUrl);
        }
        if (urls.length === 0) {
            setSpinLoading(false)
        }
        else {
            downloadFilesAsZip(urls, "All-files", setSpinLoading)
        }
    }

    return (
        <>
            <DashboardNavbar />
            {spinLoading && <Loader />}
            <ToastContainer />
            {progressLoading && <ProgressLine attachmentList={attachmentList} /> }
            
            <section className={`fade-in contactPage ${fadeAnimation ? 'visible' : ''}`}>
                <Container fluid>
                    <Row>
                        <Col md={12}>

                            <div className="filesBox">
                                <div className='filesHeading justify-content-between'>
                                    <div className='d-flex align-items-center'>
                                        <h2>My files</h2>
                                        <div className="searchName contactSearch">
                                            <input type="text" className="form-control" value={searchTerm} onChange={handleInputChange} id="inputValidation" placeholder="Search Files" />
                                            <FontAwesomeIcon icon={faSearch} />
                                        </div>
                                    </div>
                                    {userValue && userValue.user.currentStoragePlan === "PLUS" ? <div>
                                        <button className={tableActive === "files" ? 'vault' : 'vaultBlank'} onClick={() => tableTab("files")}>My files</button>
                                        <button className={tableActive === "files" ? 'vaultBlank' : 'vault'} onClick={() => tableTab("vault")}>DocoVault</button>
                                    </div> : <div className='d-flex'>
                                        <button className={tableActive === "files" ? 'vault' : 'vaultBlank'} onClick={() => tableTab("files")}>My files</button>
                                        <div className="tooltip-top" data-tooltip="Please upgrade your plan">
                                            <button className="vaultBlank">DocoVault <FontAwesomeIcon icon={faLock} /></button>
                                        </div>
                                    </div>}

                                </div>
                                {tableActive === "files" ?
                                    <>
                                        <div className="contactListTable myFilesList">
                                            {multiDownload ? <div className='chooseItem mt-2'>
                                                <button className='removeBtn mr-3' onClick={() => allDownload()}><SmallButton customWidth="82" customHeight="24" customBackgroundColor='#fff' customBorderColor='#fff' customTextColor='#747679;' >Download</SmallButton></button>
                                                <button className='removeBtn' onClick={() => removeFiles()}><SmallButton customWidth="82" customHeight="24" customBackgroundColor='#fff' customBorderColor='#fff' customTextColor='#747679;' >Remove</SmallButton></button>
                                            </div> : ""}
                                            <Table responsive className='tableBody'>
                                                <thead>
                                                    <tr>
                                                        <th>
                                                            <label className='tableCheckBox mobileTable'>
                                                                <div className="contactCheck">
                                                                    <input type="checkbox" onClick={checkAllSelect} checked={allCheck} onChange={() => { }} />
                                                                    <span className="checkmark"></span>
                                                                </div>
                                                            </label>
                                                        </th>
                                                        <th onClick={() => sortData("fileName")}>File Name {tableFileName ? <FontAwesomeIcon icon={faChevronDown} /> : <FontAwesomeIcon icon={faChevronUp} />}</th>
                                                        <th onClick={() => sortData("extension")}>Extension {tableFileExtension ? <FontAwesomeIcon icon={faChevronDown} /> : <FontAwesomeIcon icon={faChevronUp} />}</th>
                                                        <th onClick={() => sortData("size")}>File Size {tableFileSize ? <FontAwesomeIcon icon={faChevronDown} /> : <FontAwesomeIcon icon={faChevronUp} />}</th>
                                                        <th onClick={() => sortData("createdAt")}>Created Date {tableFileCreate ? <FontAwesomeIcon icon={faChevronDown} /> : <FontAwesomeIcon icon={faChevronUp} />}</th>
                                                        <th>Action</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {filesList && filesList.length ?
                                                        <>{filesList && filesList.map((data, i: number) => <tr className='leftTable' key={`contact` + i}>
                                                            <td>
                                                                <label className='tableCheckBox mobileTable'>
                                                                    <div className="contactCheck">
                                                                        <input type="checkbox" onClick={() => singleTable(data.fileId)} checked={data.isChecked || allCheck} onChange={() => { }} />
                                                                        <span className="checkmark"></span>
                                                                    </div>
                                                                </label>
                                                            </td>
                                                            <td>
                                                                <p className={data.fileName && data.fileName.length > 24 ? 'tooltip-top' : ""} data-tooltip={data.fileName && data.fileName.length > 24 ? data.fileName : ""}>
                                                                    {data.fileName && data.fileName.length > 24 ? data.fileName.substring(0, 22) + '..' : data.fileName}
                                                                </p>
                                                            </td>
                                                            <td>{data.extension && data.extension}</td>
                                                            <td>{fileSize(data.size)}</td>
                                                            <td>{moment(data.createdAt).format('MMM DD YYYY')}</td>
                                                            <td className='d-flex'>
                                                                <SmallButton style={{ marginRight: '5px' }} customWidth="68" customHeight="24" customBackgroundColor='#FFFFFF' customBorderColor='#F94A29' customTextColor='#F94A29' onClick={() => downloadFile(data.fileId)}>Download</SmallButton>
                                                                <SmallButton style={{ marginRight: '5px' }} customWidth="62" customHeight="24" customBackgroundColor='#FFFFFF' customBorderColor='#F94A29' customTextColor='#F94A29' onClick={() => handleShow(data.fileId)}>Remove</SmallButton>
                                                                <SmallButton customWidth="62" customHeight="24" customBackgroundColor='#FFFFFF' customBorderColor='#F94A29' customTextColor='#F94A29'>Restore</SmallButton>
                                                            </td>
                                                        </tr>)}</>
                                                        : <tr>
                                                            <td className='text-center fileEmpty' colSpan={6}>There are no files available for display</td>
                                                        </tr>}
                                                </tbody>
                                            </Table>
                                        </div>
                                        <div className="contactPagination">
                                            <div className='contactPagList'>
                                                <div className="contactDownload">
                                                    <button onClick={exportFiles}>Export</button>
                                                    <button onClick={() => setExportShow(true)}>Import</button>
                                                </div>
                                                <div className='w-100 paginationBottom'>
                                                    {filesList && filesList.length > 0 ? <>
                                                        <div className='w-100 paginationBottom'>
                                                            <Pagination doublePrevButton={doublePrevButton} doubleNextButton={doubleNextButton} showResult={showResult} pageResult={pageResult} prevButton={prevButton} nextButton={nextButton} pageValue={pageValue} totalValue={totalValue} prevPage={prevPage} nextPage={nextPage} resultLength={resultLength} />
                                                        </div>
                                                    </> : ""}
                                                </div>
                                            </div>
                                        </div>
                                    </>
                                    : ""}
                                {tableActive === "files" ? "" :
                                    <>
                                        <div className="contactListTable myFilesList">
                                            <Vault spinLoading={spinLoading} setSpinLoading={setSpinLoading} />
                                        </div>
                                        
                                    </>
                                }

                            </div>
                        </Col>
                    </Row>
                </Container>
            </section>

            <Modal show={show} onHide={handleClose} centered>
                <Modal.Body>
                    <div className="deleteBox">
                        <h1>You want to delete the file permanently?</h1>
                        <p>*This file can't recover once you delete it.</p>
                        <div className='d-flex justify-content-around'>
                            <div>
                                <button className='no' onClick={handleClose}>NO</button>
                            </div>
                            <div className='d-flex flex-column'>
                                <button className='yes' onClick={() => removeFile()}>YES</button>
                            </div>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>
            <WarningModal headingText={headingText} paragraphText={paragraphText} deleteEmployee={allDelete} handleWarningClose={handleWarningClose} warningShow={warningShow} />
            <ImportFile setProgressLoading={setProgressLoading} attachmentList={attachmentList} setAttachmentList={setAttachmentList} setProgressIndex={setProgressIndex} setProgressName={setProgressName} setProgressCount={setProgressCount} exportShow={exportShow} setExportShow={setExportShow} handleExportClose={handleExportClose} />
        </>
    )
}

export default MyFiles