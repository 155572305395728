import { post, get, del, patch } from './base-api';

// get all system config
export const systemConfigList = () => {
    return get(`/system-config/all-config`);
};

// single system config
export const singleSystemConfig = () => {
    return get(`/system-config`);
}

// update system config
export const updateSystemConfig = (systemId:string,body={}) => {
    return patch(`/system-config/${systemId}`,body);
}

// delete system config user
export const deleteSystemConfig = (userId: string) => {
    return del(`/system-config/remove-user/${userId}`);
}