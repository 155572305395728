import axios, { AxiosError } from 'axios';
import { get, post } from '../api/base-api';
import { itemPost } from './../api/base-api';
import { v4 as uuidv4 } from 'uuid';

/**
 * upload files with exponential backoff
 * @param url presigned url
 * @param file 
 * @param retries default 3
 * @param delay default 1000ms
 * @returns 
 */
async function uploadFileToPresignedUrl(
  url: string,
  file: File,
  index: number,
  progressCallback: (progress: number, index: number, fileName: string) => void,
  retries = 3,
  delay = 1000,
): Promise<void> {
  try {
    const { data } = await axios.put(url, file, {
      onUploadProgress: (progressEvent: any) => {
        const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
        progressCallback(percentCompleted, index, file.name);
      },
    });
  } catch (error: any) {
    if (retries === 0) {
      throw new Error(`Failed to upload file: ${error.message}`);
    }

    const axiosError = error as AxiosError;
    if (
      axiosError &&
      axiosError.response &&
      axiosError.response.status >= 500
    ) {
      const newDelay = delay * 2;
      await new Promise((resolve) => setTimeout(resolve, delay));
      return uploadFileToPresignedUrl(url, file, index, progressCallback, retries - 1, newDelay);
    }

    throw new Error(`Failed to upload file: ${error.message}`);
  }
}



export async function handleAttachmentUpload(file: any, url: string, index: number, progressCallback: any): Promise<void> {
  try {
    const response = await get(url);
    const presignedUrl = response.body.uploadUrl;
    await uploadFileToPresignedUrl(presignedUrl, file, index, progressCallback);
  } catch (error: any) {
    console.log(error);
  }
}


export async function handleFileUpload(file: File, presignedUrl: string, index: number, progressCallback: any): Promise<void> {
  try {
    await uploadFileToPresignedUrl(presignedUrl, file, index, progressCallback);
  } catch (error: any) {
    console.log(error);
  }
}