import React from 'react'
import { Container, Row, Col } from 'react-bootstrap'
import { Link } from 'react-router-dom';
import request from '../../assets/icon/request.png';
import sentFile from '../../assets/icon/sentFiles.png';
import template from '../../assets/icon/template.png';
import TopButton from '../../styleComponent/TopButton/TopButton'
interface DashboardProps {
    saveTemplateModal: () => void;
    tableActive: string;
    setTableActive: React.Dispatch<React.SetStateAction<string>>;
    setTableId: any;
    setResult: any;
    allRequestList: any;
    allSentList: any;
    setAllCheck: any;
    setLimit: any;
    setPage:any;
}
const DashboardLink = ({ setPage,setLimit, setAllCheck, allSentList, allRequestList, setResult, setTableId, tableActive, setTableActive, saveTemplateModal }: DashboardProps) => {
    const fileTypeChoose = (type: string) => {
        setTableActive(type);
        setTableId([]);
        setResult([]);
        setLimit(6);
        setPage(1);
        setAllCheck(false)
        for (let i = 0; i < allRequestList.length; i++) {
            delete allRequestList[i].isChecked;
        }
        for (let i = 0; i < allSentList.length; i++) {
            delete allSentList[i].isChecked;
        }
    }
    return (
        <>
            <Container fluid>
                <Row>
                    <Col md={12}>
                        <div className="dashboardSerial">
                            <div className="requestButton">
                                <Link to="/create-request"> <TopButton className='dashBtn' customBackgroundColor="#F94A29" customBorderColor="#F94A29" customTextColor="#fff"><img src={request} alt="" />Create Request</TopButton></Link>
                                <Link to="/sent"><TopButton className='dashBtn' customBackgroundColor="#F94A29" customBorderColor="#F94A29" customTextColor="#fff"><img src={sentFile} alt="" />Send Files</TopButton></Link>
                                <Link to="" onClick={saveTemplateModal}><TopButton className='dashBtn' customBackgroundColor="#fff" customBorderColor="#F94A29" customTextColor="#F94A29"><img src={template} alt="" />Manage Templates</TopButton></Link>
                            </div>
                            <div className="sentButton">
                                <TopButton className={tableActive !== 'sendfile' ? 'activeBtn' : ''} onClick={() => fileTypeChoose("sendfile")} customBackgroundColor="#F94A29" customBorderColor="#F94A29" customTextColor="#fff">Sent Files</TopButton>
                                <TopButton className={tableActive !== 'request' ? 'activeBtn' : ''} onClick={() => fileTypeChoose("request")} customBackgroundColor="#F94A29" customBorderColor="#F94A29" customTextColor="#fff">Requests</TopButton>
                            </div>
                        </div>
                    </Col>
                </Row>
            </Container>
        </>
    )
}

export default DashboardLink