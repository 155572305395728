import React, { useState } from 'react'
import { useForm } from 'react-hook-form';
import { createContact } from '../../api/contact';
import { Modal } from 'react-bootstrap'
import { showNotifications } from '../../helpers/toaster';
import { ToastContainer } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import PhoneInput from 'react-phone-input-2';
import { handleForbiddenError, removeCookie } from '../CommonInterface/CommonFunction';

interface AddContactProps {
    handleClose: () => void;
    show: boolean;
    setShow: (type: boolean) => void;
    setSpinLoading: (type: boolean) => void;
    count: number;
    setCount: (type: number) => void;
    setGroupListShow: (type: boolean) => void;
    groupIdentifier: string;
    groupName: string;
}

const AddContact = ({ groupName, show, handleClose, setShow, setSpinLoading, count, setCount, setGroupListShow, groupIdentifier }: AddContactProps) => {

    const [contactError, setContactError] = useState("");
    const [isShared, setIsShared] = useState(false);
    const [phoneNumber, setPhoneNumber] = useState("");
    const navigate = useNavigate();
    const handleCheckboxChange = () => {
        setIsShared(!isShared);
    };
    const {
        register,
        handleSubmit,
        setValue,
    } = useForm();
    //create new contact
    let onSubmit = (user: any) => {
        window.scrollTo({
            top: 0,
            behavior: "smooth"
        });
        const { email, firstName, lastName } = user;
        setSpinLoading(true)
        createContact([{ email, firstName, lastName, phoneNumber, isShared, groupIdentifier }]).then((data) => {
            if (data.statusCode !== 201) {
                if (data.statusCode === 403) {
                    handleForbiddenError(navigate);
                }
                else {
                    setContactError("This email already exist")
                    setSpinLoading(false)
                    setShow(false);
                }
            }
            else {
                setValue("email", "", { shouldValidate: false });
                setValue("firstName", "", { shouldValidate: false });
                setValue("lastName", "", { shouldValidate: false });
                setPhoneNumber("");
                setContactError("");
                setShow(false);
                showNotifications("success", data.message);
                setTimeout(() => {
                    setSpinLoading(false)
                    setIsShared(false);
                    setCount(count + 1)
                }, 500)
            }
        })
    }
    const addGroup = () => {
        setShow(false);
        setGroupListShow(true);
    }
    const handlePhoneChange = (value: string) => {
        setPhoneNumber(value);
    };

    return (
        <>
            <Modal show={show} onHide={handleClose} centered>
                <ToastContainer />
                <div className="addContactForm">
                    <h1>Add New Contact</h1>
                    <form onSubmit={handleSubmit(onSubmit)}>
                        <div className="registrationInput">
                            <label>First Name <sup className='asterisk'>*</sup></label>
                            <input type="text" id="firstName" placeholder='First Name' {...register("firstName", { required: true })} required />
                        </div>
                        <div className="registrationInput">
                            <label>Last Name <sup className='asterisk'>*</sup></label>
                            <input type="text" id="lastName" placeholder='Last Name' {...register("lastName", { required: true })} required />
                        </div>
                        <div className="registrationInput">
                            <label>E-mail Address <sup className='asterisk'>*</sup></label>
                            <input type="email" id="email" placeholder='Email Address'  {...register("email", { required: true })} required />
                        </div>
                        <div className="contactNumber">
                            <label>Phone Number</label>
                            <PhoneInput country={'us'} disableCountryCode={false} onChange={(value) => handlePhoneChange(value)}  />
                        </div>
                        {contactError && contactError.length ? <p className='errMsg'>{contactError}</p> : ""}
                        {groupName && groupName.length ? <>
                            <div className="groupText">
                                <h6>Selected group: {groupName}</h6>
                            </div>
                        </> : <></>}
                        <div className="contactCondition">
                            <div className="shared">
                                <label className='tableCheckBox mobileTable'>
                                    <div className="contactCheck">
                                        <input checked={isShared} name="isShared" type="checkbox" onChange={handleCheckboxChange} />
                                        <span className="checkmark"></span>
                                    </div>
                                    <p>Add as Shared Contact</p>
                                </label>
                            </div>
                            <div className="groupAdd">
                                {groupName && groupName.length ?
                                    <><p onClick={addGroup}>Change Group</p></>
                                    : <><p onClick={addGroup}>Add to Group</p></>
                                }
                            </div>
                        </div>
                        <div className="contactContent">
                            <p>If Shared Contacts are enabled, this contact will be shared with other users. Admins can always see all contacts.</p>
                        </div>
                        <div className="addContactBtn">
                            <div className="addContact">
                                <p className='back' onClick={handleClose}>Back</p>
                            </div>
                            <div className="addContact">
                                <button type='submit' className='save'>Add</button>
                            </div>
                        </div>
                    </form>
                </div>
            </Modal>
        </>
    )
}

export default AddContact