import { getCookieValue } from '../Component/CommonInterface/CommonFunction';
import { DOCOTEAM_API as API } from '../config';



// POST request
export const post = (url: string, body: any = {}) => {
    const token = JSON.parse(getCookieValue('docoteam') || '{}');
    const { jwt } = token;
    return fetch(`${API}${url}`, {
        method: 'POST',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            Authorization: (jwt) ? `Bearer ${jwt.toString()}` : "",
        },
        body: JSON.stringify(body),
    })
        .then((res) => {
            return res.json();
        })
        .catch((err) => {
            return err;
        });
};


// GET request
export const get = (url: string) => {
    const token = JSON.parse(getCookieValue('docoteam') || '{}');
    const { jwt } = token;
    return fetch(`${API}${url}`, {
        method: 'GET',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            Authorization: (jwt) ? `Bearer ${jwt.toString()}` : "",
        }
    })
        .then((res) => {
            return res.json();
        })
        .catch((err) => {
            return err;
        });
};

// PUT request
export const put = (url: string, body: any = {}) => {
    const token = JSON.parse(getCookieValue('docoteam') || '{}');
    const { jwt } = token;
    return fetch(`${API}${url}`, {
        method: 'PUT',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            Authorization: (jwt) ? `Bearer ${jwt.toString()}` : "",
        },
        body: JSON.stringify(body),
    })
        .then((res) => {
            return res.json();
        })
        .catch((err) => {
            return err;
        });
};



// PATCH request
export const patch = (url: string, body: any = {}) => {
    const token = JSON.parse(getCookieValue('docoteam') || '{}');
    const { jwt } = token;
    return fetch(`${API}${url}`, {
        method: 'PATCH',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            Authorization: (jwt) ? `Bearer ${jwt.toString()}` : "",
        },
        body: JSON.stringify(body),
    })
        .then((res) => {
            return res.json();
        })
        .catch((err) => {
            return err;
        });
};


// DELETE request
export const del = (url: string, body: any = {}) => {
    const token = JSON.parse(getCookieValue('docoteam') || '{}');
    const { jwt } = token;
    return fetch(`${API}${url}`, {
        method: 'DELETE',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            Authorization: (jwt) ? `Bearer ${jwt.toString()}` : "",
        },
        body: JSON.stringify(body),
    })
        .then((res) => {
            return res.json();
        })
        .catch((err) => {
            return err;
        });
};


// GET request
export const verifyGet = (url: string, token: string) => {
    return fetch(`${API}${url}`, {
        method: 'GET',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            Authorization: (token) ? `Bearer ${token.toString()}` : "",
        }
    })
        .then((res) => {
            return res.json();
        })
        .catch((err) => {
            return err;
        });
};


export const itemPost = (url: string, body: any = {}, token: string) => {
    return fetch(`${API}${url}`, {
        method: 'POST',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            Authorization: (token) ? `Bearer ${token.toString()}` : "",
        },
        body: JSON.stringify(body),
    })
        .then((res) => {
            return res.json();
        })
        .catch((err) => {
            return err;
        });
};
// upload get request
export const uploadGet = (url: string, tokenId: string) => {
    //   const token = JSON.parse(getCookieValue('docoteam') || '{}');
    // const { jwt } = token;
    return fetch(`${API}${url}`, {
        method: 'GET',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            Authorization: (tokenId) ? `Bearer ${tokenId.toString()}` : "",
        }
    })
        .then((res) => {
            return res.json();
        })
        .catch((err) => {
            return err;
        });
};

// Files DELETE request
export const fileDel = (url: string, body: any = {}, tokenId: string) => {
    return fetch(`${API}${url}`, {
        method: 'DELETE',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            Authorization: (tokenId) ? `Bearer ${tokenId.toString()}` : "",
        },
        body: JSON.stringify(body),
    })
        .then((res) => {
            return res.json();
        })
        .catch((err) => {
            return err;
        });
};

// all user pai request
// POST request
export const allGet = (url: string, body: any = {}) => {
    const token = JSON.parse(getCookieValue('docoteam') || '{}');
    const { jwt } = token;
    const searchParams = new URLSearchParams(body);
   
    return fetch(`${API}${url}?${searchParams.toString()}`, {
        method: 'GET',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            Authorization: (jwt) ? `Bearer ${jwt.toString()}` : "",
        },
    })
        .then((res) => {
            return res.json();
        })
        .catch((err) => {
            return err;
        });
};