import React, { useState, useEffect } from 'react'
import { Col, Container, Row, Table, Modal, Navbar } from 'react-bootstrap';
import Button from '../../styleComponent/Button/Button';
import SmallButton from '../../styleComponent/SmallButton/SmallButton';
import DropFile from './DropFile';
import './Email.css';
import { NavLink, useNavigate } from 'react-router-dom';
import { attachmentDownloadUrlApi, fileCreateUrl, filesUploadApi, markCompletedApi, uploadRequestApi } from '../../api/auth';
import { ItemUploadInterface, UploadFileInterface } from '../CommonInterface/CommonInterface';
import { handleFileUpload } from '../../helpers/fileUpload';
import { cloneDeep } from 'lodash';
import check from "../../assets/icon/doneOne.png";
import unCheck from "../../assets/icon/doneTwo.png";
import Loader from '../CommonCompoment/Loader/Loader';
import logoIcon from "../../assets/logo/clearLogo.png";
import UploadComplete from '../CommonCompoment/UploadComplete/UploadComplete';
import { getCookieValue } from '../CommonInterface/CommonFunction';

const Download = () => {
    const navigate = useNavigate();
    const tokenJson = getCookieValue('requestId');
    const uploadToken = JSON.parse(tokenJson || "");
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const [viewNotes, setViewNotes] = useState("");
    const [itemUpdate, setItemUpdate] = useState<number>(0)
    const handleShow = (itemNotes: string) => {
        setShow(true);
        setViewNotes(itemNotes)
    }
    const [completeTag, setCompleteTag] = useState<boolean>(false)
    const [attchId, setAttachId] = useState('')
    const [itemUploadId, setItemUploadId] = useState("");
    const [itemUploadName, setItemUploadName] = useState("");
    const [itemDescription, setItemDescription] = useState("");
    const [modalShow, setModalShow] = useState(false);
    const permissionModal = () => setModalShow(false);
    const [fileName, setFileName] = useState("")
    const [activeShow, setActiveShow] = useState<string[]>([])
    const [activePartial, setActivePartial] = useState("")
    const [disableComplete, setDisableComplete] = useState(false);
    // new attachment download
    const [spinLoading, setSpinLoading] = useState(false);
    const [tableList, setTableList] = useState([]);
    const [uploadItem, setUploadItem] = useState<ItemUploadInterface[]>([])
    const [uploadItemList, setUploadItemList] = useState<string[]>([])
    const [showUPloadFile, setShowUPloadFile] = useState<any>([]);
    const [newListUpdate, setNewListUpdate] = useState<ItemUploadInterface[]>([]);
    const [deleteFilesId, setDeleteFilesId] = useState("");
    const [itemList, setItemList] = useState<UploadFileInterface["body"]["items"]>([]);
    const [saveUploadItem, setSaveUploadItem] = useState<UploadFileInterface["body"]["items"]>([]);
    const itemTrueActive = findItemIdWithIsRequiredTrue(itemList);
    const [associatedUserList, setAssociatedUserList] = useState<string[]>([]);
    const [downloadComplete, setDownloadComplete] = useState(true);


    const onLoadItem = () => {
        uploadRequestApi(uploadToken.requestId, uploadToken.token).then((data) => {
            if (data.status === 401) {
                return navigate("/");
            }
            else{
                if (data.body.associatedUserIds) {
                    setAssociatedUserList(data.body.associatedUserIds);
                }
                setItemList(data.body.items);
                setSaveUploadItem(data.body.items);
                setCompleteTag(data.body.isAccepted);
            }
        })
    }

    useEffect(() => {
        onLoadItem();
    }, [])


    useEffect(() => {
        uploadRequestApi(uploadToken.requestId, uploadToken.token).then((data) => {
            if (data.status === 401) {
                return navigate("/");
            }
            else{
                if (data.body.items) {
                    itemUploadFunction(data.body.items[0]);
                }
            }
        })
    }, [itemUpdate])


    // extra
    function filterArrayByItemId(newList: ItemUploadInterface[], itemId: string) {
        const filteredData = newList.filter(obj => obj.itemId === itemId);
        setNewListUpdate(filteredData)
    }

    function filterUploadItem(newList: UploadFileInterface["body"]["items"], itemId: string) {
        const filteredData = newList.filter(obj => obj.itemId === itemId);
        setShowUPloadFile(filteredData)
    }


    const itemUploadFunction = (listItem: any) => {
        setTableList(listItem.attachments);
        filterArrayByItemId(uploadItem, listItem.itemId)
        filterUploadItem(saveUploadItem, listItem.itemId)
        setActiveShow([...activeShow, listItem.itemId])
        setAttachId(listItem.itemId)
        setItemUploadId(listItem.attachments.itemId)
        setItemUploadName(listItem.attachments.fileName)
        setItemDescription(listItem.itemDescription)
        setFileName(listItem.itemName)
    }

    // download attachment file
    const downloadAttachment = (attachmentId: string) => {
        setSpinLoading(true)
        attachmentDownloadUrlApi(attachmentId, uploadToken.token).then((data) => {
            if (data.statusCode !== 200) {
                console.log('error', data);
            }
            else {
                window.location.href = data.body.downloadUrl;
                setSpinLoading(false)
            }
        })

    }

    function bytesToMB(size: string) {
        const bytes = parseInt(size) / (1024 * 1024);
        if (bytes < 0.50) {
            return (bytes * 1024).toFixed(2) + " KB";
        }
        else {
            return bytes.toFixed(2) + " MB";
        }
    }


    function findItemIdsWithFiles(saveUploadItem: UploadFileInterface["body"]["items"]) {
        const itemIds: string[] = [];
        saveUploadItem.forEach((item) => {
            if (item.files.length > 0) {
                itemIds.push(item.itemId);
            }
        });
        return itemIds;
    }

    const uploadSet = findItemIdsWithFiles(saveUploadItem)
    function findItemIdWithIsRequiredTrue(items: UploadFileInterface["body"]["items"]) {
        const requiredItems = items.filter((item) => item.isRequired === true);
        const itemIds = requiredItems.map((item) => item.itemId);
        return itemIds
    }

    // new concept
    function findMissingValues(itemTrueActive: string[], uploadItemList: string[]) {
        return itemTrueActive.filter(value => !uploadItemList.includes(value));
    }

    const missingValues = findMissingValues(itemTrueActive, uploadItemList);
    const missingValuesShow = findMissingValues(itemTrueActive, uploadSet);

    // new concept
    function newMiss(itemTrueActive: string[], uploadItemList: string[]) {
        return itemTrueActive.filter(value => !uploadItemList.includes(value));
    }
    const newMissValue = newMiss(missingValuesShow, uploadItemList);

    // api call
    const itemUploadApi = () => {
        setModalShow(true);
        if (missingValues.length === 0 || newMissValue.length === 0) {
            setDisableComplete(false);
        }
        else {
            setDisableComplete(true);
        }
    }

    const partialClose = () => {
        setSpinLoading(true)
        for (let i = 0; i < uploadItem.length; i++) {
            const fileObj: any = cloneDeep(uploadItem[i])
            const file = fileObj.file;
            delete fileObj.file;

            if (associatedUserList) {
                fileObj["associatedUserIds"] = associatedUserList;
            }
            fileCreateUrl(fileObj, uploadToken.token).then(async (data) => {
                await handleFileUpload(file, data.body.uploadUrl, 0, (progress: any) => {});
                await filesUploadApi(data.body.fileId, uploadToken.token)
            })
        }
        if (activePartial === "complete") {
            // mark as completed
            markCompletedApi(uploadToken.requestId, uploadToken.token).then((data) => {
            })
            setModalShow(false);
            setTimeout(() => {
                setSpinLoading(false)
                setDownloadComplete(false)
            }, 500)
        }
        else if (activePartial === "partial") {
            setModalShow(false);
            setTimeout(() => {
                setSpinLoading(false)
                setDownloadComplete(false)
            }, 500)
        }
    }

    function countOccurrences(itemId: string) {
        const itemCount = itemList
            .filter((item: any) => item.itemId === itemId)
            .reduce((total: number, item: any) => total + (item.files ? item.files.length : 0), 0);
        const uploadFileCount = uploadItemList.filter((item: any) => item === itemId).length;
        return itemCount + uploadFileCount;
    }

    const mergedArray = [...uploadSet, ...uploadItem.map(item => item.itemId)];
    // active show
    function searchItemId(itemId: string) {
        return mergedArray.some(item => item === itemId);
    }

    return (
        <>

            {spinLoading && <Loader />}
            <section className="dashboardNavbar">
                <Navbar collapseOnSelect expand="lg" fixed='top' className='header'>
                    <NavLink to="/" className='logo navbar-brand'>
                        <img src={logoIcon} alt="" width="210px" />
                    </NavLink>
                </Navbar>
            </section>

            {downloadComplete ? <>
                <section className="downloadSection">
                    <Container>
                        <Row>
                            <Col md={8}>
                                <div className="downloadUpload">
                                    <div className="downloadFile">
                                        <h1>Item Upload: {fileName}</h1>

                                        {itemDescription && itemDescription ?
                                            <div className="imageDescription"><h2>Description/Instructions : {itemDescription}</h2></div>
                                            : ""}

                                        <>
                                            {tableList.length > 0 ? <Table responsive className='tableBody'>

                                                <thead>
                                                    <tr>
                                                        <th>Attachments (1)</th>
                                                        <th></th>
                                                        <th></th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {tableList && tableList.map((data: any, index: number) =>
                                                        <tr key={`tableList` + index}>
                                                            <td>{data.fileName && data.fileName.length > 15 ? data.fileName.substring(0, 13) + '..' : data.fileName}</td>
                                                            <td>{bytesToMB(data.size)}</td>
                                                            <td>
                                                                {data.notes && data.notes.length > 0 ?
                                                                    <SmallButton customWidth="80" customHeight="24" customBackgroundColor='#fff' customBorderColor='#F94A29' customTextColor='#F94A29' className='mr-2' onClick={() => handleShow(data.notes)}>View note</SmallButton> :
                                                                    <></>}
                                                                <SmallButton customWidth="80" customHeight="24" customBackgroundColor='#fff' customBorderColor='#F94A29' customTextColor='#F94A29' onClick={() => downloadAttachment(data.attachmentId)}>Download</SmallButton>
                                                            </td>
                                                        </tr>
                                                    )}
                                                </tbody>
                                            </Table> : <></>}
                                        </>

                                    </div>
                                    <div className={attchId.length > 0 ? "requestDropFile mt-5" : "requestDropFile mt-5 dropdownOff"}>
                                        <DropFile itemUpdate={itemUpdate} setItemUpdate={setItemUpdate} setSpinLoading={setSpinLoading} associatedUserList={associatedUserList} completeTag={completeTag} deleteFilesId={deleteFilesId} setDeleteFilesId={setDeleteFilesId} onLoadItem={onLoadItem} showUPloadFile={showUPloadFile} setShowUPloadFile={setShowUPloadFile} itemList={itemList} setNewListUpdate={setNewListUpdate} newListUpdate={newListUpdate} setUploadItemList={setUploadItemList} uploadItemList={uploadItemList} uploadItem={uploadItem} setUploadItem={setUploadItem} attchId={attchId} itemUploadName={itemUploadName} itemUploadId={itemUploadId} />
                                    </div>
                                </div>
                            </Col>
                            <Col md={4}>
                                <div className="requestItemList">
                                    <div className="checkList">
                                        <h2>Item List</h2>
                                        <Table responsive className='tableBody'>
                                            <thead>
                                                <tr>
                                                    <th>#</th>
                                                    <th>Item Name</th>
                                                    <th></th>
                                                    <th>Count</th>
                                                    <th>Uploaded</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {itemList && itemList.map((listItem, index: number) =>

                                                    <tr key={`itemList` + index} onClick={() => itemUploadFunction(listItem)} className={attchId === listItem.itemId ? 'activeItem' : 'deActiveItem'}>
                                                        <td className='mark'>{index + 1}</td>
                                                        <td className='mark'>{listItem.itemName && listItem.itemName.length > 15 ? listItem.itemName.substring(0, 13) + '..' : listItem.itemName}</td>
                                                        <td className='text-bold'>{listItem.isRequired === true ? "Required" : "Optional"}</td>
                                                        <td className='text-center'>{countOccurrences(listItem && listItem.itemId)}</td>
                                                        <td className='text-center'>
                                                            {searchItemId(listItem.itemId) === true ? <><img src={check} alt="check" /></> : <><img src={unCheck} alt="check" /></>}
                                                        </td>
                                                    </tr>
                                                )}
                                            </tbody>
                                        </Table>

                                    </div>
                                </div>
                            </Col>
                            <div className="downloadButton">
                                <Button customBackgroundColor="#F94A29" customBorderColor="#F94A29" customTextColor="#fff" onClick={itemUploadApi}>Continue</Button>
                            </div>
                        </Row>

                        {/* popup */}
                        <Modal show={modalShow} onHide={permissionModal} size="lg" keyboard={false} centered>
                            <Modal.Header className='modalHead' closeButton>
                                <Modal.Title className='partialHeading'><h1>Upload file to (Customer)</h1></Modal.Title>
                            </Modal.Header>
                            <Modal.Body>
                                <div className="partialModal">
                                    <div className="warningText">
                                        <h1>Note Your documents will not be submitted until you click Submit</h1>
                                    </div>
                                    <div className="submissionBox">
                                        <div onClick={() => setActivePartial("complete")} className={disableComplete === true ? 'disabledComplete submissionRules' : activePartial === "complete" ? "submissionRules activeBox" : "submissionRules"}>
                                            <h1>Complete Submission</h1>
                                            <p>The request will be marked as Complete. You can still re-access the request and add/delete documents later if you need to.</p>
                                        </div>
                                        <div onClick={() => setActivePartial("partial")} className={activePartial === "partial" ? "submissionRules activeBox" : "submissionRules"}>
                                            <h1>Partial Submission</h1>
                                            <p>Your documents will be sent, but the request will be marked as Incomplete. You will be able to re-access and complete the request later.</p>
                                        </div>
                                    </div>
                                </div>
                            </Modal.Body>
                            <Modal.Footer>
                                <Button customBackgroundColor="#F94A29" customBorderColor="#F94A29" customTextColor="#fff" onClick={partialClose}>
                                    Submit file
                                </Button>

                            </Modal.Footer>
                        </Modal>
                        {/* download file */}
                        <Modal
                            show={show}
                            onHide={handleClose}
                            backdrop="static"
                            keyboard={false}
                        >
                            <Modal.Header closeButton>
                                <Modal.Title>View Note</Modal.Title>
                            </Modal.Header>
                            <Modal.Body>
                                <div className="addNote">
                                    <textarea value={viewNotes}></textarea>
                                </div>
                            </Modal.Body>

                        </Modal>
                    </Container>
                </section></>
                : <><section className="downloadSection"><UploadComplete /></section></>
            }
        </>
    )
}

export default Download