const globalState: any = {
    files: [] as { fileUploadLockId: string; file: { name: string; type: string; size: number; base64: string } }[],
    updateFiles: (newFiles: typeof globalState.files) => {
        globalState.files = [...globalState.files, ...newFiles];
    },
    removeFilesByUploadLockId: (fileUploadLockId: string) => {
        globalState.files = globalState.files.filter((file: any) => file.fileUploadLockId !== fileUploadLockId);
    },
    clearState: () => {
        globalState.files = [];
    }
};

export default globalState;