import React, { useEffect, useState } from 'react';
import { Container } from 'react-bootstrap';
import { useSearchParams, useNavigate } from 'react-router-dom';
import { downloadCodeVerifyApi } from '../../api/auth';
import VerificationInput from 'react-verification-input';
import { decodeJwt, getCookieValue, setCookie } from '../CommonInterface/CommonFunction';
import { sendOtpCode } from '../../api/sent';
import { showNotifications } from '../../helpers/toaster';
import { reSendCode } from '../../api/job';
import { ToastContainer } from 'react-toastify';


const DownloadCode = () => {

    const navigate = useNavigate();
    const [codeValue, setCodeValue] = useState("");
    const [codeError, setCodeError] = useState("");
    const [sendId, setSendId] = useState("");
    const [externalData, setExternalData] = useState({});
    // token
    const [searchParams, setSearchParams] = useSearchParams();
    searchParams.get("accessToken")
    const token = searchParams.get("accessToken") || '{}';
    const sentOtpValue = JSON.parse(getCookieValue('sendOtp') || '{}');
    const handleVerificationCodeChange = (code: string) => {
        setCodeValue(code)
    };

    const validateCheck = () => {
        downloadCodeVerifyApi(codeValue, token).then((data) => {
            if (data.statusCode !== 200) {
                setCodeError("Please insert valid code")
            }
            else {
                setCodeError("")
                setCookie('sentId', JSON.stringify({ token, sendId: data.body.sendFileRequest.sendId }));
                return navigate('/DownloadFile')
            }
        })
    }





    useEffect(() => {
        if (!sentOtpValue.sentOtp) {
            sendOtpCode(token).then((data: any) => {
                if (data.statusCode !== 200) {
                    showNotifications('error', data.message);
                }
                else {
                    setCookie('sendOtp', JSON.stringify({ sentOtp: true }));
                }
            })
        }
        const decodedToken = decodeJwt(token);
        setSendId(decodedToken.sendId);
        setExternalData(decodedToken.externalUser);
    }, [])

    // re-send code
    const reSend = () => {
        let reSendInfo = {
            "requestType": "send-file",
            "processableID": sendId,
            "data": externalData
        }
        reSendCode(reSendInfo).then((data: any) => {
            if (data.statusCode !== 200) {
                showNotifications('error', data.message);
            }
            else {
                showNotifications('success', data.message);
            }
        })
    }

    return (
        <>
            <Container className="height-100 d-flex justify-content-center align-items-center">
                <ToastContainer />
                <div className="position-relative">
                    <div className="EmailCard text-center">
                        <h1>Docoteam</h1>
                        {sentOtpValue && sentOtpValue ? <>
                            <h6>Please enter OTP code</h6>
                            <h3>Please check your phone for the code</h3>
                        </>
                            : <><h6>Please enter e-mail code</h6></>}

                        <div id="otp" className="verificationCode inputs d-flex flex-row justify-content-center mt-3">
                            <VerificationInput
                                onChange={handleVerificationCodeChange}
                                length={6}
                                validChars="0-9"
                                inputProps={{ inputMode: "numeric" }}
                                placeholder=""
                                classNames={{
                                    container: "container",
                                    character: "character",
                                    characterInactive: "character--inactive",
                                    characterSelected: "character--selected",
                                }}
                            />
                        </div>
                        {codeError && codeError.length ? <p className='errMsg text-center'>{codeError}</p> : ''}
                        <span className='resend' onClick={reSend}>Resend Code</span>
                        <div className="mt-2"> <button className='validate' onClick={validateCheck}>Validate</button></div> </div>
                </div>
            </Container>
        </>
    )
}

export default DownloadCode