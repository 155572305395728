import React, { useEffect, useState } from 'react';
import "./PricePlan.css";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCheck, faMinus, faPlus } from '@fortawesome/free-solid-svg-icons';
import { PackageInterface } from '../../CommonInterface/CommonInterface';
import { getAllPackage } from '../../../api/package';
import { getCookieValue } from '../../CommonInterface/CommonFunction';

interface priceUserProps {
    totalUser?: any;
}

const PricePlan = ({ totalUser }: priceUserProps) => {
    const [choosePlan, setChoosePlan] = useState<string | null>("");
    const [billingPackage, setBillingPackage] = useState<string | null>("");
    const [packagePrice, setPackagePrice] = useState<PackageInterface[] | undefined>();
    const [isOpen, setIsOpen] = useState(false);

    const toggleCollapsible = () => {
        setIsOpen(!isOpen);
    };
    const featuresArray = [
        "Auto reminders",
        "App integrations",
        "Custom branding",
        "Dedicated Support",
        "Advanced security controls",
        "Employee management",
        "Docovault",
        "Fully custom solutions",
        "Enhanced 24/hr support"
    ];

    useEffect(() => {
        const choosePlanLocal = JSON.parse(getCookieValue('choosePlan') || '{}');
        setChoosePlan(choosePlanLocal.price);
        setBillingPackage(choosePlanLocal.billingType);
        getAllPackage().then((data) => {
            setPackagePrice(data.packages);
        })
    }, []);


    const getPriceByName = (packageName: string, subtractValue: number) => {
        const packageItem = packagePrice?.find(pkg => pkg.name === packageName);
        if (packageItem && typeof packageItem.price === 'number') {
            const result = (packageItem.price / 100) - subtractValue;
            return result.toString();
        }
        return null;
    };

    return (
        <>
            <div className="planBox">
                <h1>Your Current Plan</h1>
                {choosePlan === "FREE" ? <>
                    <h2>Free Plan</h2>
                </> : choosePlan === "PLUS" ? <>
                    <h2>Business Plus</h2>
                </> : <>
                    <h2>Business Standard</h2>
                </>}
                <div className="priceLine">
                    <div className="packagePrice d-flex align-items-baseline">
                        <h1>
                            {choosePlan === "FREE" ? <>
                                <span className="icon">$</span>0
                            </> : choosePlan === "PLUS" ? <>
                                {billingPackage === "Annually" ?
                                    <><span className="previousPrice"><span className="icon">$</span>{getPriceByName('PLUS', 0) ? parseInt(getPriceByName('PLUS', 0)!) * totalUser : parseInt(getPriceByName('PLUS', 0)!)}</span>
                                    <span className="icon">$</span>{getPriceByName('PLUS', 5) ? parseInt(getPriceByName('PLUS', 5)!) * totalUser : parseInt(getPriceByName('PLUS', 5)!)}</>
                                    : <><span className="icon">$</span>{getPriceByName('PLUS', 0) ? parseInt(getPriceByName('PLUS', 0)!) * totalUser : parseInt(getPriceByName('PLUS', 0)!)}</>
                                }
                            </> : <>
                                {billingPackage === "Annually" ?
                                    <><span className="previousPrice"><span className="icon">$</span>{getPriceByName('STANDARD', 0) ? parseInt(getPriceByName('STANDARD', 0)!) * totalUser : parseInt(getPriceByName('STANDARD', 0)!)}</span>
                                    <span className="icon">$</span>{getPriceByName('STANDARD', 5) ? parseInt(getPriceByName('STANDARD', 5)!) * totalUser : parseInt(getPriceByName('STANDARD', 5)!)}</>
                                    : <><span className="icon">$</span>{getPriceByName('STANDARD', 0) ? parseInt(getPriceByName('STANDARD', 0)!) * totalUser : parseInt(getPriceByName('STANDARD', 0)!)}</>
                                }
                            </>}
                            <span className="currency">USD</span></h1>
                        <p className='ml-2'>/user/month billed {billingPackage}</p>
                    </div>
                </div>
                <div className="featureLine">
                    <h1><FontAwesomeIcon icon={faCheck} /> Unlimited* secure file transfers</h1>
                    <h1><FontAwesomeIcon icon={faCheck} /> Data Rooms</h1>
                    <h1><FontAwesomeIcon icon={faCheck} /> Custom Templates</h1>
                    <h1><FontAwesomeIcon icon={faCheck} /> 2-Factor Authentication</h1>

                    {choosePlan === "FREE" ? <></> :
                        choosePlan === "PLUS" ? <>
                            <div className={`collapsible-content ${isOpen ? 'open' : 'closed'}`}>
                                {featuresArray.map((item, index) => (
                                    <h1 key={`feature` + index}><FontAwesomeIcon icon={faCheck} /> {item}</h1>
                                ))}
                            </div>
                            <h1 onClick={toggleCollapsible} style={{ cursor: "pointer" }}>
                                {isOpen ? <><FontAwesomeIcon icon={faMinus} /> Less more features</> :
                                    <><FontAwesomeIcon icon={faPlus} /> Show more features</>}
                            </h1>
                        </> : <>
                            <div className={`collapsible-content ${isOpen ? 'open' : 'closed'}`}>
                                {featuresArray.slice(0, 5).map((item, index) => (
                                    <h1 key={`feature` + index}><FontAwesomeIcon icon={faCheck} /> {item}</h1>
                                ))}
                            </div>
                            <h1 onClick={toggleCollapsible} style={{ cursor: "pointer" }}>
                                {isOpen ? <><FontAwesomeIcon icon={faMinus} /> Less more features</> :
                                    <><FontAwesomeIcon icon={faPlus} /> Show more features</>}
                            </h1>
                        </>}
                </div>
                {choosePlan === "FREE" ? <></> : <>
                    <div className="redLine"></div>
                    <div className="priceTableList">
                        <div className="leftPriceContent">
                            {choosePlan === "PLUS" ? <>
                                <h2>Business Plus</h2>
                            </> : <>
                                <h2>Business Standard</h2>
                            </>}

                            {choosePlan === "PLUS" ? <>
                                {billingPackage === "Annually" ? <p>{getPriceByName('PLUS', 0)}$ * 12 months</p>
                                    : <p>{getPriceByName('PLUS', 0)}$ monthly</p>}

                            </> : <>
                                {billingPackage === "Annually" ? <p>{getPriceByName('STANDARD', 0)}$ * 12 months</p>
                                    : <p>{getPriceByName('STANDARD', 0)}$ monthly</p>}

                            </>}
                        </div>
                        <div className="rightPriceText">
                            {choosePlan === "PLUS" ? <>
                                {billingPackage === "Annually" ? <h1>${getPriceByName('PLUS', 0) !== null ? parseInt(getPriceByName('PLUS', 0)!) * 12 : ''}</h1>
                                    : <h1>${getPriceByName('PLUS', 0) !== null ? parseInt(getPriceByName('PLUS', 0)!) * 1 : ''}</h1>}

                            </> : <>
                                {billingPackage === "Annually" ? <h1>${getPriceByName('STANDARD', 0) !== null ? parseInt(getPriceByName('STANDARD', 0)!) * 12 : ''}</h1>
                                    : <h1>${getPriceByName('STANDARD', 0) !== null ? parseInt(getPriceByName('STANDARD', 0)!) * 1 : ''}</h1>}
                            </>}
                        </div>
                    </div>
                    <div className="priceTableList">
                        {billingPackage === "Annually" ? <>
                            <div className="leftPriceContent">
                                <h1>Discount</h1>
                                <p>Annual Promotion</p>
                            </div>
                            <div className="rightPriceText">
                                <h2>-$60</h2>
                            </div>
                        </> : ""}
                    </div>
                    <div className="redLine"></div>
                    <div className="priceTableList">
                        <div className="leftPriceContent">
                            <h2>You’ve to pay</h2>
                            <p className='redText'>Annual plans funtion the same as monthly plans <br />
                                with a 25% discount applied.</p>
                        </div>
                        <div className="rightPriceText">
                            {choosePlan === "PLUS" ? <>
                                {billingPackage === "Annually" ? <h1>${(parseInt(getPriceByName('PLUS', 5)!) * totalUser) !== null ? parseInt(getPriceByName('PLUS', 5)!) * 12 * totalUser : ''}</h1>
                                    : <h1>${(parseInt(getPriceByName('PLUS', 0)!) * totalUser) !== null ? parseInt(getPriceByName('PLUS', 0)!) * 1 * totalUser : ''}</h1>}

                            </> : <>
                                {billingPackage === "Annually" ? <h1>${(parseInt(getPriceByName('STANDARD', 5)!) * totalUser )!== null ? parseInt(getPriceByName('STANDARD', 5)!) * 12 * totalUser : ''}</h1>
                                    : <h1>${(parseInt(getPriceByName('STANDARD', 0)!) * totalUser) !== null ? parseInt(getPriceByName('STANDARD', 0)!) * 1 * totalUser : ''}</h1>}
                            </>}
                        </div>
                    </div>
                </>}

            </div>
        </>
    )
}

export default PricePlan