import React, { ReactNode ,useEffect} from "react";
import Header from "../Header/Header";
import Footer from "../Footer/Footer";

interface Props {
    children?: ReactNode
}
const Layout = ({ children, ...props }: Props) => {
    useEffect(() => {
        window.scrollTo(0, 0);
      }, []);
    return (
        <>
           <Header/>
            <div {...props}>{children}</div>
            <Footer/>
        </>
    )
}

export default Layout