import React, { useEffect } from 'react'
import Banner from './Banner'
import CompanyDescription from './CompanyDescription'
import Price from './Price'
import StreamLine from './StreamLine'
import StreamPower from './StreamPower'
import './Home.css';
import Layout from '../CommonCompoment/Layout/Layout'
import { getCookieValue, removeCookie } from '../CommonInterface/CommonFunction'
import { showNotifications } from '../../helpers/toaster'
import { ToastContainer } from 'react-toastify'

const Home = () => {
  const deleteCookie = getCookieValue('deletePlan')
  useEffect(() => {
    if (deleteCookie) {
      const timeoutId = setTimeout(() => {
        removeCookie('deletePlan');
      }, 1000);
      showNotifications('success', "Your account deleted successful")
      return () => clearTimeout(timeoutId);
    }
  }, []);
  return (
    <>
      <Layout>
        <ToastContainer />
        <div className='homePage'>
          <Banner />
          <StreamPower />
          <StreamLine />
          <Price />
          <CompanyDescription />
        </div>
      </Layout>
    </>
  )
}

export default Home