import React, { useState } from 'react';
import "./Pagination.css";
import leftDouble from '../../../assets/icon/leftDouble.png';
import leftSingle from '../../../assets/icon/leftSingle.png';
import rightArrow from '../../../assets/icon/rightArrow.png';
import rightSingle from '../../../assets/icon/rightSingle.png';
import { Dropdown } from 'react-bootstrap';
import { multiplyBySixAndShowSeries } from '../../CommonInterface/CommonFunction';
interface PaginationProps {
    prevPage: (pageNumber: number) => void;
    nextPage: (pageNumber: number) => void;
    pageResult: any;
    prevButton: boolean;
    nextButton: boolean;
    doublePrevButton?: boolean;
    doubleNextButton?: boolean;
    resultLength: number;
    pageValue: any;
    totalValue: any;
    showResult: (value: number) => void;
}
const Pagination = ({ doubleNextButton,doublePrevButton,showResult, pageResult, prevPage, nextPage, prevButton, nextButton, pageValue, totalValue, resultLength }: PaginationProps) => {
    const [selectedValue, setSelectedValue] = useState<number | null>(null);
    const limitDivided = multiplyBySixAndShowSeries(totalValue)
    const handleSelect = (selectedValue: any) => {
        const integerValue = parseInt(selectedValue);
        showResult(integerValue);
        setSelectedValue(selectedValue);
    };
    return (
        <>
            <div className="paginationList">
                <nav aria-label="Page navigation example" className='bottomPagination'>
                    <ul className="pagination">
                        <li className="page-item">
                            <button onClick={() => prevPage(2)} className={doublePrevButton === true ? "page-link" : "page-link disablePag"} aria-label="Previous">
                                <span aria-hidden="true"><img src={leftDouble} alt="" /></span>
                            </button>
                        </li>
                        <li className="page-item"><button onClick={() => prevPage(1)} className={prevButton === true ? "page-link" : "page-link disablePag"}><img src={leftSingle} alt="" /></button></li>
                        <li className="page-item"><button className="paginationNumber">{pageValue}</button></li>
                        <li className="page-item"><button onClick={() => nextPage(1)} className={nextButton === true ? "page-link" : "page-link disablePag"}><img src={rightSingle} alt="" /></button></li>
                        <li className="page-item">
                            <button onClick={() => nextPage(2)} className={doubleNextButton === true ? "page-link" : "page-link disablePag"} aria-label="Next">
                                <span aria-hidden="true"> <img src={rightArrow} alt="" /></span>
                            </button>
                        </li>
                    </ul>
                </nav>
                <div className="tablePagination">
                    <div className="show-entries">
                        <div className="hint-text"> {resultLength} result showing out of</div>
                        <Dropdown onSelect={handleSelect} className="dropdownNumber">
                            <Dropdown.Toggle>
                                {selectedValue !== null ? selectedValue : (limitDivided.length > 0 && limitDivided[limitDivided.length - 1])}
                            </Dropdown.Toggle>
                            <Dropdown.Menu>
                                {limitDivided &&
                                    limitDivided.map((data, index) => (
                                        <Dropdown.Item key={`limitNumber` + index} eventKey={data}>
                                            {data}
                                        </Dropdown.Item>
                                    ))}
                            </Dropdown.Menu>
                        </Dropdown>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Pagination