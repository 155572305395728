import React from 'react';
import { Container, Row } from 'react-bootstrap';
import "./Congratulation.css";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleCheck } from '@fortawesome/free-solid-svg-icons';
import { isAuthenticate } from '../../api/auth';
import { useSearchParams, useNavigate } from 'react-router-dom';
import { emailVerifyApi } from '../../api/user';
import { showNotifications } from '../../helpers/toaster';
const Congratulation = () => {
    const navigate = useNavigate();
    const { user } = isAuthenticate();
    // token
    const [searchParams, setSearchParams] = useSearchParams();
    searchParams.get("token")
    const token = searchParams.get("token") || '{}';

    const registrationPayment = () => {
        emailVerifyApi(token).then((data) => {
            if (data.statusCode !== 200) {
                showNotifications("error", data.message);
            }
            else {
                showNotifications("success", data.message);
                if (data.package === "FREE") {
                    return navigate(`/login`);
                }
                else {
                    return navigate(`/registration-payment`);
                }
            }
        })
    }
    setTimeout(registrationPayment, 5000);
    return (
        <>
            <section className="congratulationPage">
                <Container>
                    <Row className='d-flex align-items-center justify-content-center'>
                        <div className="accountReady">
                            <FontAwesomeIcon icon={faCircleCheck} />
                            <h1>Congratulations</h1>
                            <p>Your account is ready for use, please continue the process</p>
                            <button onClick={registrationPayment}>Continue</button>
                        </div>
                    </Row>
                </Container>
            </section>
        </>
    )
}

export default Congratulation