import React, { useEffect } from 'react';
import Layout from '../CommonCompoment/Layout/Layout';
import WhyBanner from './WhyBanner';
import "./WhyDocoteam.css";
import { Col, Container, Row, Accordion } from 'react-bootstrap';
import image93 from '../../assets/content/image93.png';
import image94 from '../../assets/content/image94.png';
import image95 from '../../assets/content/image95.png';
import image96 from '../../assets/content/image96.png';
import ContentImage from '../CommonCompoment/ContentImage/ContentImage';
import ImageContent from '../CommonCompoment/ImageContent/ImageContent';
import { useLocation } from 'react-router-dom';

const WhyDocoteam = () => {
  const contentDataOne = {
    title: (
      <div dangerouslySetInnerHTML={{ __html: `If it's attached,<br/> it may be at risk` }} />
    ),
    description: "Sending attachments via email is  subject to security deficiencies and many potential threats. Email communication is susceptible to interception during transit, email servers may have vulnerabilities that can be exploited by hackers to gain unauthorized access to the attachments. Additionally, email accounts can be compromised through techniques such as phishing, allowing attackers to gain control over the account and access attachments. Furthermore, emails can be forwarded or mistakenly sent to unintended recipients, increasing the risk of sensitive attachments falling into the wrong hands. Lastly, malware can be disguised as attachments, and opening such files can lead to the infection of the recipient's computer or network. Docoteam solves these methods by providing the transmission without the compromise."
  };
  const contentDataTwo = {
    title: "",
    description: "You can't not use Docoteam in the data world we live in today. A real streamlined solution has revolutionized our operations, particularly for investor relations and document exchanges. We now effortlessly send and request crucial documents, ensuring efficient communication and enhanced collaboration with our stakeholders. This game-changing feature has significantly improved our workflow, saving us valuable time and effort."
  };
  const contentDataThree = {
    title: "Data Rooms",
    description: "Docoteam isn’t just a tool for sending files and attachments. It’s a place where live, sleep, and flow between all your clients, tools, customers and partners—wherever and whenever you’re working."
  };
  const contentDataFour = {
    title: "What Docoteam offers vs. email",
    description: "Docoteam isn’t just a tool for sending files and attachments. It’s a place where your documents live, sleep, and flow between all your clients, tools, customers and partners—wherever and whenever you’re working."
  };
  const location = useLocation();
  useEffect(() => {
    if (location) {
      const element = document.getElementById(location.hash);

      if (element) {
        element.scrollIntoView({ behavior: 'smooth' });
      }
    }
  }, [location]);

  return (
    <Layout>
      <div id="#docoteam-vs-email">
        <WhyBanner />
      </div>
      <ContentImage styleClass={`contentWhyImg`} image={image93} content={contentDataOne} />
      <ImageContent display={`block`} styleClass={`imageWhyContent`} image={image96} content={contentDataTwo} />
      <div id="#data-rooms">
        <ImageContent display={`none`} styleClass={`imageWhyContent`} image={image95} content={contentDataThree} />
      </div>
      <ContentImage styleClass={`contentWhyImg`} image={image94} content={contentDataFour} />

      <section className="emailToDocoteam">
        <Container>
          <Row>
            <Col md={12} id="#how-to-transition">
              <div className='emailHeading'>
                <h1>How to switch from email to Docoteam</h1>
              </div>
            </Col>
            <Col md={4}>
              <div className='emailBox'>
                <div className='emailNumber'>1</div>
                <h1>Sign up</h1>
                <p>Create a new Docoteam account. You can try it for free. If you need assistance, contact support.</p>
              </div>
            </Col>
            <Col md={4}>
              <div className='emailBox'>
                <div className='emailNumber'>2</div>
                <h1>Onboard your team</h1>
                <p>At Docoteam, we believe in the power of synergy because we're better together (honestly, going solo can be a bit lackluster). Inviting your team is a piece of cake, enabling effortless teamwork that amplifies our effectiveness.</p>
              </div>
            </Col>
            <Col md={4}>
              <div className='emailBox'>
                <div className='emailNumber'>3</div>
                <h1>Continue STREAMLINED</h1>
                <p>Elevate your communication with Docoteam by streamlining and organizing document sharing and requesting. Take your performance to new heights and your team to new places.</p>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
      <section className="faqSection">
        <Container>
          <Row>
            <Col md={12}>
              <div className='faqHeading'>
                <h1>Frequently asked questions</h1>
              </div>
            </Col>
            <Col md={12}>
              <div className='faqBox'>
                <Accordion>
                  <Accordion.Item eventKey="0">
                    <Accordion.Header>How does Docoteam improve business communication?</Accordion.Header>
                    <Accordion.Body>
                      Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                      eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad
                      minim veniam, quis nostrud exercitation ullamco laboris nisi ut
                      aliquip ex ea commodo consequat. Duis aute irure dolor in
                      reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla
                      pariatur. Excepteur sint occaecat cupidatat non proident, sunt in
                      culpa qui officia deserunt mollit anim id est laborum.
                    </Accordion.Body>
                  </Accordion.Item>
                  <Accordion.Item eventKey="1">
                    <Accordion.Header>Are Docoteam messages secure?</Accordion.Header>
                    <Accordion.Body>
                      Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                      eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad
                      minim veniam, quis nostrud exercitation ullamco laboris nisi ut
                      aliquip ex ea commodo consequat. Duis aute irure dolor in
                      reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla
                      pariatur. Excepteur sint occaecat cupidatat non proident, sunt in
                      culpa qui officia deserunt mollit anim id est laborum.
                    </Accordion.Body>
                  </Accordion.Item>
                  <Accordion.Item eventKey="2">
                    <Accordion.Header>Does Docoteam allow group messages?</Accordion.Header>
                    <Accordion.Body>
                      Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                      eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad
                      minim veniam, quis nostrud exercitation ullamco laboris nisi ut
                      aliquip ex ea commodo consequat. Duis aute irure dolor in
                      reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla
                      pariatur. Excepteur sint occaecat cupidatat non proident, sunt in
                      culpa qui officia deserunt mollit anim id est laborum.
                    </Accordion.Body>
                  </Accordion.Item>
                  <Accordion.Item eventKey="3">
                    <Accordion.Header>Can you forward emails to Docoteam?</Accordion.Header>
                    <Accordion.Body>
                      Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                      eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad
                      minim veniam, quis nostrud exercitation ullamco laboris nisi ut
                      aliquip ex ea commodo consequat. Duis aute irure dolor in
                      reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla
                      pariatur. Excepteur sint occaecat cupidatat non proident, sunt in
                      culpa qui officia deserunt mollit anim id est laborum.
                    </Accordion.Body>
                  </Accordion.Item>
                </Accordion>
              </div>
            </Col>
          </Row>
        </Container>
      </section>

    </Layout>
  )
}

export default WhyDocoteam