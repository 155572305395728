import React, { useState, useEffect } from 'react';
import { Modal, Table } from 'react-bootstrap';
import { addGroup } from '../../api/group';
import { showNotifications } from '../../helpers/toaster';
import { ToastContainer } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import { faSearch } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { myContacts } from '../../api/contact';
import { MyContactInterface } from '../CommonInterface/CommonInterface';
import { countCheckedItems, handleForbiddenError, removeCookie, shortEmail } from '../CommonInterface/CommonFunction';

interface AddGroupProps {
    handleGroupClose: () => void;
    groupShow: boolean;
    setGroupShow: any;
    count: number;
    setCount: any;
    userIds?: string[];
}
const CreateGroup = ({ count, setCount, groupShow, setGroupShow, handleGroupClose }: AddGroupProps) => {
    const navigate = useNavigate();
    const [groupName, setGroupName] = useState("");
    const [searchTerm, setSearchTerm] = useState("");
    const [searchString, setSearchString] = useState("");
    const [contacts, setContacts] = useState<MyContactInterface[]>([]);
    const [userIds, setUserIds] = useState<string[]>([]);
    const [selectAll, setSelectAll] = useState(false);
    const [selectedRows, setSelectedRows] = useState<any>([]);
    const addGroupName = () => {
        const group = userIds.length
            ? { groupName: groupName, userIds: userIds }
            : { groupName: groupName };
        addGroup(group).then((data) => {
            if (data.statusCode !== 200) {
                if (data.statusCode === 403) {
                    handleForbiddenError(navigate);
                }
                else {
                    showNotifications('error', data.message)
                }
            }
            else {
                showNotifications('success', data.message)
                setGroupName("");
                setGroupShow(false);
                setCount(count + 1);
            }
        })
    }

    useEffect(() => {
        myContacts(6, 1, "", searchString).then(data => {
            if (data.statusCode !== 200) {
                if (data.statusCode === 403) {
                    handleForbiddenError(navigate);
                }
                else {
                    setContacts([])
                }
            }
            else {
                const updatedResponseTable = data.body.contacts.map((r: MyContactInterface) => ({ ...r, isChecked: false, }));
                setContacts(updatedResponseTable)
            }
        })
    }, [searchString, count]);

    //    contact searching
    const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setSearchTerm(event.target.value);
        setSearchString(event.target.value);
    };

    const handleRowSelection = (contact: any, contactId: string) => {
        //    create group user id list
        setUserIds((prevGroupIds) => {
            const index = prevGroupIds.indexOf(contactId);
            if (index !== -1) {
                return [...prevGroupIds.slice(0, index), ...prevGroupIds.slice(index + 1)];
            } else {
                return [...prevGroupIds, contactId];
            }
        });

        // checkbox active inactive show
        let tempObj = contacts;
        for (let i = 0; i < tempObj.length; i++) {
            if (tempObj[i].contactId === contactId) {
                tempObj[i].isChecked = !tempObj[i].isChecked
                break;
            }
        }
        setContacts(tempObj);
        if (selectedRows.includes(contact)) {
            setSelectedRows(selectedRows.filter((id: any) => id !== contact));
        } else {
            setSelectedRows([...selectedRows, contact]);
        }
    };

    const handleAllRowSelection = () => {
        let tempObj = contacts;
        let tempArr: any = [];
        if (countCheckedItems(contacts) === contacts.length) {
            for (let i = 0; i < tempObj.length; i++) {
                tempObj[i].isChecked = false;
                tempArr.push(tempObj[i].contactId);
                //    create group user id list
                setUserIds([]);
            }
        }
        else {
            for (let i = 0; i < tempObj.length; i++) {
                tempObj[i].isChecked = true;
                tempArr.push(tempObj[i].contactId);
                //    create group user id list
                setUserIds((prevGroupIds) => {
                    // Check if contactId already exists in the array
                    if (!prevGroupIds.includes(tempObj[i].contactId)) {
                        return [...prevGroupIds, tempObj[i].contactId];
                    } else {
                        return prevGroupIds;
                    }
                });
            }
        }
        if (countCheckedItems(contacts) === contacts.length) {
            setSelectAll(true)
        }
        else {
            setSelectAll(false)
        }
        setSelectedRows([]);
    };


    const handleKeyPress = (event: KeyboardEvent) => {
        if (event.key === 'Enter') {
            if (groupShow === true) {
            addGroupName()
            }
        }
    };

    useEffect(() => {
        document.body.addEventListener('keypress', handleKeyPress);
        return () => {
            document.body.removeEventListener('keypress', handleKeyPress);
        };
    }, [groupName,userIds]);

    return (
        <>
            <ToastContainer />
            <Modal show={groupShow} onHide={handleGroupClose} centered size="lg">
                <div className="addGroupForm">
                    <h1>Add Group</h1>
                    <div style={{ padding: "0px 30px" }}>
                        <div className="registrationInput">
                            <label>Group Name</label>
                            <input type="text" id="firstName" placeholder='Enter your group name' onChange={(e) => setGroupName(e.target.value)} />
                        </div>
                        <h2>Search contact</h2>
                        <div className="searchName contactSearch">
                            <input type="text" value={searchTerm} onChange={handleInputChange} className="form-control" id="inputValidation" placeholder="Search Contacts & Groups" />
                            <FontAwesomeIcon icon={faSearch} />
                        </div>

                        <div className="groupTable">
                            <Table responsive className='tableBody'>
                                <thead>
                                    <tr>
                                        <th>
                                            <label className='tableCheckBox mobileTable'>
                                                <div className="contactCheck">
                                                    <input type="checkbox" checked={selectAll} onChange={handleAllRowSelection} />
                                                    <span className="checkmark"></span>
                                                </div>
                                            </label>
                                        </th>
                                        <th>First Name</th>
                                        <th>Last Name</th>
                                        <th>Email</th>
                                        <th>Group</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {contacts && contacts.map((contact: any, i: number) => <tr className='leftTable' key={`contact` + i}>
                                        <td>
                                            <label className='tableCheckBox mobileTable'>
                                                <div className="contactCheck">
                                                    <input type="checkbox" onChange={() => handleRowSelection(contact, contact.contactId)} checked={contact.isChecked} />
                                                    <span className="checkmark"></span>
                                                </div>
                                            </label>
                                        </td>
                                        <td>
                                            <p className={contact.firstName && contact.firstName.length > 12 ? 'tooltip-top' : ""} data-tooltip={contact.firstName && contact.firstName.length > 12 ? contact.firstName : ""}>
                                                {contact.firstName && contact.firstName.length > 12 ? contact.firstName.substring(0, 10) + '..' : contact.firstName}
                                            </p>
                                        </td>
                                        <td>
                                            <p className={contact.lastName && contact.lastName.length > 12 ? 'tooltip-top' : ""} data-tooltip={contact.lastName && contact.lastName.length > 12 ? contact.lastName : ""}>
                                                {contact.lastName && contact.lastName.length > 12 ? contact.lastName.substring(0, 10) + '..' : contact.lastName}
                                            </p>
                                        </td>
                                        <td><p className={contact.email && contact.email.length > 15 ? 'tooltip-top' : ""} data-tooltip={contact.email && contact.email.length > 15 ? contact.email : ""}>
                                            {shortEmail(contact.email)}
                                        </p></td>
                                        <td>{contact.group && contact.group ? contact.group.groupName : '-----'}</td>
                                    </tr>)}
                                </tbody>
                            </Table>
                        </div>

                        <div className="addContactBtn">
                            <div className="addContact">
                                <p className='back' onClick={handleGroupClose}>Back</p>
                            </div>
                            <div className="addContact">
                                <button type='submit' className='save' onClick={addGroupName}>Save</button>
                            </div>
                        </div>

                    </div>
                </div>
            </Modal>
        </>
    )
}

export default CreateGroup