import React from 'react';
import styled from 'styled-components';
import "./Step.css"
import { StepListInterface } from '../../CommonInterface/CommonInterface';

const MainContainer = styled.div`
  width: 100%;
  max-width: 600px;
  margin: 0 auto;
  padding: 0 16px;
  @media screen and (max-width: 768px) {
    max-width: 290px !important;
  }
`;

interface StepContainerProps {
  width: string;
}



const StepContainer = styled.div<StepContainerProps>`
  display: flex;
  justify-content: space-between;
  margin-top: 30px;
  margin-bottom: 42px;
  position: relative;
  :before {
    content: '';
    position: absolute;
    width: 100%;
    top: 50%;
    transform: translateY(-50%);
    border: 1px dashed #ADACAC;
    left: 0;
  }
  :after {
    content: '';
    position: absolute;
    border: 1px dashed rgb(249, 75, 41);
    width: ${({ width }) => width};
    top: 50%;
    transition: 0.4s ease;
    transform: translateY(-50%);
    left: 0;
  }
`;

const StepWrapper = styled.div`
  position: relative;
  z-index: 1;
  padding: 0px 10px;
  background: #F8F8F8;
`;

interface StepStyleProps {
  step: 'completed' | 'incomplete';
}

interface StepTextProps {
  textStep: 'completedText' | 'incompleteText';
}

const StepStyle = styled.div<StepStyleProps>`
  width: 15px;
  height: 15px;
  border-radius: 50%;
  background: ${({ step }) => (step === 'completed' ? '#F94B29' : '#ADACAC')};
  transition: 0.4s ease;
`;

const StepLabel = styled.span<StepTextProps>`
  font-size: 19px;
  font-weight: 600;
  color: ${({ textStep }) => (textStep === 'completedText' ? '#F94B29' : '#ADACAC')};
  @media (max-width: 600px) {
    font-size: 16px;
  }
`;


const StepsLabelContainer = styled.div`
position: absolute;
top: -8px;
left: 27px;
white-space: pre;
background: #F8F8F8;
padding: 0px 8px;
border-radius: 25px;
display: flex;
  justify-content: center;
  align-items: center;
`;



interface StepInterface {
  activeStep: any;
  setActiveStep: any;
  steps: StepListInterface[];
}

const Step = ({ activeStep, setActiveStep, steps }: StepInterface) => {
  const totalSteps = steps.length
  const width = `${(100 / (totalSteps - 1)) * (activeStep - 1)}%`

  return (
    <div className="stepBox">
      <h1 className='stepHeading'>Get Started With Docoteam</h1>
      <h2 className='stepDescription'>Setup your self Quickly</h2>
      <MainContainer>
        <StepContainer width={width}>
          {steps.map(({ step, label, textStep }) => (
            <StepWrapper key={step}>
              <StepStyle step={activeStep >= step ? 'completed' : 'incomplete'}></StepStyle>
              <StepsLabelContainer>
                <StepLabel textStep={activeStep >= textStep ? 'completedText' : 'incompleteText'}>{label}</StepLabel>
              </StepsLabelContainer>
            </StepWrapper>
          ))}
        </StepContainer>
      </MainContainer>
    </div>
  )
}

export default Step