import React from 'react';
import { Oval } from 'react-loader-spinner';
const Loader = () => {
  return (
    <div className="spinLoader">
      <Oval
        height={80}
        width={80}
        color="#f94a29"
        wrapperStyle={{}}
        wrapperClass=""
        visible={true}
        ariaLabel='oval-loading'
        secondaryColor="#000"
        strokeWidth={3}
        strokeWidthSecondary={3}
      />
    </div>
  )
}

export default Loader