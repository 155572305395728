import React from 'react';
import { Modal, Row, Col } from 'react-bootstrap'
import moment from 'moment'
import { ItemInterface, RecipientUser } from '../CommonInterface/CommonInterface';

interface ViewRequestProps {
    recipientsValue: RecipientUser[];
    requestTitleValue: any;
    receiptShow: boolean;
    reminderValueName: string;
    customEmailValue: string;
    authValue: boolean;
    dueDate: any;
    itemValue: ItemInterface[];
    fullModalClose: () => void;
    successHandleClose: () => void;
}
const ViewRequest = ({ itemValue, recipientsValue, requestTitleValue, authValue, dueDate, customEmailValue, receiptShow, reminderValueName, fullModalClose, successHandleClose }: ViewRequestProps) => {

    return (
        <>
            <Modal show={receiptShow} className="recipientModalBody" size="xl"
                aria-labelledby="contained-modal-title-vcenter"
                centered>
                <Modal.Body >
                    <div className="requestModal">
                        <Row>
                            <Col md={4} className='mobileBottom'>
                                <div className="requestInput">
                                    <h1>Request</h1>
                                    <label htmlFor="Title">Title</label>
                                    <p className='modalValue titleWidth'>{requestTitleValue}</p>
                                    <div className="itemLine">
                                        <p>Item</p>
                                        <button>{itemValue && itemValue.length > 9 ? `${itemValue.length}` : <p>0{itemValue.length}</p>}</button>
                                    </div>
                                    <div className="itemSerial">
                                        {itemValue && itemValue.map((data, index: number) =>
                                            <div key={`showItem` + index} className='modalItem'>
                                                {data.isRequired === false ?
                                                    <span className='optionalTopToggle'>Optional</span> :
                                                    <span className='requiredTopToggle'>Required</span>}
                                                <p>{index + 1}.&nbsp;{data.itemName}</p>
                                            </div>

                                        )}
                                    </div>
                                </div>
                            </Col>
                            <Col md={4} className='mobileBottom'>
                                <div className='requestInput middleRequest'>
                                    <div className="itemLine recipientsBottom">
                                        <p>Recipents</p>
                                        <button>{recipientsValue && recipientsValue.length > 9 ? `${recipientsValue.length}` : <p>0{recipientsValue.length}</p>}</button>
                                    </div>
                                    <div className="nameEmail">
                                        {recipientsValue && recipientsValue.map((data: any, index: number) =>
                                            <div key={`email` + index} className="listEmail">
                                                <p>{data.fullName}</p>
                                                <span>{data.email}</span> <br />
                                                {data.phoneNumber && data.phoneNumber.length ? <span>{data.phoneNumber}</span> : <></>}
                                            </div>
                                        )}
                                    </div>
                                </div>
                            </Col>
                            <Col md={4} className='mobileBottom'>
                                <div className='requestInput'>
                                    <h1>Settings</h1>
                                    <div className="customMessageRequest">
                                        <label htmlFor="">Custom Email Message</label>
                                        <div className="customMsg">
                                            <p>{customEmailValue}</p>
                                        </div>
                                    </div>
                                    {dueDate && dueDate ? <div className='customMessageRequestList mt-3'>
                                        <label htmlFor="">Due Date (Optional)</label>
                                        <div className='calenderShowBox'>
                                            <p className='modalValue'>{moment(dueDate).format('MM/DD/YYYY')}</p>
                                        </div>
                                    </div> 
                                    : "" }
                                    
                                    <div className='customMessageRequestList mt-3'>
                                        <label htmlFor="">Reminders</label>
                                        <p className='modalValue'>{reminderValueName}</p>
                                        <label htmlFor="" className='mt-3'>2-Factor Authentication</label>
                                        <div className="authShow">
                                            <p className='modalValue'>{authValue === false ? 'OFF' : 'ON'}</p>
                                        </div>
                                    </div>
                                </div>

                            </Col>
                        </Row>
                    </div>
                    <div className="footerModalButton">
                        <button className='backDashboard' onClick={successHandleClose}>Back</button>
                        <button className='nextButton' onClick={fullModalClose}>Confirm & Send</button>
                    </div>
                </Modal.Body>

            </Modal>
        </>
    )
}

export default ViewRequest