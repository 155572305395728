import React, { useRef, useState } from 'react'
import { Modal, Table } from 'react-bootstrap'
import dragAndDrop from '../../assets//dragDrop/dragDrop.png';
import { v4 as uuidv4 } from 'uuid';
import SmallButton from '../../styleComponent/SmallButton/SmallButton';
import { splitNameAndExtension } from '../../helpers/generalUtil';
import { SentFileInterface, SentUPloadInterface } from '../CommonInterface/CommonInterface';
import pdf from '../../assets/icon/pdf 1.png';
import excel from '../../assets/icon/excel 1.png';
import word from '../../assets/icon/word 1.png';
import powerpoint from '../../assets/icon/powerpoint 1.png'
import Button from '../../styleComponent/Button/Button';
import globalState from '../../helpers/globalState';

interface SentFileProps {
    sentFileError: string;
    setSentFileError: any;
    setSentFile: (item: SentFileInterface[]) => void;
    sentFile: SentFileInterface[];
    sentUploadURl: SentUPloadInterface[]
    setSentUploadURl: (uploadId: SentUPloadInterface[]) => void;
}

const UploadFiles = ({ setSentFileError, sentFileError, sentUploadURl, setSentUploadURl, sentFile, setSentFile }: SentFileProps) => {

    const wrapperRef = useRef<HTMLInputElement>(null);
    const [show, setShow] = useState(false);
    const [notesId, setNotesId] = useState("")
    const [addNoteValue, setAddNoteValue] = useState("");
    const [fileObject, setFileObject] = useState<any>(null);
    let uploadId = uuidv4();

    const updateFiles = (newFiles: typeof globalState.files) => {
        globalState.updateFiles(newFiles);
    };

    async function fileToBase64(file: File): Promise<string> {
        return new Promise<string>((resolve, reject) => {
            const reader = new FileReader();
            reader.onload = () => {
                resolve(reader.result?.toString().split(',')[1] || '');
            };
            reader.onerror = reject;
            reader.readAsDataURL(file);
        });
    }
    async function saveFileInLocalStorage(file: File) {
        try {
            const base64String = await fileToBase64(file);
            const fileData = {
                name: file.name,
                type: file.type,
                size: file.size,
                base64: base64String
            };
            const existingFilesJson = localStorage.getItem('sendFile');
            
            let existingFiles: any[] = [];
            if (existingFilesJson) {
                existingFiles = JSON.parse(existingFilesJson);
            }
            const uploadFileObj = {
                "fileUploadLockId": uploadId,
                "file": fileData
            };
            existingFiles.push(uploadFileObj);
            updateFiles(existingFiles);
        } catch (error) {
            console.error('Error saving file:', error);
        }
    }


    const onFileDrop = (e: any) => {
        setSentFileError("");

        const newFile = e.target.files[0];
        if (newFile) {
            saveFileInLocalStorage(newFile);
        }

        const fileNameExtension = splitNameAndExtension(newFile.name)

        let uploadObj = {
            "fileUploadLockId": uploadId,
            "fileName": fileNameExtension.name,
            "extension": fileNameExtension.extension,
            "originalFileName": newFile.name,
            "isRequireSignature": false,
            "notes": "",
            "size": newFile.size
        }
        let uploadFileObj = {
            "fileUploadLockId": uploadId,
            "file": fileObject
        }
        setSentUploadURl([...sentUploadURl, uploadFileObj]);
        setSentFile([...sentFile, uploadObj]);
    }

    // file remove
    const fileRemove = (fileUploadLockId: string) => {
        const filteredItems = sentFile.filter(item => item.fileUploadLockId !== fileUploadLockId);
        setSentFile(filteredItems);
        let localStorageValue = JSON.parse(localStorage.getItem("send") || '{}');
        const { files } = globalState;
        const sendFileObj = files;
        globalState.removeFilesByUploadLockId(fileUploadLockId);
        localStorageValue.files = filteredItems;
        localStorage.setItem('send', JSON.stringify(localStorageValue));
    }
    // notes modal
    const noteShow = (attachmentUploadLockId: string) => {
        setNotesId(attachmentUploadLockId)
        setShow(true);

    };
    // notes value
    const addNoteFunction = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
        setAddNoteValue(event.target.value)
    }
    // notes close and value save
    const notesClose = () => {
        const updatedItems = sentFile.map((item) => {
            if (item.fileUploadLockId === notesId) {
                return { ...item, notes: addNoteValue };
            }
            return item;
        });
        setSentFile(updatedItems);
        setShow(false);
        setAddNoteValue("")
    };

    //   view notes values show and update
    const notesValue = (fileUploadLockId: string) => {
        setNotesId(fileUploadLockId)
        sentFile.map((item) => {
            if (item.fileUploadLockId === fileUploadLockId) {
                setAddNoteValue(item.notes)
            }
        });
        setShow(true);
    };
    return (
        <>
            <div
                ref={wrapperRef}
                className="drop-file-input"
            >
                <div className="drop-file-input__label">
                    <img src={dragAndDrop} alt="" />
                    <p>Drag & Drop your files here</p>
                    <div className="fileIcon">
                        <img src={pdf} alt="" />
                        <img src={powerpoint} alt="" />
                        <img src={word} alt="" />
                        <img src={excel} alt="" />
                    </div>
                </div>
                <input type="file" value="" onChange={onFileDrop} />
            </div>
            <div className="requestFileList sentUpload sentFleUPload">
                <Table responsive className='tableBody text-center'>
                    <thead>
                        <tr>
                            <th></th>
                            <th>File Name</th>
                            <th>Extension</th>
                            <th></th>
                        </tr>
                    </thead>

                    <tbody>
                        {sentFileError && sentFileError ? <>
                            <tr className='text-center'>
                                <td></td>
                                <td>{sentFileError && sentFileError.length ? <p className='errMsg text-center'>{sentFileError}</p> : <></>}</td>
                                <td></td>
                                <td></td>

                            </tr>
                        </> : <></>}
                        {
                            sentFile && sentFile.map((item, index: number) => (
                                <tr key={`contact` + index} className="fileUploadText">
                                    <td>{index + 1}</td>
                                    <td>{item.fileName.length > 15 ? item.fileName.substring(0, 13) + '..' : item.fileName}</td>
                                    <td>{item.extension}</td>
                                    <td className='tableInnerButton rightButton'>
                                        {item.notes.length > 0 ?
                                            <SmallButton customWidth="71" customHeight="26" customBackgroundColor='#F94A29' customBorderColor='#F94A29' customTextColor='#fff' onClick={() => notesValue(item.fileUploadLockId)} className="mr-2">View Note</SmallButton> :
                                            <SmallButton customWidth="71" customHeight="26" customBackgroundColor='#F94A29' customBorderColor='#F94A29' customTextColor='#fff' onClick={() => noteShow(item.fileUploadLockId)} className="mr-2">Add Note</SmallButton>}
                                        <SmallButton customWidth="71" customHeight="26" customBackgroundColor='#fff' customBorderColor='#F94A29' customTextColor='#F94A29' onClick={() => fileRemove(item.fileUploadLockId)}>Remove</SmallButton>
                                    </td>
                                </tr>
                            ))
                        }
                    </tbody>

                </Table>
                {/* notes */}
                <Modal
                    show={show}
                    onHide={notesClose}
                    backdrop="static"
                    keyboard={false}
                >
                    <Modal.Header closeButton>
                        <Modal.Title>Add Note</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <div className="addNote">
                            <h1>Other Information</h1>
                            <textarea value={addNoteValue} onChange={addNoteFunction}></textarea>
                        </div>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button customBackgroundColor="#F94A29" customBorderColor="#F94A29" customTextColor="#fff" onClick={notesClose}>Save</Button>
                    </Modal.Footer>
                </Modal>

            </div>
        </>
    )
}

export default UploadFiles