import React from 'react';
import "./ImageContent.css"
import { Container, Row } from 'react-bootstrap';
interface ImageContentProps {
    styleClass: string;
    image: string;
    content: {
        title: string;
        description: string;
    };
    display: string
}
const ImageContent = ({ image, content, styleClass, display }: ImageContentProps) => {
    return (
        <div className={`${styleClass}`}>
            <Container>
                <Row>
                    <div className='lineBox'>
                        <div className="leftImage">
                            <img src={image} alt='content' />
                        </div>
                        <div className="rightContent">
                            <h1>{content.title}</h1>
                            <p>{content.description}</p>
                        </div>
                    </div>
                </Row>
            </Container>
        </div>
    )
}

export default ImageContent
