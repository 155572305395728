import { faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useState,useEffect } from 'react';
import { Modal } from 'react-bootstrap';
import { passwordCheck, userPassword } from '../../api/user';
import VerificationInput from 'react-verification-input';
import { useNavigate } from 'react-router-dom';
import { decodeJwt, getCookieValue, removeCookie, setCookie } from '../CommonInterface/CommonFunction';
import { LoginSocialGoogle, IResolveParams } from 'reactjs-social-login';
import { GOOGLE_CLIENT_ID } from "../../config";
import { showNotifications } from '../../helpers/toaster';
import { ToastContainer } from 'react-toastify';
import "./Settings.css"
import { reSendCode } from '../../api/job';

interface AccountDeleteProps {
    handleClose: () => void;
    show: boolean;
    setSpinLoading: (value: boolean) => void;
}
const AccountModal = ({ show, handleClose, setSpinLoading }: AccountDeleteProps) => {
    const navigate = useNavigate();
    const [toggleType, setToggleType] = useState("password");
    const [togglePassword, setTogglePassword] = useState(false);
    const [password, setPassword] = useState("");
    const [passwordError, setPasswordError] = useState("");
    const [codeValue, setCodeValue] = useState("");
    const [codeError, setCodeError] = useState("");
    const [userPasswordState, setUserPasswordState] = useState(true);
    const cookiesValue = JSON.parse(getCookieValue('docoteam') || "");
    const [userId, setUserId] = useState("");
    const [externalData, setExternalData] = useState({});
   
    const handleVerificationCodeChange = (code: string) => {
        setCodeValue(code)
    };

    const showPass = () => {
        setTogglePassword(!togglePassword)
        setToggleType("password")
    }

    const hidePass = () => {
        setTogglePassword(!togglePassword)
        setToggleType("")
    }

    // password check
    const deleteAccount = () => {
        let passwordBody = {
            "password": password
        }
        userPassword(passwordBody).then((data) => {
            if (data.match === false) {
                setPasswordError(data.message);
            }
            else {
                setPasswordError("");
                showNotifications("success", data.message);
                setUserPasswordState(false)
            }
        })
    }

    const tryLoginWithGoogleOAuth = (userData: any) => {

        setSpinLoading(true);
        if (userData.email.length === 0) {
            setSpinLoading(true);
        }
        else {
            const googleLoginObj: any = {
                "thirdPartyToken": userData.access_token,
                "authProviderName": "GOOGLE"
            }
            userPassword(googleLoginObj).then((data) => {
                if (data.match === true) {
                    setUserPasswordState(false)
                    showNotifications("success", data.message);
                }
                else {
                    showNotifications("error", "This email is not registered!!");
                }
            })
            setSpinLoading(false);
        }
    };

    // delete account
    const passwordAccount = () => {
        passwordCheck(codeValue).then((data) => {
            if (data.statusCode !== 200) {
                setCodeError(data.message);
            }
            else {
                removeCookie('docoteam');
                setCookie('deletePlan', JSON.stringify({ "delete": "true" }));
                return navigate('/')
            }
        })
    }

    useEffect(() => {
        const decodedToken = decodeJwt(cookiesValue.jwt);
        setUserId(decodedToken.userId);
        setExternalData(decodedToken.externalUser);
      }, [])

    // re-send code
  const reSend = () => {
    let reSendInfo = {
      "requestType": "account-delete",
      "processableID": userId,
      "data": externalData
    }
    reSendCode(reSendInfo).then((data: any) => {
      if (data.statusCode !== 200) {
        showNotifications('error', data.message);
      }
      else {
        showNotifications('success', data.message);
      }
    })
  }


    return (
        <Modal show={show} onHide={handleClose} centered>
            <ToastContainer />
            {userPasswordState ? <>
                <div className="deleteAccount">
                    <h1>Verify your password to delete the account.</h1>
                    <span className='warning'>**Remember, if you delete the account, you won't be able to recover it future.</span>
                    {cookiesValue && cookiesValue.user.authProvider === "GOOGLE" ?
                        <div className='googleBtn mt-3'>
                            <LoginSocialGoogle
                                client_id={GOOGLE_CLIENT_ID || ""}
                                isOnlyGetToken={false}
                                scope="openid profile email"
                                discoveryDocs="claims_supported"
                                onResolve={({ provider, data }: IResolveParams) => {
                                    tryLoginWithGoogleOAuth(data);
                                }}
                                onReject={err => {
                                    console.log(err);
                                }}
                            >
                                <span><img src="https://cdn1.iconfinder.com/data/icons/google-s-logo/150/Google_Icons-09-512.png" width="50px" alt="" /></span>
                            </LoginSocialGoogle>
                        </div> : <>
                            <div className="loginInput">
                                <input type={toggleType} onChange={(e) => setPassword(e.target.value)} id="password" placeholder="Password" />
                                {togglePassword ? <span onClick={() => showPass()}><FontAwesomeIcon icon={faEye} /> </span> : <span onClick={() => hidePass()}><FontAwesomeIcon icon={faEyeSlash} /></span>}
                            </div>
                            {passwordError && passwordError.length ? <p className='errMsg'>{passwordError}</p> : <></>}
                            <div className='deleteAccountBtn'>
                                <button onClick={deleteAccount}>Continue</button>
                            </div>
                        </>}

                </div>
            </> : <>
                <div className="userPasswordCheck text-center">
                    <h1>Please enter the OTP code, and be sure to check your email for further instructions.</h1>
                    <div id="otp" className="verificationCode inputs d-flex flex-row justify-content-center mt-3">
                        <VerificationInput
                            onChange={handleVerificationCodeChange}
                            length={6}
                            validChars="0-9"
                            inputProps={{ inputMode: "numeric" }}
                            placeholder=""
                            classNames={{
                                container: "container",
                                character: "character",
                                characterInactive: "character--inactive",
                                characterSelected: "character--selected",
                            }}
                        />
                    </div>
                    {codeError && codeError.length ? <p className='errMsg text-center'>{codeError}</p> : ''}
                    <span onClick={reSend}>Resend Code</span>
                    <div className='deleteAccountBtn'>
                        <button onClick={passwordAccount}>Continue</button>
                    </div>
                </div>
            </>}

        </Modal>
    )
}

export default AccountModal