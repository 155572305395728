import { faMinus, faSearch } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { useState } from 'react'
import { Modal, Row, Col } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';

interface TemplateProps {
    saveTemplateShow: boolean;
    loadTemplateInfo: any;
    confirmDelete: (value: string) => void;
    saveTemplateModalClose: () => void;
}
const LoadTemplate = ({ saveTemplateShow, loadTemplateInfo, confirmDelete, saveTemplateModalClose }: TemplateProps) => {
    const navigate = useNavigate();
    const [searchTerm, setSearchTerm] = useState("");
    const searchTemplate = loadTemplateInfo.filter((item: any) => {
        const fullName = `${item.templateTitle}`;
        return fullName.toLowerCase().includes(searchTerm.toLowerCase())
    });


    const templateShow = (requestTemplateId: string) => {
        return navigate(`/template-request/${requestTemplateId}`)
    }

    return (
        <div>
            <Modal show={saveTemplateShow} className="loadModal settingModalBody" size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered>
                <Modal.Body>
                    <div className="templateModal">
                        <Row>
                            <Col md={12}>
                                <div className="loadTemplateHeading">
                                    <h1>Manage Templates</h1>
                                </div>
                            </Col>
                            <Col md={12} className="requestInputForm ">
                                <div className="templateName">
                                    {loadTemplateInfo && loadTemplateInfo.length > 0 ?
                                        <div className="searchName dashboardInput mb-2">
                                            <input type="text" value={searchTerm} onChange={(e) => setSearchTerm(e.target.value)} className="form-control" id="inputValidation" placeholder="Search Template" />
                                            <FontAwesomeIcon icon={faSearch} />
                                        </div> : <p>No template here</p>}
                                    {searchTemplate && searchTemplate.map((data: any, index: number) =>
                                        <div className='loadTemplateButton d-flex'>
                                            <button onClick={() => templateShow(data.requestTemplateId)} key={`templateList` + index} type='submit'>
                                                {data.templateTitle}
                                            </button>
                                            <span className='deleteTemp' onClick={() => confirmDelete(data.requestTemplateId)}><FontAwesomeIcon icon={faMinus} /></span>
                                        </div>
                                    )}

                                </div>
                            </Col>
                        </Row>
                    </div>
                    <div className="footerModalButton">
                        <button className='backDashboard' onClick={saveTemplateModalClose}>Close</button>
                    </div>
                </Modal.Body>
            </Modal>
        </div>
    )
}

export default LoadTemplate