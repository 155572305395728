import React,{useEffect} from 'react';
import Layout from '../CommonCompoment/Layout/Layout';
import { Col, Container, Row } from 'react-bootstrap';
import "./Privacy.css";
import { Link } from 'react-router-dom';

const Privacy = () => {
    useEffect(() => {
        window.scrollTo(0, 0);
      }, []);
    return (
        <Layout>
            <section className='privacyPage'>
                <Container>
                    <Row>
                        <Col md={12}>
                            <div className="privacyHeading">
                                <h1>PRIVACY POLICY</h1>
                                <h2>Effective December 12, 2023</h2>
                                <p>Welcome to Docoteam, Inc (“Docoteam,” "company," "we," "us," or "our"). This Privacy Policy outlines our
                                    practices regarding the collection, use, and disclosure of personal information when you use our
                                    services
                                    ("Services"). By accessing or using the Services, you agree to the terms of this Privacy Policy.</p>
                            </div>
                            <div className="privacyContent">
                                <h1>Information We Collect</h1>
                                <p>We may collect information from you through various means, encompassing your visits to our website, the
                                    creation of an account, utilization of our Services, or other interactions with us. The categories of
                                    information we may collect include:</p>
                                <ul>
                                    <li>Personal Information: Such as your name, email address, and additional contact details.</li>
                                    <li>Account Information: Including your username and password.</li>
                                    <li>Service Utilization Information: Details about your use of our Services, such as uploaded documents
                                        and
                                        actions taken within the platform.</li>
                                    <li>Device Information: Such as your IP address, browser type, and operating system.</li>
                                    <li>Location Information: In instances where you enable location services on your device.</li>
                                </ul>
                            </div>

                            <div className="privacyContent">
                                <h1>Information Use</h1>
                                <p>The information collected may be utilized for various purposes, including:</p>
                                <ul>
                                    <li>To provide, maintain, and enhance our Services.</li>
                                    <li>To tailor and personalize your experience, presenting content and information suited to your
                                        preferences.</li>
                                    <li>To communicate essential information about your account or our Services.</li>
                                    <li>To conduct research and analysis, thereby improving our Services.</li>
                                    <li>To enforce our terms of service and prevent fraudulent or illegal activities.</li>
                                </ul>
                            </div>

                            <div className="privacyContent">
                                <h1>Data Retention</h1>
                                <p>We may retain your personal information for as long as necessary to fulfill the purposes outlined in this
                                    Privacy Policy unless a longer retention period is required or permitted by law.</p>
                            </div>

                            <div className="privacyContent">
                                <h1>Information Disclosure</h1>
                                <p>We may disclose collected information to:</p>
                                <p>Third-party Service Providers: Assisting us in the provision and improvement of our Services.
                                    Law Enforcement or Government Agencies: When mandated by law or in response to a valid legal request.
                                </p>
                                <ul>
                                    <li>Other Third Parties: When we believe disclosure is essential to safeguard the rights, property, or
                                        safety of Pigeon Documents or others.</li>
                                </ul>
                            </div>

                            <div className="privacyContent">
                                <h1>Third-Party Services</h1>
                                <p>We may utilize third-party services for analytics, advertising, or other purposes. These third-party
                                    services
                                    may collect and process information in accordance with their own privacy policies.</p>
                            </div>

                            <div className="privacyContent">
                                <h1>Security</h1>
                                <p>We take the security of your information seriously, and while we have measures implemented to keep your
                                    information protected, absolute security cannot be guaranteed due to inherent limitations in electronic
                                    storage and internet transmission.</p>
                            </div>

                            <div className="privacyContent">
                                <h1>Cookies and Other Tracking Technologies</h1>
                                <p>We may utilize cookies and tracking technologies to gather information about your Services usage.
                                    Cookies,
                                    both session and persistent, are employed for this purpose, subject to control through your browser
                                    settings.</p>
                            </div>

                            <div className="privacyContent">
                                <h1>Children's Privacy</h1>
                                <p>Our Services are explicitly designed for use by individuals aged 18 and above. We expressly refrain from
                                    knowingly collecting personal information from individuals under the age of 18. If you are under 18,
                                    please
                                    refrain from using our Services. If, despite our efforts, we inadvertently collect personal information
                                    from
                                    an individual under 18, we will do our best to take measures to delete such information from our
                                    records.
                                </p>
                            </div>

                            <div className="privacyContent">
                                <h1>Changes to this Privacy Policy</h1>
                                <p>We reserve the right to update or modify this Privacy Policy at any time. The latest version will be
                                    indicated by the "Last Updated" date. Notice of any alterations will be conspicuously posted on this
                                    page,
                                    with substantial changes receiving heightened visibility. Continued utilization of our Services
                                    subsequent
                                    to the implementation of any effective changes is construed as acceptance of the revised Privacy Policy.
                                </p>
                            </div>

                            <div className="privacyContent">
                                <h1>Contact Us</h1>
                                <p>For inquiries or concerns regarding this Privacy Policy or our data practices, please contact us at
                                <Link to="mailto:support@docoteam.com"> support@docoteam.com</Link></p>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </section>
        </Layout>
    )
}

export default Privacy