import React, { useEffect, useState } from 'react';
import { Table } from 'react-bootstrap';
import SmallButton from '../../styleComponent/SmallButton/SmallButton';
import moment from 'moment';
import { fileSize, handleForbiddenError, multiplyBySixAndShowSeries, removeCookie } from '../CommonInterface/CommonFunction';
import { FileInformationInterface } from '../CommonInterface/CommonInterface';
import { getVoltMyFiles, restoreData } from '../../api/files';
import { showNotifications } from '../../helpers/toaster';
import { ToastContainer } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
// google font awesome
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronDown, faChevronUp } from '@fortawesome/free-solid-svg-icons';
import Pagination from '../CommonCompoment/Pagination/Pagination';


interface VaultFileProps {
    spinLoading: boolean;
    setSpinLoading: (value: boolean) => void;
}
const Vault = ({spinLoading, setSpinLoading }: VaultFileProps) => {
    const [vaultFilesList, setVaultFilesList] = useState<FileInformationInterface[]>([]);
    const navigate = useNavigate();
    const [searchString, setSearchString] = useState("");
    const [sortBy, setSortBy] = useState("");
    const [sortType, setSortType] = useState("ASC");
    const [tableFileName, setTableFileName] = useState(false);
    const [tableFileExtension, setTableFileExtension] = useState(false);
    const [tableFileSize, setTableFileSize] = useState(false);
    const [tableFileCreate, setTableFileCreate] = useState(false);
    const [fadeAnimation, setFadeAnimation] = useState(false);
    const [totalValue, setTotalValue] = useState<any>();
    const [limitValue, setLimitValue] = useState<any>();
    const [page, setPage] = useState(1);
    const [limit, setLimit] = useState<number>(6);
    const [pageValue, setPageValue] = useState<number>();
    const pageCount = Math.ceil(totalValue / limitValue);

     const [prevButton, setPrevButton] = useState<boolean>(false);
     const [nextButton, setNextButton] = useState<boolean>(false);
     const [doubleNextButton, setDoubleNextButton] = useState<boolean>(false);
     const [doublePrevButton, setDoublePrevButton] = useState<boolean>(false);
     const [resultLength, setResultLength] = useState<number>(0);
     const pageResult = multiplyBySixAndShowSeries(totalValue);


    const restoreFile = (restoreId: string) => {
        setSpinLoading(true);
        restoreData(restoreId).then((data) => {
            if (data.statusCode !== 200) {
                showNotifications('error', data.message);
            }
            else {
                showNotifications('success', "File restore successfully");
                setSpinLoading(false);
            }
        })
       
    }

    useEffect(() => {
        setFadeAnimation(true);

        // docovault 
        getVoltMyFiles(limit, page, searchString, sortBy, sortType).then(data => {
            if (data.statusCode !== 200) {
                if (data.statusCode === 403) {
                    handleForbiddenError(navigate);
                  }
            }
            else {
                setSpinLoading(false);
                setVaultFilesList(data.body.files);
                setTotalValue(data.body.total);
                setLimitValue(data.body.limit);
                setPageValue(data.body.page);
                setResultLength(data.body.files.length);
            }

        });

        if (pageCount > 1) {
            setPrevButton(true)
        }
        if (page === 1) {
            setPrevButton(false)
        }
        // next button
        if (pageCount > 1) {
            setNextButton(true)
        }
        if (pageCount === page) {
            setNextButton(false)
        }
        // double next step
        if (pageCount > 2) {
            setDoubleNextButton(true)
        }
        if (pageCount - 1 === page || pageCount === page) {
            setDoubleNextButton(false)
        }
        // double prev step
        if (pageCount > 2) {
            setDoublePrevButton(true)
        }
        if (page === 1 || page === 2) {
            setDoublePrevButton(false)
        }

    }, [spinLoading, page, totalValue, limitValue, limit, searchString, sortType]);

    const nextPage = (pageNumber: number) => {
        setPage(page + pageNumber)
        setNextButton(true)
    }
    const prevPage = (pageNumber: number) => {
        setPage(page - pageNumber)
    }
    const showResult = (value: number) => {
        setPage(1)
        setLimit(value)
    }

    const sortData = (columnName: string) => {
        if (columnName === "fileName") {
            setTableFileName(!tableFileName)
        }
        if (columnName === "extension") {
            setTableFileExtension(!tableFileExtension)
        }
        if (columnName === "size") {
            setTableFileSize(!tableFileSize)
        }
        if (columnName === "createdAt") {
            setTableFileCreate(!tableFileCreate)
        }
        if (columnName === sortBy) {
            setSortType(sortType === "ASC" ? "DESC" : "ASC");
        } else {
            setSortBy(columnName);
            setSortType("ASC");
        }
    }


    return (
        <>
            <ToastContainer />
            <Table responsive className='tableBody'>
                <thead>
                    <tr>
                        <th>No</th>
                        <th onClick={() => sortData("fileName")}>File Name {tableFileName ? <FontAwesomeIcon icon={faChevronDown} /> : <FontAwesomeIcon icon={faChevronUp} />}</th>
                        <th onClick={() => sortData("extension")}>Extension {tableFileExtension ? <FontAwesomeIcon icon={faChevronDown} /> : <FontAwesomeIcon icon={faChevronUp} />}</th>
                        <th onClick={() => sortData("size")}>File Size {tableFileSize ? <FontAwesomeIcon icon={faChevronDown} /> : <FontAwesomeIcon icon={faChevronUp} />}</th>
                        <th onClick={() => sortData("createdAt")}>Created Date {tableFileCreate ? <FontAwesomeIcon icon={faChevronDown} /> : <FontAwesomeIcon icon={faChevronUp} />}</th>
                        <th>Action</th>
                    </tr>
                </thead>
                <tbody>
                    {vaultFilesList && vaultFilesList.length ?
                        <>{vaultFilesList && vaultFilesList.map((data, i: number) => <tr className='leftTable' key={`contact` + i}>
                            <td>{i + 1}</td>
                            <td>
                                <p className={data.fileName && data.fileName.length > 24 ? 'tooltip-top' : ""} data-tooltip={data.fileName && data.fileName.length > 24 ? data.fileName : ""}>
                                    {data.fileName && data.fileName.length > 24 ? data.fileName.substring(0, 22) + '..' : data.fileName}
                                </p>
                            </td>
                            <td>{data.extension && data.extension}</td>
                            <td>{fileSize(data.size)}</td>
                            <td>{moment(data.permanentDeletionPerformedOn).format('MMM DD YYYY')}</td>
                            <td className='d-flex'>
                                <SmallButton customWidth="62" customHeight="24" customBackgroundColor='#FFFFFF' customBorderColor='#F94A29' customTextColor='#F94A29' onClick={() => restoreFile(data.fileId)}>Restore</SmallButton>
                            </td>
                        </tr>)}</>
                        : <tr>
                            <td className='text-center errMsg' colSpan={6}>There are no files available for display</td>
                        </tr>}
                </tbody>
            </Table>
            <div className="contactPagination">
                                            <div className='contactPagList'>
                                                <div className='w-100 paginationBottom'>
                                                    {vaultFilesList && vaultFilesList.length > 0 ? <>
                                                        <div className='w-100 paginationBottom'>
                                                            <Pagination doublePrevButton={doublePrevButton} doubleNextButton={doubleNextButton} showResult={showResult} pageResult={pageResult} prevButton={prevButton} nextButton={nextButton} pageValue={pageValue} totalValue={totalValue} prevPage={prevPage} nextPage={nextPage} resultLength={resultLength} />
                                                        </div>
                                                    </> : ""}
                                                </div>
                                            </div>
                                        </div>
        </>
    )
}

export default Vault