import React from 'react'
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Login from './Component/Auth/Login/Login';
import Register from './Component/Auth/Register/Register';
import CreateRequest from './Component/CreateRequest/CreateRequest';
import Dashboard from './Component/Dashboard/Dashboard';
import Home from './Component/Home/Home';
import EmailCode from './Component/EmailVerification/EmailCode';
import Download from './Component/EmailVerification/Download';
import PrivateRoute from './api/PrivateRoute';
import MyContact from './Component/MyContact/MyContact';
import Sent from './Component/Sent/Sent';
import DownloadCode from './Component/Sent/DownloadCode';
import DownloadFile from './Component/Sent/DownloadFile';
import Pricing from './Component/Pricing/Pricing';
import Security from './Component/Security/Security';
import Features from './Component/Features/Features';
import WhyDocoteam from './Component/WhyDocoteam/WhyDocoteam';
import ChoosePlan from './Component/ChoosePlan/ChoosePlan';
import AccountRecovery from './Component/AccountRecovery/AccountRecovery';
import Admin from './Component/Admin/Admin';
import AdminSecurity from './Component/AdminSecurity/AdminSecurity';
import MyFiles from './Component/MyFiles/MyFiles';
import ContactUs from './Component/ContactUs/ContactUs';
import Support from './Component/Support/Support';
import ChangeBilling from './Component/ChangeBilling/ChangeBilling';
import TermsOfService from './Component/TermsOfService/TermsOfService';
import Privacy from './Component/Privacy/Privacy';
import Profile from './Component/Profile/Profile';
import BillingHistory from './Component/BillingHistory/BillingHistory';
import PaymentUpdate from './Component/CommonCompoment/UpgradePlan/PaymentUpdate';
import ErrorPage from './Component/ErrorPage/ErrorPage';
import SentContact from './Component/Sent/SentContact';
import Congratulation from './Component/Congratulation/Congratulation';
import AccountVerify from './Component/AccountVerify/AccountVerify';
import RegistrationPayment from './Component/CommonCompoment/RegistrationForm/RegistrationPayment';
import RequestContact from './Component/CreateRequest/RequestContact';




const Routing = () => {

    return (
        <>
            <BrowserRouter>
                <Routes>
                    <Route element={<PrivateRoute />}>
                        <Route path="/dashboard" element={<Dashboard />}></Route>
                        {/* <Route path="/create-request/contact" element={<SentFiles />}></Route> */}

                        {/* email verification component */}
                        <Route path="/my-contact" element={<MyContact />}></Route>
                        <Route path="/my-contact/:id" element={<MyContact />}></Route>
                        <Route path="/admin-security" element={<AdminSecurity />}></Route>
                        <Route path="/admin" element={<Admin />}></Route>
                        <Route path="/files" element={<MyFiles />}></Route>
                        <Route path="/support" element={<Support />}></Route>
                        <Route path="/change-billing" element={<ChangeBilling />}></Route>
                        <Route path="/profile" element={<Profile />}></Route>
                        <Route path="/billing-history" element={<BillingHistory />}></Route>
                        <Route path="/update-payment" element={<PaymentUpdate />}></Route>
                        {/* send files and update file */}
                        <Route path="/sent" element={<Sent />}></Route>
                        <Route path="/sent/:id" element={<Sent />}></Route>
                        <Route path="/send-contacts" element={<SentContact />}></Route>
                        <Route path="/send-contacts/:id" element={<SentContact />}></Route>
                        {/* create request file and update file */}
                        <Route path="/create-request" element={<CreateRequest />}></Route>
                        <Route path="/create-request/:id" element={<CreateRequest />}></Route>
                        <Route path="/request-contacts" element={<RequestContact />}></Route>
                        <Route path="/request-contacts/:id" element={<RequestContact />}></Route>
                        {/* template */}
                        <Route path="/template-request/:template" element={<CreateRequest />}></Route>
                    </Route>
                    {/* account recovery */}
                    <Route path="/account-recovery" element={<AccountRecovery />}></Route>
                    {/* email code check */}
                    <Route path="/send" element={<EmailCode />}></Route>
                    <Route path="/replacementFiles" element={<EmailCode />}></Route>
                    <Route path="/downloadCode" element={<DownloadCode />}></Route>
                    {/* auth */}
                    <Route path="/login" element={<Login />}></Route>
                    <Route path="/registration" element={<Register />}></Route>
                    <Route path="/registration-payment" element={<RegistrationPayment />}></Route>

                    <Route path="/upload" element={<Download />}></Route>
                    <Route path="/downloadFile" element={<DownloadFile />}></Route>

                    {/* home static pages */}
                    <Route path="/" element={<Home />}></Route>
                    <Route path="/pricing" element={<Pricing />}></Route>
                    <Route path="/security" element={<Security />}></Route>
                    <Route path="/features" element={<Features />}></Route>
                    <Route path="/why-docoteam" element={<WhyDocoteam />}></Route>
                    <Route path="/contact" element={<ContactUs />}></Route>
                    <Route path="/terms-of-service" element={<TermsOfService />}></Route>
                    <Route path="/privacy-policy" element={<Privacy />}></Route>
                    {/* payment */}
                    <Route path="/choose-plan" element={<ChoosePlan />}></Route>
                    <Route path="*" element={<ErrorPage />}></Route>

                    <Route path="/users/mail/verify" element={<Congratulation />}></Route>
                    <Route path="/account-verify" element={<AccountVerify />}></Route>
                </Routes>
            </BrowserRouter>
        </>
    )
}

export default Routing