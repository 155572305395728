import React, { useState, useEffect } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons';
import { authenticate, createUser } from '../../../api/auth';
import { showNotifications } from '../../../helpers/toaster';
import { UAParser } from 'ua-parser-js';
import axios from 'axios';
import { getAllPackage } from '../../../api/package';
import { PackageInterface } from '../../CommonInterface/CommonInterface';
import { LoginSocialGoogle, IResolveParams } from 'reactjs-social-login';
import { GOOGLE_CLIENT_ID } from "../../../config";
import { getCookieValue, getPackageIdByName, handleForbiddenError } from '../../CommonInterface/CommonFunction';
import PhoneInput from 'react-phone-input-2';


interface RegistrationInterface {
    activeStep: number;
    setActiveStep: (value: number) => void;
    setSpinLoading: (value: boolean) => void;
    setPaymentOption: (value: boolean) => void;
    setPaymentSkip: (value: boolean) => void;
}
const RegistrationForm = ({ activeStep, setActiveStep, setSpinLoading, setPaymentOption, setPaymentSkip }: RegistrationInterface) => {
    const navigate = useNavigate();
    const [companyError, setCompanyError] = useState("");
    const [organizationName, setOrganizationName] = useState("");
    const [email, setEmail] = useState("");
    const [googleAccess, setGoogleAccess] = useState("");
    const [emailExit, setEmailExit] = useState("");
    const [firstName, setFirstName] = useState("");
    const [firstNameError, setFirstNameError] = useState("");
    const [phoneNumber, setPhoneNumber] = useState("");
    const [phoneNumberError, setPhoneNumberError] = useState("");
    const [lastName, setLastName] = useState("");
    const [lastNameError, setLastNameError] = useState("");
    const [toggleType, setToggleType] = useState("password");
    const [passwordValue, setPasswordValue] = useState("");
    const [passError, setPassError] = useState("");
    const [checkMarkError, setCheckMarkError] = useState("");
    const [passwordValid, setPasswordValid] = useState<string[]>([]);
    const [togglePassword, setTogglePassword] = useState(false);
    const [checkMark, setCheckMark] = useState(false);
    const [recapError, setRecapError] = useState("");
    const [recap, setReCap] = useState<any>(false);
    const [browserInfo, setBrowserInfo] = useState<string>('');
    const [osName, setOsName] = useState<string>('');
    const [locationName, setLocationName] = useState<string[]>([]);
    const [latitude, setLatitude] = useState<number>();
    const [longitude, setLongitude] = useState<number>();
    const [choosePlan, setChoosePlan] = useState<string | null>("");
    const [packageList, setPackageList] = useState<PackageInterface[] | undefined>();
    const [packageId, setPackageId] = useState<number | null>();
 
    const [readOnly, setReadOnly] = useState(false);
    const [passwordHide, setPasswordHide] = useState(false);


    const organizationHandle = (event: React.ChangeEvent<HTMLInputElement>) => {
        setOrganizationName(event.target.value)
        setCompanyError("")
    }
    const emailHandle = (event: React.ChangeEvent<HTMLInputElement>) => {
        setEmail(event.target.value);
        setEmailExit("")
    }
    const handlePhoneChange = (value: string) => {
        setPhoneNumber(value);
        setPhoneNumberError("");
    };
    const firstNameHandle = (event: React.ChangeEvent<HTMLInputElement>) => {
        setFirstName(event.target.value)
        setFirstNameError("")
    }
    const lastNameHandle = (event: React.ChangeEvent<HTMLInputElement>) => {
        setLastName(event.target.value)
        setLastNameError("")
    }
    const passwordHandle = (event: React.ChangeEvent<HTMLInputElement>) => {
        setPasswordValue(event.target.value);
        setPassError("");
        const passwordValid: string[] = [];
        if (event.target.value.length < 8) {
            passwordValid.push("Password must be at least 8 characters");
        }
        if (!/\d/.test(event.target.value)) {
            passwordValid.push("Password must contain at least one number");
        }
        if (!/[a-z]/.test(event.target.value)) {
            passwordValid.push("Password must contain at least one lowercase letter");
        }
        if (!/[A-Z]/.test(event.target.value)) {
            passwordValid.push("Password must contain at least one uppercase letter");
        }
        setPasswordValid(passwordValid);
    }
    const checkMarkFunction = () => {
        setCheckMark(!checkMark)
        setCheckMarkError("")
    }

    const showPass = () => {
        setTogglePassword(!togglePassword)
        setToggleType("password")
    }
    const hidePass = () => {
        setTogglePassword(!togglePassword)
        setToggleType("")
    }
    function onChange(value: any) {
        if (value.length > 0) {
            setReCap(true)
            setRecapError("")
        }
    }

    useEffect(() => {

        // all packages list
        getAllPackage().then((data) => {
            if (data.statusCode === 200) {
                setPackageList(data.packages);
                const planPackage = JSON.parse(getCookieValue('choosePlan') || '{}');
                if (planPackage !== null) {
                    const packageId = getPackageIdByName(data.packages, planPackage.price);
                    setPackageId(packageId);
                }
            }
            else{
                if (data.statusCode === 403) {
                    handleForbiddenError(navigate);
                }
            }
        })

        const userAgent = window.navigator.userAgent;
        const parser = new UAParser(userAgent);
        const result = parser.getResult();
        setBrowserInfo(`${result.browser.name}`);
        if (userAgent.indexOf('Win') !== -1) {
            setOsName('Windows');
        } else if (userAgent.indexOf('Mac') !== -1) {
            setOsName('MacOS');
        } else if (userAgent.indexOf('Linux') !== -1) {
            setOsName('Linux');
        } else if (userAgent.indexOf('Android') !== -1) {
            setOsName('Android');
        } else if (userAgent.indexOf('iOS') !== -1) {
            setOsName('iOS');
        } else {
            setOsName('Unknown'); // Fallback for unrecognized OS
        }

        // location name
        navigator.geolocation.getCurrentPosition((position) => {
            const planPackage = JSON.parse(getCookieValue('choosePlan') || '{}');
            if (planPackage !== null) {
                setChoosePlan(planPackage.price);
            }

            const latitude = position.coords.latitude;
            const longitude = position.coords.longitude;
            setLatitude(latitude);
            setLongitude(longitude);
            const apiKey = 'AIzaSyB1xd6-NIxRPU0UYej-blqi3_eVlccWOQI';
            const apiUrl = `https://maps.googleapis.com/maps/api/geocode/json?latlng=${latitude},${longitude}&key=${apiKey}`;
            axios
                .get(apiUrl)
                .then((response) => {
                    const { results } = response.data;
                    if (results && results.length > 0) {
                        const shortNameArray = [];
                        for (const component of results[0].address_components) {
                            if (
                                component.types.includes("country") ||
                                component.types.includes("administrative_area_level_1") ||
                                component.types.includes("administrative_area_level_2")
                            ) {
                                shortNameArray.push(component.short_name);
                            }
                        }
                        setLocationName(shortNameArray);
                    } else {
                        setLocationName(['Location not found']);
                    }
                })
                .catch((error) => {
                    console.error('Error fetching location data', error);
                });
        });
    }, [])
    //   create account
    const createAccount = () => {
        if (phoneNumber.length === 0) {
            setPhoneNumberError("Please enter your phone number")
        }
        else {
            setPhoneNumberError("")
        }
        if (email.length === 0) {
            setEmailExit("Please enter your e-mail address")
        }
        else {
            setEmailExit("")
        }
        if (passwordValue.length === 0 && passwordHide === false) {
            setPassError("Please enter your password")
        }
        else {
            setPassError("")
        }
        if (firstName.length === 0) {
            setFirstNameError("Please enter your first name")
        }
        else {
            setFirstNameError("")
        }
        if (lastName.length === 0) {
            setLastNameError("Please enter your last name")
        }
        else {
            setLastNameError("")
        }
        if (organizationName.length === 0) {
            setCompanyError("Please enter your organization name")
        }
        else {
            setCompanyError("")
        }
        if (recap === false) {
            setRecapError("Please verify your human")
        }
        else {
            setRecapError("")
        }
        if (checkMark === false) {
            setCheckMarkError("Please agree the terms and condition")
        }
        else {
            setCheckMarkError("")
        }

        const planPackage = JSON.parse(getCookieValue('choosePlan') || '{}');

        const delimiter = ', ';
        const location = locationName.join(delimiter);
        const registerObj: any = {
            "companyName": organizationName,
            "email": email,
            "telephone": phoneNumber,
            "firstName": firstName,
            "lastName": lastName,
            "os": osName,
            "browser": browserInfo,
            "latitude": latitude,
            "longitude": longitude,
            "accessLocation": location,
            "packageId": packageId,
            "billingType": planPackage.billingType,
        }
        if (googleAccess) {
            registerObj["thirdPartyToken"] = googleAccess;
            registerObj["authProviderName"] = "GOOGLE";
        }
        if (passwordValue) {
            registerObj["password"] = passwordValue;
        }
        
        if(passwordHide === false) {
            if (checkMark === true && passwordValue.length > 0 && firstName.length > 0 && lastName.length > 0 && organizationName.length > 0 && email.length > 0) {
                setSpinLoading(true);
                createUser(registerObj).then((data) => {
                    if (data.statusCode !== 200) {
                        setSpinLoading(false);
                        showNotifications("error", data.message);
                    }
                    else {
                        setSpinLoading(false)
                     
                        authenticate(data, () => {
                            if (choosePlan === "FREE") {
                                setActiveStep(activeStep + 2);
                                setPaymentSkip(true);
                                return navigate("/account-verify");
                            }
                            else {
                                setActiveStep(activeStep + 1);
                                setPaymentOption(true);
                                return navigate("/account-verify");
                            }
                        })
                    }
                })
            }
        }
        else{
            if (checkMark === true && firstName.length > 0 && lastName.length > 0 && organizationName.length > 0 && email.length > 0) {
                setSpinLoading(true);
                createUser(registerObj).then((data) => {
                    if (data.statusCode !== 200) {
                        setSpinLoading(false);
                        showNotifications("error", data.message);
                    }
                    else {
                        setSpinLoading(false)
                        authenticate(data, () => {
                            if (choosePlan === "FREE") {
                                setActiveStep(activeStep + 2);
                                setPaymentSkip(true);
                                return navigate("/account-verify");
                            }
                            else {
                                setActiveStep(activeStep + 1);
                                setPaymentOption(true);
                                return navigate("/account-verify");
                            }
                        })
                    }
                })
            }
        }
    }

    function splitFirstNameLastName(inputString: string) {
        var firstTwoWords = inputString.split(' ').slice(0, 2);
        var firstName = firstTwoWords[0];
        var lastName = firstTwoWords[1];
        return {
            firstName: firstName,
            lastName: lastName
        };
    }

    const tryLoginWithGoogleOAuth = (userData: any) => {
        setSpinLoading(true);
        setPasswordHide(true);
        setPasswordValue("");
        setReadOnly(true);
        if (userData.email.length === 0) {
            setSpinLoading(true);
        }
        else {
            setEmail(userData.email);
            setGoogleAccess(userData.access_token);
            const { firstName, lastName } = splitFirstNameLastName(userData.name);
            setFirstName(firstName);
            setLastName(lastName);
            setSpinLoading(false);
        }

    };


    return (
        <div className="registration-form">
            <div className="registrationHeading">
                <h1 className="Auth-form-title">Your Information</h1>
                <div className="registrationInput">
                    <label>Business Name <sup className='asterisk'>*</sup></label>
                    <input
                        type="text"
                        id="text"
                        onChange={organizationHandle}
                        placeholder="Organization (eg Looms Corporation)"
                        value={organizationName}
                    />
                </div>
                {companyError && companyError.length ? <p className='errMsg'>{companyError}</p> : ""}
                <div className="registrationInput">
                    <label>Business Email <sup className='asterisk'>*</sup></label>
                    <input
                        type="email"
                        id="email"
                        onChange={emailHandle}
                        placeholder="Email (jhon@example.com)"
                        value={email}
                        readOnly={readOnly}
                        className={readOnly ? 'readOnlyClass' : ''}
                    />
                </div>
                {emailExit && emailExit.length ? <p className='errMsg'>{emailExit}</p> : ""}

                <div className="contactNumber">
                    <label>Phone Number <sup className='asterisk'>*</sup></label>
                     <PhoneInput country={'us'} disableCountryCode={false} onChange={(value) => handlePhoneChange(value)}  />
                </div>
                {phoneNumberError && phoneNumberError.length ? <p className='errMsg'>{phoneNumberError}</p> : ""}
               
                <div className="registrationInput">
                    <label>First Name <sup className='asterisk'>*</sup></label>
                    <input
                        type="text"
                        id="firstName"
                        onChange={firstNameHandle}
                        placeholder="First Name"
                        value={firstName}
                        readOnly={readOnly}
                        className={readOnly ? 'readOnlyClass' : ''}
                    />
                </div>
                {firstNameError && firstNameError.length ? <p className='errMsg'>{firstNameError}</p> : ""}
                <div className="registrationInput">
                    <label>Last Name <sup className='asterisk'>*</sup></label>
                    <input
                        type="text"
                        id="lastName"
                        onChange={lastNameHandle}
                        placeholder="Last Name"
                        value={lastName}
                        readOnly={readOnly}
                        className={readOnly ? 'readOnlyClass' : ''}
                    />
                </div>
                {lastNameError && lastNameError.length ? <p className='errMsg'>{lastNameError}</p> : ""}
                {passwordHide ? <></> : <>
                    <div className="registrationInput">
                        <label>Password <sup className='asterisk'>*</sup></label>
                        <div className="inputIcon">
                            <input
                                type={toggleType}
                                id="Password"
                                onChange={passwordHandle}
                                placeholder="Password"
                                value={passwordValue}
                            />
                            {togglePassword ? <span onClick={() => showPass()}><FontAwesomeIcon icon={faEye} /> </span> : <span onClick={() => hidePass()}><FontAwesomeIcon icon={faEyeSlash} /></span>}
                        </div>
                    </div>
                    {passwordValid.length > 0 &&
                        passwordValid.map((error, index) => (
                            <p key={index} className='errMsg'>
                                {error}
                            </p>
                        ))}
                    {passError && passError.length ? <p className='errMsg'>{passError}</p> : ""}
                </>}

                <p className='preAgreement'><span className='asterisk'>*</span> Indicates required field</p>
                <label className='agreement'>
                    <label className='tableCheckBox' style={{ marginRight: '22px' }}>
                        <div className="contactCheck">
                            <input type="checkbox" name="agreement" required onChange={checkMarkFunction} />
                            <span className="checkmark"></span>
                        </div>
                    </label>
                    <span>I agree to the <Link to="/terms-of-service" target='_blank'>Terms & Conditions</Link> and <Link to="/privacy-policy" target='_blank'>Privacy Policy</Link> of Docoteam</span>
                </label>
                {checkMarkError && checkMarkError.length ? <p className='errMsg'>{checkMarkError}</p> : ""}

                <div className="registrationBtn">
                    <button onClick={createAccount}>Sign up</button>
                </div>

                <div className='googleBtn mt-3'>
                    <LoginSocialGoogle
                        client_id={GOOGLE_CLIENT_ID || ""}
                        isOnlyGetToken={false}
                        scope="openid profile email"
                        discoveryDocs="claims_supported"
                        onResolve={({ provider, data }: IResolveParams) => {
                            tryLoginWithGoogleOAuth(data);
                        }}
                        onReject={err => {
                            console.log(err);
                        }}
                    >
                        <span><img src="https://cdn1.iconfinder.com/data/icons/google-s-logo/150/Google_Icons-09-512.png" width="50px" alt="" /></span>
                    </LoginSocialGoogle>
                </div>

                <div className="loginLink">
                    <Link to='/login'>Login</Link>
                </div>
            </div>
        </div>
    )
}

export default RegistrationForm