import React from 'react';
import { Modal } from 'react-bootstrap';
import './WarningModal.css';

interface WarningProps {
    handleWarningClose: () => void | undefined;
    warningShow: boolean;
    deleteEmployee: () => void;
    headingText?: string;
    paragraphText?: string | undefined;
}
const WarningModal = ({ headingText, paragraphText, handleWarningClose, warningShow, deleteEmployee }: WarningProps) => {
    return (
        <Modal show={warningShow} onHide={handleWarningClose} centered>
            <div className="deleteBoxModal">
                <h1>{headingText}</h1>
                {paragraphText && paragraphText.length ? <p>{paragraphText}</p>:""}
                <div className="deleteBtn mt-3">
                    <button className='noDelete' onClick={handleWarningClose}>NO</button>
                    <button className='yesDelete' onClick={deleteEmployee}>YES</button>
                </div>
            </div>
        </Modal>
    )
}

export default WarningModal