import React from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { isAuthenticate } from '../../api/auth';
import { Link } from 'react-router-dom';
import apple from '../../assets/banner/apple.png';

const FeatureBanner = () => {
    return (
        <section className="securitySection">
            <Container>
                <Row>
                    <Col md={7} className='d-flex align-items-center'>
                        <div className="securityBannerContent">
                            <h2>FEATURES AT DOCOTEAM</h2>
                            <h1>Streamline your workstream <br/> and file transmission</h1>
                            <p>Secure file requesting and sending with anyone, <br/> and where, at anytime</p>
                            {isAuthenticate() && isAuthenticate() ?
                                <>
                                    <Link to="/dashboard" className='getStarted'>Get Started</Link>
                                </> :
                                <>
                                    <Link to="/dashboard" className='getStarted'>Get Started</Link>
                                </>
                            }
                        </div>
                    </Col>
                    <Col md={5}>
                        <div className="securityBannerImage">
                            <img src={apple} alt="" />
                        </div>
                    </Col>
                </Row>
            </Container>
        </section>
    )
}

export default FeatureBanner