import React, { useState, useEffect } from 'react'
import DashboardNavbar from '../CommonCompoment/DashboardNavbar/DashboardNavbar'
import { Col, Container, Row, Dropdown } from 'react-bootstrap';
import { singleSystemConfig, updateSystemConfig } from '../../api/systemConfig';
import "./AdminSecurity.css";
// google font awesome
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faQuestion } from '@fortawesome/free-solid-svg-icons';
import { Link, useNavigate } from 'react-router-dom';
import { showNotifications } from '../../helpers/toaster';
import { ToastContainer } from 'react-toastify';
import { qrGenerate, singleUser } from '../../api/auth';
import { UserDataInterface } from '../CommonInterface/CommonInterface';
import { getCookieValue, handleForbiddenError, removeCookie } from '../CommonInterface/CommonFunction';
import AccountModal from '../Settings/AccountModal';
import VerificationInput from 'react-verification-input';
import QrModal from './QrModal';
import { updateUser } from '../../api/user';

const AdminSecurity = () => {
  const [fadeAnimation, setFadeAnimation] = useState(false);
  const [configId, setConfigId] = useState("");
  const [sharedContact, setSharedContact] = useState(false);
  const [enforceMFA, setEnforceMFA] = useState(false);
  const [enforce2FA, setEnforce2FA] = useState(false);
  const [enforceStrongPassword, setEnforceStrongPassword] = useState(false);
  const [userInfo, setUserInfo] = useState<UserDataInterface>();
  const navigate = useNavigate();
  const token = JSON.parse(getCookieValue('docoteam') || '{}');
  const { user } = token;
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const [spinLoading, setSpinLoading] = useState(false);
  const handleShow = () => setShow(true);
  const [selectedOption, setSelectedOption] = useState(0);
  const [selectedRestore, setSelectedRestore] = useState(60);
  const [qrImage, setQrImage] = useState("");
  const [qrShow, setQrShow] = useState(false);
  const [QrModalShow, setQrModal] = useState(false);
  const handleQrClose = () => setQrModal(false);
  const [selfNotify, setSelfNotify] = useState(false);
  const [employeeNotify, setEmployeeNotify] = useState(false);
  const [selfUserNotify, setSelfUserNotify] = useState(false);

  const loginAttempt = [
    { "value": 900, "time": "15 Minutes" },
    { "value": 1800, "time": "30 Minutes" },
    { "value": 3600, "time": "1 Hour" },
    { "value": 7200, "time": "2 Hours" },
    { "value": 10800, "time": "3 Hours" },
    { "value": 14400, "time": "4 Hours" },
    { "value": 18000, "time": "5 Hours" }
  ];

  const restoreDays = [
    { "value": 30, "time": "30 Days" },
    { "value": 60, "time": "60 Days" },
    { "value": 90, "time": "90 Days" },
  ];


  const updateSystemCon = (systemConfig: any) => {
    if (enforceMFA === true && enforce2FA === true) {
      showNotifications("error", "You can only enable either 2FA or MFA but not both simultaneously")
    }
    else {
      updateSystemConfig(configId, systemConfig).then((data) => {
        if (data.statusCode !== 200) {
          showNotifications("error", data.message);
        }
        else {
          showNotifications("success", data.message);
        }
      })
    }
  }


  useEffect(() => {
    setFadeAnimation(true);
    singleUser(user.userId).then((data) => {
      if (data.statusCode !== 200) {
        if (data.statusCode === 403) {
          handleForbiddenError(navigate);
        }
      }
      else {
        setUserInfo(data.user);
        if (data.user.selfNotify === true) {
          setSelfUserNotify(true);
        }
      }
    });
    singleSystemConfig().then((data) => {
      if (data.statusCode !== 200) {
        if (data.statusCode === 403) {
          handleForbiddenError(navigate);
        }
        else {
          showNotifications("error", data.message);
        }
      }
      else {
        setConfigId(data.body.configId);
        setSharedContact(data.body.allowSharedContact);
        setEnforceMFA(data.body.loginSettings.enforceMFA);
        setEnforce2FA(data.body.loginSettings.enforce2FA);
        setEnforceStrongPassword(data.body.loginSettings.enforceStrongPassword);
        setSelectedOption(data.body.sessionTime);
        setEmployeeNotify(data.body.orgSelfNotify);
        setSelfNotify(data.body.selfNotify);
        if (data.body.orgSelfNotify === true) {
          setSelfUserNotify(data.body.orgSelfNotify);
        }
       
        if (user && user.currentStoragePlan === "PLUS") {
          setSelectedRestore(data.body.restoreGracePeriodDays);
        }
      }
    })
  }, []);

  // two factor
  const sharedContactClick = () => {
    setSharedContact(!sharedContact);
    let systemConfig: any = {
      "allowSharedContact": !sharedContact,
    }
    updateSystemCon(systemConfig)
  };


  const enforceMFAClick = () => {
    setEnforceMFA(!enforceMFA);
    let systemConfig: any = {
      "loginSettings": {
        "enforceMFA": !enforceMFA,
        "enforce2FA": enforce2FA,
        "enforceStrongPassword": enforceStrongPassword
      },
    }
    updateSystemCon(systemConfig)
    if (enforceMFA === false) {
      setQrModal(true)
      qrCode()
    }

  }

  const enforce2FAClick = () => {
    setEnforce2FA(!enforce2FA);
    let systemConfig: any = {
      "loginSettings": {
        "enforceMFA": enforceMFA,
        "enforce2FA": !enforce2FA,
        "enforceStrongPassword": enforceStrongPassword
      },
    }
    updateSystemCon(systemConfig)
  }

  const enforceStrongPasswordClick = () => {
    setEnforceStrongPassword(!enforceStrongPassword);
    let systemConfig: any = {
      "loginSettings": {
        "enforceMFA": enforceMFA,
        "enforce2FA": enforce2FA,
        "enforceStrongPassword": !enforceStrongPassword
      },
    }
    updateSystemCon(systemConfig)
  }

  const handleLoginSelect = (value: number) => {
    setSelectedOption(value);
    let systemConfig: any = {
      "sessionTime": value,
    }
    updateSystemCon(systemConfig)
  };
  const selectedTime = loginAttempt.find(option => option.value === selectedOption)?.time;

  const handleRestoreDays = (value: number) => {
    setSelectedRestore(value);
    let systemConfig: any = {
      "sessionTime": selectedOption,
    }
    if (user && user.currentStoragePlan === "PLUS") {
      systemConfig.restoreGracePeriodDays = value;
    }
    updateSystemCon(systemConfig)
  };

  const selectedRestoreTime = restoreDays.find(option => option.value === selectedRestore)?.time;


  const qrCode = () => {
    let qrInfo = {
      "email": userInfo?.email
    }
    qrGenerate(qrInfo).then((data: any) => {
      setQrImage(data.qrCodeURL);
      setQrShow(true);
    })
  }


  const regenerateMFAClick = () => {
    setQrModal(true)
    qrCode()
  }



  const selfNotifyClick  = () => {
    setSelfNotify(!selfNotify);
    let systemConfig: any = {
      "selfNotify": !selfNotify,
    }
    updateSystemCon(systemConfig)
  }

  const employeeNotifyClick  = () => {
    setEmployeeNotify(!employeeNotify); 
    let systemConfig: any = {
      "orgSelfNotify": !employeeNotify,
    }
    updateSystemCon(systemConfig)
  }

  const selfUserClick  = () => {
    if (selfUserNotify === false) {
      setSelfUserNotify(!selfUserNotify); 
      let systemConfig: any = {
        "selfNotify": !selfUserNotify,
      }
      if (userInfo) {
        updateUser(userInfo.email, systemConfig).then((data) => {
          if (data.statusCode !== 200) {
            showNotifications("error", data.message);
          }
          else {
            showNotifications('success', data.message)
          }
        });
      }
    }
    else if(userInfo?.selfNotify === true){
      setSelfUserNotify(!selfUserNotify); 
      let systemConfig: any = {
        "selfNotify": !selfUserNotify,
      }
      if (userInfo) {
        updateUser(userInfo.email, systemConfig).then((data) => {
          if (data.statusCode !== 200) {
            showNotifications("error", data.message);
          }
          else {
            showNotifications('success', data.message)
          }
        });
      }
    }
   
  }

  return (
    <>
      <DashboardNavbar />
      <ToastContainer />
      <section className={`fade-in settingPage ${fadeAnimation ? 'visible' : ''}`}>

        <Container fluid>
          {userInfo && userInfo.package ? <>
            <Row>
              <Col md={12}>
                <div className="adminHeading">
                  <h2>{userInfo && userInfo.companyName}</h2>
                  <h2>Security Settings</h2>
                </div>
              </Col>
            </Row></>
            : <></>}

          <Row className='mt-3'>
            <Col md={6}>
              <div className="adminHeading mb-3 d-flex align-items-center">
                <h2>Account Deletion</h2>
                <button onClick={handleShow}>Delete</button>
              </div>
            </Col>
            <Col md={6}>
              <div className="adminHeading mb-5 d-flex align-items-center">
                <h2>Password Change</h2>
                <Link to="/account-recovery">Update</Link>
              </div>
            </Col>
          </Row>
          {userInfo && userInfo.package ? <>
            <Row className='d-flex justify-content-center'>
              <Col md={4}>
                <div className="sharedBox">
                  <p>Shared Contact
                    <div className="tooltipArrow"><FontAwesomeIcon icon={faQuestion} />
                      <span className="tooltipText">This shares contacts between all users in your organization.</span>
                    </div>
                  </p>
                  <div className="authToggle">
                    <p>OFF</p>
                    {sharedContact === true ?
                      <label className="switch">
                        <input type="checkbox" onClick={sharedContactClick} defaultChecked />
                        <span className="slider round"></span>
                      </label> :
                      <label className="switch">
                        <input type="checkbox" onClick={sharedContactClick} />
                        <span className="slider round"></span>
                      </label>}
                    <p>ON</p>
                  </div>
                </div>
              </Col>
              <Col md={4}>
                <div className="sharedBox">
                  <p>Enforce Strong Password
                    <div className="tooltipArrow"><FontAwesomeIcon icon={faQuestion} />
                      <span className="tooltipText">This requires users in your organization to have passwords that meet strict requirements.</span>
                    </div>
                  </p>
                  <div className="authToggle">
                    <p>OFF</p>
                    {enforceStrongPassword === true ?
                      <label className="switch">
                        <input type="checkbox" onClick={enforceStrongPasswordClick} defaultChecked />
                        <span className="slider round"></span>
                      </label> :
                      <label className="switch">
                        <input type="checkbox" onClick={enforceStrongPasswordClick} />
                        <span className="slider round"></span>
                      </label>}
                    <p>ON</p>
                  </div>
                </div>
              </Col>
              <Col md={4}>
                <div className="sharedBox">
                  <p>Enforce 2FA
                    <div className="tooltipArrow"><FontAwesomeIcon icon={faQuestion} />
                      <span className="tooltipText">This would require users to have a multi-factor authentication each time they login.</span>
                    </div>
                  </p>
                  <div className="authToggle">
                    <p>OFF</p>
                    {enforce2FA === true ?
                      <label className="switch">
                        <input type="checkbox" onClick={enforce2FAClick} defaultChecked />
                        <span className="slider round"></span>
                      </label> :
                      <label className="switch">
                        <input type="checkbox" onClick={enforce2FAClick} />
                        <span className="slider round"></span>
                      </label>}
                    <p>ON</p>
                  </div>
                </div>
              </Col>
              {user && user.currentStoragePlan === "PLUS" ?
                <Col md={4}>
                  <div className="sharedBox">
                    <p>File recovery(days)</p>
                    <div className="securityDropdown">
                      <Dropdown>
                        <Dropdown.Toggle className='dropdownButton' id="dropdown-basic">
                          {selectedRestoreTime}
                        </Dropdown.Toggle>
                        <Dropdown.Menu>
                          {restoreDays.map((option) => (
                            <Dropdown.Item
                              key={option.value}
                              className='dropdownImg'
                              onClick={() => handleRestoreDays(option.value)}
                            >
                              {option.time}
                            </Dropdown.Item>
                          ))}
                        </Dropdown.Menu>
                      </Dropdown>
                    </div>
                  </div>
                </Col> : ""}
              <Col md={4}>
                <div className="sharedBox">
                  <p>Enforce MFA
                    <div className="tooltipArrow"><FontAwesomeIcon icon={faQuestion} />
                      <span className="tooltipText">This would require users to have a multi-factor authentication each time they login.</span>
                    </div>
                    {enforceMFA === true ? <>
                      <br />  <span style={{ color: "#f94a29", cursor: "pointer" }} onClick={regenerateMFAClick}>Re-Generate Code</span>
                    </> : ""}
                  </p>
                  <div className="authToggle">
                    <p>OFF</p>
                    {enforceMFA === true ?
                      <label className="switch">
                        <input type="checkbox" onClick={enforceMFAClick} defaultChecked />
                        <span className="slider round"></span>
                      </label> :
                      <label className="switch">
                        <input type="checkbox" onClick={enforceMFAClick} />
                        <span className="slider round"></span>
                      </label>}
                    <p>ON</p>
                  </div>
                </div>
              </Col>
              <Col md={4}>
                <div className="sharedBox">
                  <p>Session Time</p>
                  <div className="securityDropdown">
                    <Dropdown>
                      <Dropdown.Toggle className='dropdownButton' id="dropdown-basic">
                        {selectedTime}
                      </Dropdown.Toggle>
                      <Dropdown.Menu>
                        {loginAttempt.map((option) => (
                          <Dropdown.Item
                            key={option.value}
                            className='dropdownImg'
                            onClick={() => handleLoginSelect(option.value)}
                          >
                            {option.time}
                          </Dropdown.Item>
                        ))}
                      </Dropdown.Menu>
                    </Dropdown>
                  </div>
                </div>
              </Col>
             
              <Col md={4}>
                <div className="sharedBox">
                  <p>Self Notify</p>
                  <div className="authToggle">
                    <p>OFF</p>
                    {selfNotify === true ?
                      <label className="switch">
                        <input type="checkbox" onClick={selfNotifyClick} defaultChecked />
                        <span className="slider round"></span>
                      </label> :
                      <label className="switch">
                        <input type="checkbox" onClick={selfNotifyClick} />
                        <span className="slider round"></span>
                      </label>}
                    <p>ON</p>
                  </div>
                </div>
              </Col>
              {user && user.userRole === "TENANT_ADMIN" ? 
              <Col md={4}>
                <div className="sharedBox">
                  <p>Employee Notify</p>
                  <div className="authToggle">
                    <p>OFF</p>
                    {employeeNotify === true ?
                      <label className="switch">
                        <input type="checkbox" onClick={employeeNotifyClick} defaultChecked />
                        <span className="slider round"></span>
                      </label> :
                      <label className="switch">
                        <input type="checkbox" onClick={employeeNotifyClick} />
                        <span className="slider round"></span>
                      </label>}
                    <p>ON</p>
                  </div>
                </div>
              </Col>: ""}
            </Row>
          </>
            : <>  <Row className='d-flex justify-content-center'>
              <Col md={4}>
                <div className="sharedBox">
                  <p>Self Notify</p>
                  <div className="authToggle">
                    <p>OFF</p>
                    {selfUserNotify === true ?
                    <>{userInfo?.selfNotify === true ? <>
                    <label className="switch">
                    <input type="checkbox" onClick={selfUserClick} defaultChecked />
                    <span className="slider round"></span>
                  </label>
                    </> 
                    : <label className={selfUserNotify === true ? "switch activeSwitch":"switch"}>
                    <input type="checkbox" onClick={selfUserClick} defaultChecked />
                    <span className="slider round"></span>
                  </label> }</>
                       :
                      <label className="switch">
                        <input type="checkbox" onClick={selfUserClick} />
                        <span className="slider round"></span>
                      </label>}
                    <p>ON</p>
                  </div>
                </div>
              </Col>
              <Col md={4}>
                <div className="sharedBox qrImageShow justify-content-center">
                 <button onClick={regenerateMFAClick}>Get QR Code</button>
                </div>
              </Col>
            </Row></>}
        </Container>
      </section>
      <AccountModal handleClose={handleClose} show={show} setSpinLoading={setSpinLoading} />
      <QrModal qrImage={qrImage} QrModalShow={QrModalShow} setQrModal={setQrModal} handleQrClose={handleQrClose} />
    </>
  )
}

export default AdminSecurity