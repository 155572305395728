import React from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import carouselImage from '../../assets/icon/carouselImage.png';
import iconOne from '../../assets/icon/icon(1).png'
import iconTwo from '../../assets/icon/icon(2).png'
import iconFour from '../../assets/icon/icon(4).png'
import iconSix from '../../assets/icon/icon(6).png';
import right from '../../assets/icon/right.png';
import left from '../../assets/icon/left.png';
import cloud from "../../assets/icon/cloud-storage.png"
// carousel
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";

const StreamLine = () => {
  const state = {
    responsive: {
      0: {
        items: 1,
      },
      600: {
        items: 2,
      },
      1000: {
        items: 3,
      },
      1400: {
        items: 4,
      },
    },
  }
  return (
    <>
      <section className="streamLine">
        <Container>
          <Row>
            <Col md={12}>
              <div className="streamLineHeading">
                <h1>Streamlining infrastructure</h1>
                <p>Docoteam in action</p>
              </div>
            </Col>
            <Col md={12}>
              <OwlCarousel
                className="owl-theme customNav"
                loop
                items={4}
                margin={30}
                nav
                dots={false}
                autoplay={false}
                autoplayTimeout={2000}
                responsive={state.responsive}
                navText={[
                  `<span class='arrow prev'><img src=${left} alt="" /></span>`,
                  `<span class='arrow next'><img src=${right} alt="" /></span>`
                ]}

              >
                <div className="item">
                  <div className="streamCard">
                    <img className='sideImage' src={carouselImage} alt="" />
                    <img className='carouselIcon' src={iconFour} alt="" />
                    <div className="streamInfo">
                      <h1>File Share</h1>
                      <p>Docoteam's file share brings organization, security, and management to teams in a world of file sharing.</p>
                    </div>
                  </div>
                </div>
                <div className="item">
                  <div className="streamCard">
                    <img className='sideImage' src={carouselImage} alt="" />
                    <img className='carouselIcon' src={iconOne} alt="" />
                    <div className="streamInfo">
                      <h1>Security</h1>
                      <p>Enterprise grade protection that meets your compliance requirements.</p>
                    </div>
                  </div>
                </div>
                <div className="item">
                  <div className="streamCard">
                    <img className='sideImage' src={carouselImage} alt="" />
                    <img className='carouselIcon' src={iconTwo} alt="" />
                    <div className="streamInfo">
                      <h1>Document Tracking</h1>
                      <p>Users can track their documents and requests easily with our tracking features.</p>
                    </div>
                  </div>
                </div>
                <div className="item">
                  <div className="streamCard">
                    <img className='sideImage' src={carouselImage} alt="" />
                    <img className='carouselIcon' src={iconOne} alt="" />
                    <div className="streamInfo">
                      <h1>Manage Employees</h1>
                      <p>Manage employee and additional user accounts, while maintaining the security standards of your organization.</p>
                    </div>
                  </div>
                </div>
                <div className="item">
                  <div className="streamCard">
                    <img className='sideImage' src={carouselImage} alt="" />
                    <img className='carouselIcon' src={iconSix} alt="" />
                    <div className="streamInfo">
                      <h1>24/7 Support</h1>
                      <p>Docoteam's file share brings organization, security, and management to teams in a world of file sharing</p>
                    </div>
                  </div>
                </div>
                <div className="item">
                  <div className="streamCard">
                    <img className='sideImage' src={carouselImage} alt="" />
                    <img className='carouselIcon' src={cloud} alt="" />
                    <div className="streamInfo">
                      <h1>DocoVault</h1>
                      <p>DocoVault allows admins and users to comply with stricter data retention policies as well as allow for data recovery</p>
                    </div>
                  </div>
                </div>
              </OwlCarousel>
            </Col>
          </Row>
        </Container>
      </section>
    </>
  )
}

export default StreamLine