import React from 'react'
import { Modal, Row, Col, Dropdown, OverlayTrigger, Tooltip } from 'react-bootstrap'
import DatePicker from 'react-datepicker';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronDown } from '@fortawesome/free-solid-svg-icons';
import { CustomDateFormatInput, CustomDatePickerInput } from '../CommonInterface/CommonFunction';

interface ModalSettingsProps {
    show: boolean;
    dateError: string;
    startDate: any;
    reminderValueName: string;
    reminderError: string;
    authValue: boolean;
    authClick: () => void;
    customEmail: any;
    customEmailValue: string;
    reminderDownMenu: () => any;
    dueDateChange: (date: any) => void;
    handleClose: () => void;
    successHandleShow: () => void;
    dueDateError?: string;
}

const Settings = ({dueDateError, dueDateChange, customEmail, customEmailValue, authClick, reminderDownMenu, successHandleShow, handleClose, authValue, reminderError, reminderValueName, startDate, dateError, show }: ModalSettingsProps) => {
    const renderTooltip = (props:any) => (
        <Tooltip id="button-tooltip" {...props}>
         Please specify a due date if you want to receive reminders.
        </Tooltip>
      );
    return (
        <>
            <Modal show={show} className="settingModalBody" size="xl"
                aria-labelledby="contained-modal-title-vcenter"
                centered>
                <Modal.Body >
                    <div className="settingModal">
                        <h1>Settings</h1>
                        <div className="customBox">
                            <label htmlFor="customMessage">Custom Email Message</label>
                            <textarea id='customMessage' onChange={customEmail} value={customEmailValue}></textarea>
                        </div>
                        <Row className='mb-3 settingBox'>
                            <Col md={4}>
                                <div className='modalInput requestInputForm' style={{ maxWidth: '240px' }}>
                                    <label htmlFor="requestTitle">Due Date (Optional)</label>
                                    {startDate && startDate ?
                                        <DatePicker selected={startDate} placeholderText="Select a date" onChange={dueDateChange} dateFormat="MM/dd/yyyy" customInput={<CustomDatePickerInput />} />
                                        : <div className='dueDateFormat'>
                                            <DatePicker placeholderText="Select a date" onChange={dueDateChange} dateFormat="MM/dd/yyyy" customInput={<CustomDateFormatInput />} />
                                        </div>
                                    }
                                      {dueDateError && dueDateError.length ? <p className='errMsg'>{dueDateError}</p> : <></>}
                                </div>
                            </Col>
                            <Col md={4} className='reminderBox'>
                                <div className='modalInput'>
                                    <label htmlFor="requestTitle">Reminders</label>
                                        <Dropdown>
                                            <Dropdown.Toggle className='dropButton' id="dropdown-basic">
                                                {reminderValueName && reminderValueName.length ? reminderValueName : 'None'}
                                                <FontAwesomeIcon icon={faChevronDown} />
                                            </Dropdown.Toggle>
                                            <Dropdown.Menu className='remindersTime'>
                                                {reminderDownMenu()}
                                            </Dropdown.Menu>
                                        </Dropdown>
                                    {reminderError && reminderError.length ? <p className='errMsg'>{reminderError}</p> : ""}
                                </div>
                            </Col>
                            <Col md={4} className='twoFA'>
                                <div className="modalInput">
                                    <label htmlFor="requestTitle">2-Factor Authentication (SMS):</label>
                                    <div className="authToggle">
                                        <p>OFF</p>
                                        {authValue === true ?
                                            <label className="switch">
                                                <input type="checkbox" onClick={authClick} checked />
                                                <span className="slider round"></span>
                                            </label> :
                                            <label className="switch">
                                                <input type="checkbox" onClick={authClick} />
                                                <span className="slider round"></span>
                                            </label>}
                                        <p>ON</p>
                                    </div>
                                </div>
                            </Col>
                        </Row>
                    </div>
                    <div className="footerModalButton">
                        <button className='backDashboard' onClick={handleClose}>Back</button>
                        <button className='nextButton' onClick={successHandleShow}>Next</button>
                    </div>
                </Modal.Body>

            </Modal>
        </>
    )
}

export default Settings