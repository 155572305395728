import React, { useEffect } from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import { Link } from 'react-router-dom';
import Button from '../../styleComponent/Button/Button';
import Layout from '../CommonCompoment/Layout/Layout';
import "./Security.css"
import ImageContent from '../CommonCompoment/ImageContent/ImageContent';
// content import
import image70 from '../../assets/content/image70.png';
import image73 from '../../assets/content/image73.png';
import image74 from '../../assets/content/image74.png';
import image77 from '../../assets/content/image77.png';
import image79 from '../../assets/content/image79.png';
import image80 from '../../assets/content/image80.png';
import image81 from '../../assets/content/image81.png';
import image82 from '../../assets/content/image82.png';
import ContentImage from '../CommonCompoment/ContentImage/ContentImage';
import SecurityBanner from './SecurityBanner';
import CompanyDescription from '../Home/CompanyDescription';
import { useLocation } from 'react-router-dom';


const Security = () => {
    // content value
    const contentDataOne = {
        title: "Data Encryption",
        description: "At Docoteam, we take data security very seriously, and we use encryption to protect all data in storage and transmission. This ensures that sensitive information cannot be accessed by unauthorized parties, even if it is intercepted during transmission or if the storage server is breached. We employ strong encryption protocols and keys to ensure that all data is adequately protected. Additionally, we strictly control access to encryption keys to ensure that only authorized personnel can access sensitive information. With our approach to data encryption, you can rest assured that your data is secure and protected."
    };
    const contentDataTwo = {
        title: "Access Control and Team Management",
        description: "We understand the importance of access control and team management when it comes to safeguarding sensitive data. That's why we provide powerful access control tools to help you manage user access and permissions. You can easily grant or revoke access to individual team members or groups, ensuring that only authorized personnel can access confidential data. Our team management features also allow you to assign roles and responsibilities to team members, track their activity and monitor performance. These tools enable you to create a secure environment for collaboration, communication, and data sharing. With our access control and team management features, you can stay in control of your data and ensure that it is handled securely and efficiently."
    };
    const contentDataThree = {
        title: "Two Factor Authentication",
        description: "We offer two-factor authentication (2FA) as an extra layer of protection for your account. 2FA requires users to provide two forms of identification to verify their identity, usually a password and a second factor such as a code sent to their mobile device or an app. This helps to prevent unauthorized access to accounts, even if your password is compromised. Our 2FA feature is easy to use and can be set up in just a few minutes."
    };
    const contentDataFour = {
        title: "Data Retention and Loss Prevention",
        description: "Retaining your data and protecting it from loss or damage is of paramount importance to us. We employ strict data retention policies to ensure that your data is retained only for as long as it is necessary, in compliance with applicable laws and regulations. We also take measures to protect your data from loss or damage, such as regular data backups, redundancy, and disaster recovery planning. Additionally, we use advanced security measures, such as firewalls, intrusion detection, and prevention systems, to protect your data from external threats. With our focus on data retention and loss prevention, you can trust that your data is always safe, secure, and available when you need it."
    };
    const contentDataFive = {
        title: "Data Tracking and Management",
        description: "Data tracking and management is not only for data-driven decision making but also for compliance with applicable regulations and industry standards. That's why we offer powerful data tracking and management tools that are designed with compliance and internal controls in mind. Our platform provides granular access controls to ensure that only authorized personnel can access and manage sensitive data. We also offer detailed audit logs that track all changes and activities related to your data, enabling you to trace the history of your data and meet regulatory requirements. Our platform is also designed to generate logs that comply with industry standards. With our data tracking and management tools, audits and logs, you can stay in control of your data and ensure that it is managed and protected according to applicable regulations and industry standards."
    };

    const contentDataSix = {
        title: "Data Governance and Control",
        description: "Our data governance and controls ensure that your data is properly managed, protected, and utilized. We provide robust data governance and control features that enable you to set policies, define workflows, and establish controls for your data. You can easily manage your data by defining data access levels, ensuring that sensitive data is accessed only by authorized personnel. We also offer data masking and anonymization features to further protect sensitive data. Our data governance and control features also enable you to establish data retention policies, define data backup and disaster recovery procedures, and ensure that your data is in compliance with applicable regulations and industry standards."
    };
    const contentDataSeven = {
        title: "Regular Security Audits and Updates",
        description: "We conduct regular security audits and updates to ensure that our platform is secure and up-to-date. Our security team regularly reviews our platform to identify potential vulnerabilities and address them promptly. We also keep our platform up-to-date with the latest security patches and updates to ensure that it is protected against the latest threats and attacks. In addition, we follow industry best practices such as penetration testing and vulnerability scanning to identify potential security risks and address them promptly. Our regular security audits and updates enable us to maintain the highest level of security for your data, giving you peace of mind that your data is always safe and secure."
    };
    const contentDataEight = {
        title: "Enterprise Solutions",
        description: "Every enterprise has unique data management and security needs. That's why we offer customizable controls and management tools to meet the specific needs of your enterprise. We provide a flexible and scalable solution that can be tailored to your enterprise's specific requirements. With our custom controls, you can set policies, define workflows, and establish controls for your data that align with your enterprise's security and compliance needs. Our management tools enable you to easily manage your data, set access levels, and establish retention policies to ensure that your data is protected and managed according to best practices. Our platform is designed to integrate seamlessly with your existing systems, providing a smooth transition and reducing the risk of disruption."
    };
    const location = useLocation();
    useEffect(() => {
        if (location) {
            const element = document.getElementById(location.hash);

            if (element) {
                element.scrollIntoView({ behavior: 'smooth' });
            }
        }
    }, [location]);

    return (
        <Layout>
            <SecurityBanner />
            <CompanyDescription />
            <div id="#data-encryption">
                <ContentImage styleClass={`contentImage`} image={image70} content={contentDataOne} />
            </div>
            <ImageContent display={`none`} styleClass={`imageContent`} image={image82} content={contentDataTwo} />
            <ContentImage styleClass={`contentImage`} image={image81} content={contentDataThree} />
            <ImageContent display={`none`} styleClass={`imageContent`} image={image73} content={contentDataFour} />
            <div id="#data-tracking">
                <ContentImage styleClass={`contentImage`} image={image74} content={contentDataFive} />
            </div>
            <ImageContent display={`none`} styleClass={`imageContent`} image={image80} content={contentDataSix} />
            <ContentImage styleClass={`contentImage`} image={image79} content={contentDataSeven} />
            <ImageContent display={`none`} styleClass={`imageContent`} image={image77} content={contentDataEight} />
            <section className='keyFeatureSection' id="#key-feature">
                <Container>
                    <div className="keyFeatureHeading">
                        <h1>KEY FEATURES</h1>
                    </div>
                    <div className="keyFeature">
                        <div className="featureBox">
                            <h1>Content<br /> classification</h1>
                            <p>The content classification feature enables assigning labels to files based on their sensitivity, restricting access and preventing unauthorized data breaches. For instance, a "confidential" label allows clearance-based access to the file. This feature provides greater control and security for sensitive cloud-stored data.</p>
                        </div>
                        <div className="featureBox">
                            <h1>Data loss prevention<br />
                                and controls</h1>
                            <p>Data loss prevention is a security feature that helps prevent the unauthorized disclosure or leakage of sensitive data. You can further analyze data in transit or in storage to detect and block potential data breaches. This feature helps organizations protect their confidential data from internal and external threats and ensures compliance with data privacy regulations.</p>
                        </div>
                        <div className="featureBox">
                            <h1>Data loss prevention<br />
                                and controls</h1>
                            <p>Docoteam's advanced security system detects sophisticated malware in new and historical content almost instantly, allowing for real-time protection. It can automatically restrict downloads of potentially harmful files while still allowing uploads, ensuring that users can collaborate safely without compromising their security.</p>
                        </div>
                    </div>
                </Container>
            </section>
            <section className="industry">
                <Container>
                    <Row>
                        <Col md={12}>
                            <div className="industryHeading">
                                <h1>Industry-accepted best practices and frameworks</h1>
                                <p>Our security approach focuses on security governance, risk management, and compliance. This includes <br /> encryption while in storage and in transmission, network security and server hardening, administrative <br /> access control, system monitoring, logging and alerting, and more.</p>
                                <Button className='industryBtn' customBackgroundColor="#F94A29" customBorderColor="#F94A29" customTextColor="#fff">READ THE WHITE PAPER</Button>
                                <h5>Docoteam’s security controls also align to the National Cyber Security Centre's (NCSC) cloud <br /> security principles. <Link to='/'>Read more</Link> </h5>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </section>
        </Layout>
    )
}

export default Security