import React, { useState, useEffect } from 'react'
import PhoneInput from 'react-phone-input-2';
import { Modal } from 'react-bootstrap';

interface SingleContactProps {
    handleContactClose: () => void;
    contactShow: boolean;
    editContact: (type: string) => void;
    contactInfo: any;
}

const SingleContact = ({ editContact, contactShow, handleContactClose, contactInfo }: SingleContactProps) => {
    const [firstName, setFirstName] = useState("");
    const [lastName, setLastName] = useState("");
    const [emailAddress, setEmailAddress] = useState("");
    const [contactId, setContactId] = useState("");
    const [isShared, setIsShared] = useState(false);
    const [phoneNumber, setPhoneNumber] = useState("");
    const [groupName, setGroupName] = useState("");

    useEffect(() => {
        setContactId(contactInfo.contactId);
        setFirstName(contactInfo.firstName);
        setLastName(contactInfo.lastName);
        setEmailAddress(contactInfo.email);
        setIsShared(contactInfo.isShared);
        setPhoneNumber(contactInfo.phoneNumber);
        if (contactInfo.group) {
            setGroupName(contactInfo.group.groupName);
        }
    }, [contactInfo]);
    return (
        <>
            <Modal show={contactShow} onHide={handleContactClose}>
                <div className="addContactForm">
                    <h1>Contact Information</h1>
                    <div className="registrationInput">
                        <label>First Name</label>
                        <input type="text" value={firstName && firstName} id="firstName" placeholder='First Name' />
                    </div>
                    <div className="registrationInput">
                        <label>Last Name</label>
                        <input type="text" value={lastName} id="lastName" placeholder='Last Name' />
                    </div>
                    <div className="registrationInput">
                        <label>E-mail Address</label>
                        <input type="email" value={emailAddress} id="email" placeholder='Email Address' />
                    </div>
                    <div className="contactNumber">
                        <label>Phone Number</label>
                        <PhoneInput country={'us'} disableCountryCode={false} value={phoneNumber} />
                    </div>
                    {groupName && groupName.length ? <>
                        <div className="groupText">
                            <h6>Selected group: {groupName}</h6>
                        </div>
                    </> : <></>}
                    <div className="contactCondition">
                        <div className="shared">
                            <label className='tableCheckBox mobileTable'>
                                <div className="contactCheck">
                                    <input checked={isShared} name="isShared" type="checkbox" />
                                    <span className="checkmark"></span>
                                </div>
                                <p>Add as Shared Contact</p>
                            </label>
                        </div>
                    </div>
                    <div className="addContactBtn d-flex justify-content-center">
                        <div className="addContact d-flex justify-content-center">
                            <button type='submit' className='back' onClick={() => editContact(contactId)}>Update</button>
                        </div>
                    </div>
                </div>
            </Modal>
        </>
    )
}

export default SingleContact