import React from 'react';
import { Container, Navbar } from 'react-bootstrap';
import { NavLink } from 'react-router-dom';
import logoIcon from '../../../assets/logo/clearLogo.png';

const SignUpNavbar = () => {
  return (
    <section className="header">
      <Navbar collapseOnSelect expand="lg" fixed='top' className='header d-flex justify-content-between'>
        <Container>
          <NavLink to="/" className='logo navbar-brand'>
            <img src={logoIcon} alt="logo" width="210px" />
          </NavLink>
          <NavLink to="/login" className='regisLoginBtn'>
            Login
          </NavLink>
        </Container>
      </Navbar>
    </section>
  )
}

export default SignUpNavbar