import React from 'react'
import { Container, Row, Col, Nav, Tab } from 'react-bootstrap'
import userDashboard from '../../assets/banner/user.png'
import sentDashboard from '../../assets/banner/sent.png';
import live from "../../assets/dashboard/live.png";
import preview from "../../assets/dashboard/preview.png";

const StreamPower = () => {
    return (
        <>
            <section id='feature' className="StreamPower">
                <Container>
                    <Row>
                        <Col md={12}>
                            <div className="powerHeading">
                                <h1>A streamlining powerhouse</h1>
                                <p>Docoteam 101</p>
                            </div>
                        </Col>
                        <Col md={12}>
                            <Tab.Container id="left-tabs-example" defaultActiveKey="first">
                                <Row>
                                    <Col md={5}>
                                        <Nav variant="pills" className="flex-column streamNav">
                                            <Nav.Item>
                                                <Nav.Link className='powerNav' eventKey="first">
                                                    <div className="powerDescriptionActive">
                                                        <h1>User Dashboard</h1>
                                                        <p>The dashboard provides an intuitive interface designed to provide the most important information in a summary.</p>
                                                    </div>
                                                </Nav.Link>
                                            </Nav.Item>
                                            <Nav.Item>
                                                <Nav.Link className='powerNav' eventKey="second">
                                                    <div className="powerDescription">
                                                        <h1>Request & Send</h1>
                                                        <p>Docoteam's requesting and sending provide the user with the ability to customize and specify checklist formatted requests.</p>
                                                    </div>
                                                </Nav.Link>
                                            </Nav.Item>
                                            <Nav.Item>
                                                <Nav.Link className='powerNav' eventKey="third">
                                                    <div className="powerDescription">
                                                        <h1>Secure Preview</h1>
                                                        <p>Securely store, access, and manage your business data with our reliable, flexible and secure cloud storage solution</p>
                                                    </div>
                                                </Nav.Link>
                                            </Nav.Item>
                                            <Nav.Item>
                                                <Nav.Link className='powerNav' eventKey="fourth">
                                                    <div className="powerDescription">
                                                        <h1>Live Data Room</h1>
                                                        <p>Clients interact with our data room to securely upload and download the files being transferred</p>
                                                    </div>
                                                </Nav.Link>
                                            </Nav.Item>
                                        </Nav>
                                    </Col>
                                    <Col md={7}>
                                        <Tab.Content className='tabRightVideo'>
                                            <Tab.Pane className='tabRight' eventKey="first">
                                                <div className="videoBox">
                                                    <img src={userDashboard} alt="" />
                                                </div>
                                            </Tab.Pane>
                                            <Tab.Pane className='tabRight' eventKey="second">
                                                <div className="videoBox">
                                                <img src={sentDashboard} alt="" />
                                                </div>
                                            </Tab.Pane>
                                            <Tab.Pane className='tabRight' eventKey="third">
                                                <div className="videoBox">
                                                <img src={preview} alt="" />
                                                </div>
                                            </Tab.Pane>
                                            <Tab.Pane className='tabRight' eventKey="fourth">
                                                <div className="videoBox">
                                                <img src={live} alt="" />
                                                </div>
                                            </Tab.Pane>
                                        </Tab.Content>
                                    </Col>
                                </Row>
                            </Tab.Container>
                        </Col>
                    </Row>
                </Container>
            </section>
        </>
    )
}

export default StreamPower