import React, { useState, useEffect } from 'react'
import { Modal } from 'react-bootstrap'
import { updateContact } from '../../api/contact';
import { showNotifications } from '../../helpers/toaster';
import { groupList } from '../../api/group';
import { ContactDataInterface, GroupData } from '../CommonInterface/CommonInterface';
import PhoneInput from 'react-phone-input-2';
import { useForm } from 'react-hook-form';

interface EditContactProps {
    handleEditClose: () => void;
    editShow: boolean;
    setEditShow: (type: boolean) => void;
    contactInfo: any;
    setCount: (type: number) => void;
    count: number;
    setPhoneNumber: (type: string) => void;
    phoneNumber: string;
}
const EditContact = ({ phoneNumber, setPhoneNumber, editShow, setEditShow, contactInfo, handleEditClose, count, setCount }: EditContactProps) => {
    const [firstName, setFirstName] = useState("");
    const [lastName, setLastName] = useState("");
    const [contactId, setContactId] = useState("");
    const [emailAddress, setEmailAddress] = useState("");
    const [saveEmail, setSaveEmail] = useState("");
    const [isShared, setIsShared] = useState(false);
    const [groupIdentifier, setGroupID] = useState("");
    const [groupName, setGroupName] = useState("");
    const [group, setGroup] = useState<GroupData[]>([]);
    const [show, setShow] = useState(false);
    const {
        register,
        handleSubmit,
        setValue,
      } = useForm();
    const handleClose = () => {
        setShow(false);
    }

    useEffect(() => {
        setFirstName(contactInfo.firstName);
        setLastName(contactInfo.lastName);
        setEmailAddress(contactInfo.email);
        setIsShared(contactInfo.isShared);
        setContactId(contactInfo.contactId);
        if (contactInfo.phoneNumber) {
            setPhoneNumber(contactInfo.phoneNumber);
        }
        else {
            setPhoneNumber("");
        }
        if (contactInfo.group) {
            setGroupID(contactInfo.group.groupId);
            setGroupName(contactInfo.group.groupName);
        }
        else {
            setGroupID("");
            setGroupName("");
        }

    }, [contactInfo]);

    const handleCheckboxChange = () => {
        setIsShared(!isShared); // Toggle the value (invert it)
    };

    const emailSave = (email: string) => {
        setSaveEmail(email);
        setEmailAddress(email);
    };


    const editContact = () => {

        let contactInfo: ContactDataInterface = {
            "firstName": firstName,
            "lastName": lastName,
            "isShared": isShared,
        };

        if (saveEmail) {
            contactInfo.email = saveEmail;
        }
        if (phoneNumber) {
            contactInfo.phoneNumber = phoneNumber;
        }
        if (groupIdentifier) {
            contactInfo.groupIdentifier = groupIdentifier;
        }
        updateContact(contactId, contactInfo).then((data) => {
            if (data.statusCode != 200) {
                showNotifications("error", data.message)
            }
            else {
                showNotifications("success", data.message)
                setEditShow(false);
                setCount(count + 1);
            }

        })
    }

    const changeGroup = () => {
        groupList().then((data) => {
            if (data.groups) {
                setGroup(data.groups);
            }
        })
        setShow(true);
        setEditShow(false);
    }
    const handleGroupListClose = () => {
        setShow(false);
        setEditShow(true);
    }

    let onSubmit = () => {
        editContact()
    }

    return (
        <>
            <Modal show={editShow} onHide={handleEditClose}>
                <form onSubmit={handleSubmit(onSubmit)}  className="addContactForm">
                    <h1>Edit Contact</h1>
                    <div className="registrationInput">
                        <label>First Name</label>
                        <input type="text" value={firstName && firstName} onChange={(e) => setFirstName(e.target.value)} id="firstName" placeholder='First Name' />
                    </div>
                    <div className="registrationInput">
                        <label>Last Name</label>
                        <input type="text" value={lastName} onChange={(e) => setLastName(e.target.value)} id="lastName" placeholder='Last Name' />
                    </div>
                    <div className="registrationInput">
                        <label>E-mail Address</label>
                        <input type="email" value={emailAddress} onChange={(e) => emailSave(e.target.value)} id="email" placeholder='Email Address' />
                    </div>
                    <div className="contactNumber">
                        <label>Phone Number</label>
                        <PhoneInput country={'us'} disableCountryCode={false} value={phoneNumber} onChange={(value) => setPhoneNumber(value)} />
                    </div>
                    {groupName && groupName.length ? <>
                        <div className="groupText">
                            <h6>Selected group: {groupName}</h6>
                        </div>
                    </> : <></>}
                    <div className="contactCondition">
                        <div className="shared">
                            <label className='tableCheckBox mobileTable'>
                                <div className="contactCheck">
                                    <input checked={isShared} name="isShared" type="checkbox" onChange={handleCheckboxChange} />
                                    <span className="checkmark"></span>
                                </div>
                                <p>Add as Shared Contact</p>
                            </label>
                        </div>
                        <div className="groupAdd">
                            <p onClick={changeGroup}>Change Group</p>
                        </div>
                    </div>
                    <div className="addContactBtn d-flex justify-content-center">
                        <div className="addContact d-flex justify-content-center">
                            <button type='submit' className='back'>Update</button>
                        </div>
                    </div>
                </form>

            </Modal>

            <Modal show={show} onHide={handleClose} centered size="sm">
                <div className="groupModal">
                    {group && group.length > 0 ? <h1>Group List</h1> : <></>}
                    <div className='groupListItem'>
                        {group && group.length === 0 ? <h1>No Group List</h1> : <>
                            <div className="radio-list">
                                {group && group.map((data, i) =>
                                    <label key={`group` + i} className="timeCheckbox groupNameList">
                                        {data.groupName}
                                        <input type="radio" name="group" onChange={() => { setGroupID(data.groupId), setGroupName(data.groupName) }} checked={data.groupId === groupIdentifier ? true : false} />
                                        <span className="checkmark"></span>
                                    </label>
                                )}
                            </div>
                        </>}

                    </div>
                    <div className="addContactBtn d-flex justify-content-center">
                        <div className="addContact">
                            {group && group.length === 0 ? <button type='submit' className='save' onClick={handleClose}>Close</button> :
                                <><button type='submit' className='save' onClick={handleGroupListClose}>Save</button></>}
                        </div>
                    </div>
                </div>
            </Modal>
        </>
    )
}

export default EditContact