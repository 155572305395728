import React, { useRef, useEffect, useState } from 'react'
import { Table } from 'react-bootstrap';
import dragAndDrop from '../../assets//dragDrop/dragDrop.png';
import { v4 as uuidv4 } from 'uuid';
import SmallButton from '../../styleComponent/SmallButton/SmallButton';
import { deleteFiles, filesUploadApi } from '../../api/auth';
import { splitNameAndExtension } from '../../helpers/generalUtil';
import { ItemUploadInterface, UploadFileInterface } from '../CommonInterface/CommonInterface';
import { getCookieValue } from '../CommonInterface/CommonFunction';
import { replaceOldFiles } from '../../api/files';
import { handleFileUpload } from '../../helpers/fileUpload';
import { showNotifications } from '../../helpers/toaster';
import { ToastContainer } from 'react-toastify';


interface fileUploadProps {
    completeTag: boolean;
    itemUploadId: string;
    itemUploadName: string;
    attchId: string;
    uploadItemList: any;
    setUploadItemList: any;
    uploadItem: ItemUploadInterface[];
    setUploadItem: (item: ItemUploadInterface[]) => void;
    newListUpdate: ItemUploadInterface[];
    setNewListUpdate: any;
    itemList: UploadFileInterface["body"]["items"];
    showUPloadFile: any;
    setShowUPloadFile: any;
    associatedUserList: any;
    onLoadItem: () => void;
    deleteFilesId: string;
    setDeleteFilesId: React.Dispatch<React.SetStateAction<string>>;
    setSpinLoading: any;
    setItemUpdate: any;
    itemUpdate: number;
}
const DropFile = ({ setItemUpdate, itemUpdate, setSpinLoading, associatedUserList, completeTag, deleteFilesId, setDeleteFilesId, onLoadItem, setShowUPloadFile, showUPloadFile, itemList, setNewListUpdate, newListUpdate, uploadItemList, setUploadItemList, uploadItem, setUploadItem, attchId, itemUploadId, itemUploadName }: fileUploadProps) => {
    const [oldFileId, setOldFileId] = useState<string>("");
    const [oldItemId, setOldItemId] = useState<string>("");
    const [newReplaceJson, setNewReplaceJson] = useState<any>({});
    const tokenJson = getCookieValue('requestId');
    const uploadToken = JSON.parse(tokenJson || "");
    const wrapperRef = useRef<HTMLInputElement>(null);
    const fileInputRef = useRef<HTMLInputElement>(null);

    const onFileDrop = (e: any) => {
        const newFile = e.target.files[0];
        const fileNameExtension = splitNameAndExtension(newFile.name);
        let uploadObj = {
            "itemId": attchId,
            "fileLockId": uuidv4(),
            "fileName": fileNameExtension.name,
            "extension": fileNameExtension.extension,
            "originalFileName": newFile.name,
            "size": newFile.size,
            "file": newFile
        }
        setUploadItem([...uploadItem, uploadObj]);
        setNewListUpdate([...newListUpdate, uploadObj]);
        setUploadItemList([...uploadItemList, uploadObj.itemId]);
        setShowUPloadFile([...showUPloadFile, uploadObj.itemId]);
    }

    const fileRemove = (fileLockId: string, itemId: string) => {
        const filteredItems = uploadItem.filter(item => item.fileLockId !== fileLockId);
        setUploadItem(filteredItems)
        const updateList = newListUpdate.filter((item) => item.fileLockId !== fileLockId);
        setNewListUpdate(updateList)
        const deleteFormList = uploadItemList.filter((item: any) => item !== itemId);
        setUploadItemList(deleteFormList);
        const indexToRemove = showUPloadFile.indexOf(itemId);
        if (indexToRemove !== -1) {
            const updatedItems = [...showUPloadFile];
            updatedItems.splice(indexToRemove, 1);
            setShowUPloadFile(updatedItems);
        }
    }

    useEffect(() => {
        onLoadItem()
    }, [deleteFilesId, showUPloadFile])

    const permanentDelete = (fileId: string) => {
        setDeleteFilesId(fileId)
        deleteFiles(fileId, uploadToken.token).then((data) => {
            onLoadItem()
        })
    }


    const onNewFileDrop = (e: any) => {
        const newFile = e.target.files[0];
        const fileNameExtension = splitNameAndExtension(newFile.name);
        let uploadObj = {
            "itemId": oldFileId,
            "requestId": uploadToken.requestId,
            "fileName": fileNameExtension.name,
            "extension": fileNameExtension.extension,
            "originalFileName": newFile.name,
            "size": newFile.size,
            "file": newFile,
            "associatedUserIds": associatedUserList
        }
        setNewReplaceJson(uploadObj);
        setNewListUpdate([...newListUpdate, uploadObj]);
    }



    const replaceFiles = () => {
        if (fileInputRef.current) {
            fileInputRef.current.click(); // Ensure ref is not null before accessing
        }
    };

    const fileChange = async () => {
        setSpinLoading(true);
        try {
            const data = await replaceOldFiles(oldItemId, newReplaceJson, uploadToken.token);
            await handleFileUpload(newReplaceJson.file, data.body.uploadUrl, 0, (progress: any) => {});
            await filesUploadApi(data.body.fileId, uploadToken.token);
        
            setNewListUpdate([]);
            showNotifications('success', "File replaced successfully!!");
        } catch (error) {
            // Handle errors here
            console.error('Error:', error);
            showNotifications('error', "Failed to replace file");
        } finally {
            setSpinLoading(false);
            setItemUpdate(itemUpdate + 1);
        }
    };

    return (
        <>
            <ToastContainer />
            {completeTag === true ? <div ref={wrapperRef} onClick={replaceFiles} className="drop-file-Upload" >
                <div className="drop-file-input__label">
                    <img src={dragAndDrop} alt="" />
                    <p>Drag & Drop your files here</p>
                </div>
                <input ref={fileInputRef} type="file" value="" onChange={onNewFileDrop} />
            </div>
                : <div ref={wrapperRef} className="drop-file-input" >
                    <div className="drop-file-input__label">
                        <img src={dragAndDrop} alt="" />
                        <p>Drag & Drop your files here</p>
                    </div>
                    <input type="file" value="" onChange={onFileDrop} />
                </div>
            }



            <div className="requestFileList">
                <Table responsive className='tableBody text-center downloadTable'>
                    <thead>
                        <tr>
                            <th>File Name</th>
                            <th>Extension</th>
                            <th></th>
                        </tr>
                    </thead>

                    <tbody>

                        {showUPloadFile && showUPloadFile.map((item: any, index: number) => <>
                            {item.files && item.files.length ? <>

                                {item.files.map((files: any, i: number) => <>
                                    <tr key={`contact` + i} className="fileUploadText">
                                        <td>{files.fileName.length > 15 ? files.fileName.substring(0, 13) + '..' : files.fileName}</td>
                                        <td>{files.extension}</td>
                                        <td className='tableInnerButton rightButton'>
                                        {completeTag === true ? <>
                                            {files.replacementMessage && files.replacementMessage.length ?
                                                <SmallButton customWidth="71" customHeight="26" customBackgroundColor='#F94A29' customBorderColor='#F94A29' customTextColor='#FFF'>Replace</SmallButton>
                                                : <SmallButton customWidth="71" customHeight="26" customBackgroundColor='#fff' customBorderColor='#F94A29' customTextColor='#F94A29'>Accepted</SmallButton>
                                            }
                                            </>:<>
                                            {files.replacementMessage && files.replacementMessage.length ?
                                                <SmallButton onClick={() => { replaceFiles(); setOldFileId(item.itemId); setOldItemId(files.fileId) }} customWidth="71" customHeight="26" customBackgroundColor='#F94A29' customBorderColor='#F94A29' customTextColor='#FFF'>Replace</SmallButton>
                                                : <SmallButton customWidth="71" customHeight="26" customBackgroundColor='#fff' customBorderColor='#F94A29' customTextColor='#F94A29'>Accepted</SmallButton>
                                            }
                                            </>}
                                        </td>
                                    </tr>
                                </>)}
                            </> : <></>}
                        </>)}
                        {completeTag ? <>
                            {
                                newListUpdate && newListUpdate.map((item, index: number) => (
                                    <tr key={`contact` + index} className="fileUploadText">
                                        <td>{item.fileName.length > 15 ? item.fileName.substring(0, 13) + '..' : item.fileName}</td>
                                        <td>{item.extension}</td>
                                        <td className='tableInnerButton rightButton'>
                                            <SmallButton customWidth="71" customHeight="26" customBackgroundColor='#fff' customBorderColor='#F94A29' customTextColor='#F94A29' onClick={() => fileRemove(item.fileLockId, item.itemId)}>Remove</SmallButton>
                                            <SmallButton className='ml-2' customWidth="71" customHeight="26" customBackgroundColor='#fff' customBorderColor='#F94A29' customTextColor='#F94A29' onClick={() => fileChange()}>Change</SmallButton>
                                        </td>
                                    </tr>
                                ))
                            }</> : <>
                            {
                                newListUpdate && newListUpdate.map((item, index: number) => (
                                    <tr key={`contact` + index} className="fileUploadText">
                                        <td>{item.fileName.length > 15 ? item.fileName.substring(0, 13) + '..' : item.fileName}</td>
                                        <td>{item.extension}</td>
                                        <td className='tableInnerButton rightButton'>
                                            <SmallButton customWidth="71" customHeight="26" customBackgroundColor='#fff' customBorderColor='#F94A29' customTextColor='#F94A29' onClick={() => fileRemove(item.fileLockId, item.itemId)}>Remove</SmallButton>
                                        </td>
                                    </tr>
                                ))
                            }</>}


                    </tbody>

                </Table>



            </div>
        </>
    )
}

export default DropFile