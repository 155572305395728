import React from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import { Link } from 'react-router-dom';
import secure from '../../assets/company/Secure1.png'
import lock from '../../assets/company/lock.png'
import database from '../../assets/company/database.png'

const CompanyDescription = () => {
    return (
        <>
            <section className="CompanyInfo" id="security">
                <Container>
                    <Row>
                        <Col md={12}>
                            <div className="companyHeading">
                                <h1>The secure document infrastructure <br />
                                    for companies in all industries</h1>
                            </div>
                        </Col>
                        <Col md={4}>
                            <div className="CompanyCard">
                                <div className="IconImg">
                                    <img src={secure} alt="" />
                                </div>
                                <h1>Enterprise grade security</h1>
                                <p>Docoteam encrypts the data in storage and the data being transferred for all of our users. We use additional security management and account control and access tools like advanced multi-factor authentication, audit logs, and integrations with data loss prevention providers.</p>
                                <Link to='/security'>Learn more about security at Docoteam</Link>
                            </div>
                        </Col>
                        <Col md={4}>
                            <div className="CompanyCard">
                                <div className="IconImg">
                                    <img src={lock} alt="" />
                                </div>
                                <h1>Identity and device controls</h1>
                                <p>Ensure that only authorized users and approved devices can access your data. Secure features like single sign-on and MFA support and enforcement for teams to be managed.</p>
                            </div>
                        </Col>
                        <Col md={4}>
                            <div className="CompanyCard">
                                <div className="IconImg">
                                    <img src={database} alt="" />
                                </div>
                                <h1>Enterprise governance protocols</h1>
                                <p>Docoteam provides governance and risk-management solutions to meet your organization’s needs and requirements, no matter what they are. This includes global retention policies, custom terms of service, and support for enterprise security controls.</p>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </section>
        </>
    )
}

export default CompanyDescription