import React,{useEffect} from 'react'
import { Table } from 'react-bootstrap'
import SmallButton from '../../styleComponent/SmallButton/SmallButton'
import { RecipientUser } from '../CommonInterface/CommonInterface';
import { shortEmail } from '../CommonInterface/CommonFunction';
import { useParams } from 'react-router-dom';
interface RecipientInterfaceProps {
    errorContact?: string;
    senderTypeValue: string;
    senderError?: string;
    senderTypeFunction: (type: string) => void;
    fileRemove: any;
    recipientsValue: RecipientUser[];
    setRecipientsValue?: (type: RecipientUser[]) => void;
}

const Recipient = ({ fileRemove,  senderTypeFunction, senderError, senderTypeValue, errorContact, recipientsValue }: RecipientInterfaceProps) => {
    const { id }: any = useParams();
   
    return (
        <>
            <div className="receiptList">
                <h1>Recipients</h1>

                <Table responsive className='tableBody'>
                    <thead>
                        <tr>
                            <th>Name</th>
                            <th>Email</th>
                            <th></th>
                        </tr>
                    </thead>
                    {errorContact && errorContact.length ? <p className='errMsg text-center'>{errorContact}</p> : ''}
                    <tbody>
                        {recipientsValue && recipientsValue.map((data, index: number) =>
                            <tr key={`item` + index} className='leftTable recipientName'>
                                <td className='fullName'>
                                    <p className={data.fullName && data.fullName.length > 12 ? 'tooltip-top' : ""} data-tooltip={data.fullName && data.fullName.length > 12 ? data.fullName : ""}>
                                        {data.fullName && data.fullName.length > 12 ? data.fullName.substring(0, 10) + '..' : data.fullName}
                                    </p>
                                </td>
                                <td>
                                    <p className={data.email && data.email.length > 15 ? 'tooltip-top' : ""} data-tooltip={data.email && data.email.length > 15 ? data.email : ""}>
                                        {shortEmail(data.email)}
                                    </p>
                                </td>
                                <td className='tableInnerButton'>
                                    <SmallButton customWidth="66" customHeight="24" customBackgroundColor='#fff' customBorderColor='#F94A29' customTextColor='#F94A29' onClick={() => fileRemove(data.email)}>Remove</SmallButton>
                                </td>
                            </tr>
                        )}
                    </tbody>
                </Table>

                <div className="optionalButton recipientOption">
                    <div className="groupBottom">

                        {id && id ? <><p>Sended As "{senderTypeValue}"</p></> : <>
                            <p>Send As</p>
                            {/* edit */}
                            <div className="groupList">
                                <button className={senderTypeValue === 'group' ? 'group' : 'individual'} onClick={() => senderTypeFunction("group")}>Group</button>
                                <button className={senderTypeValue === 'individual' ? 'group' : 'individual'} onClick={() => senderTypeFunction("individual")}>Individual</button>
                            </div>
                            {senderError && senderError.length ? <p className='errMsg'>{senderError}</p> : ""}
                        </>}
                    </div>
                    <div className={id && id ? "required" : "required mtAdd"}>
                        <button>
                            {recipientsValue && recipientsValue.length > 9 ? `${recipientsValue.length}` : <p>0{recipientsValue.length}</p>}
                        </button>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Recipient