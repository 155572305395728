const requestState: any = {
    files: [] as { attachmentUploadLockId: string; file: { name: string; type: string; size: number; base64: string } }[],
    updateFiles: (newFiles: typeof requestState.files) => {
        requestState.files = [...requestState.files, ...newFiles];
    },
    removeFilesByUploadLockId: (attachmentUploadLockId: string) => {
        requestState.files = requestState.files.filter((file:any) => file.attachmentUploadLockId !== attachmentUploadLockId);
    },
    clearState: () => {
        requestState.files = [];
    }
};

export default requestState;