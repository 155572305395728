import React, { useEffect, useState } from 'react'
import { Modal } from 'react-bootstrap'
import { updateBillingInfo } from '../../api/payment';
import { useForm } from 'react-hook-form';
import { showNotifications } from '../../helpers/toaster';

interface EditBillingProps {
    handleEditClose: () => void;
    editShow: boolean;
    setEditShow: (type: boolean) => void;
    billingInfo: any;
}
const UpdateBilling = ({ editShow, handleEditClose, setEditShow, billingInfo }: EditBillingProps) => {
 
    const [billingId, setBillingId] = useState("");
    const [billingAddress1, setBillingAddress1] = useState("");
    const [billingAddress2, setBillingAddress2] = useState("");
    const [billingCity, setBillingCity] = useState("");
    const [billingState, setBillingState] = useState("");
    const [billingZIPCode, setBillingZIPCode] = useState("");
    const {
        register,
        handleSubmit,
        setValue,
      } = useForm();

    useEffect(() => {
        setBillingId(billingInfo && billingInfo.billingID);
        setBillingAddress1(billingInfo && billingInfo.billingAddress1);
        setBillingAddress2(billingInfo && billingInfo.billingAddress2);
        setBillingCity(billingInfo && billingInfo.billingCity);
        setBillingState(billingInfo && billingInfo.billingState);
        setBillingZIPCode(billingInfo && billingInfo.billingZIPCode);
    }, [billingInfo]);

    const billingUpdate = () => {
        let billing = {
            "billingID": billingId,
            "billingAddress1": billingAddress1,
            "billingAddress2": billingAddress2,
            "billingCity": billingCity,
            "billingState": billingState,
            "billingZIPCode": billingZIPCode,
        }
        updateBillingInfo(billing).then((data) => {
            setEditShow(false);
            showNotifications("success",data.message);
        })
    }

    let onSubmit = () => {
        billingUpdate()
    }

    return (
        <>
            <Modal show={editShow} onHide={handleEditClose}>
                <form onSubmit={handleSubmit(onSubmit)} className="addContactForm">
                    <h1>Update Billing Information</h1>
                    <div style={{ padding: "0px 30px" }}>
                        <div className="registrationInput">
                            <label>Address One</label>
                            <input type="text" {...register("billingAddress1", { required: false })} value={billingAddress1} onChange={(e) => setBillingAddress1(e.target.value)} placeholder='Address One' />
                        </div>
                        <div className="registrationInput">
                            <label>Address Two</label>
                            <input type="text" {...register("billingAddress2", { required: false })} value={billingAddress2} onChange={(e) => setBillingAddress2(e.target.value)} placeholder='Address Two' />
                        </div>
                        <div className="registrationInput">
                            <label>City</label>
                            <input type="text" {...register("billingCity", { required: false })} value={billingCity} onChange={(e) => setBillingCity(e.target.value)} placeholder='City' />
                        </div>
                        <div className="registrationInput">
                            <label>State/Provience</label>
                            <input type="text" {...register("billingState", { required: false })} value={billingState} onChange={(e) => setBillingState(e.target.value)} placeholder='State/Provience' />
                        </div>
                        <div className="registrationInput">
                            <label>Zip Code</label>
                            <input type="text" {...register("billingZIPCode", { required: false })} value={billingZIPCode} onChange={(e) => setBillingZIPCode(e.target.value)} placeholder='Zip Code' />
                        </div>
                    </div>
                    <div className="editContactBtn mt-4">
                        <button type='button' className='back' onClick={handleEditClose}>Back</button>
                        <button type='submit' className='save'>Update</button>
                    </div>
                </form>
            </Modal>
        </>
    )
}

export default UpdateBilling