import { get, put, verifyGet } from './base-api';

// single sent file
export const getSingleSentFile = (sentId: string) => {
    return get(`/sendFiles/${sentId}`);
};

// sent file update
export const editSentFile = (sentId: string, sendFileObj: {}) => {
    return put(`/sendFiles/${sentId}`, sendFileObj);
};

// request verify OTP code
export const requestOtpCode = ( jwt: string) => {
    return verifyGet(`/requests/send-code`, jwt);
}; 
// send verify OTP code
export const sendOtpCode = ( jwt: string) => {
    return verifyGet(`/sendFiles/send-code`, jwt);
}; 
