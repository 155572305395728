import React, { useState, useEffect } from 'react'
import { Col, Container, Row, Tab, Tabs } from 'react-bootstrap';
// google font awesome
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCheck } from '@fortawesome/free-solid-svg-icons';
import Button from '../../styleComponent/Button/Button';
import { Link, useNavigate } from 'react-router-dom';
import { ENTERPRISE_FORM } from "../../config";
import { PackageInterface } from '../CommonInterface/CommonInterface';
import { getAllPackage } from '../../api/package';
import { convertGB, getCookieValue, getPriceByName, setCookie } from '../CommonInterface/CommonFunction';


const Price = () => {
    const navigate = useNavigate();
    const storedPaymentPlan = JSON.parse(getCookieValue("choosePlan") || '{}');
    const [billingType, setBillingType] = useState("Monthly");
    const [packagePrice, setPackagePrice] = useState<PackageInterface[] | undefined>();


    useEffect(() => {
        getAllPackage().then((data) => {
            setPackagePrice(data.packages);
        })
    }, []);

    const choosePlan = (plan: string) => {
        if (storedPaymentPlan) {
            storedPaymentPlan.price = plan;
            storedPaymentPlan.billingType = billingType;
            setCookie('choosePlan', JSON.stringify(storedPaymentPlan));
        }
        return navigate(`/registration`);
    }


    return (
        <>
            <section id='pricing' className="priceTable">
                <Container>
                    <Row>
                        <Col md={12}>
                            <div className="priceHeading">
                                <h1>Choose your Docoteam Pricing Plan <br /> Try it free for 7 days</h1>
                            </div>
                        </Col>
                        <div className="priceTableBox">
                            <Tabs
                                defaultActiveKey="monthly"
                                id="uncontrolled-tab-example"
                                className="priceTab"
                                onSelect={(key: string | null) => {
                                    if (key) {
                                        setBillingType(key === 'monthly' ? 'Monthly' : 'Annually');
                                    }
                                }}
                            >
                                <Tab className='monthly' eventKey="monthly" title="Monthly">
                                    <Row>
                                        <Col md={4}>
                                            <div className="priceBox">
                                                <div className="packageName">
                                                    <h1>Business <br />
                                                        Standard</h1>

                                                </div>
                                                <div className="packagePrice">
                                                    <h1><span className='icon'>$</span>{getPriceByName(packagePrice, 'STANDARD', 0, 1)}<span className='currency'>USD</span></h1>
                                                    <p>/User/Month Billed Monthly</p>
                                                </div>
                                                <div className="packageInfo">
                                                    <p><FontAwesomeIcon icon={faCheck} />Unlimited* secure file transfers </p>
                                                    <p><FontAwesomeIcon icon={faCheck} />File share and request tracking </p>
                                                    <p><FontAwesomeIcon icon={faCheck} />Auto reminders </p>
                                                    <p><FontAwesomeIcon icon={faCheck} />Custom Templates</p>
                                                    <p><FontAwesomeIcon icon={faCheck} />App integrations </p>
                                                    <p><FontAwesomeIcon icon={faCheck} />Custom branding</p>
                                                    <p><FontAwesomeIcon icon={faCheck} />2-Factor Authentication</p>
                                                    <p><FontAwesomeIcon icon={faCheck} />Standard Support</p>
                                                    <p><FontAwesomeIcon icon={faCheck} /> <span>{packagePrice && convertGB(packagePrice[1].totalStorage)} GB</span> Storage</p>
                                                    <div className="priceStart">
                                                        <Button onClick={() => choosePlan("STANDARD")} customBackgroundColor="#F94A29" customBorderColor="#F94A29" customTextColor="#fff">Get Started</Button>
                                                    </div>
                                                </div>
                                            </div>
                                        </Col>
                                        <Col md={4}>
                                            <div className="priceBox RecommendedBox">
                                                <div className="recommendLine">
                                                    <p>Recommended</p>
                                                </div>
                                                <div className="packageName">
                                                    <h1>Business  <br />
                                                        Plus</h1>

                                                </div>
                                                <div className="packagePrice">
                                                    <h1><span className='icon'>$</span>{getPriceByName(packagePrice, 'PLUS', 0, 1)}<span className='currency'>USD</span></h1>
                                                    <p>/User/Month Billed Monthly</p>
                                                </div>
                                                <div className="packageInfo">
                                                    <p><FontAwesomeIcon icon={faCheck} />Unlimited* secure file transfers </p>
                                                    <p><FontAwesomeIcon icon={faCheck} />File share and request tracking </p>
                                                    <p><FontAwesomeIcon icon={faCheck} />Auto reminders </p>
                                                    <p><FontAwesomeIcon icon={faCheck} />Custom Templates</p>
                                                    <p><FontAwesomeIcon icon={faCheck} />App integrations </p>
                                                    <p><FontAwesomeIcon icon={faCheck} />Custom branding</p>
                                                    <p><FontAwesomeIcon icon={faCheck} />2-Factor Authentication</p>
                                                    <p><FontAwesomeIcon icon={faCheck} />Standard Support</p>
                                                    <p><FontAwesomeIcon icon={faCheck} /> <span>{packagePrice && convertGB(packagePrice[2].totalStorage)} GB</span> Storage</p>
                                                    <Button onClick={() => choosePlan("PLUS")} customBackgroundColor="#F94A29" customBorderColor="#F94A29" customTextColor="#fff">Get Started</Button>
                                                </div>
                                            </div>
                                        </Col>
                                        <Col md={4}>
                                            <div className="priceBox">
                                                <div className="packageName">
                                                    <h1>Enterprise</h1>
                                                </div>
                                                <div className="enterprisePackage">
                                                    <h2> Contact Sales</h2>
                                                </div>
                                                <div className="packageLow">
                                                    <p><FontAwesomeIcon icon={faCheck} />All features of lower tiers</p>
                                                    <p><FontAwesomeIcon icon={faCheck} />File share and request tracking </p>
                                                    <p><FontAwesomeIcon icon={faCheck} />Custom space requirements </p>
                                                    <p><FontAwesomeIcon icon={faCheck} />Advanced security controls </p>
                                                    <p><FontAwesomeIcon icon={faCheck} />Employee management </p>
                                                    <p><FontAwesomeIcon icon={faCheck} />Enhanced 24/hr support</p>
                                                </div>
                                                <div className='priceStart'>
                                                    <Link className='googleForm' target="_blank" to={`${ENTERPRISE_FORM}`}>Contact us</Link>
                                                </div>
                                            </div>
                                        </Col>
                                    </Row>
                                </Tab>
                                <Tab className='yearly' eventKey="yearly" title="Yearly">
                                    <Row>
                                        <Col md={4}>
                                            <div className="priceBox">
                                                <div className="packageName">
                                                    <h1>Business <br />
                                                        Standard</h1>

                                                </div>
                                                <div className="packagePrice">
                                                    <h1><span className='icon'>$</span>{getPriceByName(packagePrice, 'STANDARD', 5, 1)}<span className='currency'>USD</span></h1>
                                                    <p>/User/Month Billed Annually</p>
                                                </div>
                                                <div className="packageInfo">
                                                    <p><FontAwesomeIcon icon={faCheck} />Unlimited* secure file transfers </p>
                                                    <p><FontAwesomeIcon icon={faCheck} />File share and request tracking </p>
                                                    <p><FontAwesomeIcon icon={faCheck} />Auto reminders </p>
                                                    <p><FontAwesomeIcon icon={faCheck} />Custom Templates</p>
                                                    <p><FontAwesomeIcon icon={faCheck} />App integrations </p>
                                                    <p><FontAwesomeIcon icon={faCheck} />Custom branding</p>
                                                    <p><FontAwesomeIcon icon={faCheck} />2-Factor Authentication</p>
                                                    <p><FontAwesomeIcon icon={faCheck} />Standard Support</p>
                                                    <p><FontAwesomeIcon icon={faCheck} /> <span>{packagePrice && convertGB(packagePrice[1].totalStorage)} GB</span> Storage</p>
                                                    <div className="priceStart">
                                                        <Button onClick={() => choosePlan("STANDARD")} customBackgroundColor="#F94A29" customBorderColor="#F94A29" customTextColor="#fff">Get Started</Button>
                                                    </div>
                                                </div>
                                            </div>
                                        </Col>
                                        <Col md={4}>
                                            <div className="priceBox RecommendedBox">
                                                <div className="recommendLine">
                                                    <p>Recommended</p>
                                                </div>
                                                <div className="packageName">
                                                    <h1>Business  <br />
                                                        Plus</h1>
                                                </div>
                                                <div className="packagePrice">
                                                    <h1><span className='icon'>$</span>{getPriceByName(packagePrice, 'PLUS', 5, 1)}<span className='currency'>USD</span></h1>
                                                    <p>/User/Month Billed Annually</p>
                                                </div>
                                                <div className="packageInfo">
                                                    <p><FontAwesomeIcon icon={faCheck} />Unlimited* secure file transfers </p>
                                                    <p><FontAwesomeIcon icon={faCheck} />File share and request tracking </p>
                                                    <p><FontAwesomeIcon icon={faCheck} />Auto reminders </p>
                                                    <p><FontAwesomeIcon icon={faCheck} />Custom Templates</p>
                                                    <p><FontAwesomeIcon icon={faCheck} />App integrations </p>
                                                    <p><FontAwesomeIcon icon={faCheck} />Custom branding</p>
                                                    <p><FontAwesomeIcon icon={faCheck} />2-Factor Authentication</p>
                                                    <p><FontAwesomeIcon icon={faCheck} />Standard Support</p>
                                                    <p><FontAwesomeIcon icon={faCheck} /> <span>{packagePrice && convertGB(packagePrice[2].totalStorage)} GB</span> Storage</p>
                                                    <Button onClick={() => choosePlan("PLUS")} customBackgroundColor="#F94A29" customBorderColor="#F94A29" customTextColor="#fff">Get Started</Button>
                                                </div>
                                            </div>
                                        </Col>
                                        <Col md={4}>
                                            <div className="priceBox">
                                                <div className="packageName">
                                                    <h1>Enterprise</h1>

                                                </div>
                                                <div className="enterprisePackage">
                                                    <h2> Contact Sales</h2>
                                                </div>
                                                <div className="packageLow">
                                                    <p><FontAwesomeIcon icon={faCheck} />All features of lower tiers</p>
                                                    <p><FontAwesomeIcon icon={faCheck} />File share and request tracking </p>
                                                    <p><FontAwesomeIcon icon={faCheck} />Custom space requirements </p>
                                                    <p><FontAwesomeIcon icon={faCheck} />Advanced security controls </p>
                                                    <p><FontAwesomeIcon icon={faCheck} />Employee management </p>
                                                    <p><FontAwesomeIcon icon={faCheck} />Enhanced 24/hr support</p>
                                                </div>
                                                <div className='priceStart'>
                                                    <Link className='googleForm' target="_blank" to={`${ENTERPRISE_FORM}`}>Contact us</Link>
                                                </div>
                                            </div>
                                        </Col>
                                    </Row>
                                </Tab>

                            </Tabs>
                        </div>

                        <Col md={12}>
                            <div className="text-center learnMoreBtn">
                                <Link to="/choose-plan">Learn more about Pricing</Link>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </section>
        </>
    )
}

export default Price