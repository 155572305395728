import React from 'react'
import { Container, Navbar, Nav, Form } from 'react-bootstrap';
import { useNavigate, NavLink } from 'react-router-dom';
import './Header.css';
import Button from '../../../styleComponent/Button/Button';
import logo from "../../../assets/logo/clearLogo.png";
import { getCookieValue, setCookie } from '../../CommonInterface/CommonFunction';

const Header = () => {
  const navigate = useNavigate();
  const loginAuth = JSON.parse(getCookieValue('docoteam') || '{}');
  const storedPaymentPlan = JSON.parse(getCookieValue('choosePlan') || '{}');
  const choosePlan = (plan: string) => {
    if (storedPaymentPlan) {
      storedPaymentPlan.price = plan;
      storedPaymentPlan.billingType = "Monthly";
      setCookie('choosePlan', JSON.stringify(storedPaymentPlan));
    }
    return navigate(`/registration`);
  }

  return (
    <>
      <Navbar collapseOnSelect expand="lg" fixed='top' className='header'>
        <Container>
          <NavLink to="/" className='logo navbar-brand'>
            <img src={logo} alt="" width="210px" />
          </NavLink>
          <Navbar.Toggle aria-controls="responsive-navbar-nav" />
          <Navbar.Collapse id="responsive-navbar-nav">
            <Nav className="m-auto headerItem">
              <NavLink className={({ isActive }) => isActive ? 'nav-link activeNavbar' : 'nav-link'} to="/why-docoteam">Why Docoteam?</NavLink>
              <NavLink className={({ isActive }) => isActive ? 'nav-link activeNavbar' : 'nav-link'} to="/features">Features</NavLink>
              <NavLink className={({ isActive }) => isActive ? 'nav-link activeNavbar' : 'nav-link'} to="/pricing">Pricing</NavLink>
              <NavLink className={({ isActive }) => isActive ? 'nav-link activeNavbar' : 'nav-link'} to="/security">Security</NavLink>
              <NavLink className={({ isActive }) => isActive ? 'nav-link activeNavbar' : 'nav-link'} to="/contact">Contact Us</NavLink>
            </Nav>
            {loginAuth.jwt ? <>
                <Form className="d-flex headerBtn">
                  <NavLink to="/dashboard"><Button className='registerBtn' customBackgroundColor="#F94A29" customBorderColor="#F94A29" customTextColor="#fff">Dashboard</Button></NavLink>
                </Form>
              </>: <>
                <Form className="d-flex headerBtn">
                  <NavLink to="/registration"><Button onClick={() => choosePlan("FREE")} className='registerBtn' customBackgroundColor="#F94A29" customBorderColor="#F94A29" customTextColor="#fff">Free Trial</Button></NavLink>
                  <NavLink to="/login"><Button className='loginBtn' customBackgroundColor="#fff" customBorderColor="#F94A29" customTextColor="#F94A29">Log In</Button> </NavLink>
                </Form>
              </>}
          </Navbar.Collapse>
        </Container>
      </Navbar>

    </>
  )
}

export default Header