import React from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import securityImage from '../../assets/banner/securityBanner.png';

const SecurityBanner = () => {
    return (
        <section className="securitySection">
            <Container>
                <Row>
                    <Col md={7} className='d-flex align-items-center'>
                        <div className="securityBannerContent">
                            <h2>SECURITY AT DOCOTEAM</h2>
                            <h1>Enterprise-grade data protection</h1>
                            <p>Trust Docoteam to keep your data secure and meet your compliance requirements.</p>
                        </div>
                    </Col>
                    <Col md={5}>
                        <div className="securityBannerImage">
                            <img src={securityImage} alt="" />
                        </div>
                    </Col>

                </Row>
            </Container>
        </section>
    )
}

export default SecurityBanner