import React, { useState, useEffect } from 'react';
import './Email.css'
import { Container } from 'react-bootstrap';
import { useSearchParams, useNavigate } from 'react-router-dom';
import { verifyCode } from '../../api/auth';
import VerificationInput from 'react-verification-input';
import { decodeJwt, getCookieValue, setCookie } from '../CommonInterface/CommonFunction';
import { requestOtpCode } from '../../api/sent';
import { showNotifications } from '../../helpers/toaster';
import { ToastContainer } from 'react-toastify';
import { reSendCode } from '../../api/job';

const EmailVerification = () => {

  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  searchParams.get("accessToken")
  const token = searchParams.get("accessToken") || '{}';
  const [codeValue, setCodeValue] = useState("")
  const [codeError, setCodeError] = useState("")
  const [requestIdValue, setRequestIdValue] = useState("")
  const requestOtpValue = JSON.parse(getCookieValue('requestOtp') || '{}');
  const [requestId, setRequestId] = useState("");
  const [externalData, setExternalData] = useState({});


  const validateCheck = () => {
    verifyCode(codeValue, token).then((data) => {
      if (data.statusCode !== 200) {
        setCodeError("Please insert valid code")
      }
      else {
        setCodeError("")
        setRequestIdValue(data.body.request.requestId);
        setCookie('requestId', JSON.stringify({ token, requestId: data.body.request.requestId }));
        return navigate('/upload')
      }
    })
  }

  const handleVerificationCodeChange = (code: string) => {
    setCodeValue(code)
  };

  useEffect(() => {
    if (requestIdValue) {
      return navigate('/download')
    }
  }, [requestIdValue])

  useEffect(() => {
    if (!requestOtpValue.requestOtp) {
      requestOtpCode(token).then((data: any) => {
        if (data.statusCode !== 200) {
          showNotifications('error', data.message);
        }
        else {
          setCookie('requestOtp', JSON.stringify({ requestOtp: true }));
        }
      })
    }
    const decodedToken = decodeJwt(token);
    setRequestId(decodedToken.requestId);
    setExternalData(decodedToken.externalUser);
  }, [])

  // re-send code
  const reSend = () => {
    let reSendInfo = {
      "requestType": "request-file",
      "processableID": requestId,
      "data": externalData
    }
    reSendCode(reSendInfo).then((data: any) => {
      if (data.statusCode !== 200) {
        showNotifications('error', data.message);
      }
      else {
        showNotifications('success', data.message);
      }
    })
  }

  return (
    <>
      <Container className="height-100 d-flex justify-content-center align-items-center">
        <ToastContainer />
        <div className="position-relative">
          <div className="EmailCard text-center">
            <h1>Docoteam</h1>
            {requestOtpValue && requestOtpValue ? <>
              <h6>Please enter OTP code</h6>
              <h3>Please check your phone for the code</h3>
            </>
              : <><h6>Please enter e-mail code</h6></>}

            <div id="otp" className="verificationCode inputs d-flex flex-row justify-content-center mt-3">
              <VerificationInput
                onChange={handleVerificationCodeChange}
                length={6}
                validChars="0-9"
                inputProps={{ inputMode: "numeric" }}
                placeholder=""
                classNames={{
                  container: "container",
                  character: "character",
                  characterInactive: "character--inactive",
                  characterSelected: "character--selected",
                }}
              />
            </div>
            {codeError && codeError.length ? <p className='errMsg text-center'>{codeError}</p> : ''}
            <span className='resend' onClick={reSend}>Resend Code</span>
            <div className="mt-2"> <button className='validate' onClick={validateCheck}>Validate</button></div> </div>
        </div>
      </Container>
    </>
  )
}

export default EmailVerification