import JSZip from 'jszip';
import axios from 'axios';
import { saveAs } from 'file-saver';

// Polyfill for the Blob constructor
import 'blob-polyfill';

function getFilenameFromUrl(url: string): string {
  const decodedUrl = decodeURIComponent(url);
  const urlParams = new URLSearchParams(decodedUrl);
  const filenameParam = urlParams.get('response-content-disposition');
  
  if (filenameParam) {
    const filenameMatch = filenameParam.match(/filename=([^&]*)/);
    if (filenameMatch) {
      return filenameMatch[1];
    }
  }

  // If the filename is not found in the response-content-disposition, fallback to extracting from the URL path
  return decodeURIComponent(url.substring(url.lastIndexOf('/') + 1)).split('?')[0]; // '?' remove query parameters;
}


/**
 * Downloads files from the given presigned URLs and saves them as a ZIP file.
 * @param urls - An array of URLs to download files from.
 * @param zipFileName - The name to give to the resulting ZIP file.
 * @returns A promise that resolves when the ZIP file has been created and downloaded.
 * 
 * @throws If any of the URLs fail to download or if an error occurs during zip file creation or download.
 */
export async function downloadFilesAsZip(urls: string[], zipFileName: string, setSpinLoading: any): Promise<void> {
  const zip = new JSZip();
  try {
    const promises = urls.map(async (url) => {
      const response = await axios.get(url, { responseType: 'arraybuffer' });
      if (response.status !== 200) {
        throw new Error('Failed to download file');
      }
      const filename = getFilenameFromUrl(url);
      zip.file(filename, response.data);
    });

    await Promise.all(promises);
    const content = await zip.generateAsync({ type: 'blob' });
    const zipBlob = new Blob([content]);

    saveAs(zipBlob, `${zipFileName}.zip`);
  } catch (error: any) {
    console.error(error);
    // TODO: replace this with your own alert component.
    alert('Failed to download files as zip. Please try again.');
  } finally {
    setSpinLoading(false)
  }
}
