import React, { useState, useEffect } from 'react';
import Step from '../CommonCompoment/Step/Step';
import { useNavigate } from 'react-router-dom';
import "./AccountRecovery.css";
import { Col, Container, Row } from 'react-bootstrap';
import { checkMail, codeVerify, forgetPasswordCheck, updatePassword } from '../../api/auth';
import VerificationInput from 'react-verification-input';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons';
import { showNotifications } from '../../helpers/toaster';
import { ToastContainer } from 'react-toastify';
import { checkSecurity, getSecurity, loginOtpCheck } from '../../api/user';
import SignUpNavbar from '../CommonCompoment/SignUpNavbar/SignUpNavbar';
import { UAParser } from 'ua-parser-js';
import { decodeJwt, getCookieValue } from '../CommonInterface/CommonFunction';
import axios from 'axios';
import { reSendCode } from '../../api/job';

const AccountRecovery = () => {

    const token = JSON.parse(getCookieValue('docoteam') || '{}');
    const { user } = token;
    const navigate = useNavigate();
    const [activeStep, setActiveStep] = React.useState(1);
    const [email, setEmail] = useState(user && user.email ? user.email : "");
    const [emailValid, setEmailValid] = useState(true);
    const [emailExit, setEmailExit] = useState("");
    const [mailCheck, setMailCheck] = useState(false);
    const [emailCheck, setEmailCheck] = useState(true);
    const [enforceMFA, setEnforceMFA] = useState(false);
    const [verifyGetCode, setVerifyGetCode] = useState<number>(0);
    const [passwordMatch, setPasswordMatch] = useState<boolean | null>(null);
    const [passwordValid, setPasswordValid] = useState<string[]>([]);
    const [togglePassword, setTogglePassword] = useState(false);
    const [toggleType, setToggleType] = useState("password");
    const [phoneCheck, setPhoneCheck] = useState(false);
    const [phoneNumber, setPhoneNumber] = useState("");
    const [securityQus1, setSecurityQus1] = useState("");
    const [securityQus2, setSecurityQus2] = useState("");
    const [securityAns1, setSecurityAns1] = useState("");
    const [securityAns2, setSecurityAns2] = useState("");
    const [browserInfo, setBrowserInfo] = useState<string>('');
    const [osName, setOsName] = useState<string>('');
    const [locationName, setLocationName] = useState<string[]>([]);
    const [latitude, setLatitude] = useState<number>();
    const [longitude, setLongitude] = useState<number>();
    const [userId, setUserId] = useState("");
    const [externalData, setExternalData] = useState({});
    const [codeValue, setCodeValue] = useState("");
    const steps = [
        {
            label: 'Step 1',
            step: 1,
            textStep: 1,
        },
        {
            label: 'Step 2',
            step: 2,
            textStep: 2,
        },
        {
            label: 'Step 3',
            step: 3,
            textStep: 3,
        },
        {
            label: 'Finish',
            step: 4,
            textStep: 4,
        }
    ];
    useEffect(() => {
        getSecurity(email).then((data) => {
            if (data.user) {
                setSecurityQus1(data.user.securityQus1)
                setSecurityQus2(data.user.securityQus2)
            }
        })
    }, [activeStep])

    useEffect(() => {

        const userAgent = window.navigator.userAgent;
        const parser = new UAParser(userAgent);
        const result = parser.getResult();
        setBrowserInfo(`${result.browser.name}`);
        if (userAgent.indexOf('Win') !== -1) {
            setOsName('Windows');
        } else if (userAgent.indexOf('Mac') !== -1) {
            setOsName('MacOS');
        } else if (userAgent.indexOf('Linux') !== -1) {
            setOsName('Linux');
        } else if (userAgent.indexOf('Android') !== -1) {
            setOsName('Android');
        } else if (userAgent.indexOf('iOS') !== -1) {
            setOsName('iOS');
        } else {
            setOsName('Unknown'); // Fallback for unrecognized OS
        }

        // location name
        navigator.geolocation.getCurrentPosition((position) => {
            const latitude = position.coords.latitude;
            const longitude = position.coords.longitude;
            setLatitude(latitude);
            setLongitude(longitude);
            const apiKey = 'AIzaSyB1xd6-NIxRPU0UYej-blqi3_eVlccWOQI';
            const apiUrl = `https://maps.googleapis.com/maps/api/geocode/json?latlng=${latitude},${longitude}&key=${apiKey}`;
            axios
                .get(apiUrl)
                .then((response) => {
                    const { results } = response.data;
                    if (results && results.length > 0) {
                        const shortNameArray = [];
                        for (const component of results[0].address_components) {
                            if (
                                component.types.includes("country") ||
                                component.types.includes("administrative_area_level_1") ||
                                component.types.includes("administrative_area_level_2")
                            ) {
                                shortNameArray.push(component.short_name);
                            }
                        }
                        setLocationName(shortNameArray);
                    } else {
                        setLocationName(['Location not found']);
                    }
                })
                .catch((error) => {
                    console.error('Error fetching location data', error);
                });
        });

        if (token.jwt) {
            const decodedToken = decodeJwt(token.jwt);
            setUserId(decodedToken.userId);
            setExternalData(decodedToken.externalUser);
        }
    }, [])
    // forget password
    const emailHandle = (event: React.ChangeEvent<HTMLInputElement>) => {
        setEmail(event.target.value);
        const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        setEmailValid(regex.test(event.target.value));
        setEmailExit("")
        if (regex.test(event.target.value) === true) {
            checkMail(event.target.value).then((data) => {
                if (data.statusCode !== 200) {
                    setEmailExit("")
                    setMailCheck(true)
                }
                else {
                    setEmailExit("This e-mail is not registration")
                }
            })
        }
    }
    // email check
    const emailValidApi = () => {
        const delimiter = ', ';
        const location = locationName.join(delimiter);
        let forgetPass = {
            "email": true,
            "os": osName,
            "browser": browserInfo,
            "location": location
        }
        checkMail(email).then((data) => {
            if (data.status === true) {
                setEmailExit("")
                setMailCheck(true)
                setEmailCheck(false)
                forgetPasswordCheck(email, forgetPass).then((data) => {
                    if (data.statusCode !== 200) {
                        showNotifications('error', data.message);
                    }
                    else {
                        showNotifications('success', data.message);
                        if (activeStep === 1) {
                            setActiveStep(activeStep + 1);
                        }
                    }
                })
            }
            else {
                setEmailExit("This e-mail is not registration")
            }
        })

        if (email.length === 0) {
            setEmailExit("Enter your e-mail address")
        }
    }

    // fancy input box line
    const handleVerificationCodeChange = (code: string) => {
        setVerifyGetCode(parseInt(code))
    };


    // password check
    const [formData, setFormData] = useState({
        password: '',
        confirmPassword: '',
    });

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value,
        });
        if (name === 'confirmPassword') {
            setPasswordMatch(formData.password === value);
        } else if (name === 'password' && passwordMatch === false) {
            setPasswordMatch(null);
        }
    };


    // handlePasswordBlur
    const handlePasswordBlur = (event: React.ChangeEvent<HTMLInputElement>) => {
        const passwordValid: string[] = [];
        if (event.target.value.length < 8) {
            passwordValid.push("Password must be at least 8 characters");
        }
        if (!/\d/.test(event.target.value)) {
            passwordValid.push("Password must contain at least one number");
        }
        if (!/[a-z]/.test(event.target.value)) {
            passwordValid.push("Password must contain at least one lowercase letter");
        }
        if (!/[A-Z]/.test(event.target.value)) {
            passwordValid.push("Password must contain at least one uppercase letter");
        }
        setPasswordValid(passwordValid);
    }

    const showPass = () => {
        setTogglePassword(!togglePassword)
        setToggleType("password")
    }

    const hidePass = () => {
        setTogglePassword(!togglePassword)
        setToggleType("")
    }

    const phoneHandle = (event: React.ChangeEvent<HTMLInputElement>) => {
        setPhoneNumber(event.target.value);
    }


    function codeValid(codeInfo: {}) {
        codeVerify(codeInfo).then((data) => {
            if (data.statusCode !== 200) {
                showNotifications('error', data.message);
            }
            else {
                showNotifications('success', data.message);
                if (data.enforceMFA === true) {
                    setActiveStep(activeStep + 1);
                }
                else {
                    setActiveStep(activeStep + 2);
                }
            }
        })
    }

    const codeCheck = () => {
        if (phoneCheck === false) {
            let codeInfo = {
                "identifier": email,
                "code": verifyGetCode
            }
            codeValid(codeInfo)
        }
        else {
            let codeInfo = {
                "identifier": phoneNumber,
                "code": verifyGetCode
            }
            codeValid(codeInfo)
        }
    }



    const confirmCheck = () => {
        let newPass = {
            "email": email,
            "password": formData.confirmPassword
        }
        if (passwordMatch) {
            updatePassword(newPass).then((data) => {
                if (data.statusCode !== 200) {
                    showNotifications('error', data.message);
                }
                else {
                    showNotifications('success', data.message);
                    return navigate("/login");
                }
            })
        }
        else {
            showNotifications('error', "Incorrect Password !!");
        }
    }

    const phoneOption = () => {
        setPhoneCheck(true);
        setActiveStep(1);
        setEmailValid(true);
    }

    const emailOption = () => {
        setPhoneCheck(false);
        setActiveStep(1);
    }

    const phoneValidValidApi = () => {
        const delimiter = ', ';
        const location = locationName.join(delimiter);
        let forgetPass = {
            "phone": true,
            "os": osName,
            "browser": browserInfo,
            "location": location
        }
        forgetPasswordCheck(phoneNumber, forgetPass).then((data) => {
            if (data.statusCode !== 200) {
                showNotifications('error', data.message);
            }
            else {
                showNotifications('success', data.message);
                if (activeStep === 1) {
                    setActiveStep(activeStep + 1);
                }
            }
        })
    }

    // re-send code
    const reSend = () => {
        const delimiter = ', ';
        const location = locationName.join(delimiter);
        let reSendInfo = {
            "requestType": "change-password",
            "processableID": email,
            "data": {
                "os": osName,
                "browser": browserInfo,
                "accessLocation": location,
            }
        }
        reSendCode(reSendInfo).then((data: any) => {
            if (data.statusCode !== 200) {
                showNotifications('error', data.message);
            }
            else {
                showNotifications('success', data.message);
            }
        })
    }

    // re-send code phone
    const reSendPhone = () => {
        const delimiter = ', ';
        const location = locationName.join(delimiter);
        let reSendInfo = {
            "requestType": "change-password",
            "processableID": phoneNumber,
            "data": {
                "os": osName,
                "browser": browserInfo,
                "accessLocation": location,
                "phone": true
            }
        }
        reSendCode(reSendInfo).then((data: any) => {
            if (data.statusCode !== 200) {
                showNotifications('error', data.message);
            }
            else {
                showNotifications('success', data.message);
            }
        })
    }

    const handleOtpCodeChange = (code: string) => {
        setCodeValue(code)
    };

    const validateCheck = () => {
        const delimiter = ', ';
        const location = locationName.join(delimiter);
        const loginObj: any = {
            "email": email,
            "otp": parseInt(codeValue),
            "otpType":  "MFA",
            "os": osName,
            "browser": browserInfo,
            "latitude": latitude,
            "longitude": longitude,
            "accessLocation": location
        }
        loginOtpCheck(loginObj).then((data) => {
            setActiveStep(activeStep + 1);
        })
    }


    return (
        <>
            <SignUpNavbar />
            <section className='registrationStep'>
                <ToastContainer />
                <Step activeStep={activeStep} setActiveStep={setActiveStep} steps={steps} />
                <Container>
                    <Row>
                        <Col md={12} className='d-flex justify-content-center'>
                            <div className="recoveryBox">
                                {phoneCheck ? <>
                                    {activeStep === 1 ? <>
                                        <div className="registrationInput">
                                            <label>Registered Phone Number</label>
                                            <input
                                                type="number"
                                                id="email"
                                                placeholder="Enter your Registered Phone Number"
                                                onChange={phoneHandle}
                                            />
                                        </div>
                                        <div className="recoveryBtn">
                                            <button onClick={phoneValidValidApi}>Next</button>
                                        </div>
                                        <div className="extraOption">
                                            <button onClick={emailOption}>Send code to Email Address?</button>
                                        </div>
                                    </> : <></>}
                                    {activeStep === 2 ? <>
                                        <div className="verificationCode">
                                            <h1>Enter the verification code sent to your Phone Number</h1>
                                            <VerificationInput
                                                onChange={handleVerificationCodeChange}
                                                length={6}
                                                validChars="0-9"
                                                inputProps={{ inputMode: "numeric" }}
                                                placeholder=""
                                                classNames={{
                                                    container: "container",
                                                    character: "character",
                                                    characterInactive: "character--inactive",
                                                    characterSelected: "character--selected",
                                                }}
                                            />
                                            <span onClick={reSendPhone}>Resend Code</span>
                                        </div>
                                        <div className="recoveryBtn">
                                            <button onClick={codeCheck}>Next</button>
                                        </div>
                                        <div className="anotherOption">
                                            <button onClick={phoneValidValidApi}>Didn't Receive Code? Send Again</button>
                                            <button onClick={emailOption}>Send code to Email Address?</button>
                                        </div>
                                    </> : <></>}
                                </> : <>
                                    {activeStep === 1 ? <>
                                        <div className="registrationInput">
                                            <label>Email Address</label>
                                            <input
                                                type="email"
                                                id="email"
                                                placeholder="Enter your Email"
                                                value={email}
                                                onChange={emailHandle}
                                            />
                                            {emailValid && emailValid === true ? "" : <p className='errMsg'>Please enter valid E-mail</p>}
                                            {emailExit && emailExit.length ? <p className='errMsg'>{emailExit}</p> : ""}
                                        </div>
                                        <div className="recoveryBtn">
                                            <button onClick={emailValidApi}>Next</button>
                                        </div>

                                        <div className="extraOption">
                                            <button onClick={phoneOption}>Send code to Phone Number?</button>
                                        </div>
                                    </> : <></>}
                                    {activeStep === 2 ? <>
                                        <div className="verificationCode">
                                            <h1>Enter the verification code sent to your Email Address</h1>
                                            <VerificationInput
                                                onChange={handleVerificationCodeChange}
                                                length={6}
                                                validChars="0-9"
                                                inputProps={{ inputMode: "numeric" }}
                                                placeholder=""
                                                classNames={{
                                                    container: "container",
                                                    character: "character",
                                                    characterInactive: "character--inactive",
                                                    characterSelected: "character--selected",
                                                }}
                                            />
                                            <span onClick={reSend}>Resend Code</span>
                                        </div>
                                        <div className="recoveryBtn">
                                            <button onClick={codeCheck}>Next</button>
                                        </div>
                                        <div className="anotherOption">
                                            <button onClick={emailValidApi}>Didn't Receive Code? Send Again</button>
                                            <button onClick={phoneOption}>Send code to Phone Number?</button>
                                        </div>
                                    </> : <></>}
                                </>}


                                {activeStep === 3 ? <>
                                    <div className='securityQus'>
                                        <h1>Please open your app to insert the code</h1>
                                        <div id="otp" className="verificationCode inputs d-flex flex-row justify-content-center mt-3 flex-column"> 
                                        <VerificationInput
                                            onChange={handleOtpCodeChange}
                                            length={6}
                                            validChars="0-9"
                                            inputProps={{ inputMode: "numeric" }}
                                            placeholder=""
                                            classNames={{
                                                container: "container",
                                                character: "character",
                                                characterInactive: "character--inactive",
                                                characterSelected: "character--selected",
                                            }}
                                        />
                                        {/* {codeError && codeError.length ? <p className='errMsg text-center'>{codeError}</p> : ''} */}
                                        <div className="recoveryBtn text-center">
                                            <button onClick={validateCheck}>Next</button>
                                        </div>
                                        </div>
                                    </div>
                                </> : <></>}
                                {activeStep === 4 ? <>
                                    <div className='passwordCheck'>
                                        <h1>Enter New Password</h1>
                                        <div>
                                            <label htmlFor="password" className='textColor'>Password:</label>
                                            <div className="loginInput">
                                                <input
                                                    type={toggleType}
                                                    id="password"
                                                    name="password"
                                                    onBlur={handlePasswordBlur}
                                                    value={formData.password}
                                                    onChange={handleChange}
                                                />
                                                {passwordValid.length > 0 &&
                                                    passwordValid.map((error, index) => (
                                                        <p key={index} className='errMsg'>
                                                            {error}
                                                        </p>
                                                    ))}
                                                {togglePassword ? <span onClick={() => showPass()}><FontAwesomeIcon icon={faEye} /> </span> : <span onClick={() => hidePass()}><FontAwesomeIcon icon={faEyeSlash} /></span>}
                                            </div>
                                        </div>
                                        <div className="mt-4">
                                            <label htmlFor="confirmPassword" className='textColor'>Confirm Password:</label>
                                            <div className="loginInput">
                                                <input
                                                    type={toggleType}
                                                    id="confirmPassword"
                                                    name="confirmPassword"
                                                    value={formData.confirmPassword}
                                                    onChange={handleChange}
                                                />

                                                {togglePassword ? <span onClick={() => showPass()}><FontAwesomeIcon icon={faEye} /> </span> : <span onClick={() => hidePass()}><FontAwesomeIcon icon={faEyeSlash} /></span>}
                                            </div>
                                            {passwordMatch === false && (
                                                <p className='errMsg'>Password and Confirm Password do not match.</p>
                                            )}
                                        </div>
                                        <div className="recoveryBtn text-center">
                                            <button onClick={confirmCheck}>Confirm</button>
                                        </div>
                                    </div>
                                </> : <></>}
                            </div>
                        </Col>
                    </Row>
                </Container>
            </section>
        </>
    )
}

export default AccountRecovery