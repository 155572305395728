import React from 'react'
import { Container, Row } from 'react-bootstrap';
interface ImageContentProps {
    styleClass: string;
    image: string;
    content: {
        title: React.ReactNode;
        description: string;
    };
}
const ContentImage = ({ image, content, styleClass }: ImageContentProps) => {
    return (
        <div className={`${styleClass}`}>
            <Container>
                <Row>
                <div className='lineBox'>
                    <div className="rightContent">
                        <h1>{content.title}</h1>
                        <p>{content.description}</p>
                    </div>
                    <div className="leftImage">
                        <img src={image} alt='content' />
                    </div>
                </div>
                </Row>
            </Container>
        </div>
    )
}

export default ContentImage