import React, { useState, useEffect } from 'react';
import DashboardNavbar from '../CommonCompoment/DashboardNavbar/DashboardNavbar';
import Loader from '../CommonCompoment/Loader/Loader';
import { Container, Row, Col } from 'react-bootstrap';
import ContactList from '../MyContact/ContactList';
import Recipient from '../MyContact/Recipient';
import CreateContact from '../MyContact/CreateContact';
import { RecipientInterface, RecipientUser, SentFileInterface, SentUPloadInterface, UserInterface } from '../CommonInterface/CommonInterface';
import { myContacts } from '../../api/contact';
import { fileRemove, getCookieValue, handleForbiddenError, removeCookie } from '../CommonInterface/CommonFunction';
import { useNavigate, useParams } from 'react-router-dom';
import TwoFA from './TwoFA';
import SentResult from './SentResult';
import { editSentFile } from '../../api/sent';
import { handleAttachmentUpload } from '../../helpers/fileUpload';
import { sendFileApi, sentCompleteUploadApi } from '../../api/auth';
import { Oval } from 'react-loader-spinner';
import globalState from '../../helpers/globalState';
import ProgressLine from '../CommonCompoment/ProgressBar/ProgressLine';

const SentContact = () => {
    const navigate = useNavigate();
    const [spinLoading, setSpinLoading] = useState<boolean>(false);
    const [activeSender, setActiveSender] = useState(false);
    const [spinNumberLoading, setSpinNumberLoading] = useState(false);
    const [userIds, setUserIds] = useState<string[]>([]);
    const [dateShredding, setDateShredding] = useState("");
    const [downloadTooltip, setDownloadTooltip] = useState(false);
    const [sentUploadURl, setSentUploadURl] = useState<SentUPloadInterface[]>([]);
    const [progressLoading, setProgressLoading] = useState<boolean>(false);
    const [shreddingDate, setShreddingDate] = useState<any>(new Date());
    const [count, setCount] = useState(0);
    const { id }: any = useParams();
    // contact
    const [contacts, setContacts] = useState<UserInterface[]>([]);
    const [recipientsValue, setRecipientsValue] = useState<RecipientUser[]>([]);
    const [errorContact, setErrorContact] = useState("");
    const [senderTypeValue, setSenderTypeValue] = useState<string>("individual");
    const [authValue, setAuthValue] = useState(false)
    const [authShow, setAuthShow] = useState(false);
    const [receiptShow, setReceiptShow] = useState(false);
    // send file 
    const [sentFile, setSentFile] = useState<SentFileInterface[]>([]);
    const [title, setTitle] = useState("");
    const [customMshHandle, setCustomMshHandle] = useState("");
    const [dueDate, setDueDate] = useState<any>("");


    const AuthModalClose = () => {
        setAuthShow(false)
    }

    //get my contacts 
    let getMyContacts = () => {
        myContacts(100, 1, "", "").then(data => {
            if (data.statusCode !== 200) {
                if (data.statusCode === 403) {
                    handleForbiddenError(navigate);
                }
                else {
                    setContacts([])
                }
            }
            else {
                setContacts(data.body.contacts);
            }
        })
    }

    //load my contacts on load
    useEffect(() => {
        getMyContacts();
        const sendObj = JSON.parse(localStorage.getItem("send") || '{}');
        const { files } = globalState;
        const sendFileObj = files;
        setTitle(sendObj.settings.title);
        setCustomMshHandle(sendObj.settings.customEmailMsg);
        setDueDate(sendObj.settings.dueDate);
        setSentFile(sendObj.files);
        setSentUploadURl(sendFileObj);
        if (sendObj.recipients) {
            setRecipientsValue(sendObj.recipients.users);
            setSenderTypeValue(sendObj.recipients.senderType);
        }
        if (sendObj.associatedUserIds) {
            setUserIds(sendObj.associatedUserIds);
        }
        setAuthValue(sendObj.settings.isMfaRequired);

    }, []);


    const senderTypeFunction = (type: string) => {
        setSenderTypeValue(type);
        setActiveSender(!activeSender);
        const existingDataString = localStorage.getItem('send');
        const existingData = existingDataString ? JSON.parse(existingDataString) : {};
        existingData.recipients = {
            ...existingData.recipients,
            senderType: type,
        };
        const jsonString = JSON.stringify(existingData);
        localStorage.setItem('send', jsonString);
    }
    // two fa
    const modalClose = () => {
        if (authValue === true) {
            setReceiptShow(false)
            setAuthShow(true)
        }
        else {
            setReceiptShow(false)
        }
    }
    // back send file upload
    const backToSent = () => {
        window.scrollTo({
            top: 0,
            behavior: "smooth"
        });
        if (id) {
            return navigate(`/sent/${id}`);
        }
        else {
            return navigate('/sent');
        }
    }
    // send file modal
    const sentModal = () => {
        window.scrollTo({
            top: 0,
            behavior: "smooth"
        });
        if (recipientsValue.length === 0) {
            setErrorContact("Please select one contact")
        }
        else {
            setErrorContact("")
        }
        if (recipientsValue.length > 0) {
            // setReceiptShow(true)
            if (authValue === true) {
                setAuthShow(true)
            }
            else {
                setReceiptShow(true)
            }
        }
    }

    function getFileFromLocalStorage(fileDataString: any): File | null {
        if (fileDataString) {
            const fileData = fileDataString;
            const base64String = fileData.base64;
            const byteCharacters = atob(base64String);
            const byteNumbers = new Array(byteCharacters.length);
            for (let i = 0; i < byteCharacters.length; i++) {
                byteNumbers[i] = byteCharacters.charCodeAt(i);
            }
            const byteArray = new Uint8Array(byteNumbers);
            const blob = new Blob([byteArray], { type: fileData.type });
            const file = new File([blob], fileData.name, { type: fileData.type });
            return file;
        } else {
            return null;
        }
    }

    // send file api call
    const sentConfirm = () => {
        window.scrollTo({
            top: 0,
            behavior: "smooth"
        });
        let sentFileObj: any = {
            "sendId": id,
            "files": sentFile,
            "settings": {
                "title": title,
                "customEmailMsg": customMshHandle,
                ...(dueDate && { "dueDate": dueDate }),
                "isMfaRequired": authValue,
                "shreddingDate": dateShredding
            },
            "recipients": {
                "users": recipientsValue,
                "senderType": senderTypeValue
            }
        }
        if (userIds.length > 0) {
            sentFileObj["associatedUserIds"] = userIds;
        }
        // backend change
        if (dateShredding.length === 0) {
            delete sentFileObj.settings.shreddingDate;
        }
        setSpinNumberLoading(true)
        setProgressLoading(true)
        if (id) {
            editSentFile(id, sentFileObj).then(async (data) => {
                setSpinNumberLoading(true);
                if (data.message === "Failed to Send SMS!") {
                    setSpinNumberLoading(false)
                    setProgressLoading(false)
                    setDownloadTooltip(true)
                }
                else {
                    const uploadLockIds = data.fileUploadLockIds;
                    for (let i = 0; i < sentUploadURl.length; i++) {
                        const uploadUrlId = uploadLockIds[sentUploadURl[i].fileUploadLockId];
                        let fileValue = getFileFromLocalStorage(sentUploadURl[i].file);
                        await handleAttachmentUpload(fileValue, `/files/uploadUrl/${uploadUrlId}`, i, (progress: any) => {
                            setSentFile((prevList: any) => {
                                const newList = [...prevList];
                                newList[i] = { ...newList[i], progress };
                                return newList;
                            });
                        });
                        await sentCompleteUploadApi(uploadUrlId)
                        setSentFile((prevList: any) => {
                            const newList = [...prevList];
                            newList[i] = { ...newList[i], status: 'uploaded' };
                            return newList;
                        });

                    }
                    setSpinNumberLoading(false);
                    setProgressLoading(false)
                    return navigate('/dashboard');
                }
            })
        }
        else {
            sendFileApi(sentFileObj).then(async (data) => {
                setSpinNumberLoading(true)
                setProgressLoading(true)
                if (data.message === "Failed to Send SMS!") {
                    setSpinNumberLoading(false)
                    setProgressLoading(false)
                    setDownloadTooltip(true)
                }
                else {
                    let progress = 0;
                    const uploadLockIds = data.body.fileUploadLockIds;
                    for (let i = 0; i < sentUploadURl.length; i++) {
                        const uploadUrlId = uploadLockIds[sentUploadURl[i].fileUploadLockId];
                        let fileValue = getFileFromLocalStorage(sentUploadURl[i].file);
                        await handleAttachmentUpload(fileValue, `/files/uploadUrl/${uploadUrlId}`, i, (progress: any) => {
                            setSentFile((prevList: any) => {
                                const newList = [...prevList];
                                newList[i] = { ...newList[i], progress };
                                return newList;
                            });
                        });
                        await sentCompleteUploadApi(uploadUrlId)
                        setSentFile((prevList: any) => {
                            const newList = [...prevList];
                            newList[i] = { ...newList[i], status: 'uploaded' };
                            return newList;
                        });
                    }
                    setSpinNumberLoading(false);
                    setProgressLoading(false)
                    return navigate('/dashboard');
                }
            })
        }
    }

    const fileRemove = (emailToRemove: string) => {
        const updatedRecipients: any = recipientsValue.filter(recipient => recipient.email !== emailToRemove);
        setRecipientsValue(updatedRecipients);
        let localStorageValue = JSON.parse(localStorage.getItem("send") || '{}');
        localStorageValue.recipients.users = updatedRecipients;
        localStorage.setItem('send', JSON.stringify(localStorageValue));
    };




    const handleKeyPress = (event: KeyboardEvent) => {
        if (event.key === 'Enter') {
            if (receiptShow === true) {
                sentConfirm();
            }
            else {
                sentModal();
            }
        }
    };

    useEffect(() => {
        document.body.addEventListener('keypress', handleKeyPress);
        return () => {
            document.body.removeEventListener('keypress', handleKeyPress);
        };
    }, [recipientsValue, authValue, senderTypeValue, userIds, id, receiptShow]);


    return (
        <>
            <DashboardNavbar />
            {spinLoading && <Loader />}
            {progressLoading && <ProgressLine attachmentList={sentFile} />}
            {spinNumberLoading && <Loader />}
            <section className='createRequest'>
                <Container fluid>
                    <Row>
                        <Col md={5}>
                            <ContactList contacts={contacts} senderTypeValue={senderTypeValue} setErrorContact={setErrorContact} recipientsValue={recipientsValue} setRecipientsValue={setRecipientsValue} />
                        </Col>
                        <Col md={4}>
                            <Recipient errorContact={errorContact} senderTypeValue={senderTypeValue} senderTypeFunction={senderTypeFunction} fileRemove={fileRemove} recipientsValue={recipientsValue} />
                        </Col>
                        <Col md={3}>
                            <CreateContact getMyContacts={getMyContacts} setSpinLoading={setSpinLoading} />
                        </Col>

                        <Col md={12}>
                            <div className="footerDashboard">
                                <div className="templateButton">
                                    <button className='backDashboard' onClick={backToSent}>Back</button>
                                    <button className='nextButton' onClick={sentModal}>Next</button>
                                </div>
                            </div>
                        </Col>
                    </Row>

                    {/* two FA */}
                    <TwoFA setAuthShow={setAuthShow} setReceiptShow={setReceiptShow} setRecipientsValue={setRecipientsValue} authShow={authShow} AuthModalClose={AuthModalClose} recipientsValue={recipientsValue} />
                    {/* send file modal */}
                    <SentResult modalClose={modalClose} sentConfirm={sentConfirm} shreddingDate={shreddingDate} authValue={authValue} dueDate={dueDate} customMshHandle={customMshHandle} recipientsValue={recipientsValue} sentFile={sentFile} title={title} receiptShow={receiptShow} />

                </Container>
            </section>

        </>
    )
}

export default SentContact