import React from 'react'
import { Modal } from 'react-bootstrap'

interface QrProps {
    handleQrClose: () => void;
    QrModalShow: boolean;
    setQrModal: (type: boolean) => void;
    qrImage: string;
}
const QrModal = ({ QrModalShow, setQrModal, handleQrClose, qrImage }: QrProps) => {
    return (
        <>
            <Modal show={QrModalShow}>
                <div className="QrImageBox">
                    <div className="appInstruction">
                        <h6>Set up Google Authenticator</h6>
                        <ul>
                            <li>Download the from your device app store</li>
                            <li>Install the app and sign-in using your google account</li>
                            <li>Tap the + icon to scan the QR code</li>
                        </ul>
                    </div>
                    <hr />
                    <div className="qrImageShow">
                        <h6>Please scan the QR code otherwise you can't recover the account</h6>
                        <img src={qrImage} alt="qr-code" />
                        <div className='d-flex justify-content-center mt-3'>
                            <button onClick={handleQrClose}>Close</button>
                        </div>
                    </div>
                </div>
            </Modal>
        </>
    )
}

export default QrModal