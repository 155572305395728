import React, { useEffect, useState } from 'react'
import { Modal, Row, Col, Table, Dropdown } from 'react-bootstrap';
import moment from 'moment'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRotateBack, faChevronDown, faCloudArrowDown, faDownload, faEye } from '@fortawesome/free-solid-svg-icons';
import { acceptFiles, fileReplace } from '../../api/files';
import SmallButton from '../../styleComponent/SmallButton/SmallButton';
import { filesDownloadApi, singleRequests } from '../../api/auth';
import Loader from '../CommonCompoment/Loader/Loader';
import { showNotifications } from '../../helpers/toaster';

interface informationProps {
    sumAttachmentSizeInMB: (items: any) => void;
    profileId: string;
    setInformationShow: any;
    informationShow: any;
    handleInformationClose: () => void;
    itemDownload: (attachmentObj: any) => void;
}
const SingleProfileModal = ({ itemDownload, informationShow, setInformationShow, handleInformationClose, profileId, sumAttachmentSizeInMB }: informationProps) => {
    const [showFilesIndex, setShowFilesIndex] = useState<number | null>(null);
    const [count, setCount] = useState<number>(0);
    const [spinLoading, setSpinLoading] = useState<boolean>(false);
    const [profileInformation, setProfileInformation] = useState<any>({});
    const [filesArray, setFilesArray] = useState<any>([]);

    useEffect(() => {
        singleRequests(profileId).then((data) => {
            setProfileInformation(data.body)
        })
    }, [profileId, count])


    const requestFileReplace = () => {

        fileReplace(profileInformation.requestId, { replacementFiles: filesArray }).then((data) => {
            if (data.statusCode !== 200) {
                showNotifications("error", data.message);
            }
            else {
                showNotifications("success", "Replacement send successfully!!");
                setCount(count + 1);
            }

        })
    }

    const requestFileAdd = (fileId: string) => {
        let newFile = {
            "fileId": fileId,
            "isMarkForReplacement": true,
            "replacementMessage": "This is optional value"
        }
        setFilesArray([...filesArray, newFile]);
    }

    const downloadFile = (fileId: string) => {
        filesDownloadApi(fileId).then((data) => {
            if (data.statusCode !== 200) {
                setSpinLoading(false)
            }
            else {
                window.location.href = data.body.downloadUrl;
                setSpinLoading(false)
            }
        })
    }

    function checkFileIdExists(fileId: string) {
        return filesArray.some((item: any) => item.fileId === fileId);
    }

    const filesAccept = (fileId: string) =>{
        let acceptBody={
            "isAccepted": true
        }
        acceptFiles(fileId,acceptBody).then((data) => {
            if (data.statusCode !== 200) {
                showNotifications("error",data.message)
            }
            else {
               showNotifications("success",data.message)
            }
        })
    }

    return (
        <>
            <Modal show={informationShow} className="recipientModalBody" onHide={handleInformationClose} size="lg" aria-labelledby="contained-modal-title-vcenter" centered>
                <Modal.Body >
                    <div className="requestModal">
                        <Row>
                            <Col md={12} className='itemProfile'>
                                <div className="infoTop">
                                    <h1>Title name</h1>
                                    <p>{profileInformation && profileInformation.title ? profileInformation.title : ''}</p>
                                </div>
                                <div className="infoTop">
                                    <h1>Due date (Optional)</h1>
                                    <p>{profileInformation && profileInformation.settings && profileInformation.settings.dueDate ? <>{moment(profileInformation.settings.dueDate).format('MMM DD YYYY')}</> : 'No Date'}</p>
                                </div>
                                <div className="infoTop">
                                    <button className='btn btn-download' onClick={() => itemDownload(profileInformation)}><FontAwesomeIcon icon={faCloudArrowDown} /></button>
                                    <h1>Size</h1>
                                    <p>{profileInformation && profileInformation.items ? <>{sumAttachmentSizeInMB(profileInformation.items)}</> : ''}</p>
                                </div>
                            </Col>
                            <Col md={7}>
                                <div className="requestInput">

                                    <div className="itemLine">
                                        <p>Item</p>
                                        <button>{profileInformation && profileInformation.items ? <>{profileInformation.items.length}</> : ''}</button>
                                    </div>
                                    <div className="itemSerial">
                                        <div className="replaceButton">
                                        <button className='mr-2' onClick={() => filesAccept(profileInformation.requestId)}>Accept All</button>
                                            <button onClick={requestFileReplace}>Replace File</button>
                                        </div>
                                        <Table responsive className='tableBody'>
                                            <thead>
                                                <tr className='profileView'>
                                                    <th>#</th>
                                                    <th>Item Name</th>
                                                    <th>Option</th>
                                                    <th>File</th>
                                                    <th className='initialFiles'>Action</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {profileInformation && profileInformation.items && profileInformation.items.map((data: any, index: number) =>
                                                    <>
                                                        <tr key={`profileList` + index} className='modalItemTable profileView'>
                                                            <td className='mark'>{index + 1}</td>
                                                            <td className='mark'>{data.itemName && data.itemName.length > 15 ? data.itemName.substring(0, 13) + '..' : data.itemName}</td>
                                                            <td className='text-bold'>{data.isRequired === false ?
                                                                'Optional' :
                                                                'Required'}</td>
                                                            <td className='text-left'>{data.files.length}</td>
                                                            <td className='initialFiles'>
                                                                <button onClick={() => setShowFilesIndex(showFilesIndex === index ? null : index)}><FontAwesomeIcon icon={faEye} /></button>
                                                            </td>
                                                        </tr>
                                                        {showFilesIndex === index && data.files.map((files: any, j: number) =>
                                                            <tr key={`filesReplace` + j} className='modalItemListTable'>
                                                                <td></td>
                                                                <td><FontAwesomeIcon icon={faChevronDown} /></td>
                                                                <td>{files.fileName.length > 7 ? `${files.fileName.substring(0, 7)}..` : files.fileName}</td>
                                                                <td>{files.extension}</td>
                                                                <td>
                                                                    <SmallButton className='mr-2' onClick={() => downloadFile(files.fileId)} customWidth="45" customHeight="24" customBackgroundColor='#f94a29' customBorderColor='#f94a29' customTextColor='#FFFFFF'><FontAwesomeIcon icon={faDownload} /></SmallButton>
                                                                    {files.isMarkForReplacement === true ? <SmallButton customWidth="75" customHeight="24" customBackgroundColor='#f94a29' customBorderColor='#f94a29' customTextColor='#FFFFFF'>Pending</SmallButton>
                                                                        : <>{checkFileIdExists(files.fileId) === true ? <SmallButton customWidth="45" customHeight="24" customBackgroundColor='#F4F4F4' customBorderColor='#f94a29' customTextColor='#f94a29'><FontAwesomeIcon icon={faArrowRotateBack} /></SmallButton>
                                                                            : <SmallButton onClick={() => requestFileAdd(files.fileId)} customWidth="45" customHeight="24" customBackgroundColor='#f94a29' customBorderColor='#f94a29' customTextColor='#FFFFFF'><FontAwesomeIcon icon={faArrowRotateBack} /></SmallButton>}</>}
                                                                </td>
                                                            </tr>
                                                        )}
                                                    </>
                                                )}
                                            </tbody>
                                        </Table>
                                    </div>
                                </div>
                            </Col>
                            <Col md={5}>
                                <div className='requestInput'>
                                    <div className="itemLine">
                                        <p>Recipents</p>
                                        <button>{profileInformation && profileInformation.recipients && profileInformation.recipients.users.length ? <>{profileInformation.recipients.users.length}</> : <></>}</button>
                                    </div>
                                    <div className="nameEmail">

                                        <div className="listEmail">
                                            {profileInformation && profileInformation.recipients && profileInformation.recipients.users.map((data: any, index: number) =>
                                                <div key={`email` + index} className="listEmail">
                                                    <p>{data.fullName}</p>
                                                    <span>{data.email}</span>
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                </div>
                            </Col>
                        </Row>
                    </div>
                    <div className="footerModalButton">
                        <button className='nextButton' onClick={() => setInformationShow(false)}>Back to dashboard</button>
                    </div>
                </Modal.Body>
            </Modal>
        </>
    )
}

export default SingleProfileModal