import React, { useState } from 'react'
import { useForm } from 'react-hook-form';
import { createContact } from '../../api/contact';
import { useNavigate } from 'react-router-dom';
import PhoneInput from 'react-phone-input-2';
import { handleForbiddenError, removeCookie } from '../CommonInterface/CommonFunction';

interface myContactProps {
    getMyContacts: (() => void);
    setSpinLoading?: any;
}
const CreateContact = ({ setSpinLoading, getMyContacts }: myContactProps) => {
    const navigate = useNavigate();
    const [contactError, setContactError] = useState("");
    const [phoneNumber, setPhoneNumber] = useState("");
    const {
        register,
        handleSubmit,
        setValue,
    } = useForm();
    //create new contact
    let onSubmit = (user: any) => {
        window.scrollTo({
            top: 0,
            behavior: "smooth"
        });
        const { email, firstName, lastName } = user;
        setSpinLoading(true)
        createContact([{ email, firstName, lastName, phoneNumber }]).then((data) => {
            if (data.statusCode !== 201) {
                if (data.statusCode === 403) {
                    handleForbiddenError(navigate);
                }
                else {
                    setContactError("This email already exist")
                    setSpinLoading(false)
                }
            }
            else {
                setValue("email", "", { shouldValidate: false });
                setValue("firstName", "", { shouldValidate: false });
                setValue("lastName", "", { shouldValidate: false });
                setPhoneNumber("");
                setContactError("")
                setTimeout(() => {
                    setSpinLoading(false)
                    getMyContacts();
                }, 500)

            }
        })
    }


    const handlePhoneChange = (value: string) => {
        setPhoneNumber(value);
    };
    return (
        <>
            <div className="addContactInput">
                <h1>Add New Contact</h1>

                <form onSubmit={handleSubmit(onSubmit)}>
                    <label htmlFor="requestTitle">First Name <sup className='asterisk'>*</sup></label>
                    <input type="text" id="firstName" placeholder='First Name' {...register("firstName", { required: true })} />
                    <label htmlFor="requestTitle">Last Name <sup className='asterisk'>*</sup></label>
                    <input type="text" id="lastName" placeholder='Last Name' {...register("lastName", { required: true })} />
                    <label htmlFor="requestTitle">Email Address <sup className='asterisk'>*</sup></label>
                    <input type="email" id="email" placeholder='Email Address' {...register("email", { required: true })} />
                    {contactError && contactError.length ? <p className='errMsg'>{contactError}</p> : ""}
                    <div className="phoneNo">
                        <label>Phone Number</label>
                        <PhoneInput value={phoneNumber} country={'us'} disableCountryCode={false} onChange={(value) => handlePhoneChange(value)} />
                    </div>
                    <div className="addContact mt-3">
                        <button type='submit' className='save'>Add</button>
                    </div>
                </form>

            </div>
        </>
    )
}

export default CreateContact