import React from 'react';
import "./ErrorPage.css";
import { NavLink } from 'react-router-dom';
import Button from '../../styleComponent/Button/Button';

const ErrorPage = () => {
    return (
        <>
            <div id="notfound">
                <div className="notfound">
                    <div className="notfound-404">
                        <h3>Oops! Page not found</h3>
                        <h1><span>4</span><span>0</span><span>4</span></h1>
                    </div>
                    <h2>we are sorry, but the page you requested was not found</h2>
                    <NavLink to="/"><Button className='registerBtn' customBackgroundColor="#F94A29" customBorderColor="#F94A29" customTextColor="#fff">Back To Home</Button></NavLink>
                </div>
            </div>
        </>
    )
}

export default ErrorPage