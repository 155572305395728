import React, { useState } from 'react'
import { updatePassword } from '../../../api/auth'
import { showNotifications } from '../../../helpers/toaster';
import { useNavigate } from 'react-router-dom';
// google font awesome
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons';
import { Modal } from 'react-bootstrap';

interface UpdateUserProps {
    email: string;
    handleClose: () => void;
    show: boolean;
    setShow: (type: boolean) => void;
    requiredPass: boolean;
}

const UpdatePassword = ({ show, setShow, requiredPass, handleClose, email }: UpdateUserProps) => {
    const navigate = useNavigate();
    const [passwordMatch, setPasswordMatch] = useState<boolean | null>(null);
    const [togglePassword, setTogglePassword] = useState(false);
    const [passwordValid, setPasswordValid] = useState<string[]>([]);
    const [toggleType, setToggleType] = useState("password");
    const [formData, setFormData] = useState({
        password: '',
        confirmPassword: '',
    });
    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value,
        });
        if (name === 'confirmPassword') {
            setPasswordMatch(formData.password === value);
        } else if (name === 'password' && passwordMatch === false) {
            setPasswordMatch(null);
        }
    };
    // handlePasswordBlur
    const handlePasswordBlur = (event: React.ChangeEvent<HTMLInputElement>) => {

        const passwordValid: string[] = [];
        if (event.target.value.length < 8) {
            passwordValid.push("Password must be at least 8 characters");
        }
        if (!/\d/.test(event.target.value)) {
            passwordValid.push("Password must contain at least one number");
        }
        if (!/[a-z]/.test(event.target.value)) {
            passwordValid.push("Password must contain at least one lowercase letter");
        }
        if (!/[A-Z]/.test(event.target.value)) {
            passwordValid.push("Password must contain at least one uppercase letter");
        }
        setPasswordValid(passwordValid);
        const { name, value } = event.target;
        setFormData({
            ...formData,
            [name]: value,
        });
        if (name === 'confirmPassword') {
            setPasswordMatch(formData.password === value);
        } else if (name === 'password' && passwordMatch === false) {
            setPasswordMatch(null);
        }
    }
    const showPass = () => {
        setTogglePassword(!togglePassword)
        setToggleType("password")
    }

    const hidePass = () => {
        setTogglePassword(!togglePassword)
        setToggleType("")
    }
    const confirmCheck = () => {
        let newPass = {
            "email": email,
            "password": formData.confirmPassword
        }

        if (passwordMatch) {
            updatePassword(newPass).then((data) => {
                if (data.statusCode !== 200) {
                    showNotifications('error', data.message);
                }
                else {
                    showNotifications('success', data.message);
                    setShow(false);
                    return navigate("/login");
                }
            })
        }
        else {
            showNotifications('error', "Incorrect Password !!");
        }
    }
    return (
        <>
            <Modal show={show} onHide={handleClose}>
                <div className='passwordCheck' style={{ padding: "20px" }} >
                    <h1>Enter New Password</h1>
                    <div>
                        <label htmlFor="password" className='textColor'>Password:</label>
                        <div className="loginInput">
                            <input
                                type={toggleType}
                                id="password"
                                name="password"
                                value={formData.password}
                                onChange={handlePasswordBlur}
                            />

                            {togglePassword ? <span onClick={() => showPass()}><FontAwesomeIcon icon={faEye} /> </span> : <span onClick={() => hidePass()}><FontAwesomeIcon icon={faEyeSlash} /></span>}
                        </div>
                        {requiredPass ? <>{
                            passwordValid.length > 0 &&
                                passwordValid.map((error, index) => (
                                    <p key={index} className='errMsg'>
                                        {error}
                                    </p>
                                ))
                        }</> : ""}

                    </div>

                    <div className="mt-4">
                        <label htmlFor="confirmPassword" className='textColor'>Confirm Password:</label>
                        <div className="loginInput">
                            <input
                                type={toggleType}
                                id="confirmPassword"
                                name="confirmPassword"
                                value={formData.confirmPassword}
                                onChange={handleChange}
                            />

                            {togglePassword ? <span onClick={() => showPass()}><FontAwesomeIcon icon={faEye} /> </span> : <span onClick={() => hidePass()}><FontAwesomeIcon icon={faEyeSlash} /></span>}
                        </div>
                        {passwordMatch === false && (
                            <p className='errMsg'>Password and Confirm Password do not match.</p>
                        )}
                    </div>



                    <div className="recoveryBtn text-center">
                        <button onClick={confirmCheck}>Confirm</button>
                    </div>
                </div>
            </Modal>
        </>
    )
}

export default UpdatePassword