import styled from 'styled-components';

type Props = {
  customBackgroundColor: string,
  customBorderColor: string,
  customTextColor: string,
  customWidth: string,
  customHeight: string
}

export default styled.button<Props>`

background: ${(props) => props.customBackgroundColor};
border: 1px solid ${(props) => props.customBorderColor};
border-radius: 5px;
font-family: 'Inter';
font-style: normal;
font-weight: 500;
font-size: 12px;
text-align: center;
color: ${(props) => props.customTextColor};
width: ${(props) => props.customWidth}px;
height: ${(props) => props.customHeight}px;
white-space: nowrap;
`