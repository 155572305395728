import React from 'react';
import Layout from '../CommonCompoment/Layout/Layout';
import { Col, Container, Row } from 'react-bootstrap';
import "../Privacy/Privacy.css";
import { Link } from 'react-router-dom';

const TermsOfService = () => {
  return (
    <Layout>
         <section className='privacyPage'>
                <Container>
                    <Row>
                        <Col md={12}>
                            <div className="privacyHeading">
                                <h1>TERMS OF SERVICE</h1>
                                <h2>Effective December 12, 2023</h2>
                                <p>Welcome to Docoteam, Inc (“Docoteam,” "company," "we," "us," or "our"). These Terms of Service ("Terms") govern your use of Docoteam's services (including, but not limited to what is described below), and other related services (collectively, the "Services"). By accessing or using the Services, you agree to comply with and be bound by these Terms. If you do not agree to these Terms, please do not use the Services.</p>
                            </div>
                            <div className="privacyContent">
                                <h1>Description of Services</h1>
                                <p>Docoteam provides a range of cloud-based storage and document management services. Some of these services include, but are not limited to, allowing users to upload, share, request, store, and download documents. These services can be accessed through our website at <Link to="/">www.docoteam.com.</Link></p>
                            </div>

                            <div className="privacyContent">
                                <h1>User Accounts</h1>
                                <p className='mt-2'>In order to use the Services, you must create an account, agreeing to provide accurate and complete information during the registration process. You are responsible for maintaining the confidentiality of your account and password, as well as for all activities under your account. Promptly notify us of any unauthorized account or password use. We disclaim liability for any loss or damage resulting from failure to comply with these obligations.</p>
                                <p className='mt-2'>Furthermore, you may not use another person's account without permission, nor create multiple accounts to abuse the system or evade account suspension or termination. Your access to certain Service features may necessitate accurate, current, and complete information during registration, with an ongoing responsibility for maintaining account confidentiality and overseeing all activities under your account.</p>

                            </div>

                            <div className="privacyContent">
                                <h1>Your Use of the Services</h1>
                                <p  className='mt-2'>You agree to use the Services only for lawful purposes and in strict adherence to all prevailing laws and regulations. Your use must not involve the uploading, storage, or sharing of any content that is deemed illegal, infringing, or harmful to others. This includes, but is not limited to, content that is copyrighted, trademarked, defamatory, pornographic, or constitutes hate speech.</p>
                                <p  className='mt-2'>You may not use the Services for the uploading, storage, or sharing of content that contains malware or other detrimental code. Moreover, your use must not encompass activities that disrupt or interfere with the seamless operation of the Services or the associated servers and networks.</p>
                                <p  className='mt-2'>You bear full responsibility for all content uploaded, stored, or shared through our Services. By doing so, you affirm and guarantee that you possess all requisite rights and licenses for the content you upload, store, or share via the Services.</p>
                                <p  className='mt-2'>While Docoteam assumes no obligation to routinely monitor the content you upload, store, or share, it retains the right to do so. This right is exercised for the purpose of upholding these Terms, fulfilling legal obligations, or safeguarding the rights, property, or safety of Docoteam, our users, or others.</p>
                            </div>

                            <div className="privacyContent">
                                <h1>Fees and Payment</h1>
                                <p>Some of our Services may require payment of fees. By using those Services, you agree to pay the fees specified. All fees are exclusive of applicable taxes, and you are responsible for paying any taxes applicable to your use of the Services.</p>
                            </div>

                            <div className="privacyContent">
                                <h1>Termination of Services</h1>
                                <p className='mt-2'>We reserve the right to terminate your account or access to the Services at our discretion, effective at any time and for any reason, including, but not limited to, the violation of these Terms or any other grounds deemed appropriate in our sole judgment. Additionally, your account or access to the Services may be terminated in the event of prolonged inactivity.</p>
                                <p className='mt-2'>In the event of termination, we assume no liability, whether to you or any third party, for the cessation of your account or access to the Services. This termination authority is absolute and does not create any obligation, expressed or implied, to provide notice or justification for such actions.</p>
                            </div>

                            <div className="privacyContent">
                                <h1>Intellectual Property</h1>
                                <p>The content, features, and functionality of the Services, including but not limited to text, graphics, logos, icons, and images, are the exclusive property of Docoteam and are protected by intellectual property laws.</p>
                            </div>

                            <div className="privacyContent">
                                <h1>Limitation of Liability</h1>
                                <p>To the fullest extent permitted by law, Docoteam and its affiliates, officers, employees, agents, partners, and licensors shall not be liable for any indirect, incidental, special, consequential, or punitive damages, or any loss of profits or revenues, whether incurred directly or indirectly, or any loss of data, use, goodwill, or other intangible losses resulting from:</p>
                            <ul>
                                <li>Your use or inability to use the Services.</li>
                                <li>Any unauthorized access to or use of our servers and/or any personal information stored therein.</li>
                                <li>Any interruption or cessation of transmission to or from the Services.</li>
                            </ul>
                            </div>

                            <div className="privacyContent">
                                <h1>Dispute Resolution</h1>
                                <p>Any dispute arising out of or in connection with these Terms or the Services shall be resolved through binding arbitration in accordance with the rules of the American Arbitration Association.</p>
                            </div>

                            <div className="privacyContent">
                                <h1>Modifications and Discontinuation of Services</h1>
                                <p>We reserve the right to modify or discontinue the Services at any time and without notice. We assume no liability, either to you or any third party, for any such modifications or discontinuation of the Services. This authority extends without limitation and does not incur any obligation to furnish advanced notification or rationale for such actions.</p>
                            </div>

                            <div className="privacyContent">
                                <h1>Warranty Disclaimer</h1>
                                <h6>WE PROVIDE OUR SERVICES “AS IS” AND “AS AVAILABLE” WITHOUT ANY EXPRESS OR IMPLIED WARRANTIES, INCLUDING THE IMPLIED WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, AND NON-INFRINGEMENT. FOR EXAMPLE, WE DON’T MAKE ANY WARRANTIES ABOUT THE CONTENT OR FEATURES OF THE SERVICES, INCLUDING THEIR ACCURACY, RELIABILITY, AVAILABILITY, OR ABILITY TO MEET YOUR NEEDS.</h6>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </section>
    </Layout>
  )
}

export default TermsOfService