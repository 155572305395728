import React, { useEffect, useState } from 'react';
import { groupList } from '../../api/group';
import { Modal } from 'react-bootstrap';
import { GroupData } from '../CommonInterface/CommonInterface';
import { handleForbiddenError } from '../CommonInterface/CommonFunction';
import { useNavigate } from 'react-router-dom';

interface ListGroupProps {
    handleGroupListClose: () => void;
    groupListShow: boolean;
    setGroupID: (type: string) => void;
    count: number;
    setGroupName: (type: string) => void;
    groupName: string;
}
const GroupList = ({ groupName, count, groupListShow, handleGroupListClose, setGroupID, setGroupName }: ListGroupProps) => {
    const [group, setGroup] = useState<GroupData[]>([]);
    const navigate = useNavigate();

    useEffect(() => {
        groupList().then((data) => {
            if (data.statusCode != 200) {
                if (data.statusCode === 403) {
                    handleForbiddenError(navigate);
                  }
            }
            else {
                setGroup(data.groups);
            }

        })
    }, [count])

    return (
        <>
            <Modal show={groupListShow} onHide={handleGroupListClose} centered size="sm">
                <div className="groupModal">
                    {group && group.length > 0 ? <h1>Group List</h1> : <></>}
                    <div className='groupListItem'>
                        {group && group.length === 0 ? <h1>No Group List</h1> : <>
                            <div className="radio-list">
                                {group && group.map((data, i) =>
                                    <label key={`group` + i} className="timeCheckbox groupNameList">
                                        {data.groupName}
                                        <input type="radio" name="group" onChange={() => { setGroupID(data.groupId), setGroupName(data.groupName) }} checked={groupName === data.groupName ? true : false} />
                                        <span className="checkmark"></span>
                                    </label>
                                )}
                            </div>
                        </>}
                    </div>
                    <div className="addContactBtn d-flex justify-content-center">
                        <div className="addContact">
                            {group && group.length === 0 ? <button type='submit' className='save' onClick={handleGroupListClose}>Close</button> :
                                <><button type='submit' className='save' onClick={handleGroupListClose}>Save</button></>}

                        </div>
                    </div>
                </div>
            </Modal>
        </>
    )
}

export default GroupList