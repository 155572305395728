import { get, patch, post, verifyGet, itemPost, del, put, uploadGet, fileDel, allGet } from './base-api';

//get user signup info
export const createUser = (data: any) => {
    return post(`/users/create`, data);
};
// check mail
export const checkMail = (createEmail: string) => {
    return get(`/users/check/mail/${createEmail}`);
};
// forget password
export const forgetPasswordCheck = (emailPhone: string, body = {}) => {
    return post(`/users/forget-password/${emailPhone}`, body);
};

// email verify code
export const codeVerify = (userInfo: any) => {
    return post(`/users/code-verify/forget-password`, userInfo);
};
// update password
export const updatePassword = (updatePasswordObj: any) => {
    return put(`/users/update/password`, updatePasswordObj);
};
// user login
export const login = (user: any) => {
    return post(`/users/login`, user);
};

//save jwt to local storage
export const authenticate = (data: any, next: any) => {
    if (typeof document !== "undefined") {
        document.cookie = `docoteam=${JSON.stringify(data)}; path=/`;
        next();
    }
};

export const isAuthenticate = () => {
    if (typeof document === "undefined") {
        return false;
    }
    const cookieValue = document.cookie
        .split('; ')
        .find(row => row.startsWith('docoteam='));
    if (cookieValue) {
        return JSON.parse(decodeURIComponent(cookieValue.split('=')[1]));
    } else {
        return false;
    }
};

// request file
export const requests = (request: any) => {
    return post(`/requests`, request);
};
// all request
export const allRequests = (limit: number, page: number, filterTag: string, recipientArr: any[]) => {
    const recipientQueryParam = encodeURIComponent(JSON.stringify(recipientArr));
    const isDeletedParam = filterTag === 'delete' ? '&isDeleted=true' : '';
    return get(`/requests/myrequests?limit=${limit}&page=${page}&filterTag=${filterTag}&recipientEmails=${recipientQueryParam}${isDeletedParam}`,);
    // 
};

// single  request
export const singleRequests = (singleRequestId: string) => {
    return get(`/requests/${singleRequestId}`);
};
// update template load
export const updateRequest = (requestId: string, templateObj: any) => {
    return put(`/requests/${requestId}`, templateObj);
};

// delete  request
export const deleteRequestsApi = (deleteRequestId: string) => {
    return del(`/requests/${deleteRequestId}`);
};
export const permanentlyDeleteRequest = (deleteSentId: string) => {
    return del(`/requests/${deleteSentId}?deletePermanently=true`);
};

// save template
export const saveTemplate = (templateBody: any) => {
    return post(`/request-templates`, templateBody);
};

// load template
export const loadTemplate = () => {
    return get(`/request-templates/myrequest-templates?limit=10&page=1`);
};

// single template
export const singleTemplate = (templateId: string) => {
    return get(`/request-templates/${templateId}`);
};
// delete template
export const deleteTemplateApi = (deleteRequestId: string) => {
    return del(`/request-templates/${deleteRequestId}`);
};

// update template load
export const updateTemplate = (templateId: string, templateObj: any) => {
    return put(`/request-templates/${templateId}`, templateObj);
};
// my contact list
// export const myContact = () => {
//     return get(`/contacts/mycontacts/?limit=100&page=1`);
// };

// my contact list
export const verifyCode = (verifyCode: any, jwt: string) => {
    return verifyGet(`/requests/verify-code/${verifyCode}`, jwt);
};


// item upload
export const itemUpload = (itemObj: any, token: string) => {
    return itemPost(`/item-upload`, itemObj, token);
};

// save template
export const attachmentItem = (attachmentObj: any) => {
    return post(`/item-attachment`, attachmentObj);
};

// item download

export const itemDownloadApi = (itemDownloadObg: any) => {
    return post(`/requests/item-download`, itemDownloadObg);
};

// attachment download

export const attachmentDownloadApi = (attachmentDownloadObg: any) => {
    return post(`/item-attachment/download`, attachmentDownloadObg);
};

// attachment download new

export const attachmentDownloadUrlApi = (attachmentDownloadId: string, tokenId: string) => {
    return uploadGet(`/item-attachment/downloadUrl/${attachmentDownloadId}`, tokenId);
};

// complete upload
export const completeUploadApi = (attachmentDownloadId: string) => {
    return post(`/item-attachment/completeUpload/${attachmentDownloadId}`);
};
// file sent complete upload
export const sentCompleteUploadApi = (uploadId: string) => {
    return post(`/files/completeUpload/${uploadId}`);
};


// upload complete
export const uploadCompletedApi = (itemId: any, token: string) => {
    return itemPost(`/item-upload/completed`, itemId, token);
};

// single user
export const singleUser = (userId: string, fetchOccupiedStorage = false) => {
    return get(`/users/${userId}?occupiedStorage=${fetchOccupiedStorage}`);
};

// files upload url
export const fileCreateUrl = (fileUploadObj: any, token: string) => {
    return itemPost(`/files/createAndInitiateUpload`, fileUploadObj, token);
};

// files upload url
export const filesCreateUrl = (fileUploadObj: any,) => {
    return post(`/files/createAndInitiateUpload`, fileUploadObj);
};

// files complete upload
export const filesUploadApi = (itemId: string, token: string) => {
    return itemPost(`/files/completeUpload/${itemId}`, {}, token);
};
// files complete upload
export const fileUploadApi = (itemId: string) => {
    return post(`/files/completeUpload/${itemId}`, {});
};


// files download
export const filesDownloadApi = (filesId: string, downloadAs?: string) => {
    return get(`/files/downloadUrl/${filesId}${downloadAs ? `?downloadAs=${downloadAs}` : ''}`);
};
// files download api
export const fileDownloadApi = (filesId: string, token: string) => {
    return uploadGet(`/files/downloadUrl/${filesId}`, token);
};
// delete  request
export const deleteSentApi = (deleteSentId: string) => {
    return del(`/sendFiles/${deleteSentId}`);
};
export const permanentlyDeleteSent = (deleteSentId: string) => {
    return del(`/sendFiles/${deleteSentId}?deletePermanently=true`);
};
// mark as complete
export const markCompletedApi = (requestId: string, tokenId: string) => {
    return itemPost(`/requests/completed/${requestId}`, {}, tokenId);
};

// upload item list
export const uploadRequestApi = (requestId: string, tokenId: string) => {
    return uploadGet(`/requests/${requestId}`, tokenId);
};


// permanent delete file
export const deleteFiles = (fileId: string, tokenId: string) => {
    return fileDel(`/files/${fileId}?deletePermanently=true`, {}, tokenId);
};

// send file
export const sendFileApi = (sendFileObj: any) => {
    return post(`/sendFiles`, sendFileObj);
};
// files upload url 
// export const filesUploadUrlApi = (filesId: string) => {
//     return get(`/files/uploadUrl/${filesId}`);
// };
// download code verify
export const downloadCodeVerifyApi = (verifyCode: string, tokenId: string) => {
    return verifyGet(`/sendFiles/verify-code/${verifyCode}`, tokenId);
};

// single sent file
export const singleSentFileApi = (sentId: string, tokenId: string) => {
    return uploadGet(`/sendFiles/${sentId}`, tokenId);
};

// all sent request
export const allSent = (limit: number, page: number, filterTag: string, recipientArr: any[]) => {
    const recipientQueryParam = encodeURIComponent(JSON.stringify(recipientArr));
    const isDeletedParam = filterTag === 'delete' ? '&isDeleted=true' : '';
    return get(`/sendFiles/mySendFileRequests?limit=${limit}&page=${page}&filterTag=${filterTag}&recipientEmails=${recipientQueryParam}${isDeletedParam}`);
};
// QR code
export const qrGenerate = (body={}) => {
    return post(`/users/login-mfa-code`, body);
};