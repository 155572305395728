import React, { useState, useEffect } from 'react'
import { Col, Container, Row, Tab, Tabs } from 'react-bootstrap';
// google font awesome
import Layout from '../CommonCompoment/Layout/Layout';
import "./Pricing.css";
import PriceTable from './PriceTable';
import { getAllPackage } from '../../api/package';
import { PackageInterface } from '../CommonInterface/CommonInterface';
import { Link, useNavigate } from 'react-router-dom';
import { getCookieValue, getPriceByName, setCookie } from '../CommonInterface/CommonFunction';

const Pricing = () => {
  const navigate = useNavigate();
  const [packagePrice, setPackagePrice] = useState<PackageInterface[] | undefined>();
  const [apiCalled, setApiCalled] = useState(false);
  const [billingType, setBillingType] = useState("Monthly");
  const storedPaymentPlan = JSON.parse(getCookieValue("choosePlan") || '{}');

  useEffect(() => {
    if (!apiCalled) {
      getAllPackage().then((data) => {
        setPackagePrice(data.packages);
        setApiCalled(true);
      })
    }
  }, []);

  const choosePlan = (plan: string) => {
    setBillingType(plan)
    if (storedPaymentPlan) {
      storedPaymentPlan.billingType = plan;
      setCookie('choosePlan', JSON.stringify(storedPaymentPlan));
    }
  }

  const chooseZeroPlan = (plan: string) => {
    if (storedPaymentPlan) {
      storedPaymentPlan.price = plan;
      storedPaymentPlan.billingType = "Monthly";
      setCookie('choosePlan', JSON.stringify(storedPaymentPlan));
    }
    return navigate(`/registration`);
  }

  const selectChoosePlan = (plan: string) => {
    if (storedPaymentPlan) {
      storedPaymentPlan.price = plan;
      storedPaymentPlan.billingType = billingType;
      setCookie('choosePlan', JSON.stringify(storedPaymentPlan));
    }
    return navigate(`/registration`);
  }


  return (
    <Layout>
      <section className="paymentInfo">
        <Container>
          <Row>
            <Col md={12}>
              <div className="paymentHeading">
                <h1>Secure file travel - and so much more</h1>
              </div>
            </Col>
            <Col md={12}>
              <div className="paymentFeature">
                <p onClick={() => chooseZeroPlan("FREE")}>Sign up for a free trial</p>
                <h2>Compare all features</h2>
              </div>
            </Col>
          </Row>
          <Row className='d-flex justify-content-center'>
            <Col md={10}>
              <div className="coreFeature">
                <Tabs
                  defaultActiveKey="monthly"
                  id="uncontrolled-tab-example"
                  className="priceTab"
                  onSelect={(key: string | null) => {
                    if (key) {
                      choosePlan(key === 'monthly' ? 'Monthly' : 'Annually');
                    }
                  }}
                >
                  <Tab className='monthly' eventKey="monthly" title="Monthly">
                    <Row>
                      <PriceTable selectChoosePlan={selectChoosePlan} packagePrice={packagePrice} billingType={billingType} standardMin={getPriceByName(packagePrice, 'STANDARD', 0, 1)} standardMax="" plusMin={getPriceByName(packagePrice, 'PLUS', 0, 1)} plusMax="" />
                    </Row>
                  </Tab>
                  <Tab className='yearly' eventKey="yearly" title="Yearly">
                    <Row>
                      <PriceTable selectChoosePlan={selectChoosePlan} packagePrice={packagePrice} billingType={billingType} standardMin={getPriceByName(packagePrice, 'STANDARD', 5, 1)} standardMax={getPriceByName(packagePrice, 'STANDARD', 0, 1)} plusMin={getPriceByName(packagePrice, 'PLUS', 5, 1)} plusMax={getPriceByName(packagePrice, 'PLUS', 0, 1)} />
                    </Row>
                  </Tab>
                </Tabs>
              </div>
            </Col>
          </Row>
          <Row>
            <Col md={12}>
              <div className="pricingContent">
                <p>Business Standard, and Business Plus plans can be purchased for a maximum of 300 users. There is no minimum or maximum user limit for Enterprise plans.</p>
                <p>Docoteam provides flexible pooled storage per user that is shared across the organization. Enterprise plan customers have custom limits of pooled storage per user and can request additional storage if needed by contacting Docoteam support.</p>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
      <div className="freePrice">
        <h1>Try Docoteam for Free</h1>
        <p>See why businesses love and rely on Docoteam for their operational and business needs.</p>
        <Link to="/registration" onClick={() => chooseZeroPlan("FREE")} className='getStarted'>Get Started</Link>
      </div>
    </Layout>
  )
}

export default Pricing