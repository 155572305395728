import React, { useState, useRef } from 'react'
import { Modal, Table } from 'react-bootstrap'
import { createContact } from '../../api/contact';
import { CsvDataInterface, CsvFileInterface } from '../CommonInterface/CommonInterface';
import { showNotifications } from '../../helpers/toaster';
import { ToastContainer } from 'react-toastify';
import dragAndDrop from '../../assets//dragDrop/dragDrop.png';
import { splitNameAndExtension } from '../../helpers/generalUtil';
import SmallButton from '../../styleComponent/SmallButton/SmallButton';
import { v4 as uuidv4 } from 'uuid';
import { useNavigate } from 'react-router-dom';
import { handleForbiddenError } from '../CommonInterface/CommonFunction';
import { CSVLink } from 'react-csv';

interface AddExportProps {
  handleExportClose: () => void;
  exportShow: boolean;
  setExportShow: (type: boolean) => void;
  setCount: (type: number) => void;
  count: number;
}
const ExportFile = ({ count, setCount, exportShow, setExportShow, handleExportClose }: AddExportProps) => {
  const [csvData, setCsvData] = useState<CsvDataInterface[]>();
  const wrapperRef = useRef<HTMLInputElement>(null);
  const [attachmentList, setAttachmentList] = useState<CsvFileInterface[] | undefined>([]);
  const navigate = useNavigate();

  function removeEmptyObjects(data: CsvDataInterface[]) {
    return data.filter((item) => {
      return Object.values(item).some(value => value !== '');
    });
  }
  function removeExtraQuotes(data: any[]): any[] {
    return data.map((item) => {
      const cleanedItem: any = {};
      for (const key in item) {
        if (Object.prototype.hasOwnProperty.call(item, key)) {
          cleanedItem[key] = item[key].replace(/^"(.*)"$/, '$1');
        }
      }
      return cleanedItem;
    });
  }

  const handleFileChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    if (file) {
      const fileNameExtension = splitNameAndExtension(file.name)
      const results: any[] = [];
      const reader = new FileReader();

      reader.onload = () => {
        const csvText = reader.result as string;
        const lines = csvText.split('\n');
        const headers = lines[0].split(',');

        for (let i = 1; i < lines.length; i++) {
          const data: any = { firstName: '', lastName: '', email: '', isShared: '', groupIdentifier: '' };
          const values = lines[i].split(',');
          for (let j = 0; j < headers.length; j++) {
            const header = headers[j].trim();
            const value = values[j] ? values[j].trim() : '';

            if (header === '"First Name"' || header === 'First Name') {
              data.firstName = value;
            } else if (header === 'Last Name' || header === '"Last Name"') {
              data.lastName = value;
            } else if (header === 'Email' || header === '"Email"') {
              data.email = value;
            } else if (header === 'Share' || header === '"Share"') {
              data.isShared = value;
            } else if (header === 'Group' || header === '"Group"') {
              data.groupIdentifier = value;
            }

          }
          results.push(data);
        }
        const emptyObject = removeEmptyObjects(results);
        const removeQuotes = removeExtraQuotes(emptyObject);
        setCsvData(removeQuotes);
      };
      reader.readAsText(file);
      let fileId = uuidv4();
      let fileObj: CsvFileInterface = {
        "fileId": fileId,
        "extension": fileNameExtension.extension,
        "originalFileName": file.name,
        "size": file.size
      }
      setAttachmentList([...attachmentList!, fileObj]);
    }
  };


  const addContacts = () => {
    createContact(csvData).then((data) => {
      if (data.statusCode !== 201) {
        if (data.statusCode === 403) {
          handleForbiddenError(navigate);
        }
        else {
          showNotifications("error", data.message);
        }
      }
      else {
        showNotifications("success", data.message);
        setCount(count + 1);
        setExportShow(false);
        setAttachmentList([]);
      }
    })
  }
  // file remove
  const fileRemove = (fileId: string) => {
    const filteredItems = attachmentList?.filter(item => item.fileId !== fileId);
    setAttachmentList(filteredItems)
  }

  const contactData = [
    { 'First Name': '', 'Last Name': '', Email: '', Share: '', Group: '' },
  ];

  return (
    <Modal show={exportShow} onHide={handleExportClose} centered>
      <ToastContainer />
      <div className="exportFileBox">
        <h1>Import</h1>
        <p>Please upload a CSV file containing the following information: <b>First Name</b>, <b>Last Name</b>, <b>Email</b>, <b>Share</b> and <b>Group</b>. The 'Share' option is not mandatory.</p>
        <div className='contactTemplate'>

          <CSVLink data={contactData} filename="contacts.csv">
            <button>Download template</button>
          </CSVLink>
        </div>
        <div ref={wrapperRef} className="drop-file-input">
          <div className="drop-file-input__label">
            <img src={dragAndDrop} alt="" />
            <p>Drag & Drop your CSV files here</p>
          </div>
          <input type="file" value="" accept=".csv" onChange={handleFileChange} />
        </div>

        {attachmentList && attachmentList.length ? <>
          <Table responsive className='tableBody'>
            <thead>
              <tr>
                <th>File Name</th>
                <th>Extension</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              {attachmentList && attachmentList.length ? attachmentList.map((item, index: number) => (
                <tr key={`contactCSV` + index} className="fileUploadText">
                  <td>{item.originalFileName.length > 20 ? item.originalFileName.substring(0, 18) + '..' : item.originalFileName}</td>
                  <td>{item.extension}</td>
                  <td className='tableInnerButton'>
                    <SmallButton customWidth="71" customHeight="25" customBackgroundColor='#F94A29' customBorderColor='#F94A29' customTextColor='#FFF' onClick={() => fileRemove(item.fileId)}>Remove</SmallButton>
                  </td>
                </tr>
              )) : ""}
            </tbody>
          </Table>
        </> : <></>}

        <div className='text-center mt-3 mb-4 fileSave'>
          <button onClick={addContacts}>Save</button>
        </div>

      </div>
    </Modal>
  )
}

export default ExportFile